import React, { useState, useEffect } from "react";
import { inputFields, tables, GetProPopup } from "../../components";

const Programs = (props) => {
  const data = props?.uniData;

  const [programs, setPrograms] = useState([]);
  const [sortedInfoPrograms, setSortedInfoPrograms] = useState({});
  const [sortedInfoSubRanking, setSortedInfoSubRanking] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    const filteredPrograms = data?.programs?.filter((program) =>
      program?.programName?.toLowerCase()?.startsWith(search.toLowerCase())
    );
    setPrograms(filteredPrograms);
  }, [data, search]);

  const handleChangePrograms = (pagination, filters, sorter) => {
    setSortedInfoPrograms(sorter);
  };

  const handleChangeSubRanking = (pagination, filters, sorter) => {
    setSortedInfoSubRanking(sorter);
  };

  const programColumns = [
    // {
    //   title: "#",
    //   dataIndex: "serial",
    //   key: "0",
    //   width: 50,
    //   render: (text, record, index) => <div>{index + 1}</div>,
    // },
    {
      title: "Degree Name",
      key: "1",
      sorter: (a, b) => a?.programName?.localeCompare(b?.programName),
      width: 180,
      defaultSortOrder: "ascend",
      sortOrder:
        sortedInfoPrograms?.columnKey === "1"
          ? sortedInfoPrograms?.order
          : null,
      render: (text, record) => <div>{record?.programName ?? "NA"}</div>,
    },
    {
      title: "Type",
      key: "2",
      width: 100,
      sorter: (a, b) =>
        a?.programType?.programType?.localeCompare(b?.programType?.programType),
      sortOrder:
        sortedInfoPrograms?.columnKey === "2"
          ? sortedInfoPrograms?.order
          : null,
      render: (text, record) => (
        <div>{record?.programType?.programType ?? "NA"}</div>
      ),
    },
    {
      title: "Duration",
      key: "3",
      width: 80,
      sorter: (a, b) => a?.duration?.localeCompare(b?.duration),
      sortOrder:
        sortedInfoPrograms?.columnKey === "3"
          ? sortedInfoPrograms?.order
          : null,
      render: (text, record) => <div>{record?.duration ?? "NA"}</div>,
    },
    {
      title: "Fees",
      key: "4",
      width: 100,
      sorter: (a, b) => a?.tuitionFee?.localeCompare(b?.tuitionFee),
      sortOrder:
        sortedInfoPrograms?.columnKey === "4"
          ? sortedInfoPrograms?.order
          : null,
      render: (text, record) => <div>{record?.tuitionFee ?? "NA"}</div>,
    },
  ];

  const subRankingColumns = [
    // {
    //   title: "#",
    //   dataIndex: "serial",
    //   key: "0",
    //   width: 50,
    //   render: (text, record, index) => <div>{index + 1}</div>,
    // },
    {
      title: "Subject Name",
      key: "1",
      width: 180,
      render: (text, record) => <div>{record?.subject ?? "NA"}</div>,
    },
    {
      title: "Ranking",
      key: "2",
      width: 100,
      // sorter: (a, b) => {
      //   const aRanking = parseInt(a?.ranking?.split("-")[0], 10) || 0;
      //   const bRanking = parseInt(b?.ranking?.split("-")[0], 10) || 0;
      //   return aRanking - bRanking;
      // },
      // defaultSortOrder: "ascend",
      // sortOrder:
      //   sortedInfoSubRanking?.columnKey === "2"
      //     ? sortedInfoSubRanking?.order
      //     : "ascend",
      render: (text, record) => <div>{record?.ranking ?? "NA"}</div>,
    },
  ];

  return (
    <div className="relative">
      {props?.blur && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div
        className={`grid grid-cols-5 gap-[50px] ${
          props?.blur && "blur pointer-events-none"
        }`}
      >
        <div className="col-span-3">
          <div className="flex bg-main rounded-t-xl px-3 py-[12px] justify-center items-center">
            <inputFields.DefaultSearchBox
              iconposition="right"
              paddingy="8px"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="border border-gray-200">
            <tables.UniProgramsTable
              columns={programColumns}
              data={programs}
              rowKey={() => Math.random()}
              onChange={handleChangePrograms}
              scroll={{ y: 600 }}
              // pagination={true}
              onRow={(record) => ({
                onClick: () => {
                  window.open(`${record?.programLink}`, "_blank");
                },
              })}
            />
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex bg-main rounded-t-xl px-[12px] py-[20px] justify-center items-center text-[18px] text-white font-semibold">
            Subject Rankings
          </div>
          <div className="border border-gray-200">
            <tables.UniProgramsTable
              columns={subRankingColumns}
              data={data?.subjectRanking}
              rowKey={() => Math.random()}
              onChange={handleChangeSubRanking}
              scroll={{ y: 600 }}
              cursornormal={true}
              // pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
