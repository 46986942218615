import {
  ADD_OR_REMOVE_STAY_CONNECTED,
  ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS,
  ADD_OR_REMOVE_STAY_CONNECTED_FAILURE,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES_GRID,
  GET_UNIVERSITIES_GRID_SUCCESS,
  GET_UNIVERSITIES_GRID_FAILURE,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
  COMPARE_UNIVERSITIES,
  COMPARE_UNIVERISTIES_SUCCESS,
  COMPARE_UNIVERISTIES_FAILURE,
  GET_UNIS_BY_BUDGET,
  GET_UNIS_BY_BUDGET_SUCCESS,
  GET_UNIS_BY_BUDGET_FAILURE,
  GET_POPULAR_COUNTRIES,
  GET_POPULAR_COUNTRIES_SUCCESS,
  GET_POPULAR_COUNTRIES_FAILURE,
} from "../constants";

export default class UniversityAction {
  static getUniversities(payload, cb) {
    return {
      type: GET_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getUniversitiesSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getUniversitiesFailure(payload) {
    return {
      type: GET_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getUniversitiesGrid(payload, cb) {
    return {
      type: GET_UNIVERSITIES_GRID,
      payload,
      cb,
    };
  }
  static getUniversitiesGridSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_GRID_SUCCESS,
      payload,
    };
  }
  static getUniversitiesGridFailure(payload) {
    return {
      type: GET_UNIVERSITIES_GRID_FAILURE,
      payload,
    };
  }
  static getAllUniversities(cb) {
    return {
      type: GET_ALL_UNIVERSITIES,
      cb,
    };
  }
  static getAllUniversitiesSuccess(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getAllUniversitiesFailure(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getUnisByBudget(payload, cb) {
    return {
      type: GET_UNIS_BY_BUDGET,
      payload,
      cb,
    };
  }
  static getUnisByBudgetSuccess(payload) {
    return {
      type: GET_UNIS_BY_BUDGET_SUCCESS,
      payload,
    };
  }
  static getUnisByBudgetFailure(payload) {
    return {
      type: GET_UNIS_BY_BUDGET_FAILURE,
      payload,
    };
  }
  static getPopularCountries(cb) {
    return {
      type: GET_POPULAR_COUNTRIES,
      cb,
    };
  }
  static getPopularCountriesSuccess(payload) {
    return {
      type: GET_POPULAR_COUNTRIES_SUCCESS,
      payload,
    };
  }
  static getPopularCountriesFailure(payload) {
    return {
      type: GET_POPULAR_COUNTRIES_FAILURE,
      payload,
    };
  }
  static getUniversityDetails(id, cb) {
    return {
      type: GET_UNIVERSITY_DETAILS,
      id,
      cb,
    };
  }
  static getUniversityDetailsSuccess(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_SUCCESS,
      payload,
    };
  }
  static getUniversityDetailsFailure(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_FAILURE,
      payload,
    };
  }

  static compareUniverities(payload, cb) {
    return {
      type: COMPARE_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static compareUniveritiesSuccess(payload) {
    return {
      type: COMPARE_UNIVERISTIES_SUCCESS,
      payload,
    };
  }
  static compareUniveritiesFailure(payload) {
    return {
      type: COMPARE_UNIVERISTIES_FAILURE,
      payload,
    };
  }
  static addOrRemoveStayConnected(payload, cb) {
    return {
      type: ADD_OR_REMOVE_STAY_CONNECTED,
      payload,
      cb,
    };
  }
  static addOrRemoveStayConnectedSuccess(payload) {
    return {
      type: ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS,
      payload,
    };
  }
  static addOrRemoveStayConnectedFailure(payload) {
    return {
      type: ADD_OR_REMOVE_STAY_CONNECTED_FAILURE,
      payload,
    };
  }
}
