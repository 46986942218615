import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CiCircleChevLeft } from "react-icons/ci";
import { FaCircleCheck } from "react-icons/fa6";

import { QuestionnaireAction } from "../../store/actions";

import {
  boxContainer,
  layouts,
  Modal,
  Buttons,
  Spacer,
  progressBar,
} from "../../components";
import { Images } from "../../config";

import GeneralInfo from "./general-info";
import Academics from "./academics";
import Activities from "./activities";
import Address from "./address";
import ContactProfileBuilder from "./contacts";
import Counselor from "./counselor";
import Demographics from "./demographics";
import Education from "./education";
import ExternalId from "./external-id";
import Facilities from "./facilities";
import Financial from "./financial";
import Location from "./location";
import Others from "./others";
import OverallGPA from "./overall-gpa";
import People from "./people";
import TestScores from "./test-scores";

import "./styles.css";
import Loader from "../../components/loader";

const ProfileBuilderAfterSignin = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [page, setPage] = useState("Profile Builder");
  const [allCompleted, setAllCompleted] = useState(false);
  const [showInitialModal, setShowInitialModal] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    getQuestionnaire();
  }, [profileData, page]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  useEffect(() => {
    if (questionnaire?.completion) {
      const allComplete = Object.values(questionnaire?.completion).every(
        (value) => value === 100
      );
      setAllCompleted(allComplete);
    } else {
      setAllCompleted(false);
    }
  }, [profileData, questionnaire]);

  const handleExit = () => {
    setShowCancelModal(false);
    localStorage.removeItem("showProfileBuilder");
  };
  const handleDontShow = () => {
    setShowCancelModal(false);
    localStorage.removeItem("showProfileBuilder");
    dispatch(QuestionnaireAction.updateQuestionnairePref());
  };

  const profileInfo = [
    {
      title: "General Information",
      icon: Images.ProfileBuildergeneral,
      percentage: questionnaire.completion?.generalCompletion,
    },
    {
      title: "Address",
      icon: Images.ProfileBuilderlocation,
      percentage: questionnaire.completion?.addressCompletion,
    },
    {
      title: "Overall GPA",
      icon: Images.ProfileBuilderoverallgpa,
      percentage: questionnaire.completion?.overallGpaCompletion,
    },
    {
      title: "Education",
      icon: Images.ProfileBuildereducation,
      percentage: questionnaire.completion?.educationCompletion,
    },
    {
      title: "Test Scores",
      icon: Images.ProfileBuildertestscores,
      percentage: questionnaire.completion?.testScoresCompletion,
    },
    {
      title: "Contacts",
      icon: Images.ProfileBuildercontacts,
      percentage: questionnaire.completion?.contactsCompletion,
    },
    {
      title: "Counselor",
      icon: Images.ProfileBuildercounselor,
      percentage: questionnaire.completion?.counselorCompletion,
    },
    {
      title: "Activities",
      icon: Images.ProfileBuilderactivities,
      percentage: questionnaire.completion?.activitiesCompletion,
    },
    {
      title: "External ID",
      icon: Images.ProfileBuilderexternalid,
      percentage: questionnaire.completion?.externalIdCompletion,
    },
  ];

  const universityPref = [
    {
      title: "Academics",
      icon: Images.ProfileBuilderacademics,
      percentage: questionnaire.completion?.academicsCompletion,
    },
    {
      title: "Location",
      icon: Images.ProfileBuilderlocation,
      percentage: questionnaire.completion?.locationCompletion,
    },
    {
      title: "Financial",
      icon: Images.ProfileBuilderfinancial,
      percentage: questionnaire.completion?.financialCompletion,
    },
    {
      title: "People",
      icon: Images.ProfileBuilderpeople,
      percentage: questionnaire.completion?.peopleCompletion,
    },
    {
      title: "Facilities",
      icon: Images.ProfileBuilderfacilities,
      percentage: questionnaire.completion?.faciltiesCompletion,
    },
    {
      title: "Demographics",
      icon: Images.ProfileBuilderdemographics,
      percentage: questionnaire.completion?.demographicsCompletion,
    },
    {
      title: "Others",
      icon: Images.ProfileBuilderothers,
      percentage: questionnaire.completion?.othersCompletion,
    },
  ];

  if (isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loader.Circular />
      </div>
    );
  } else {
    return (
      <layouts.LoginLayout>
        <div className="w-[85vw]">
          <boxContainer.LiteBoxContainer>
            <div className="flex flex-col gap-3 relative px-6 py-4">
              <div className="text-center header-lg">{page}</div>
              {page === "Profile Builder" ? (
                <div className="-top-1 right-3 absolute text-[38px] cursor-pointer">
                  {allCompleted ? (
                    <Buttons.DefaultButton onClick={handleExit}>
                      Done
                    </Buttons.DefaultButton>
                  ) : (
                    <Buttons.WhiteButton
                      onClick={() => setShowCancelModal(true)}
                    >
                      Skip
                    </Buttons.WhiteButton>
                  )}
                </div>
              ) : (
                <CiCircleChevLeft
                  className="top-3 left-5 absolute text-[38px] cursor-pointer"
                  onClick={() => {
                    setPage("Profile Builder");
                  }}
                />
              )}
              {page === "General Information" ? (
                <GeneralInfo setPage={setPage} />
              ) : page === "Address" ? (
                <Address setPage={setPage} />
              ) : page === "Overall GPA" ? (
                <OverallGPA setPage={setPage} />
              ) : page === "Education" ? (
                <Education setPage={setPage} />
              ) : page === "Test Scores" ? (
                <TestScores setPage={setPage} />
              ) : page === "Contacts" ? (
                <ContactProfileBuilder setPage={setPage} />
              ) : page === "Counselor" ? (
                <Counselor setPage={setPage} />
              ) : page === "Activities" ? (
                <Activities setPage={setPage} />
              ) : page === "External ID" ? (
                <ExternalId setPage={setPage} />
              ) : page === "Academics" ? (
                <Academics setPage={setPage} />
              ) : page === "Location" ? (
                <Location setPage={setPage} />
              ) : page === "Financial" ? (
                <Financial setPage={setPage} />
              ) : page === "People" ? (
                <People setPage={setPage} />
              ) : page === "Facilities" ? (
                <Facilities setPage={setPage} />
              ) : page === "Demographics" ? (
                <Demographics setPage={setPage} />
              ) : page === "Others" ? (
                <Others setPage={setPage} />
              ) : (
                <div className="max-h-[80vh] overflow-auto">
                  <div className="flex flex-col gap-2">
                    <div className="header-md">Profile Information</div>
                    <div className="grid grid-cols-5 gap-4">
                      {profileInfo?.map((item, index) => (
                        <div
                          className={`profile-builder-card ${
                            item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 &&
                            "items-start"
                          }`}
                          key={index}
                          onClick={() => setPage(item?.title)}
                        >
                          {item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 && (
                              <div className="absolute right-2 top-2 px-2 py-1 rounded-lg bg-liteBoxBorder text-[14px] font-semibold">
                                Not Completed
                              </div>
                            )}
                          <img src={item?.icon} className="h-[56px] w-[56px]" />
                          <div className="font-semibold text-[16px]">
                            {item?.title}
                          </div>
                          {item?.percentage === 100 &&
                            questionnaire?.completion?.totalCompletion > 0 && (
                              <div className="flex bg-main w-full gap-2 items-center justify-center h-[30px] text-white font-semibold">
                                <FaCircleCheck />
                                <div className="text-[14px]">Completed</div>
                              </div>
                            )}
                          {item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 && (
                              <progressBar.DefaultProgress
                                percent={item?.percentage}
                              />
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="header-md">University Preference</div>
                    <div className="grid grid-cols-5 gap-4">
                      {universityPref?.map((item, index) => (
                        <div
                          className={`profile-builder-card ${
                            item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 &&
                            "items-start"
                          }`}
                          key={index}
                          onClick={() => setPage(item?.title)}
                        >
                          {item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 && (
                              <div className="absolute right-2 top-2 px-2 py-1 rounded-lg bg-liteBoxBorder text-[14px] font-semibold">
                                Not Completed
                              </div>
                            )}
                          <img src={item?.icon} className="h-[56px] w-[56px]" />
                          <div className="font-semibold text-[16px]">
                            {item?.title}
                          </div>
                          {item?.percentage === 100 && (
                            <div className="flex bg-main w-full gap-2 items-center justify-center h-[30px] text-white font-semibold">
                              <FaCircleCheck />
                              <div className="text-[14px]">Completed</div>
                            </div>
                          )}
                          {item?.percentage < 100 &&
                            questionnaire?.completion?.totalCompletion > 0 && (
                              <progressBar.DefaultProgress
                                percent={item?.percentage}
                              />
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <Modal.DefaultModal
          open={showInitialModal}
          closeIcon={false}
          onCancel={() => setShowInitialModal(false)}
        >
          <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[500px]">
            <img src={Images.LogoIconOnly} className="h-[66px]" />
            <div className="font-bold">
              Welcome,
              <br />
              {profileData.firstName} {profileData.lastName}!
            </div>
            <div className="font-semibold">
              Please check out our Profile Builder section where you can input
              your information to help us curate the most accurate university
              recommendations for you!
            </div>
            <div className="w-[250px]">
              <Buttons.DefaultButton
                width="full"
                onClick={() => setShowInitialModal(false)}
              >
                Continue
              </Buttons.DefaultButton>
            </div>
          </div>
        </Modal.DefaultModal>
        <Modal.DefaultModal
          open={showCancelModal}
          closeIcon={false}
          onCancel={() => setShowCancelModal(false)}
        >
          <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[550px]">
            <img src={Images.ExclamationIcon} className="h-[66px]" />
            <div className="font-bold">ARE YOU SURE?</div>
            <div className="font-semibold">
              We will use the information you provide here to generate
              university recommendations for you based on your profile. You can
              also access this section in the <b>"Profile Builder"</b> section
              on the UniVision Student Portal later.
            </div>
            <Spacer height={5} />
            <div className="flex gap-3 w-full">
              <Buttons.DefaultButton
                width="full"
                onClick={() => setShowCancelModal(false)}
              >
                Continue Now
              </Buttons.DefaultButton>
              <Buttons.WhiteButton width="full" onClick={() => handleExit()}>
                Continue Later
              </Buttons.WhiteButton>
            </div>
            <Spacer height={5} />
            <div
              className="underline font-bold cursor-pointer"
              onClick={() => handleDontShow()}
            >
              Exit & Don't show again
            </div>
          </div>
        </Modal.DefaultModal>
      </layouts.LoginLayout>
    );
  }
};

export default ProfileBuilderAfterSignin;
