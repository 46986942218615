import React from "react";
import { Radio, ConfigProvider } from "antd";

import { Colors } from "../../config";

const HorizontalRadio = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: Colors.main, colorBorder: Colors.main },
      }}
    >
      <Radio.Group {...props} buttonStyle="solid">
        <div className="flex gap-3">
          {props?.items?.map((item, index) => (
            <Radio value={item.value} key={index} className="font-bold">
              {item?.label}
            </Radio>
          ))}
        </div>
      </Radio.Group>
    </ConfigProvider>
  );
};
export default HorizontalRadio;
