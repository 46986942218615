import React, { useState } from "react";

import { IoSend } from "react-icons/io5";

const ChatInput = ({ handleSendMsg }) => {
  const [msg, setMsg] = useState("");

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  return (
    <div className="px-3">
      <form
        className="chatbot-message-input-container"
        onSubmit={(event) => sendChat(event)}
      >
        <input
          type="text"
          placeholder="Type your message here"
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
          className="w-full outline-none"
        />
        <button className="chatbot-message-send-button" type="submit">
          <IoSend />
        </button>
      </form>
    </div>
  );
};

export default ChatInput;
