import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";

import { CiCircleChevLeft } from "react-icons/ci";

import { AuthAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import { Buttons, Spacer, inputFields, loginAndSignup } from "../../components";

import { Images, Utils } from "../../config";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const prevData = location.state;

  console.log(prevData);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleContinue = () => {
    if (!password) {
      message.error("Password cannot be empty.");
    } else if (!Utils.passwordRegex.test(password)) {
      message.error(
        "Your password must have at least one uppercase letter, one lowecase letter and a number."
      );
    } else if (!confirmPassword) {
      message.error("Confirm password cannot be empty.");
    } else if (password !== confirmPassword) {
      message.error("Passwords do not match.");
    } else {
      const body = {
        ...prevData,
        password,
      };

      dispatch(
        AuthAction.ResetPassword(body, (res) => {
          if (res.status === 200) {
            navigate("/login");
          }
        })
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleContinue();
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col items-center justify-center  px-[100px] py-[80px] bg-login-gradient gap-4">
        <div className="relative">
          <CiCircleChevLeft
            className="text-[40px] absolute -left-[150px] top-3 cursor-pointer"
            onClick={() => {
              window.history.back();
            }}
          />
          <img src={Images.UnivisionLogoWithText} className="h-[60px]" />
        </div>
        <div className="flex flex-col gap-2 items-center w-full text-center">
          <div className="text-main font-bold text-[42px]">
            Set New Password
          </div>
          <div className="text-[22px] font-semibold">
            Enter New Password And Confirm Password
          </div>
        </div>
        <div className="flex flex-col gap-2 w-[500px]">
          <div>
            <loginAndSignup.LoginInputTitle title="Set Password" />
            <Spacer height={10} />
            <inputFields.DefaultInput
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              onKeyDown={handleKeyDown}
              placeholder="Enter your password"
              type="password"
            />
          </div>
          <div>
            <loginAndSignup.LoginInputTitle title="Confirm New Password" />
            <Spacer height={10} />
            <inputFields.DefaultInput
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              onKeyDown={handleKeyDown}
              placeholder="Enter your password"
              type="password"
            />
          </div>
          <Spacer height={20} />
          <Buttons.DefaultButton onClick={handleContinue} width="full">
            Continue
          </Buttons.DefaultButton>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
