import { all, takeLatest } from "redux-saga/effects";

import {
  ADD_OR_REMOVE_STAY_CONNECTED,
  RETRIEVE_SESSION,
  ADD_APPLICATIONS,
  CHANGE_APP_STATUS,
  ADD_CONTACT,
  ADD_MEETING,
  ADD_MESSAGE,
  ADD_QUESTIONNAIRE,
  ADD_OR_REMOVE_FROM_WISHLIST,
  CANCEL_SUBSCRIPTION,
  CREATE_CHECKOUT_SESSION_MONTHLY,
  CREATE_CHECKOUT_SESSION_ONETIME,
  CHECK_EMAIL,
  CHANGE_NOTIFICATIONS_STATUS,
  CHANGE_MEETING_STATUS,
  CHANGE_PASSWORD,
  COMPARE_UNIVERSITIES,
  EDIT_PROFILE,
  FORGOT_PASSWORD,
  GET_COUNTRIES,
  GET_PROGRAM_TYPES,
  GET_PROGRAMS,
  GET_ONLINE_USERS,
  GET_RECOMMENDER,
  GET_NOTIFICATIONS,
  GET_ONGOING_APPLICATIONS,
  GET_SUBMITTED_APPLICATIONS,
  GET_MEETINGS_INVITES,
  GET_MEETINGS_REQUESTS,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_MEETING_DETAILS,
  GET_MEETINGS_BASED_ON_DATE,
  GET_PROFILE,
  GET_COUNSELORS,
  GET_STUDENT_WISHLIST,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_GRID,
  GET_UPCOMING_MEETINGS,
  GET_ALL_UNIVERSITIES,
  GET_UNIVERSITY_DETAILS,
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  GET_POPULAR_SUBS,
  GET_TOP_STORIES,
  GET_USERS,
  GET_UDEMY_COURSES,
  GET_QUESTIONNAIRE,
  GET_SCHOOLS,
  GET_ETEXTBOOKS,
  GET_VISA_INFO,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  RESCHEDULE_MEETING,
  SIGNUPSTUDENT,
  UPDATE_SHOW_TRIAL_MODAL,
  UPLOAD_IMAGE_QUESTIONNAIRE,
  UPLOAD_IMAGE,
  UPLOAD_FILE,
  UPLOAD_APPLICATION_PROOF,
  VERIFY_OTP,
  VERIFY_SCHOOL_CODE,
  UPDATE_QUESTIONNAIRE_PREF,
  GET_TASKS,
  GET_SUGGESTIONS,
  UPDATE_ESSAY_GRADER_COUNT,
  GET_UNIS_BY_BUDGET,
  GET_POPULAR_COUNTRIES,
  GET_INTERNSHIPS,
  GET_PAYMENT_PLANS,
  REFRESH_TOKEN,
  UNI_SEARCH,
  SHORTLIST_SEARCH,
  EXPLORE_SEARCH,
} from "../constants";
import {
  ApplicationMiddleware,
  AuthMiddleware,
  ChatbotMiddleware,
  ContactMiddleware,
  CounselorMiddleware,
  OthersMiddleware,
  ETextbookMiddleware,
  InteractiveLearningMiddleware,
  IndividualStudentMiddleware,
  InternshipsMiddleware,
  MeetingMiddleware,
  NotificationsMiddleware,
  ProfileMiddleware,
  QuestionnaireMiddleware,
  RecommenderMiddleware,
  SearchMiddleware,
  UniversityMiddleware,
  WatchlistMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    //login flow
    yield takeLatest(LOGIN, AuthMiddleware.SignIn),
    yield takeLatest(REFRESH_TOKEN, AuthMiddleware.refreshToken),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(VERIFY_OTP, AuthMiddleware.VerifyOtp),
    yield takeLatest(SIGNUPSTUDENT, AuthMiddleware.studentSignup),
    yield takeLatest(CHECK_EMAIL, AuthMiddleware.CheckEmail),
    yield takeLatest(VERIFY_SCHOOL_CODE, AuthMiddleware.verifySchoolCode),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),

    //university
    yield takeLatest(GET_UNIVERSITIES, UniversityMiddleware.getUniversities),
    yield takeLatest(
      GET_UNIVERSITIES_GRID,
      UniversityMiddleware.getUniversitiesGrid
    ),
    yield takeLatest(
      GET_ALL_UNIVERSITIES,
      UniversityMiddleware.getAllUniversities
    ),
    yield takeLatest(
      GET_UNIVERSITY_DETAILS,
      UniversityMiddleware.getUniversityDetails
    ),
    yield takeLatest(
      ADD_OR_REMOVE_STAY_CONNECTED,
      UniversityMiddleware.addOrRemoveStayConnected
    ),
    yield takeLatest(
      COMPARE_UNIVERSITIES,
      UniversityMiddleware.compareUniverities
    ),
    yield takeLatest(
      GET_POPULAR_COUNTRIES,
      UniversityMiddleware.getPopularCountries
    ),
    yield takeLatest(GET_UNIS_BY_BUDGET, UniversityMiddleware.getUnisByBudget),

    //students
    yield takeLatest(GET_STUDENT_WISHLIST, WatchlistMiddleware.getWatchlist),

    //user profile
    yield takeLatest(GET_PROFILE, ProfileMiddleware.getUserProfile),
    yield takeLatest(EDIT_PROFILE, ProfileMiddleware.editUserProfile),
    yield takeLatest(CHANGE_PASSWORD, ProfileMiddleware.changePassword),

    //other
    yield takeLatest(ADD_CONTACT, ContactMiddleware.addContact),

    //counselors
    yield takeLatest(GET_COUNSELORS, CounselorMiddleware.getCounselors),

    //notifications
    yield takeLatest(
      GET_NOTIFICATIONS,
      NotificationsMiddleware.getNotifications
    ),
    yield takeLatest(
      CHANGE_NOTIFICATIONS_STATUS,
      NotificationsMiddleware.changeNotificationsStatus
    ),

    // recommender
    yield takeLatest(GET_RECOMMENDER, RecommenderMiddleware.getRecommender),

    //meetings
    yield takeLatest(ADD_MEETING, MeetingMiddleware.addMeeting),
    yield takeLatest(RESCHEDULE_MEETING, MeetingMiddleware.rescheduleMeeting),
    yield takeLatest(GET_MEETINGS_INVITES, MeetingMiddleware.getMeetingInvites),
    yield takeLatest(
      GET_MEETINGS_REQUESTS,
      MeetingMiddleware.getMeetingRequests
    ),
    yield takeLatest(GET_MEETING_DETAILS, MeetingMiddleware.getMeetingDetails),
    yield takeLatest(
      GET_UPCOMING_MEETINGS,
      MeetingMiddleware.getUpcomingMeetings
    ),
    yield takeLatest(
      GET_ALL_MEETINGS_AND_COUNT,
      MeetingMiddleware.getAllMeetingsAndCount
    ),
    yield takeLatest(
      GET_MEETINGS_BASED_ON_DATE,
      MeetingMiddleware.getMeetingsBasedOnDate
    ),
    yield takeLatest(
      CHANGE_MEETING_STATUS,
      MeetingMiddleware.changeMeetingStatus
    ),

    //applications

    yield takeLatest(
      GET_ONGOING_APPLICATIONS,
      ApplicationMiddleware.getOngoingApplications
    ),
    yield takeLatest(
      GET_SUBMITTED_APPLICATIONS,
      ApplicationMiddleware.getSubmittedApplications
    ),
    yield takeLatest(ADD_APPLICATIONS, ApplicationMiddleware.addApplications),
    yield takeLatest(
      CHANGE_APP_STATUS,
      ApplicationMiddleware.changeApplicationStatus
    ),
    yield takeLatest(
      UPLOAD_APPLICATION_PROOF,
      ApplicationMiddleware.uploadApplicationProof
    ),

    // watchlist
    yield takeLatest(
      ADD_OR_REMOVE_FROM_WISHLIST,
      WatchlistMiddleware.addOrRemoveWishlist
    ),

    //chatbot
    yield takeLatest(GET_MESSAGES, ChatbotMiddleware.getMessages),
    yield takeLatest(GET_UNREAD_MESSAGES, ChatbotMiddleware.getUnreadMessages),
    yield takeLatest(ADD_MESSAGE, ChatbotMiddleware.addMessage),
    yield takeLatest(GET_USERS, ChatbotMiddleware.getUsers),

    //interactive learning
    yield takeLatest(
      GET_UDEMY_COURSES,
      InteractiveLearningMiddleware.getUdemyCourses
    ),

    //e textbooks
    yield takeLatest(GET_ETEXTBOOKS, ETextbookMiddleware.getETextbooks),

    //questionnaire
    yield takeLatest(
      ADD_QUESTIONNAIRE,
      QuestionnaireMiddleware.addQuestionnaire
    ),
    yield takeLatest(
      GET_QUESTIONNAIRE,
      QuestionnaireMiddleware.getQuestionnaire
    ),
    yield takeLatest(
      UPLOAD_IMAGE_QUESTIONNAIRE,
      QuestionnaireMiddleware.uploadImageQuestionnaire
    ),
    yield takeLatest(
      UPDATE_QUESTIONNAIRE_PREF,
      QuestionnaireMiddleware.updateQuestionnairePref
    ),

    // indiVidual student
    yield takeLatest(
      RETRIEVE_SESSION,
      IndividualStudentMiddleware.retrieveSession
    ),
    yield takeLatest(
      UPDATE_SHOW_TRIAL_MODAL,
      IndividualStudentMiddleware.updateTrialModal
    ),

    yield takeLatest(
      CANCEL_SUBSCRIPTION,
      IndividualStudentMiddleware.cancelSubscription
    ),
    yield takeLatest(
      CREATE_CHECKOUT_SESSION_MONTHLY,
      IndividualStudentMiddleware.createCheckoutMonthly
    ),
    yield takeLatest(
      CREATE_CHECKOUT_SESSION_ONETIME,
      IndividualStudentMiddleware.createCheckoutOnetime
    ),

    // others
    yield takeLatest(GET_COUNTRIES, OthersMiddleware.getCountries),
    yield takeLatest(GET_ONLINE_USERS, OthersMiddleware.getOnlineUsers),
    yield takeLatest(GET_PROGRAM_TYPES, OthersMiddleware.getProgramTypes),
    yield takeLatest(GET_PROGRAMS, OthersMiddleware.getPrograms),
    yield takeLatest(UPLOAD_FILE, OthersMiddleware.uploadFile),
    yield takeLatest(UPLOAD_IMAGE, OthersMiddleware.uploadImage),
    yield takeLatest(GET_VISA_INFO, OthersMiddleware.getVisaInfo),
    yield takeLatest(GET_SCHOOLS, OthersMiddleware.getSchools),
    yield takeLatest(GET_TOP_STORIES, OthersMiddleware.getTopStories),
    yield takeLatest(GET_TASKS, OthersMiddleware.getTasks),
    yield takeLatest(GET_SUGGESTIONS, OthersMiddleware.getSuggestions),
    yield takeLatest(GET_POPULAR_SUBS, OthersMiddleware.getPopularSubs),
    yield takeLatest(GET_PAYMENT_PLANS, OthersMiddleware.getPaymentPlans),
    yield takeLatest(
      UPDATE_ESSAY_GRADER_COUNT,
      OthersMiddleware.updateEssayGraderCount
    ),
    // internships
    yield takeLatest(GET_INTERNSHIPS, InternshipsMiddleware.getInternships),

    // internships
    yield takeLatest(UNI_SEARCH, SearchMiddleware.uniSearch),
    yield takeLatest(SHORTLIST_SEARCH, SearchMiddleware.shortlistSearch),
    yield takeLatest(EXPLORE_SEARCH, SearchMiddleware.exploreSearch),
  ]);
}
