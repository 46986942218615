import React, { useState, useEffect } from "react";

const QuestionnaireMultipleSelect = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    props?.onChange(selectedItems);
  }, [selectedItems]);

  return (
    <div className="flex gap-2 flex-wrap" {...props}>
      {props?.array?.map((item, index) => (
        <div
          key={index}
          className={`questionnaire-multiple-select ${
            props?.value?.includes(item)
              ? "bg-main text-white"
              : "bg-white text-gray text-opacity-70"
          }`}
          onClick={() => handleSelect(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default QuestionnaireMultipleSelect;
