import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { MdOutlineCancel } from "react-icons/md";
import { CiCircleChevLeft } from "react-icons/ci";

import {
  ProfileAction,
  IndividualStudentAction,
  OthersAction,
  AuthAction,
} from "../../store/actions";

import {
  boxContainer,
  layouts,
  Buttons,
  Spacer,
  Modal,
  Loader,
} from "../../components";
import { Images } from "../../config";

const Payment = () => {
  const dispatch = useDispatch();

  const url = window.location.origin;

  const profileData = useSelector((state) => state.profile?.user);
  const { paymentPlans, isPaymentPlansLoading } = useSelector(
    (state) => state.others
  );

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showSuccessModalMonthly, setShowSuccessModalMonthly] = useState(false);
  const [showSuccessModalOnetime, setShowSuccessModalOnetime] = useState(false);

  useEffect(() => {
    dispatch(OthersAction.getPaymentPlans());
  }, [profileData]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const sessionId = query.get("session_id");

      dispatch(
        IndividualStudentAction.retrieveSession({ sessionId }, (res) => {
          if (res.status === 200) {
            console.log(res.data.type);
            if (res.data.type === "monthly") {
              setShowSuccessModalMonthly(true);
            }
            if (res.data.type === "onetime") {
              setShowSuccessModalOnetime(true);
            }
          }
        })
      );
    }
    if (query.get("canceled")) {
      message.error("Payment failed.");
    }
  }, []);

  const handleCancel = () => {
    setShowSuccessModalMonthly(false);
    setShowSuccessModalOnetime(false);

    console.log({
      email: profileData?.email,
      password: profileData?.password,
    });
    dispatch(
      AuthAction.refreshToken({}, () => {
        dispatch(ProfileAction.getUserProfile());
      })
    );

    localStorage.removeItem("showPaymentPage");
  };

  const handleBack = () => {
    localStorage.setItem("showProfileBuilder", true);
  };

  const handleSelect = (value) => {
    if (value === "Free") {
      handleCancel();
    }
    if (value === "Monthly") {
      dispatch(
        IndividualStudentAction.createCheckoutMonthly({ url }, (res) => {
          window.location.href = res.data?.url;
        })
      );
    }
    if (value === "One Time Payment") {
      dispatch(
        IndividualStudentAction.createCheckoutOnetime({ url }, (res) => {
          window.location.href = res.data?.url;
        })
      );
    }
  };

  return (
    <layouts.LoginLayout>
      <div className="w-[75vw]">
        {isPaymentPlansLoading ? (
          <Loader.Circular />
        ) : (
          <boxContainer.LiteBoxContainer>
            <div className="p-4">
              <div className="flex flex-col items-center gap-3 relative p-4 pt-2">
                <MdOutlineCancel
                  className="top-3 right-3 absolute text-[38px] cursor-pointer"
                  onClick={() => handleCancel()}
                />
                <CiCircleChevLeft
                  className="top-3 left-3 absolute text-[38px] cursor-pointer"
                  onClick={handleBack}
                />
                <div className="text-center header-lg">
                  Choose Your Right Plan
                </div>
                <div className="text-center text-[20px] font-medium">
                  Select from best plans, ensuring a perfect match. Need more or
                  less? <br /> Customize your subscription for a seamless fit!
                </div>
              </div>
              <Spacer height={20} />
              <div className="grid grid-cols-3 gap-5">
                {paymentPlans?.map((item, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    className={`flex flex-col gap-4 p-3 border border-main shadow rounded-3xl hover:bg-payment-card-gradient transition-all duration-300`}
                  >
                    <div className="flex justify-between items-center">
                      <div
                        className={`w-max font-bold rounded-xl py-2 px-3 shadow transition-colors duration-300 ${
                          hoveredIndex === index
                            ? "bg-white text-main"
                            : "bg-main text-white"
                        }`}
                      >
                        {item?.topText}
                      </div>
                      {item?.currentPlan && (
                        <div
                          className={`w-max font-bold rounded-xl py-2 px-3 text-[14px] bg-[#FBBE28]  text-black`}
                        >
                          Current Plan
                        </div>
                      )}
                      {item?.topTextRight && (
                        <div
                          className={`w-max font-bold rounded-xl py-2 px-3 text-[14px] bg-[#FFEAB7] border border-[#FBBE28] text-black`}
                        >
                          {item?.topTextRight}
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 items-center border-b-[2px] border-b-gray-300">
                      <div className="text-[36px] font-bold">{item?.title}</div>
                      <div className="text-[18px] font-medium opacity-50">
                        {item?.subtitle}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      {item?.items?.map((values) => (
                        <div
                          className="flex gap-3 items-center"
                          key={Math.random()}
                        >
                          <img
                            src={
                              values.availibility
                                ? Images.WavyCheck
                                : Images?.WavyCancel
                            }
                            className="h-[20px]"
                          />
                          <div className="font-medium text-[18px]">
                            {values.text}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="text-main text-center text-[14px] font-semibold pb-3 border-b-[2px] border-b-gray-300">
                      & Access to 25+ More Features
                    </div>
                    {hoveredIndex === index ? (
                      <Buttons.DefaultButton
                        width="full"
                        onClick={() => handleSelect(item.topText)}
                      >
                        Select
                      </Buttons.DefaultButton>
                    ) : (
                      <Buttons.WhiteButton
                        width="full"
                        onClick={() => handleSelect(item.topText)}
                      >
                        Select
                      </Buttons.WhiteButton>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </boxContainer.LiteBoxContainer>
        )}
      </div>
      <Modal.DefaultModal
        open={showSuccessModalOnetime}
        closeIcon={false}
        onCancel={() => handleCancel()}
      >
        <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[550px]">
          <img src={Images.WavyCheck} className="h-[66px]" />
          <div className="font-bold">
            Thank you for subscribing to UniVision Pro!
          </div>
          <div className="font-semibold">
            Enjoy your <span className="text-main">UniVision Pro</span>{" "}
            platform.
          </div>
          <div className="w-[250px]">
            <Buttons.DefaultButton width="full" onClick={() => handleCancel()}>
              Okay
            </Buttons.DefaultButton>
          </div>
        </div>
      </Modal.DefaultModal>
      <Modal.DefaultModal
        open={showSuccessModalMonthly}
        closeIcon={false}
        onCancel={() => handleCancel()}
      >
        <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[550px]">
          <img src={Images.WavyCheck} className="h-[66px]" />
          <div className="font-bold">
            Thank you for subscribing to UniVision Pro!
          </div>
          <div className="font-semibold">
            Enjoy your first month for <span className="text-main">FREE!</span>{" "}
            From the next month onward, your subscription will continue with our
            regular charge. Feel free to cancel anytime—no strings attached.
          </div>
          <div className="w-[250px]">
            <Buttons.DefaultButton width="full" onClick={() => handleCancel()}>
              Okay
            </Buttons.DefaultButton>
          </div>
        </div>
      </Modal.DefaultModal>
    </layouts.LoginLayout>
  );
};

export default Payment;
