import Ages from "./ages";
import { budgetRange, budgetValues } from "./budget-range";
import Currency from "./currencies";
import Countries from "./countries";
import Currencies from "./converter";
import genderArray from "./gender-array";
import gradeArray from "./grade-array";
import gradYearsArray from "./grad-year";
import homeCurrency from "./home-currency";
import NavbarRoutes from "./navbar-routes";
import pageSizes from "./pagesizes";
import supportArray from "./support-array";
import scholarshipValues from "./scholarship-values";
import TimeSlots from "./timeslots";
import testNames from "./test-name-array";
import nationalities from "./nationalityarray";
import ethnicityArray from "./ethnicityarray";

const UtilityArrays = {
  Ages,
  budgetRange,
  budgetValues,
  Currencies,
  Countries,
  Currency,
  ethnicityArray,
  genderArray,
  gradeArray,
  gradYearsArray,
  homeCurrency,
  NavbarRoutes,
  pageSizes,
  nationalities,
  supportArray,
  scholarshipValues,
  TimeSlots,
  testNames,
};

export default UtilityArrays;
