import React from "react";

import { Spacer } from "../../components";

function TermsAndCondition() {
  return (
    <div className="w-full max-h-[calc(100vh-220px)] overflow-auto">
      <div className="flex flex-col w-full p-4 justify-center">
        <div className="font-bold">Terms And Conditions</div>
        <Spacer height={10} />
        <div className="font-medium">
          These Terms and Conditions are between each Customer (“You”) and
          UniVision (“Us” “We” or “Our”) for the use of our company platform in
          the university selection process. By downloading and accessing the
          (Application), You confirm that You are in agreement with these Terms
          & Conditions outlined below. These terms apply to the entire Mobile
          Application and Web Portals and any email or other type of
          communication between You and UniVision. Under no circumstances shall
          UniVision team be liable for any direct, indirect, special,
          incidental, or consequential damages, including, but not limited to,
          loss of data or profit, arising out of the use, or the inability to
          use, the available materials on this site, even if UniVision or an
          authorized representative has been advised of the possibility of such
          damages. If your use of materials from this site results in the need
          for servicing, repair or correction of equipment or data, you assume
          any costs thereof. UniVision will not be responsible for any outcome
          that may occur during the usage of our resources. We reserve the right
          to change prices and revise the resources usage policy at any moment.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Definitions and Key Terms</div>
        <Spacer height={10} />
        <div className="font-medium">
          For these Terms & Conditions: “Governing Law” means the Federal laws
          of Malaysia. Additionally, “, We” “us” or “our” refers to the
          UniVision Platform. “Customers”, “you”, “your” shall mean and include
          any individual that accesses the Platform to avail the services in
          order to facilitate applying to colleges and universities. Visitors,
          viewers, subscribers, members, affiliates, or customers are also
          considered “Customers”. If a customer is accessing and/or using the
          Platform on behalf of a customer’s employer or as a consultant or
          agent of a third party, they are parties to this Agreement. Where
          other services or products, including those of related or third
          parties, are offered as part of UniVision, then such products and
          services to the extent they are offered on the UniVision Platform
          shall also be included in the definition of Platform. “Customer”
          refers to the organizations (namely schools and educational/college
          counselling agencies) that Sign Up to use the UniVision Services.
          “Device” is any Internet connected machine such as a phone, tablet,
          computer, or any other device that can be used to visit UniVision
          Application or and use the services. “Personal Data” refers to any
          information that directly, indirectly, or in connection with other
          information, including a personal identification number that allows
          for the identification or identifiability of a natural person.
          “Services” refers to the service provided by UniVision as described in
          the relative terms (if available) and on this platform. “Third-party
          service” refers to advertisers, contest sponsors, promotional and
          marketing partners, and others who provide our content or whose
          products or services we think may interest you.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Parties to this Agreement</div>
        <Spacer height={10} />
        <div className="font-medium">
          By using the UniVision Platform and accessing all the services, the
          Customer shall comply and accept these Terms and Conditions. Where the
          Customer is acting on behalf of another, Customer represents and
          warrants that Customer has the authority to act on behalf of and bind
          the other to this Agreement and the Terms. Amendment to these Terms
          and Conditions UniVision reserves the right to change, amend or update
          these Terms and Conditions at any moment and such modification shall
          be effective immediately upon its posting in the UniVision Platform.
          If Customer continues to use the Platform after any modification is
          posted, then the Customer shall agree on the amended Terms and
          Conditions.
        </div>

        <Spacer height={20} />
        <div className="font-bold">Amendment to these Terms and Conditions</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision reserves the right to change, amend or update these Terms
          and Conditions at any moment and such modification shall be effective
          immediately upon its posting in the UniVision Platform. If Customer
          continues to use the UniVision after any modification is posted, then
          the Customer shall agree on the amended Terms and Conditions.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Eligibility</div>
        <Spacer height={10} />
        <div className="font-medium">
          Customers, as approved by UniVision, shall have the right to use the
          Services provided on the Platform. The Services are available only to
          those Customers who can form legally binding contracts under the
          applicable law. UniVision advises its customers that while accessing
          the Platform, they must follow and abide by the Governing Laws.
          UniVision reserves the right to deny Services to any Customer(s).
        </div>

        <Spacer height={20} />
        <div className="font-bold">Privacy</div>
        <Spacer height={10} />
        <div className="font-medium">
          If you submit or post any ideas, creative suggestions, designs,
          photographs, information, advertisements, data or proposals, including
          ideas for new or improved products, services, features, technologies,
          or promotions, you expressly agree that such submissions will
          automatically be treated as non-confidential and non-proprietary and
          will become the sole property of UniVision without any compensation or
          credit to you whatsoever. We and our affiliates shall have no
          obligations with respect to such submissions or posts and may use the
          ideas contained in such submissions or posts for any purposes in any
          medium in perpetuity, including, but not limited to, developing,
          manufacturing, and marketing products and services using such ideas.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Privacy Policy & Data Protection</div>
        <Spacer height={10} />
        <div className="font-medium">
          While using the Platform, UniVision collects data when the
          Institution(s) and Customer(s) create, update, or use their accounts.
          This may include but is not limited to their name, email, phone
          number, login name and password, address, location and other
          government identification documents (which may indicate document
          numbers as well as birth date, gender, and photo). This data may be
          used and distributed by UniVision at discretion for functions
          including but not limited to carrying out background checks and
          identity verification information. By using the Platform and our
          Services, the Customer provides us with information, files, and
          folders that the Customer submits to the Platform. UniVision does not
          claim any ownership of any of it. These Terms do not grant us any
          rights to the Customer files or intellectual property except for the
          limited rights that are needed to render the Services, as explained
          herein. We may need the Customer’s permission to carry out any service
          the Customer asks us to do, including but not limited to hosting
          files, sharing them at the Customer’s discretion or sending reminders.
          The Customer gives us the permissions we need to do those things
          solely to provide the Services. This permission also extends to trust
          third parties we work with to provide the Services. The Customer is
          solely responsible for the information and data provided through the
          Application. The Customer agrees to the use of his/her data in
          accordance with these Terms and UniVision privacy policy. UniVision
          may share Customers’ personal data if it is required by applicable
          law, regulation, operating license or agreement, legal process, or
          governmental request, or where the disclosure is otherwise appropriate
          due to safety or similar concerns. All the calls made to the UniVision
          customer service center will be recorded for quality and training
          purposes.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Intellectual Rights & Ownership</div>
        <Spacer height={10} />
        <div className="font-medium">
          The Customer acknowledges and agrees that UniVision owns all legal
          rights, title, and interest in and to the Services and the Platform,
          including any intellectual property rights which subsist in the
          Services and the Platform (whether those rights happen to be
          registered or not, and wherever in the world those rights may exist).
          You further acknowledge that the Services may contain information
          which is designated confidential by UniVision that you shall not
          disclose such information without UniVision’s prior written consent.
          Unless you have agreed otherwise in writing with UniVision, nothing in
          the Terms gives you the right to use any of UniVision’s trade names,
          trademarks, service marks, logos, domain names, and other distinctive
          brand features.
        </div>
        <Spacer height={20} />
        <div className="font-bold">License</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision grants you a revocable, non-exclusive, non- transferable,
          limited license to download, install and use the website & app
          strictly in accordance with the terms of this Agreement. These Terms &
          Conditions are a contract between You and UniVision and they grant you
          a revocable, non-exclusive, non- transferable, limited license to
          download, install and use the Mobile Application and Web Portals
          strictly in accordance with these Terms and Conditions.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          Notwithstanding any damages that the Customer might incur, the entire
          liability of us and any of our suppliers under any provision of this
          Agreement and your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by you for the service.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          To the maximum extent permitted by applicable law, in no event shall
          we or our suppliers be liable for any special, incidental, indirect,
          or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, for loss of data or other information,
          for business interruption, for personal injury, for loss of privacy
          arising out of or in any way related to the use of or inability to use
          the service, third-party software and/or third-party hardware used
          with the service, or otherwise in connection with any provision of
          this Agreement), even if we or any supplier has been advised of the
          possibility of such damages and even if the remedy fails of its
          essential purpose. Some states/jurisdictions do not allow the
          exclusion or limitation of incidental or consequential damages, so the
          above limitation or exclusion may not apply to the Customer.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Termination</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision may suspend or terminate a customer’s access to the Platform
          at any time if the Customer breaches these Terms and Conditions. Also,
          UniVision may terminate or withdraw the use of the Platform at any
          time at its sole discretion without notice and without having to
          assign any reason. There is no warranty is given, and the service is
          provided to You “AS IS” and “AS AVAILABLE” and with all faults and
          defects without a warranty of any kind. To the maximum extent
          permitted under applicable law, we, on our own behalf and on behalf of
          our affiliates and our respective licensors and service providers,
          expressly disclaim all warranties, whether express, implied,
          statutory, or otherwise. with respect to the service, including all
          implied warranties of merchantability, fitness for a particular
          purpose, title and non- infringement, and warranties that may arise
          out of course of dealing, course of performance, usage, or trade
          practice. Without limitation to the foregoing, we provide no warranty
          or undertaking, and make no representation of any kind that the
          service will meet your requirements, achieve any intended results, be
          compatible or work with any other software, websites, systems, or
          services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects
          can or will be corrected. Without limiting the foregoing, neither us
          nor any provider makes any representation or warranty of any kind,
          express or implied:
        </div>
        <Spacer height={20} />
        <div className="font-bold">No Warranty</div>
        <Spacer height={10} />
        <div className="font-medium">
          The service is provided to You “AS IS” and “AS AVAILABLE” and with all
          faults and defects without a warranty of any kind. To the maximum
          extent permitted under applicable law, we, on our own behalf and on
          behalf of our affiliates and our respective licensors and service
          providers, expressly disclaim all warranties, whether express,
          implied, statutory, or otherwise. with respect to the service,
          including all implied warranties of merchantability, fitness for a
          particular purpose, title and non- infringement, and warranties that
          may arise out of course of dealing, course of performance, usage, or
          trade practice. Without limitation to the foregoing, we provide no
          warranty or undertaking, and make no representation of any kind that
          the service will meet your requirements, achieve any intended results,
          be compatible or work with any other software, websites, systems, or
          services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects
          can or will be corrected.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          (i) as to the operation or availability of the service, or the
          information, content materials or products included thereon;
        </div>
        <div className="font-medium">
          (ii) that the service will be uninterrupted or error-free;
        </div>
        <div className="font-medium">
          (iii) as to the accuracy, reliability, or currency of any information
          or content provided through the service;
        </div>
        <div className="font-medium">
          (iv) that the service, its servers, the content, messages or e-mails
          sent from or on behalf of us are free of viruses, scripts, trojan
          horses, worms, malware, timebombs or other harmful components. Some
          jurisdictions do not allow the exclusion of or limitations on implied
          warranties or the limitations on the applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to you.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Third Parties</div>
        <Spacer height={10} />
        <div className="font-medium">
          We may display, include, or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          (“Third- Party Services”). You acknowledge and agree that we shall not
          be responsible for any Third-Party Services, including their accuracy,
          completeness, timeliness, validity, copyright compliance, legality,
          decency, quality, or any other aspect thereof. We do not assume and
          shall not have any liability or responsibility to you or any other
          person or entity for any Third-Party Services. Third-Party Services
          and links thereto are provided solely as a convenience to you, and you
          access and use them entirely at your own risk and subject to such
          third parties’ terms and conditions.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Governing Law</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision and the Customers agree that these Terms shall be governed
          by and construed in accordance with the Federal laws of Malaysia. In
          case of any dispute, controversy or claim arising out of or relating
          to these Terms, or the breach, termination, or invalidity thereof, the
          dispute shall be subject to the exclusive jurisdiction of the
          Malaysian courts.
        </div>
        <Spacer height={20} />
        <div className="font-bold">Contact</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision is solely responsible for addressing any question,
          complaints or claim related to the use of the Application. For any
          query, please do first refer to the Privacy Policy document. If you
          still require further clarification, do not hesitate to contact us via
          email on:{" "}
          <a
            href="mailto:admin@univision.hk"
            className="text-primary font-semibold"
          >
            admin@univision.hk
          </a>
          . Additionally, you can seek this link for further information:
          <a href="https://univision.hk/contact-us/">
            https://univision.hk/contact-us/
          </a>
        </div>
      </div>
    </div>
  );
}

export default TermsAndCondition;
