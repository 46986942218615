import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "antd";

import { RxCross2 } from "react-icons/rx";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { CiGlobe } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { FaPhone, FaLocationDot } from "react-icons/fa6";

import { UniversityAction, WatchlistAction } from "../../store/actions";

import General from "../../containers/university-details/general-data";

import Spacer from "../spacer";
import Loader from "../loader";
import tabs from "../tabs";
import modal from "../modal";
import { Buttons } from "..";

import { Colors, Images } from "../../config";

import "./styles.css";

const UniversityInfoModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = props?.uniId;

  const profileData = useSelector((state) => state?.profile?.user);
  const university = useSelector((state) => state?.university);
  const watchlist = useSelector((state) => state?.watchlist);

  const [watchlistText, setWatchlistText] = useState("Add to Shortlist");
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  useEffect(() => {
    if (watchlist?.watchlists.length > 0) {
      const containsId = watchlist.watchlists.some((watchlist) => {
        return watchlist.university?.id === parseInt(id);
      });
      if (containsId) {
        setWatchlistText("Remove from Shortlist");
      } else {
        setWatchlistText("Add to Shortlist");
      }
    }
  }, [watchlist?.watchlists]);

  useEffect(() => {
    if (props?.open) {
      dispatch(UniversityAction.getUniversityDetails(id));
    }
  }, [props?.open]);

  const compareUni = () => {
    const payload = {
      uniIds: [id],
    };
    dispatch(
      UniversityAction.compareUniverities(payload, () => {
        navigate("/universities/compare");
      })
    );
  };

  const handleShortlist = () => {
    const payload = { id };
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(payload, () => {
        dispatch(WatchlistAction.getWatchlist({}));
      })
    );
  };

  const handleStayConnected = () => {
    const payload = { id };
    dispatch(UniversityAction.addOrRemoveStayConnected(payload));
  };

  const tabsArray = [
    {
      label: (
        <div className="uni-details-tabs-container">
          <div className="uni-details-tabs-image">
            <img src={Images.Unigeneral} />
          </div>
          <div>General</div>
        </div>
      ),
      key: "1",
      children: <General uniData={university?.university?.general} />,
    },
    {
      label: (
        <div className="uni-details-tabs-container">
          <div className="uni-details-tabs-image">
            <img src={Images.Uniadmission} />
          </div>
          <div>Admission</div>
        </div>
      ),
      key: "2",
      children: null,
    },
    {
      label: (
        <div className="uni-details-tabs-container">
          <div className="uni-details-tabs-image">
            <img src={Images.Uniprograms} />
          </div>
          <div>Programs</div>
        </div>
      ),
      key: "3",
      children: null,
    },
    {
      label: (
        <div className="uni-details-tabs-container">
          <div className="uni-details-tabs-image">
            <img src={Images.Unifinance} />
          </div>
          <div>Finance</div>
        </div>
      ),
      key: "4",
      children: null,
    },
    {
      label: (
        <div className="uni-details-tabs-container">
          <div className="uni-details-tabs-image">
            <img src={Images.Uniappjourney} />
          </div>
          <div>Application Journey</div>
        </div>
      ),
      key: "5",
      children: null,
    },
  ];

  const heroRightData = [
    {
      icon: <CiGlobe color="black" fontSize={12} />,
      value: (
        <Link to={university?.university?.common?.website} target="_blank">
          <div className="text-white font-normal text-[14px] underline">
            {university?.university?.common?.website}
          </div>
        </Link>
      ),
    },
    {
      icon: <MdOutlineEmail color="black" fontSize={12} />,
      value: (
        <Link to={`mailto:${university?.university?.common?.email}`}>
          <div className="text-white font-normal text-[14px]">
            {university?.university?.common?.email}
          </div>
        </Link>
      ),
    },
    {
      icon: <FaPhone color="black" fontSize={10} />,
      value: (
        <div>
          {university?.university?.common?.phone?.trim()?.startsWith("+")
            ? university?.university?.common?.phone
            : `+${university?.university?.common?.phone}`}
        </div>
      ),
    },
    {
      icon: <FaLocationDot color="black" fontSize={10} />,
      value: <div>{university?.university?.common?.address}</div>,
    },
  ];

  return (
    <Modal
      title={null}
      footer={null}
      centered={true}
      closeIcon={false}
      className="uni-info-modal"
      {...props}
    >
      <div className="text-black">
        {university?.isLoading ? (
          <div className="py-20">
            <Loader.Circular />
          </div>
        ) : (
          <>
            <div
              className="university-hero-section-modal"
              style={{
                backgroundImage: `linear-gradient(to right,rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(${
                  university?.university?.common?.campusPic ??
                  Images?.DefaultUniCover
                })`,
              }}
            >
              <RxCross2
                className="text-white text-[30px] absolute top-4 right-4 cursor-pointer"
                onClick={() => props?.onCancel()}
              />
              <div className="flex justify-between">
                <div className="p-3" onClick={() => setIsAboutModalOpen(true)}>
                  <div className="flex bg-white rounded-lg justify-center items-center gap-2 p-2 cursor-pointer">
                    <BsFillInfoCircleFill
                      style={{ marginTop: "2px", color: Colors.main }}
                    />
                    <div className="font-medium">Learn more about us</div>
                  </div>
                </div>
                <div className="hero-right-part">
                  <div className="flex gap-2 items-center pb-2 border-b">
                    <img
                      src={university?.university?.common?.profilePic}
                      className="h-[36px] w-[36px] object-contain"
                    />
                    <div>Contact Us</div>
                  </div>
                  {heroRightData?.map((item, index) => (
                    <div
                      className="flex gap-2 items-center text-[14px]"
                      key={index}
                    >
                      <div className="bg-white rounded-full flex justify-center items-center p-1">
                        {item.icon}
                      </div>
                      {item?.value}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-between gap-2 px-5 pt-2 pb-4 bg-gradient-to-tl from-[#000000c7] from-1% to-transparent to-90% rounded-b-2xl">
                <div className="flex gap-3 text-white items-center">
                  <img
                    src={university?.university?.common?.profilePic}
                    className="uni-profilepic-container"
                  />
                  <div className="flex flex-col gap-2 ">
                    <div className="text-[22px] uppercase font-bold">
                      {university?.university?.common?.name}
                    </div>
                    <div className="flex gap-2">
                      <img src={Images.LocationPin} />
                      <div className="font-semibold">
                        {university?.university?.common?.city &&
                          `${university?.university?.common?.city}`}
                        {university?.university?.common?.state &&
                          `, ${university?.university?.common?.state}`}
                        {university?.university?.common?.country?.name &&
                          `, ${university?.university?.common?.country.name}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 items-center">
                  <Buttons.WhiteButtonWithoutBorder
                    onClick={handleShortlist}
                    disabled={profileData?.freeUser}
                  >
                    {watchlistText}
                  </Buttons.WhiteButtonWithoutBorder>
                  <Buttons.WhiteButtonWithoutBorder
                    onClick={compareUni}
                    disabled={profileData?.freeUser}
                  >
                    Compare
                  </Buttons.WhiteButtonWithoutBorder>
                  <Buttons.WhiteButtonWithoutBorder
                    onClick={handleStayConnected}
                    disabled={profileData?.freeUser}
                  >
                    Stay Connected
                  </Buttons.WhiteButtonWithoutBorder>
                  <Buttons.DefaultButton
                    onClick={() => {
                      // window.open(university?.university?.common?.appLink, "_blank")
                      setIsApplicationModalOpen(true);
                      setTimeout(() => {
                        setIsApplicationModalOpen(false);
                        window.open(
                          university?.university?.common?.appLink,
                          "_blank"
                        );
                      }, 3000);
                    }}
                  >
                    Apply Now
                  </Buttons.DefaultButton>
                </div>
              </div>
            </div>
            <Spacer height={30} />
            <div className="px-4">
              <tabs.UniversityTab
                items={tabsArray}
                onChange={(value) => {
                  switch (value) {
                    case "2":
                      navigate(`/universities/admission/${id}`);
                      break;
                    case "3":
                      navigate(`/universities/programs/${id}`);
                      break;
                    case "4":
                      navigate(`/universities/finance/${id}`);
                      break;
                    case "5":
                      navigate(`/universities/application-journey/${id}`);
                      break;
                    default:
                      navigate(`/universities/general/${id}`);
                  }
                }}
              />
            </div>
            <Spacer height={30} />
          </>
        )}
        <modal.DefaultModal
          open={isAboutModalOpen}
          onCancel={() => setIsAboutModalOpen(false)}
          closable
        >
          <div className="flex flex-col justify-center items-center p-4 w-[600px] gap-5">
            <div className="text-[26px] font-bold text-center">
              {university?.university?.common?.name}
            </div>
            <div className="font-medium text-justify text-[16px]">
              {university?.university?.common?.aboutUs}
            </div>
          </div>
        </modal.DefaultModal>
        <modal.DefaultModal
          open={isApplicationModalOpen}
          onCancel={() => setIsApplicationModalOpen(false)}
          closable={false}
        >
          <div className="flex flex-col justify-center items-center p-4 w-[500px] gap-5">
            <img src={Images.Unigeneralapplynow} />
            <div className="font-medium text-center text-[16px]">
              You will now be directed to the university application page.
              Please submit your application & upload proof of your
              applicationin the UniVision Applications section.
            </div>
          </div>
        </modal.DefaultModal>
      </div>
    </Modal>
  );
};
export default UniversityInfoModal;
