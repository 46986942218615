import {
  ADD_QUESTIONNAIRE,
  ADD_QUESTIONNAIRE_SUCCESS,
  ADD_QUESTIONNAIRE_FAILURE,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE,
  UPLOAD_IMAGE_QUESTIONNAIRE,
  UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS,
  UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE,
  UPDATE_QUESTIONNAIRE_PREF,
  UPDATE_QUESTIONNAIRE_PREF_SUCCESS,
  UPDATE_QUESTIONNAIRE_PREF_FAILURE,
} from "../constants";

export default class QuestionnaireAction {
  static addQuestionnaire(payload, cb) {
    return {
      type: ADD_QUESTIONNAIRE,
      payload,
      cb,
    };
  }
  static addQuestionnaireSuccess(payload) {
    return {
      type: ADD_QUESTIONNAIRE_SUCCESS,
      payload,
    };
  }
  static addQuestionnaireFailure(payload) {
    return {
      type: ADD_QUESTIONNAIRE_FAILURE,
      payload,
    };
  }
  static getQuestionnaire(cb) {
    return {
      type: GET_QUESTIONNAIRE,
      cb,
    };
  }
  static getQuestionnaireSuccess(payload) {
    return {
      type: GET_QUESTIONNAIRE_SUCCESS,
      payload,
    };
  }
  static getQuestionnaireFailure(payload) {
    return {
      type: GET_QUESTIONNAIRE_FAILURE,
      payload,
    };
  }
  static uploadImageQuestionnaire(payload, cb) {
    return {
      type: UPLOAD_IMAGE_QUESTIONNAIRE,
      payload,
      cb,
    };
  }
  static uploadImageQuestionnaireSuccess(payload) {
    return {
      type: UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS,
      payload,
    };
  }
  static uploadImageQuestionnaireFailure(payload) {
    return {
      type: UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE,
      payload,
    };
  }
  static updateQuestionnairePref(cb) {
    return {
      type: UPDATE_QUESTIONNAIRE_PREF,
      cb,
    };
  }
  static updateQuestionnairePrefSuccess(payload) {
    return {
      type: UPDATE_QUESTIONNAIRE_PREF_SUCCESS,
      payload,
    };
  }
  static updateQuestionnairePrefFailure(payload) {
    return {
      type: UPDATE_QUESTIONNAIRE_PREF_FAILURE,
      payload,
    };
  }
}
