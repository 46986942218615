import React from "react";

const UniversityBoxContainer = ({ addstyle, children, ...rest }) => {
  const additionalStyle = addstyle ?? "";
  return (
    <div
      className={`!border !border-liteBoxBorder shadow-boxContainer bg-white rounded-[36px] ${additionalStyle}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default UniversityBoxContainer;
