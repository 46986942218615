import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Colors } from "../../config";

const VerticalBarChart = (props) => {
  const colors = ["#54aea1", "#73a59e", "#6dc6f3", "#c3d8d5", "#d4fdf7"];

  return (
    <BarChart
      skipAnimation={false}
      height={350}
      grid={{ vertical: true, horizontal: true }}
      bottomAxis={{
        disableTicks: true,
        disableLine: true,
        tickLabelStyle: {
          fontSize: "14px",
          fontWeight: "600",
        },
      }}
      leftAxis={{
        disableTicks: true,
        disableLine: true,
        tickLabelStyle: {
          fontSize: "14px",
          fontWeight: "600",
          display: "none",
        },
      }}
      slotProps={{
        bar: (index) => ({
          style: {
            fill: colors[index.dataIndex] || Colors.main,
            stroke: Colors.main,
            strokeWidth: 1,
            clipPath: "inset(0 0 0 0 round 5px)",
          },
        }),
      }}
      margin={{ top: 5, right: 1, bottom: 30, left: 0 }}
      tooltip={{ trigger: "item" }}
      {...props}
    />
  );
};

export default VerticalBarChart;
