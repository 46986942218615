import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import moment from "moment";

import { MeetingAction } from "../../store/actions";

import { tables, StatusIndicator } from "../../components";

function MeetingsSent() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { meetingSent, meetingSentCount, isMeetingSentLoading } = useSelector(
    (state) => state.meeting
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    fetchMeeting();
  }, [pageNo]);

  const fetchMeeting = () => {
    const meetingPayload = {
      requestedBy: "student",
      studentId: profileData?.studentId,
      limit,
      offset,
    };
    dispatch(MeetingAction.getMeetingRequests(meetingPayload));
    dispatch(
      MeetingAction.getAllMeetingsAndCount({
        requestedBy: "student",
        studentId: profileData?.studentId,
      })
    );
  };

  const handlePagination = (pagenumber) => {
    setOffset((pagenumber - 1) * limit);
    setPageNo(pagenumber);
  };

  const columns = [
    {
      title: "Counselor",
      key: "0",
      width: 110,
      align: "center",
      render: (text, record) => (
        <div>
          {record?.userSchool?.firstName} {record?.userSchool?.lastName}
        </div>
      ),
    },
    {
      title: "Subject",
      key: "1",
      width: 150,
      align: "center",
      render: (text, record) => <div>{record?.subject}</div>,
    },
    {
      title: "Meeting Date",
      key: "2",
      width: 100,
      align: "center",
      render: (text, record) => (
        <div>
          {record?.status === "rescheduled" ? (
            <div className="flex flex-col gap-2 justify-center items-center">
              <StatusIndicator
                showarrow={false}
                text={
                  <div>
                    {moment(record?.meetingDatePrev).format("DD MMM, YYYY")}{" "}
                    <span className="text-[10px] font-semibold ml-2">
                      Previous Date
                    </span>
                  </div>
                }
                color="yellow"
              />
              <StatusIndicator
                showarrow={false}
                text={
                  <div>
                    {moment(record?.meetingDate).format("DD MMM, YYYY")}{" "}
                    <span className="text-[10px] font-semibold ml-2">
                      New Date
                    </span>
                  </div>
                }
                color="green"
              />
            </div>
          ) : (
            <div>{moment(record?.meetingDate).format("DD MMM, YYYY")}</div>
          )}
        </div>
      ),
    },
    {
      title: "Meeting Time",
      key: "3",
      width: 150,
      align: "center",
      render: (text, record) => (
        <div>
          {record?.status === "rescheduled" ? (
            <div className="flex flex-col gap-2 justify-center items-center">
              <StatusIndicator
                showarrow={false}
                text={
                  <div>
                    {record?.timeSlotPrev}{" "}
                    <span className="text-[10px] font-semibold ml-2">
                      Previous Date
                    </span>
                  </div>
                }
                color="yellow"
              />
              <StatusIndicator
                showarrow={false}
                text={
                  <div>
                    {record?.timeSlot}{" "}
                    <span className="text-[10px] font-semibold ml-2">
                      New Date
                    </span>
                  </div>
                }
                color="green"
              />
            </div>
          ) : (
            <div>{record?.timeSlot}</div>
          )}
        </div>
      ),
    },
    {
      title: "Meeting Status",
      key: "4",
      width: 50,
      align: "center",
      render: (text, record) => (
        <div className="flex items-center justify-center">
          <StatusIndicator
            showarrow={false}
            text={record?.status}
            color={
              record?.status === "accepted"
                ? "green"
                : record?.status === "pending"
                ? "yellow"
                : "blue"
            }
          />
        </div>
      ),
    },
  ];
  return (
    <div className="text-black">
      <tables.MeetingTable
        columns={columns}
        data={meetingSent}
        rowKey={(record) => record.id}
        loading={isMeetingSentLoading}
      />
      <div className="flex justify-center items-center p-3">
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={pageNo}
          pageSize={limit}
          total={meetingSentCount}
          onChange={handlePagination}
        />
      </div>
    </div>
  );
}
export default MeetingsSent;
