import {
  ADD_APPLICATIONS,
  ADD_APPLICATIONS_SUCCESS,
  ADD_APPLICATIONS_FAILURE,
  CHANGE_APP_STATUS,
  CHANGE_APP_STATUS_SUCCESS,
  CHANGE_APP_STATUS_FAILURE,
  GET_SUBMITTED_APPLICATIONS,
  GET_SUBMITTED_APPLICATIONS_SUCCESS,
  GET_SUBMITTED_APPLICATIONS_FAILURE,
  GET_ONGOING_APPLICATIONS,
  GET_ONGOING_APPLICATIONS_SUCCESS,
  GET_ONGOING_APPLICATIONS_FAILURE,
  UPLOAD_APPLICATION_PROOF,
  UPLOAD_APPLICATION_PROOF_SUCCESS,
  UPLOAD_APPLICATION_PROOF_FAILURE,
} from "../constants";

const initialState = {
  ongoingApplications: [],
  submittedApplications: [],
  ongoingApplicationsCount: 0,
  submittedApplicationsCount: 0,
  application: {},
  isLoading: false,
  isOngoingLoading: false,
  isSubmittedLoading: false,
  error: null,
};

export default function ApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APPLICATIONS:
    case CHANGE_APP_STATUS:
    case UPLOAD_APPLICATION_PROOF:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ONGOING_APPLICATIONS:
      state = {
        ...state,
        isOngoingLoading: true,
        error: null,
      };
      break;
    case GET_SUBMITTED_APPLICATIONS:
      state = {
        ...state,
        isSubmittedLoading: true,
        error: null,
      };
      break;
    case GET_ONGOING_APPLICATIONS_SUCCESS:
      state = {
        ...state,
        ongoingApplications: action.payload.applications,
        ongoingApplicationsCount: action.payload.count,
        isOngoingLoading: false,
      };
      break;
    case GET_SUBMITTED_APPLICATIONS_SUCCESS:
      state = {
        ...state,
        submittedApplications: action.payload.applications,
        submittedApplicationsCount: action.payload.count,
        isSubmittedLoading: false,
      };
      break;
    case ADD_APPLICATIONS_SUCCESS:
    case CHANGE_APP_STATUS_SUCCESS:
    case UPLOAD_APPLICATION_PROOF_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
    case ADD_APPLICATIONS_FAILURE:
    case CHANGE_APP_STATUS_FAILURE:
    case UPLOAD_APPLICATION_PROOF_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_ONGOING_APPLICATIONS_FAILURE:
      state = {
        ...state,
        isOngoingLoading: false,
        error: action.payload,
      };
      break;
    case GET_SUBMITTED_APPLICATIONS_FAILURE:
      state = {
        ...state,
        isSubmittedLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
