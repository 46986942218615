import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import RootReducer from "./reducers";
import { Sagas } from "./sagas/Sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV !== "production"
      ? getDefaultMiddleware({ serializableCheck: false }).concat(
          createLogger(),
          sagaMiddleware
        )
      : getDefaultMiddleware({ serializableCheck: false }).concat(
          sagaMiddleware
        ),
});

let persistor = persistStore(store);

sagaMiddleware.run(Sagas);

export { store, persistor };
