import React from "react";
import { Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { Spacer } from "../../components";

const { Panel } = Collapse;

function FAQ() {
  return (
    <div className="text-black p-3 h-[calc(100vh-220px)] overflow-auto">
      <Spacer height={5} />
      <div className="text-[20px] font-medium">User Account Onboarding</div>
      <Spacer height={20} />
      <Collapse
        bordered={false}
        accordion={true}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} className="h-[16px]" />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="How do I add more students accounts?"
          key="1"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="When will a new student be added to our billing?"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="How do I cancel a students subscription?"
          key="3"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="Is there a limit to how many students I can have?"
          key="4"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
      </Collapse>
      <Spacer height={20} />
      <div className="text-[20px] font-medium">Staff Account Onboarding</div>
      <Spacer height={20} />
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} className="h-[16px]" />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="How do I add more staff accounts?"
          key="1"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="Do we pay for staff accounts?"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="How do I remove staff account?"
          key="3"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="How can I make a staff aacount an admin?"
          key="4"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="Is there a limit to how many staff account I can have?"
          key="5"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
      </Collapse>
      <Spacer height={20} />
      <div className="text-[20px] font-medium">Payment</div>
      <Spacer height={20} />
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} className="h-[16px]" />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="How much does my bill cost?"
          key="1"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="When do I need to pay?"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="How do I pay?"
          key="3"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
      </Collapse>
      <Spacer height={20} />
      <div className="text-[20px] font-medium">Renewal Process</div>
      <Spacer height={20} />
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} className="h-[16px]" />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="When is my renewal period?"
          key="1"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
        <Panel
          header="What happens if I miss my renewal period?"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>
            You can add more staff accounts in the admin section. Go to "Add
            more staff" and enter the new staff account's email address. An
            account will be created for this email and an activation email will
            be sent to the account
            <br />
            <br />
            Note that only an admin account can add more staff accounts.
          </p>
        </Panel>
      </Collapse>
    </div>
  );
}

export default FAQ;
