import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import { ChatbotAction } from "../../store/actions";

import { tabs, boxContainer, Spacer } from "../../components";

import CounsellorChat from "./counsellor-chat";
import SupportChat from "./support-chat";

import "./styles.css";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket"],
  withCredentials: true,
});

function Chatbot() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");

    if (tabParam) {
      setActiveTab(tabParam);
    } else {
      if (profileData?.student?.studentType === "schoolStudent") {
        setActiveTab("counsellor");
      } else {
        setActiveTab("support");
      }
    }
  }, [profileData]);

  useEffect(() => {
    const payload = {
      schoolId: profileData?.student?.schoolId,
    };
    dispatch(ChatbotAction.getUsers(payload));
  }, []);

  useEffect(() => {
    const handleUser = () => {
      const payload = {
        schoolId: profileData?.student?.schoolId,
      };
      dispatch(
        ChatbotAction.getUsers(payload, () => {
          dispatch(
            ChatbotAction.getUnreadMessages({
              from: profileData?.id,
            })
          );
        })
      );
    };

    socket.on("message-sent", handleUser);
    socket.on("message-received", handleUser);

    return () => {
      socket.off("message-sent", handleUser);
      socket.off("message-received", handleUser);
    };
  }, [profileData]);

  const tabItems =
    profileData?.student?.studentType === "schoolStudent"
      ? [
          {
            title: "Chat with Counselor",
            value: "counsellor",
          },
          { title: "Chat With Support", value: "support" },
        ]
      : [{ title: "Chat With Support", value: "support" }];

  return (
    <div className="text-black">
      <tabs.DefaultTab
        tabs={tabItems}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <Spacer height={20} />
      <boxContainer.LiteBoxContainer addstyle="px-0 py-0">
        {activeTab === "counsellor" ? <CounsellorChat /> : <SupportChat />}
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default Chatbot;
