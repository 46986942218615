import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { Images, Utils } from "../../config";

import { boxContainer, Spacer, Charts, Buttons } from "../../components";

const General = (props) => {
  const data = props?.uniData;

  const profileData = [
    {
      icon: Images.Unigeneraltotalundergrad,
      title: "Total Undergraduate Students",
      value: data?.totalUgStudents
        ? Utils.numWithCommas(data?.totalUgStudents)
        : "NA",
    },
    {
      icon: Images.Unigeneralavgclasssize,
      title: "Average Class Size",
      value: data?.avgClassSize ?? "NA",
    },
    {
      icon: Images.Unigeneralfacultystudentratio,
      title: "Faculty to Student Ratio",
      value: data?.facultyToStudentRatio ?? "NA",
    },
    {
      icon: Images.Unigeneralmaletofemale,
      title: "Male to Female Ratio",
      value: data?.maleToFemaleRatio ?? "NA",
    },
  ];
  const rankingData = [
    {
      icon: Images.Unigeneralqs,
      title: "QS World",
      value: data?.rankings?.QS ? `#${data?.rankings?.QS}` : "NA",
    },
    {
      icon: Images.Unigeneralcwur,
      title: "CWUR",
      value: data?.rankings?.CWUR ? `#${data?.rankings?.CWUR}` : "NA",
    },
    {
      icon: Images.Unigeneraltimes,
      title: "Times",
      value: data?.rankings?.TIMES ? `#${data?.rankings?.TIMES}` : "NA",
    },
  ];
  const coreInfoDta = [
    {
      icon: Images.Unigeneralunitype,
      title: "University Type",
      value: data?.Type?.type ?? "NA",
    },
    {
      icon: Images.Unigeneralacceptancerate,
      title: "Acceptance Rate",
      value: data?.acceptanceRate ? `${data?.acceptanceRate * 100}%` : "NA",
    },
    {
      icon: Images.Unigeneralavgcourseduration,
      title: "Average Course Duration",
      value: data?.courseDuration ?? "NA",
    },
    {
      icon: Images.Unigenerallocationtype,
      title: "Location Type",
      value: data?.LocationType?.locationType ?? "NA",
    },
    {
      icon: Images.Unigeneralresearchoutput,
      title: "Research Output",
      value: data?.researchOutput ?? "NA",
    },
  ];
  const pieChartData = [
    {
      label: "GAP Year or Seeking Employment",
      value: data?.placementRate ? 100 - data?.placementRate * 100 : null,
      color: "#d70d57",
    },
    {
      label: "Employed or in Graduate School",
      value: data?.placementRate ? data?.placementRate * 100 : null,
      color: "#3ad1bb",
    },
  ];
  const facilitiesAvailableData = [
    {
      icon: Images.Unigenerallibrary,
      title: "Library",
    },
    {
      icon: Images.Unigeneralprinting,
      title: "Printing",
    },
    {
      icon: Images.Unigeneralcafe,
      title: "Cafe",
    },
    {
      icon: Images.Unigeneralaid,
      title: "First Aid",
    },
  ];
  const publicTransportData = [
    {
      icon: Images.Unigeneraltrain,
      title: "Metro",
      value: data?.studentLife?.trainDistance
        ? `${data?.studentLife?.trainDistance}km Away`
        : "NA",
    },
    {
      icon: Images.Unigeneralbus,
      title: "Bus",
      value: data?.studentLife?.busDistance
        ? `${data?.studentLife?.busDistance}km Away`
        : "NA",
    },
  ];
  return (
    <div>
      <div className="grid grid-cols-3 grid-rows-2 gap-[20px]">
        <div className="flex gap-2 flex-col row-span-2 h-full">
          <div className="box-title">Profile</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex flex-col gap-4 py-3 px-2 justify-center h-full">
              {profileData?.map((item, index) => (
                <div
                  className={`box-content-container ${
                    index !== profileData?.length - 1 &&
                    "box-content-border pb-[20px]"
                  }`}
                  key={index}
                >
                  <div className="box-icons-container">
                    <img src={item?.icon} />
                  </div>
                  <div>
                    <div className="box-content-title">{item?.title}</div>
                    <div className="box-content-value">{item?.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-1 col-span-1">
          <div className="box-title">Ranking</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex justify-around items-center h-full flex-wrap">
              {rankingData?.map((item, index) => (
                <div
                  className="flex flex-col gap-2 items-center justify-center"
                  key={index}
                >
                  <img src={item?.icon} className="h-[60px] w-[60px]" />
                  <div className="box-content-title pb-2 border-b-[2px] border-b-gray-300 w-full text-center">
                    {item.title}
                  </div>
                  <div className="box-content-value">{item.value}</div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-1 col-span-1">
          <div className="box-title">Post Graduation</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex gap-2 items-center h-full">
              {data?.placementRate && (
                <div className="w-[240px] ">
                  <Charts.PieChartUniGeneral data={pieChartData} />
                </div>
              )}
              <div className="w-full">
                <div className="flex flex-col gap-1 pb-3 border-b-[2px] border-b-gray-300">
                  {pieChartData?.map((item, index) => (
                    <div
                      className="flex gap-2 items-center font-semibold text-[14px]"
                      key={index}
                    >
                      <div
                        style={{
                          backgroundColor: item.color,
                          width: "20px",
                          height: "10px",
                        }}
                      ></div>
                      {item.label}
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 items-center pt-3">
                  <div className="h-[32px] w-[32px] flex justify-center items-center">
                    <img src={Images.Unigeneralavgannualgradsalary} />
                  </div>
                  <div>
                    <div className="text-[14px] font-semibold">
                      Average Annual Fresh Graduate Salary
                    </div>
                    <div className="box-content-value">
                      {data?.postGrads?.gradSalary ?? "NA"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-1 col-span-2">
          <div className="box-title">Core Information</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex justify-around items-center h-full">
              {coreInfoDta?.map((item, index) => (
                <div
                  className="flex flex-col gap-2 items-center justify-center"
                  key={index}
                >
                  <img src={item?.icon} className="h-[60px] w-[60px]" />
                  <div className="box-content-title pb-2 border-b-[2px] border-b-gray-300 w-full text-center">
                    {item.title}
                  </div>
                  <div className="font-bold text-[18px]">{item.value}</div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
      <Spacer height={20} />
      <div className="grid grid-cols-4 grid-rows-2 gap-[20px]">
        <div className="flex gap-2 flex-col row-span-2 col-span-2">
          <div className="box-title">Student Life</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div>
              <div className="flex justify-between items-center">
                <div className="text-[16px] font-semibold">
                  Nearby Food Availibility
                </div>
                <div
                  className={`text-[14px] py-2 px-3 font-semibold flex gap-3 items-center`}
                >
                  Halal Food Availibility:{" "}
                  <span
                    className={`text-white rounded-md px-2 py-0.5 ${
                      data?.studentLife?.halalFoodOption?.halalFoodOption ===
                      "Easy"
                        ? "bg-green-600"
                        : data?.studentLife?.halalFoodOption
                            ?.halalFoodOption === "Medium"
                        ? "bg-yellow-500 "
                        : "bg-red-600 "
                    }`}
                  >
                    {data?.studentLife?.halalFoodOption?.halalFoodOption}
                  </span>
                </div>
              </div>
              <Spacer height={20} />
              <div className="grid grid-cols-2 justify-center flex-wrap gap-x-[30px] gap-y-3">
                {data?.restaurants?.map((item, index) => (
                  <div key={index} className="flex gap-4 items-center">
                    <img
                      src={item?.imageURL ?? Images.Unigeneraldefaultrestaurant}
                      className="restaurant-image-container"
                    />
                    <div>
                      <div className="box-content-title">
                        {item?.restaurantName}
                      </div>
                      <div className="box-content-value">
                        {item?.distance}km Away
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-2 col-span-1">
          <div className="box-title">Facilities Available</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex flex-col gap-4 px-2 items-center justify-around h-full">
              <div className="flex flex-col gap-[25px] justify-center pl-[40px] mt-[16px] ml-[30px] mr-[20px] border-l-[2px] border-dashed border-l-gray-300 w-full">
                {facilitiesAvailableData?.map((item, index) => (
                  <div
                    className={`box-content-container justify-between relative pr-4 ${
                      index !== facilitiesAvailableData?.length - 1 &&
                      "box-content-border pb-[25px]"
                    }`}
                    key={index}
                  >
                    <img
                      src={Images.Unigeneralfacilities}
                      className="absolute h-[18px] w-[18px] left-[-50px] top-0"
                    />
                    <div className="box-icons-container">
                      <img src={item?.icon} />
                    </div>

                    <div>
                      <div className="text-[16px] font-semibold">
                        {item?.title}
                      </div>
                    </div>
                    <div className="box-icons-container">
                      <img src={Images.GreenCheck} />
                    </div>
                  </div>
                ))}
              </div>
              <Link
                to={data?.facilitiesLink}
                target="_blank"
                className="w-[90%]"
              >
                <Buttons.DefaultButton width="full">
                  Check Out More
                </Buttons.DefaultButton>
              </Link>
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-1 col-span-1">
          <div className="box-title">Public Transport</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="flex flex-col gap-3 px-2 justify-center h-full">
              {publicTransportData?.map((item, index) => (
                <div
                  className={`box-content-container ${
                    index !== publicTransportData?.length - 1 &&
                    "box-content-border"
                  }`}
                  key={index}
                >
                  <div className="box-icons-container">
                    <img src={item?.icon} />
                  </div>
                  <div>
                    <div className="box-content-title">{item?.title}</div>
                    <div className="box-content-value">{item?.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="flex gap-2 flex-col row-span-1 col-span-1">
          <div className="box-title">Application Deadline</div>
          <boxContainer.LiteBoxContainer addstyle={"h-full"}>
            {data?.deadlines?.length === 0 ? (
              <div className={`box-content-container box-content-border`}>
                <div className="box-icons-container">
                  <img src={Images.Unigeneralappdeadline} />
                </div>
                <div>
                  <div className="box-content-title">Application Deadline</div>
                  <div className="box-content-value">N/A</div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-3 px-2 justify-center">
                <div className={`box-content-container box-content-border`}>
                  <div className="box-icons-container">
                    <img src={Images.Unigeneralappdeadline} />
                  </div>
                  <div>
                    <div className="box-content-title">
                      {data?.deadlines[0]?.deadlineType ??
                        "Application Deadline"}
                    </div>
                    <div className="box-content-value">
                      {data?.deadlines[0]?.appDeadline
                        ? moment(data?.deadlines[0]?.appDeadline).format(
                            "DD MMMM, YYYY"
                          )
                        : "NA"}
                    </div>
                  </div>
                </div>
                <div className={`box-content-container `}>
                  <div className="box-icons-container">
                    <img src={Images.Unigeneralappdeadline} />
                  </div>
                  <div>
                    <div className="box-content-title">Result Date</div>
                    <div className="box-content-value">
                      {data?.deadlines[0]?.resultDate
                        ? moment(data?.deadlines[0]?.resultDate).format(
                            "DD MMMM, YYYY"
                          )
                        : "NA"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
    </div>
  );
};

export default General;
