//--------------login flow
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SIGNUPSTUDENT = "SIGNUPSTUDENT";
export const SIGNUPSTUDENT_SUCCESS = "SIGNUPSTUDENT_SUCCESS";
export const SIGNUPSTUDENT_FAILURE = "SIGNUPSTUDENT_FAILURE";

export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const VERIFY_SCHOOL_CODE = "VERIFY_SCHOOL_CODE";
export const VERIFY_SCHOOL_CODE_SUCCESS = "VERIFY_SCHOOL_CODE_SUCCESS";
export const VERIFY_SCHOOL_CODE_FAILURE = "VERIFY_SCHOOL_CODE_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

//-------------universities
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITIES_FAILURE = "GET_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITIES_GRID = "GET_UNIVERSITIES_GRID";
export const GET_UNIVERSITIES_GRID_SUCCESS = "GET_UNIVERSITIES_GRID_SUCCESS";
export const GET_UNIVERSITIES_GRID_FAILURE = "GET_UNIVERSITIES_GRID_FAILURE";

export const GET_ALL_UNIVERSITIES = "GET_ALL_UNIVERSITIES";
export const GET_ALL_UNIVERSITIES_SUCCESS = "GET_ALL_UNIVERSITIES_SUCCESS";
export const GET_ALL_UNIVERSITIES_FAILURE = "GET_ALL_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITY_DETAILS = "GET_UNIVERSITY_DETAILS";
export const GET_UNIVERSITY_DETAILS_SUCCESS = "GET_UNIVERSITY_DETAILS_SUCCESS";
export const GET_UNIVERSITY_DETAILS_FAILURE = "GET_UNIVERSITY_DETAILS_FAILURE";

export const ADD_OR_REMOVE_FROM_WISHLIST = "ADD_OR_REMOVE_FROM_WISHLIST";
export const ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS =
  "ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS";
export const ADD_OR_REMOVE_FROM_WISHLIST_FAILURE =
  "ADD_OR_REMOVE_FROM_WISHLIST_FAILURE";

export const COMPARE_UNIVERSITIES = "COMPARE_UNIVERISTIES";
export const COMPARE_UNIVERISTIES_SUCCESS = "COMPARE_UNIVERISTIES_SUCCESS";
export const COMPARE_UNIVERISTIES_FAILURE = "COMPARE_UNIVERISTIES_FAILURE";

export const GET_UNIS_BY_BUDGET = "GET_UNIS_BY_BUDGET";
export const GET_UNIS_BY_BUDGET_SUCCESS = "GET_UNIS_BY_BUDGET_SUCCESS";
export const GET_UNIS_BY_BUDGET_FAILURE = "GET_UNIS_BY_BUDGET_FAILURE";

export const GET_POPULAR_COUNTRIES = "GET_POPULAR_COUNTRIES";
export const GET_POPULAR_COUNTRIES_SUCCESS = "GET_POPULAR_COUNTRIES_SUCCESS";
export const GET_POPULAR_COUNTRIES_FAILURE = "GET_POPULAR_COUNTRIES_FAILURE";

export const ADD_OR_REMOVE_STAY_CONNECTED = "ADD_OR_REMOVE_STAY_CONNECTED";
export const ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS =
  "ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS";
export const ADD_OR_REMOVE_STAY_CONNECTED_FAILURE =
  "ADD_OR_REMOVE_STAY_CONNECTED_FAILURE";

//students
export const GET_STUDENT_WISHLIST = "GET_STUDENT_WISHLIST";
export const GET_STUDENT_WISHLIST_SUCCESS = "GET_STUDENT_WISHLIST_SUCCESS";
export const GET_STUDENT_WISHLIST_FAILURE = "GET_STUDENT_WISHLIST_FAILURE";

export const SEND_STUDENT_EMAIL = "SEND_STUDENT_EMAIL";
export const SEND_STUDENT_EMAIL_SUCCESS = "SEND_STUDENT_EMAIL_SUCCESS";
export const SEND_STUDENT_EMAIL_FAILURE = "SEND_STUDENT_EMAIL_FAILURE";

//profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//contact
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

//Staff
export const GET_COUNSELORS = "GET_COUNSELORS";
export const GET_COUNSELORS_SUCCESS = "GET_COUNSELORS_SUCCESS";
export const GET_COUNSELORS_FAILURE = "GET_COUNSELORS_FAILURE";

//meetings
export const ADD_MEETING = "ADD_MEETING";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const ADD_MEETING_FAILURE = "ADD_MEETING_FAILURE";

export const RESCHEDULE_MEETING = "RESCHEDULE_MEETING";
export const RESCHEDULE_MEETING_SUCCESS = "RESCHEDULE_MEETING_SUCCESS";
export const RESCHEDULE_MEETING_FAILURE = "RESCHEDULE_MEETING_FAILURE";

export const GET_MEETING_DETAILS = "GET_MEETING_DETAILS";
export const GET_MEETING_DETAILS_SUCCESS = "GET_MEETING_DETAILS_SUCCESS";
export const GET_MEETING_DETAILS_FAILURE = "GET_MEETING_DETAILS_FAILURE";

export const GET_MEETINGS = "GET_MEETINGS";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_FAILURE = "GET_MEETINGS_FAILURE";

export const GET_MEETINGS_INVITES = "GET_MEETINGS_INVITES";
export const GET_MEETINGS_INVITES_SUCCESS = "GET_MEETINGS_INVITES_SUCCESS";
export const GET_MEETINGS_INVITES_FAILURE = "GET_MEETINGS_INVITES_FAILURE";

export const GET_MEETINGS_REQUESTS = "GET_MEETINGS_REQUESTS";
export const GET_MEETINGS_REQUESTS_SUCCESS = "GET_MEETINGS_REQUESTS_SUCCESS";
export const GET_MEETINGS_REQUESTS_FAILURE = "GET_MEETINGS_REQUESTS_FAILURE";

export const GET_UPCOMING_MEETINGS = "GET_UPCOMING_MEETINGS";
export const GET_UPCOMING_MEETINGS_SUCCESS = "GET_UPCOMING_MEETINGS_SUCCESS";
export const GET_UPCOMING_MEETINGS_FAILURE = "GET_UPCOMING_MEETINGS_FAILURE";

export const GET_ALL_MEETINGS_AND_COUNT = "GET_ALL_MEETINGS_AND_COUNT";
export const GET_ALL_MEETINGS_AND_COUNT_SUCCESS =
  "GET_ALL_MEETINGS_AND_COUNT_SUCCESS";
export const GET_ALL_MEETINGS_AND_COUNT_FAILURE =
  "GET_ALL_MEETINGS_AND_COUNT_FAILURE";

export const GET_MEETINGS_BASED_ON_DATE = "GET_MEETINGS_BASED_ON_DATE";
export const GET_MEETINGS_BASED_ON_DATE_SUCCESS =
  "GET_MEETINGS_BASED_ON_DATE_SUCCESS";
export const GET_MEETINGS_BASED_ON_DATE_FAILURE =
  "GET_MEETINGS_BASED_ON_DATE_FAILURE";

export const CHANGE_MEETING_STATUS = "CHANGE_MEETING_STATUS";
export const CHANGE_MEETING_STATUS_SUCCESS = "CHANGE_MEETING_STATUS_SUCCESS";
export const CHANGE_MEETING_STATUS_FAILURE = "CHANGE_MEETING_STATUS_FAILURE";

// deadlines
export const GET_DEADLINES = "GET_DEADLINES";
export const GET_DEADLINES_SUCCESS = "GET_DEADLINES_SUCCESS";
export const GET_DEADLINES_FAILURE = "GET_DEADLINES_FAILURE";

//applications
export const GET_SUBMITTED_APPLICATIONS = "GET_SUBMITTED_APPLICATIONS";
export const GET_SUBMITTED_APPLICATIONS_SUCCESS =
  "GET_SUBMITTED_APPLICATIONS_SUCCESS";
export const GET_SUBMITTED_APPLICATIONS_FAILURE =
  "GET_SUBMITTED_APPLICATIONS_FAILURE";

export const GET_ONGOING_APPLICATIONS = "GET_ONGOING_APPLICATIONS";
export const GET_ONGOING_APPLICATIONS_SUCCESS =
  "GET_ONGOING_APPLICATIONS_SUCCESS";
export const GET_ONGOING_APPLICATIONS_FAILURE =
  "GET_ONGOING_APPLICATIONS_FAILURE";

export const ADD_APPLICATIONS = "ADD_APPLICATIONS";
export const ADD_APPLICATIONS_SUCCESS = "ADD_APPLICATIONS_SUCCESS";
export const ADD_APPLICATIONS_FAILURE = "ADD_APPLICATIONS_FAILURE";

export const CHANGE_APP_STATUS = "CHANGE_APP_STATUS";
export const CHANGE_APP_STATUS_SUCCESS = "CHANGE_APP_STATUS_SUCCESS";
export const CHANGE_APP_STATUS_FAILURE = "CHANGE_APP_STATUS_FAILURE";

export const UPLOAD_APPLICATION_PROOF = "UPLOAD_APPLICATION_PROOF";
export const UPLOAD_APPLICATION_PROOF_SUCCESS =
  "UPLOAD_APPLICATION_PROOF_SUCCESS";
export const UPLOAD_APPLICATION_PROOF_FAILURE =
  "UPLOAD_APPLICATION_PROOF_FAILURE";

//notifications
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const ADD_NOTIFICATIONS_SUCCESS = "ADD_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_FAILURE = "ADD_NOTIFICATIONS_FAILURE";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const CHANGE_NOTIFICATIONS_STATUS = "CHANGE_NOTIFICATIONS_STATUS";
export const CHANGE_NOTIFICATIONS_STATUS_SUCCESS =
  "CHANGE_NOTIFICATIONS_STATUS_SUCCESS";
export const CHANGE_NOTIFICATIONS_STATUS_FAILURE =
  "CHANGE_NOTIFICATIONS_STATUS_FAILURE";

//recommender
export const GET_RECOMMENDER = "GET_RECOMMENDER";
export const GET_RECOMMENDER_SUCCESS = "GET_RECOMMENDER_SUCCESS";
export const GET_RECOMMENDER_FAILURE = "GET_RECOMMENDER_FAILURE";

//marketplace products
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_STUDENT_PRODUCT = "GET_STUDENT_PRODUCT";
export const GET_STUDENT_PRODUCT_SUCCESS = "GET_STUDENT_PRODUCT_SUCCESS";
export const GET_STUDENT_PRODUCT_FAILURE = "GET_STUDENT_PRODUCT_FAILURE";

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

// chatbot
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";
export const GET_UNREAD_MESSAGES_SUCCESS = "GET_UNREAD_MESSAGES_SUCCESS";
export const GET_UNREAD_MESSAGES_FAILURE = "GET_UNREAD_MESSAGES_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// interactive learning
export const GET_UDEMY_COURSES = "GET_UDEMY_COURSES";
export const GET_UDEMY_COURSES_SUCCESS = "GET_UDEMY_COURSES_SUCCESS";
export const GET_UDEMY_COURSES_FAILURE = "GET_UDEMY_COURSES_FAILURE";

// etextbooks
export const GET_ETEXTBOOKS = "GET_ETEXTBOOKS";
export const GET_ETEXTBOOKS_SUCCESS = "GET_ETEXTBOOKS_SUCCESS";
export const GET_ETEXTBOOKS_FAILURE = "GET_ETEXTBOOKS_FAILURE";

// questionnaire
export const ADD_QUESTIONNAIRE = "ADD_QUESTIONNAIRE";
export const ADD_QUESTIONNAIRE_SUCCESS = "ADD_QUESTIONNAIRE_SUCCESS";
export const ADD_QUESTIONNAIRE_FAILURE = "ADD_QUESTIONNAIRE_FAILURE";

export const GET_QUESTIONNAIRE = "GET_QUESTIONNAIRE";
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_FAILURE = "GET_QUESTIONNAIRE_FAILURE";

export const UPDATE_QUESTIONNAIRE_PREF = "UPDATE_QUESTIONNAIRE_PREF";
export const UPDATE_QUESTIONNAIRE_PREF_SUCCESS =
  "UPDATE_QUESTIONNAIRE_PREF_SUCCESS";
export const UPDATE_QUESTIONNAIRE_PREF_FAILURE =
  "UPDATE_QUESTIONNAIRE_PREF_FAILURE";

export const UPLOAD_IMAGE_QUESTIONNAIRE = "UPLOAD_IMAGE_QUESTIONNAIRE";
export const UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS =
  "UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS";
export const UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE =
  "UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE";

// individual student
export const UPDATE_SHOW_TRIAL_MODAL = "UPDATE_SHOW_TRIAL_MODAL";
export const UPDATE_SHOW_TRIAL_MODAL_SUCCESS =
  "UPDATE_SHOW_TRIAL_MODAL_SUCCESS";
export const UPDATE_SHOW_TRIAL_MODAL_FAILURE =
  "UPDATE_SHOW_TRIAL_MODAL_FAILURE";

export const CREATE_CHECKOUT_SESSION_MONTHLY =
  "CREATE_CHECKOUT_SESSION_MONTHLY";
export const CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS =
  "CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS";
export const CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE =
  "CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE";

export const RETRIEVE_SESSION = "RETRIEVE_SESSION";
export const RETRIEVE_SESSION_SUCCESS = "RETRIEVE_SESSION_SUCCESS";
export const RETRIEVE_SESSION_FAILURE = "RETRIEVE_SESSION_FAILURE";

export const CREATE_CHECKOUT_SESSION_ONETIME =
  "CREATE_CHECKOUT_SESSION_ONETIME";
export const CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS =
  "CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS";
export const CREATE_CHECKOUT_SESSION_ONETIME_FAILURE =
  "CREATE_CHECKOUT_SESSION_ONETIME_FAILURE";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

// others
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_ONLINE_USERS = "GET_ONLINE_USERS";
export const GET_ONLINE_USERS_SUCCESS = "GET_ONLINE_USERS_SUCCESS";
export const GET_ONLINE_USERS_FAILURE = "GET_ONLINE_USERS_FAILURE";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const GET_VISA_INFO = "GET_VISA_INFO";
export const GET_VISA_INFO_SUCCESS = "GET_VISA_INFO_SUCCESS";
export const GET_VISA_INFO_FAILURE = "GET_VISA_INFO_FAILURE";

export const GET_PROGRAM_TYPES = "GET_PROGRAM_TYPES";
export const GET_PROGRAM_TYPES_SUCCESS = "GET_PROGRAM_TYPES_SUCCESS";
export const GET_PROGRAM_TYPES_FAILURE = "GET_PROGRAM_TYPES_FAILURE";

export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAILURE = "GET_PROGRAMS_FAILURE";

export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAILURE = "GET_SCHOOLS_FAILURE";

export const GET_TOP_STORIES = "GET_TOP_STORIES";
export const GET_TOP_STORIES_SUCCESS = "GET_TOP_STORIES_SUCCESS";
export const GET_TOP_STORIES_FAILURE = "GET_TOP_STORIES_FAILURE";

export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAILURE = "GET_TASKS_FAILURE";

export const GET_SUGGESTIONS = "GET_SUGGESTIONS";
export const GET_SUGGESTIONS_SUCCESS = "GET_SUGGESTIONS_SUCCESS";
export const GET_SUGGESTIONS_FAILURE = "GET_SUGGESTIONS_FAILURE";

export const GET_POPULAR_SUBS = "GET_POPULAR_SUBS";
export const GET_POPULAR_SUBS_SUCCESS = "GET_POPULAR_SUBS_SUCCESS";
export const GET_POPULAR_SUBS_FAILURE = "GET_POPULAR_SUBS_FAILURE";

export const UPDATE_ESSAY_GRADER_COUNT = "UPDATE_ESSAY_GRADER_COUNT";
export const UPDATE_ESSAY_GRADER_COUNT_SUCCESS =
  "UPDATE_ESSAY_GRADER_COUNT_SUCCESS";
export const UPDATE_ESSAY_GRADER_COUNT_FAILURE =
  "UPDATE_ESSAY_GRADER_COUNT_FAILURE";

export const GET_PAYMENT_PLANS = "GET_PAYMENT_PLANS";
export const GET_PAYMENT_PLANS_SUCCESS = "GET_PAYMENT_PLANS_SUCCESS";
export const GET_PAYMENT_PLANS_FAILURE = "GET_PAYMENT_PLANS_FAILURE";

// internships
export const GET_INTERNSHIPS = "GET_INTERNSHIPS";
export const GET_INTERNSHIPS_SUCCESS = "GET_INTERNSHIPS_SUCCESS";
export const GET_INTERNSHIPS_FAILURE = "GET_INTERNSHIPS_FAILURE";

// search
export const UNI_SEARCH = "UNI_SEARCH";
export const UNI_SEARCH_SUCCESS = "UNI_SEARCH_SUCCESS";
export const UNI_SEARCH_FAILURE = "UNI_SEARCH_FAILURE";

export const SHORTLIST_SEARCH = "SHORTLIST_SEARCH";
export const SHORTLIST_SEARCH_SUCCESS = "SHORTLIST_SEARCH_SUCCESS";
export const SHORTLIST_SEARCH_FAILURE = "SHORTLIST_SEARCH_FAILURE";

export const EXPLORE_SEARCH = "EXPLORE_SEARCH";
export const EXPLORE_SEARCH_SUCCESS = "EXPLORE_SEARCH_SUCCESS";
export const EXPLORE_SEARCH_FAILURE = "EXPLORE_SEARCH_FAILURE";
