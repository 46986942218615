import DefaultTable from "./default-table";
import MeetingTable from "./meeting-table";
import RecommenderTable from "./recommender-table";
import UniProgramsTable from "./uni-programs-table";

export default {
  DefaultTable,
  MeetingTable,
  RecommenderTable,
  UniProgramsTable,
};
