import AppjourneySteps from "./appjourneys-steps";
import Compare from "./compare";
import ExploreSwiper from "./explore-swiper";
import ExploreCard from "./explore-card";
import GridViewCard from "./grid-view-card";
import GridViewSwiper from "./grid-view-swiper";
import UniversityInfoModal from "./university-info-modal";

export default {
  AppjourneySteps,
  Compare,
  ExploreSwiper,
  ExploreCard,
  GridViewCard,
  GridViewSwiper,
  UniversityInfoModal,
};
