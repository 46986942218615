import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

import { FaArrowCircleLeft } from "react-icons/fa";

import ChatbotPopupContact from "./chatbot-popup-contact";
import ChatbotPopupChat from "./chatbot-popup-chats";

const ChatbotPopupCounselor = () => {
  const socket = useRef();

  const { counselors } = useSelector((state) => state.chatbot);
  const profileData = useSelector((state) => state.profile?.user);

  const [activeUser, setActiveUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setCurrentUser(profileData.id);
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL);
    }
  }, [currentUser]);

  const handleActiveUser = (user) => {
    setActiveUser(user);
  };

  return (
    <div className="w-full relative">
      {activeUser && (
        <FaArrowCircleLeft
          className="absolute -top-11 -left-2 text-main text-[24px] cursor-pointer"
          onClick={() => setActiveUser(null)}
        />
      )}
      {!activeUser ? (
        <ChatbotPopupContact
          contacts={counselors}
          changeChat={handleActiveUser}
          activeUser={activeUser}
        />
      ) : (
        <ChatbotPopupChat currentChat={activeUser} socket={socket} />
      )}
    </div>
  );
};

export default ChatbotPopupCounselor;
