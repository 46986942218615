import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const Activities = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [extraActivities, setExtraActivities] = useState([
    {
      orgName: "",
      timeSpent: "",
      position: "",
      certificate: "",
    },
  ]);

  useEffect(() => {
    setExtraActivities(
      questionnaire?.extraActivities?.length > 0
        ? questionnaire?.extraActivities
        : [
            {
              orgName: "",
              timeSpent: "",
              position: "",
              certificate: "",
            },
          ]
    );
  }, [questionnaire]);

  const addExtraCurricular = () => {
    const lastActivity = extraActivities[extraActivities.length - 1];

    if (
      lastActivity?.orgName ||
      lastActivity?.timeSpent ||
      lastActivity?.position ||
      lastActivity?.certificate
    ) {
      setExtraActivities([
        ...extraActivities,
        {
          orgName: "",
          timeSpent: "",
          position: "",
          certificate: "",
        },
      ]);
    } else {
      message.error(
        "Please fill in all required fields before adding a new activity."
      );
    }
  };

  const handleExtraCurricularChange = (index, field, value) => {
    const updatedExtraCurricular = [...extraActivities];
    updatedExtraCurricular[index][field] = value;
    setExtraActivities(updatedExtraCurricular);
  };

  const handleNext = () => {
    const filteredExtraActivities = extraActivities.filter((extraActivity) => {
      if (!extraActivity?.orgName) {
        return false;
      }
      return true;
    });

    if (filteredExtraActivities.length === 0) {
      message.error("Please fill in all the required fields.");
      return;
    }

    const payload = {
      userId: profileData?.id,
      extraActivities: filteredExtraActivities,
    };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          setPage("External ID");
        }
      })
    );
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="max-h-[550px] overflow-y-auto">
            {extraActivities?.map((activity, index) => (
              <div className={`${index > 0 ? "mt-6" : ""} `}>
                <div className="section-header">Activity {index + 1}</div>
                <div className="grid grid-cols-2 gap-x-7 gap-y-6" key={index}>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Organization/Club Name" />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleExtraCurricularChange(
                          index,
                          "orgName",
                          e.target.value
                        )
                      }
                      placeholder="Astronomy Club"
                      value={activity.orgName}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Total Spending Time" />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleExtraCurricularChange(
                          index,
                          "timeSpent",
                          e.target.value
                        )
                      }
                      placeholder="1 hour/Week, 12 weeks/Year"
                      value={activity.timeSpent}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Position" />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleExtraCurricularChange(
                          index,
                          "position",
                          e.target.value
                        )
                      }
                      placeholder="Enter Position"
                      value={activity.position}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Certificate" />
                    <Spacer height="5px" />
                    <questionnaireComponents.QuestionnaireFileInput
                      onChange={(fileLink) => {
                        console.log("activity image link:", fileLink);
                        handleExtraCurricularChange(
                          index,
                          "certificate",
                          fileLink
                        );
                      }}
                      type="file"
                      photo={activity?.certificate}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={addExtraCurricular} size="medium">
              + Add More
            </Buttons.DefaultButton>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Activities;
