import React from "react";

import { Images } from "../config";

const GetProPopup = () => {
  return (
    <div className="rounded-[28px] drop-shadow-md bg-white flex flex-col justify-center items-center gap-4 text-black p-5">
      <img src={Images.GetProYellow} className="h-[66px]" />
      <div className="text-[24px] font-bold text-center">
        To View More Get <span className="text-main">Univision</span>
        <br /> Pro Version!
      </div>
      <div
        className="flex gap-2 bg-getProOrange rounded-lg p-3 items-center cursor-pointer"
        onClick={() => localStorage.setItem("showPaymentPage", true)}
      >
        <div className="font-semibold text-[14px]">Get UniVIsion Pro</div>
        <img src={Images.GetPro} className="h-[14px]" />
      </div>
    </div>
  );
};

export default GetProPopup;
