const Colors = {
  main: "#387F75",
  liteBoxBorder: "#c5e0dc",
  liteBoxBg: "#FCFFFE",
  searchBorder: "#DEDEDE",
  lightOrange: "#FBBD14",
  redButton: "#fd4755",
  LightGray: "#f5f5f5",
  Secondary: "#6DC6C3",
  Secondary2: "#214854",
  LightSecondary: "#97BAB5",
  GrayText: "#474747",
  LightGrayText: "#8F8F8F",
};

export default Colors;
