import React from "react";

const QuestionnaireTextInput = (props) => {
  return (
    <input
      className="questionnaire-text-input"
      {...props}
      placeholder={props?.placeholder ?? "Please enter your value"}
      value={props?.value ?? ""}
    ></input>
  );
};

export default QuestionnaireTextInput;
