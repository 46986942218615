import React, { useState } from "react";
import moment from "moment";

import { Images } from "../../config";

import { boxContainer, tooltip, tables, GetProPopup } from "../../components";

const Finance = (props) => {
  const data = props?.uniData;

  const scholarships = data?.scholarships;

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleChange = (pagination) => {
    setPageNo(pagination.current);
    setLimit(pagination.pageSize);
  };

  const studentLifeCosts = [
    {
      icon: Images.Unifinanceaccommodation,
      title: "Accommodation",
      subTitle: "ON CAMPUS",
      value: data?.finance?.onAccCost ?? "NA",
    },
    {
      icon: Images.Unifinanceaccommodation,
      title: "Accommodation",
      subTitle: "OFF CAMPUS",
      value: data?.finance?.offAccCost ?? "NA",
    },
    {
      icon: Images.Unifinancefood,
      title: "Food",
      value: data?.finance?.offFoodCost ?? "NA",
    },
    {
      icon: Images.Unifinancetransport,
      title: "Transport",
      value: data?.finances?.transportCost ?? "NA",
    },
    {
      icon: Images.Unifinanceentertainment,
      title: "Entertainment",
      value: data?.finance?.offOtherCost ?? "NA",
    },
  ];

  const scholarshipColumns = [
    {
      title: "#",
      dataIndex: "serial",
      key: "0",
      width: 50,
      render: (text, record, index) => (
        <div>{(pageNo - 1) * limit + index + 1}</div>
      ),
    },
    {
      title: "Scholarship",
      key: "1",
      width: 200,
      render: (text, record) => <div>{record?.title ?? "NA"}</div>,
    },
    {
      title: "Entry Type",
      key: "2",
      width: 100,
      render: (text, record) => <div>{record?.entryType ?? "NA"}</div>,
    },
    {
      title: "Award Type",
      key: "3",
      width: 100,
      render: (text, record) => <div>{record?.awardType ?? "NA"}</div>,
    },
    {
      title: "Tution Coverage",
      key: "4",
      width: 150,
      render: (text, record) => <div>{record?.tuitionCoverage ?? "NA"}</div>,
    },
    {
      title: "Accommodation Coverage",
      key: "5",
      width: 80,
      render: (text, record) => (
        <div>{record?.accommodationCoverage ?? "NA"}</div>
      ),
    },
    {
      title: "Application Deadline",
      key: "6",
      width: 100,
      render: (text, record) => (
        <div>
          {data?.appDeadline
            ? moment(data?.appDeadline).format("DD MMMM, YYYY")
            : "NA"}
        </div>
      ),
    },
    {
      title: "Academic Requirement",
      key: "6",
      width: 80,
      render: (text, record) =>
        record?.academicReq ? (
          <tooltip.WhiteTooltip text={record?.academicReq}>
            <div className="text-main">See More</div>
          </tooltip.WhiteTooltip>
        ) : (
          "NA"
        ),
    },
    {
      title: "Additional Requirement",
      key: "7",
      width: 80,
      render: (text, record) =>
        record?.additionalReq ? (
          <tooltip.WhiteTooltip text={record?.additionalReq}>
            <div className="text-main">See More</div>
          </tooltip.WhiteTooltip>
        ) : (
          "NA"
        ),
    },
  ];

  return (
    <div className="relative">
      {props?.blur && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div
        className={`flex flex-col gap-2 ${
          props?.blur && "blur pointer-events-none"
        }`}
      >
        <div className="flex flex-col gap-2">
          <div className="box-title">Student Life Costs</div>
          <boxContainer.LiteBoxContainer>
            <div className="flex justify-around items-center h-full">
              {studentLifeCosts?.map((item, index) => (
                <div
                  className="flex flex-col gap-2 items-center justify-center"
                  key={index}
                >
                  <img src={item?.icon} className="h-[60px] w-[60px]" />
                  <div className="box-content-title pb-2 border-b-[2px] border-b-gray-300 w-full text-center">
                    {item.title} <br />
                    <div className="text-[10px] text-main w-full text-center h-[12px]">
                      {item.subTitle}
                    </div>
                  </div>
                  <div className="box-content-value">{item.value}</div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>

        <div className="flex flex-col gap-2">
          <div className="box-title">Scholarships</div>
          <div className="w-[calc(100vw-320px)] overflow-x-auto max-w-full">
            <tables.DefaultTable
              columns={scholarshipColumns}
              data={scholarships}
              rowKey={(record) => record.id}
              onChange={handleChange}
              pagination={true}
              onRow={(record) => ({
                onClick: () => {
                  window.open(`${record?.link}`, "_blank");
                },
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
