import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const Academics = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const programTypes = useSelector((state) => state.others?.programTypes);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [majorsFiltered, setMajorsFiltered] = useState([]);
  const [state, setState] = useState({
    uniRankingImportance: null,
    uniType: null,
    uniRankingInCoursesImportance: null,
    knowMajor: null,
    majorsInterested: [],
    courseDurationPreference: null,
  });

  useEffect(() => {
    setState({
      uniRankingImportance: questionnaire?.uniRankingImportance ?? null,
      uniType: questionnaire?.uniType ?? null,
      uniRankingInCoursesImportance:
        questionnaire?.uniRankingInCoursesImportance ?? null,
      knowMajor: questionnaire?.knowMajor ?? null,
      majorsInterested: questionnaire?.majorsInterested ?? [],
      courseDurationPreference: questionnaire?.courseDurationPreference ?? null,
    });
  }, [questionnaire]);

  useEffect(() => {
    const programNames = programTypes.map((program) => ({
      label: program.programType,
      value: program.programType,
    }));
    setMajorsFiltered(programNames);
  }, [programTypes]);

  const handleNext = () => {
    if (
      !state.uniRankingImportance ||
      !state.uniType ||
      !state.uniRankingInCoursesImportance ||
      !state.knowMajor
    ) {
      message.error("Please fill all the required fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            setPage("Location");
          }
        })
      );
    }
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="section-input-title flex justify-between">
            <questionnaireComponents.QuestionnaireLabel
              text="1. How important is the overall ranking of the university?"
              required
            />
            <span className="text-[12px] text-grayText opacity-60">
              (least important 1, important 3, most important 5)
            </span>
          </div>
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.uniRankingImportance}
            handleChange={(e) =>
              setState({ ...state, uniRankingImportance: e })
            }
          />
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel
            text="2. Which type of university would you prefer?"
            required
          />
          <Spacer height="10px" />
          <div className="pl-3">
            <inputFields.HorizontalRadio
              onChange={(e) => setState({ ...state, uniType: e.target.value })}
              value={state.uniType}
              items={[
                { label: "Private", value: "private" },
                { label: "Public", value: "public" },
                { label: "No Preference", value: "no_pref" },
              ]}
            />
          </div>

          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="3. How important is the university's ranking in the courses/majors of
          your choice?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.uniRankingInCoursesImportance}
            handleChange={(e) =>
              setState({ ...state, uniRankingInCoursesImportance: e })
            }
          />
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel
            text="4. Do you know what course or major you would like to study?"
            required
          />
          <Spacer height="10px" />
          <div className="pl-3">
            <inputFields.HorizontalRadio
              onChange={(e) =>
                setState({ ...state, knowMajor: e.target.value })
              }
              value={state.knowMajor}
              items={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
            />
          </div>

          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel text="5. Which course(s)/majors(s) are you interested in?" />
          <Spacer height="5px" />
          <div className="w-[500px]">
            <inputFields.SelectDropdownMultiple
              style={{ width: "50%", paddingLeft: "12px" }}
              onChange={(e) => setState({ ...state, majorsInterested: e })}
              placeholder="Search here"
              value={state?.majorsInterested}
              items={majorsFiltered}
            />
          </div>
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel
            text={
              <div>
                6. What is your preferred course duration?
                <span className="text-[12px] text-grayText opacity-60 ml-5">
                  {"(5 -> 5+)"}
                </span>
              </div>
            }
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            courseduration
            value={state?.courseDurationPreference}
            handleChange={(e) =>
              setState({ ...state, courseDurationPreference: e })
            }
          />
          <Spacer height="24px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Academics;
