import React, { useState } from "react";
import { Select, ConfigProvider } from "antd";

import { Colors } from "../../config";

import { FaChevronDown } from "react-icons/fa";

import { Buttons } from "..";
import DefaultInput from "./default-input";

import "./styles.css";

const handleFilter = (input, option) => {
  if (!option || !option.label) return false;
  return option.label.toString().toLowerCase().startsWith(input.toLowerCase());
};

const SelectDropdownMultiple = ({
  showSearch = true,
  placeholder = "Select",
  loading = false,
  items = [],
  custominput = false,
  disabled = false,
  ...props
}) => {
  const [otherValue, setOtherValue] = useState("");

  const handleAddOtherValue = () => {
    if (otherValue.trim()) {
      const newValues = [...(props.value || []), otherValue];
      props?.onChange(newValues);
      setOtherValue("");
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlOutline: "none",
            colorPrimaryTextActive: Colors.main,
            colorPrimaryHover: Colors.main,
            colorPrimaryActive: Colors.main,
            colorPrimaryBorder: Colors.main,
            optionSelectedBg: Colors.liteBoxBorder,
            multipleItemBorderColor: Colors.liteBoxBorder,
            multipleItemBg: Colors.liteBoxBg,
            multipleItemHeight: 36,
          },
        },
        token: {
          colorBorder: Colors.main,
          paddingSM: 16,
          fontSize: 16,
          borderRadius: 10,
        },
      }}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        loading={loading}
        suffixIcon={<FaChevronDown color={Colors.main} fontSize={18} />}
        className={`default-select-dropdown ${
          disabled
            ? "default-select-dropdown-multiple-disabled"
            : "default-select-dropdown-multiple"
        }`}
        mode="multiple"
        filterOption={handleFilter}
        disabled={disabled}
        options={items.map((item) => ({
          label: item?.label,
          value: item?.value,
        }))}
        dropdownRender={(menu) => (
          <>
            {menu}
            {custominput && (
              <div className="flex gap-1 mt-2 items-center p-2">
                <DefaultInput
                  placeholder="Please enter value"
                  value={otherValue}
                  onChange={(e) => setOtherValue(e.target.value)}
                />
                <div className="w-[100px]">
                  <Buttons.DefaultButton
                    size="medium"
                    width="full"
                    onClick={handleAddOtherValue}
                  >
                    Add
                  </Buttons.DefaultButton>
                </div>
              </div>
            )}
          </>
        )}
        {...props}
      />
    </ConfigProvider>
  );
};

export default SelectDropdownMultiple;
