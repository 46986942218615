import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const ExternalId = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    collegeBoard: "",
    linkedIn: "",
  });

  useEffect(() => {
    setState({
      collegeBoard: questionnaire?.collegeBoard ?? "",
      linkedIn: questionnaire?.linkedIn ?? "",
    });
  }, [questionnaire]);

  const handleNext = () => {
    const payload = { userId: profileData?.id, ...state };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          setPage("Academics");
        }
      })
    );
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6">
            <div>
              <questionnaireComponents.QuestionnaireLabel text="College Board" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, collegeBoard: e.target.value })
                }
                placeholder="Enter value"
                value={state?.collegeBoard}
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="LinkedIn" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, linkedIn: e.target.value })
                }
                value={state?.linkedIn}
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalId;
