import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { ContactAction } from "../../store/actions";

import { Buttons, Spacer, inputFields } from "../../components";

const ContactUs = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.contact);

  const [state, setState] = useState({
    subject: "",
    message: "",
  });
  const [buttonText, setButtonText] = useState("Submit");

  const handleSubmit = () => {
    if (!state?.subject || !state?.message) {
      message.error("Please fill all the fields.");
    } else {
      setButtonText("Submitting...");
      const payload = state;

      dispatch(
        ContactAction.addContact(payload, () => {
          setButtonText("Submit");
          setState({
            subject: "",
            message: "",
          });
        })
      );
    }
  };
  return (
    <div className="text-black h-[calc(100vh-220px)] overflow-auto">
      <Spacer height={5} />
      <div className="header-md text-main">Contact Us</div>
      <Spacer height={10} />
      <div className="text-[16px] font-semibold">
        Are you facing any problem?
      </div>

      <Spacer height={5} />
      <div className="text-[16px] font-medium">
        Send us your query or complain and our support team will get back to you
        soon.
      </div>

      <Spacer height={30} />
      <div className="w-[500px]">
        <div className="header-sm text-main">Subject</div>
        <Spacer height={10} />
        <inputFields.DefaultInput
          placeholder="What problem are you facing?"
          onChange={(e) => setState({ ...state, subject: e.target.value })}
          value={state?.subject}
        />
      </div>
      <Spacer height={20} />
      <div className="header-sm text-main">Message</div>
      <Spacer height={10} />
      <div className="w-[500px]">
        <inputFields.DefaultTextbox
          rows={6}
          placeholder="Tell us more about your problem"
          onChange={(e) => setState({ ...state, message: e.target.value })}
          value={state.message}
        />
      </div>

      <Spacer height={30} />
      <Buttons.DefaultButton
        onClick={handleSubmit}
        disabled={isLoading}
        loading={isLoading}
      >
        {buttonText}
      </Buttons.DefaultButton>
    </div>
  );
};

export default ContactUs;
