import React from "react";

import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";

const QuestionnairePhoneInput = (props) => {
  return (
    <PhoneInput
      country={"ae"}
      placeholder="Phone Number"
      {...props}
      value={props?.value ?? ""}
      className="questionnaire-phone-input"
      countryCodeEditable={false}
    />
  );
};

export default QuestionnairePhoneInput;
