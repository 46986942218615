import React from "react";

const ChatbotPopupTab = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="bg-main p-2 rounded-full flex gap-2 items-center justify-start w-max">
      {tabs?.map((item, index) => (
        <div
          onClick={() => onTabClick(item?.value)}
          key={index}
          className={`${
            activeTab === item?.value
              ? "text-main bg-white"
              : "text-white bg-main"
          } px-2 py-1 rounded-full transition-all cursor-pointer font-semibold`}
        >
          {item?.title}
        </div>
      ))}
    </div>
  );
};

export default ChatbotPopupTab;
