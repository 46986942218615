import * as React from "react";

import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const sizing = {
  margin: { right: 10, left: 10 },
  width: 280,
  height: 280,
  legend: { hidden: true },
};

export default function PieChartPopularSubs(props) {
  return (
    <PieChart
      series={[
        {
          arcLabel: (item) => `${item.value}%`,
          arcLabelMinAngle: 25,
          innerRadius: 50,
          paddingAngle: 5,
          cornerRadius: 10,
          highlightScope: { fade: "global", highlight: "item" },
          faded: { additionalRadius: -10, color: "gray" },
          data: props?.data,
          valueFormatter: (v) => (
            <div className="text-[16px] font-bold">{v.value}%</div>
          ),
        },
      ]}
      tooltip={{ trigger: "item" }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: 500,
          fontSize: 11,
        },
      }}
      {...sizing}
    />
  );
}
