import React from "react";
import { useSelector } from "react-redux";

import { Spacer } from "..";
import { Images } from "../../config";

export default function Welcome() {
  const profileData = useSelector((state) => state.profile.user);

  return (
    <div className="flex justify-center items-center flex-col h-full">
      <img src={Images.Chatbot} alt="" className="h-[80px] w-[80px]" />
      <Spacer height={20} />
      <h1>
        Welcome,{" "}
        <span className="capitalize text-main font-semibold">
          {profileData?.firstName} {profileData?.lastName}!
        </span>
      </h1>
      <h3>Please select a chat to start messaging.</h3>
    </div>
  );
}
