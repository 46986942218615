import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

import { chatbot } from "../../components";

const CounsellorChat = () => {
  const socket = useRef();

  const { counselors } = useSelector((state) => state.chatbot);
  const profileData = useSelector((state) => state.profile?.user);

  const [activeUser, setActiveUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setCurrentUser(profileData.id);
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL);
    }
  }, [currentUser]);

  const handleActiveUser = (user) => {
    setActiveUser(user);
  };

  return (
    <div className="grid grid-cols-5 h-[calc(100vh-250px)]">
      <div className="col-span-1 h-full border-r-[1px] border-r-gray-200">
        <chatbot.Contacts
          contacts={counselors}
          changeChat={handleActiveUser}
          activeUser={activeUser}
        />
      </div>
      <div className="col-span-4">
        {!activeUser ? (
          <chatbot.Welcome />
        ) : (
          <chatbot.ChatContainer currentChat={activeUser} socket={socket} />
        )}
      </div>
    </div>
  );
};

export default CounsellorChat;
