import React from "react";
import getTagColor from "../get-tag-color";

const TaskProgress = ({ percent }) => {
  const containerStyle = {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "30px",
  };

  const progressStyle = {
    width: `${percent}%`,
    backgroundColor: getTagColor(percent),
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "600",
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    zIndex: 2,
  };

  const linesStyle = {
    background: `repeating-linear-gradient(
      90deg,
      ${getTagColor(percent)},    /* Line color same as progress bar */
      ${getTagColor(percent)} 4px, /* Line width */
      transparent 4px, /* Space between lines */
      transparent 8px
    )`,
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1, // Lines stay below the text
  };

  return (
    <div style={containerStyle}>
      <div style={linesStyle}></div>
      <div style={progressStyle}>{percent}%</div>
    </div>
  );
};

export default TaskProgress;
