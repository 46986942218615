import React from "react";

import { FaCircleCheck } from "react-icons/fa6";

import { boxContainer, university, GetProPopup } from "../../components";

const ApplicationJourney = (props) => {
  const data = props?.uniData;

  return (
    <div className="relative">
      {props?.blur && (
        <div className="absolute top-[25px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div
        className={`flex gap-[30px] ${
          props?.blur && "blur pointer-events-none"
        }`}
      >
        <div className="w-[45%]">
          <div className="flex flex-col gap-2 h-full">
            <div className="box-title">Application Journey</div>
            <boxContainer.LiteBoxContainer addstyle="h-full">
              <university.AppjourneySteps array={data?.appJourneys} />
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
        <div className="w-[55%]">
          <div className="flex flex-col gap-2">
            <div className="box-title">Checklist</div>
            <boxContainer.LiteBoxContainer addstyle="h-full">
              <div>
                {data?.checklists?.map((item, index) => (
                  <div
                    className={`box-content-container ${
                      index !== 0 && "pt-[10px]"
                    }  items-start`}
                  >
                    <FaCircleCheck
                      color={"#19A857"}
                      fontSize={18}
                      className="mt-[5px]"
                    />

                    <div className="w-[90%] text-justify">
                      <div className="font-bold text-[20px]">
                        {item?.title
                          ? item?.title?.trim()
                          : `Checklist #${index + 1}`}
                      </div>
                      <div className="font-semibold text-[18px] text-justify opacity-80">
                        {item?.details?.trim()}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationJourney;
