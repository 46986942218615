import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import ReactCodeInput from "react-code-input";

import { CiCircleChevLeft } from "react-icons/ci";

import { AuthAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import { Buttons, Spacer } from "../../components";

import { Images, Colors } from "../../config";

const VerifySchoolOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const prevData = location.state;

  const [otpCode, setOtpCode] = useState("");

  const handleVerifyCode = () => {
    if (otpCode.length === 0) {
      message.error("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      message.error("Incomplete Code");
    } else {
      const body = {
        code: otpCode,
        schoolId: prevData?.schoolId,
      };

      dispatch(
        AuthAction.verifySchoolCode(body, (res) => {
          if (res.status === 200) {
            navigate("/signup/password", {
              state: prevData,
            });
          }
        })
      );
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col items-center justify-center  px-[120px] py-[40px] bg-login-gradient gap-4">
        <div className="relative">
          <CiCircleChevLeft
            className="text-[40px] absolute -left-[150px] top-3 cursor-pointer"
            onClick={() => {
              window.history.back();
            }}
          />
          <img src={Images.UnivisionLogoWithText} className="h-[60px]" />
        </div>
        <div className="flex flex-col gap-2 items-center w-full text-center">
          <div className="text-main font-bold text-[40px]">School Code</div>
          <div className="text-[22px] font-semibold">
            Please enter your 4-digit school code
            <br /> as provided by school admin.
          </div>
        </div>
        <Spacer height={5} />
        <ReactCodeInput
          type="text"
          fields={4}
          onChange={(e) => setOtpCode(e)}
          value={otpCode}
          inputStyle={{
            border: `1px solid #707070`,
            borderRadius: "10px",
            fontSize: "38px",
            color: Colors.main,
            fontWeight: "700",
            height: "70px",
            width: "50px",
            margin: "0 10px",
            textAlign: "center",
          }}
        />
        <Spacer height={5} />

        <Buttons.DefaultButton onClick={handleVerifyCode} width="full">
          Continue
        </Buttons.DefaultButton>
        <div className="flex justify-center items-center font-medium gap-1">
          <div>Haven't received code? </div>
          <div className="text-main font-semibold">
            Please reach out to your counselor!
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default VerifySchoolOTP;
