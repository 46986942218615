const budgetRange = [
  { label: "All", value: "" },
  { label: "0 - 20,000 $", value: "0-20000" },
  { label: "20,000 - 40,000 $", value: "20000-40000" },
  { label: "40,000 - 60,000 $", value: "40000-60000" },
  { label: "60,000 - 80,000 $", value: "60000-80000" },
  { label: "> 80,000 $", value: "80000-100000000" },
];

const budgetValues = [
  { title: "All", value: "" },
  { title: "0 - 20,000 $", value: "0-20000" },
  { title: "20,000 - 40,000 $", value: "20000-40000" },
  { title: "40,000 - 60,000 $", value: "40000-60000" },
  { title: "60,000 - 80,000 $", value: "60000-80000" },
  { title: "> 80,000 $", value: "80000-100000000" },
];

export { budgetRange, budgetValues };
