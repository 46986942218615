import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Empty, Progress } from "antd";
import ReactCountryFlag from "react-country-flag";

import { HiMiniCurrencyDollar, HiBriefcase } from "react-icons/hi2";
import { PiMonitorFill } from "react-icons/pi";

import { FaCircleCheck } from "react-icons/fa6";

import {
  Charts,
  Loader,
  Spacer,
  boxContainer,
  task,
  Modal,
  Buttons,
  customCalendar,
  topStoriesComponents,
  progressBar,
  popover,
  getTagColor,
  StatusIndicator,
  GetProPopup,
  inputFields,
} from "../../components";

import { UtilityArrays } from "../../config";

import {
  WatchlistAction,
  OthersAction,
  ApplicationAction,
  UniversityAction,
  IndividualStudentAction,
  MeetingAction,
} from "../../store/actions";

import UpcomingMeetings from "../meeting/upcoming-meetings";
import MeetingTabs from "../../components/meeting/meeting-tabs";
import MeetingsSent from "../meeting/meetings-sent";
import MeetingsReceived from "../meeting/meetings-received";

import { Images, Colors } from "../../config";

import "./styles.css";

const colors = [
  Colors.main,
  "#5AEE9C",
  "#BE4642",
  "#73C7F6",
  "#41a89c",
  "#40DAC2",
];

const currencies = [
  { title: "USD", value: "USD" },
  { title: "GBP", value: "GBP" },
  { title: "EUR", value: "EUR" },
  { title: "AED", value: "AED" },
];

const valueFormatter = (value) => {
  return `${value}`;
};

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.user);
  const showTrialModal = useSelector(
    (state) => state?.profile?.user?.individualStudent?.showTrialModal
  );
  const { count } = useSelector((state) => state?.watchlist);
  const { questionnaire } = useSelector((state) => state?.questionnaire);
  const { ongoingApplicationsCount, submittedApplicationsCount } = useSelector(
    (state) => state?.application
  );

  const {
    upcomingMeetingsCount,
    meetingSentCount,
    meetingsAll,
    meetingInvitesCount,
  } = useSelector((state) => state.meeting);

  const {
    topStories,
    isTopStoriesLoading,
    tasks,
    isTasksLoading,
    taskPercentage,
    popularSubjects,
    suggestions,
    isSuggestionsLoading,
    isPopularSubsLoading,
  } = useSelector((state) => state?.others);
  const {
    universitiesByBudget,
    popularCountries,
    isPopularCountryLoading,
    isUniByBudgetLoading,
  } = useSelector((state) => state?.university);

  const [meetingTab, setMeetingTab] = useState("sent");
  const [showModal, setShowModal] = useState(false);
  const [currencyValue, setCurrencyValue] = useState("USD");
  const [profileArrayData, setProfileArrayData] = useState([]);
  const [uniPrefArrayData, setUniPrefArrayData] = useState([]);
  const [popularSubsArray, setPopularSubsArray] = useState([]);

  useEffect(() => {
    if (topStories?.length === 0) {
      dispatch(OthersAction.getTopStories());
    }
    if (popularSubjects?.length === 0) {
      dispatch(OthersAction.getPopularSubs());
    }
    if (suggestions?.length === 0) {
      dispatch(OthersAction.getSuggestions());
    }
    if (tasks?.length === 0) {
      dispatch(OthersAction.getTasks());
    }
    dispatch(UniversityAction.getPopularCountries());
    if (!profileData?.freeUser) {
      dispatch(ApplicationAction.getOngoingApplications({}));
      dispatch(ApplicationAction.getSubmittedApplications({}));
      dispatch(WatchlistAction.getWatchlist({}));
    }
  }, [profileData]);

  useEffect(() => {
    dispatch(UniversityAction.getUnisByBudget({ currency: currencyValue }));
  }, [profileData, currencyValue]);

  useEffect(() => {
    setShowModal(showTrialModal);
  }, [profileData]);

  useEffect(() => {
    getProfilePartValues();
    getUniPartValues();
  }, [questionnaire]);

  useEffect(() => {
    const popularSubsData = popularSubjects?.map((item, index) => ({
      label: item?.program,
      value: item?.value,
      color: colors[index],
    }));
    setPopularSubsArray(popularSubsData);
  }, [popularSubjects]);

  useEffect(() => {
    fetchMeetings();
  }, [profileData]);

  const fetchMeetings = () => {
    const meetingPayload = {
      requestedBy: "student",
      studentId: profileData?.studentId,
      limit: 10,
      offset: 0,
    };
    dispatch(MeetingAction.getMeetingInvites(meetingPayload));
    dispatch(MeetingAction.getMeetingRequests(meetingPayload));
    dispatch(MeetingAction.getUpcomingMeetings(meetingPayload));
    dispatch(
      MeetingAction.getAllMeetingsAndCount({
        requestedBy: "student",
        studentId: profileData?.studentId,
      })
    );
  };

  const handleTrialModal = () => {
    dispatch(
      IndividualStudentAction.updateTrialModal(() => setShowModal(false))
    );
  };
  const getProfilePartValues = () => {
    const profileArray = [
      {
        title: "General",
        value: questionnaire?.completion?.generalCompletion ?? 0,
      },
      {
        title: "Address",
        value: questionnaire?.completion?.addressCompletion ?? 0,
      },
      {
        title: "Overall GPA",
        value: questionnaire?.completion?.overallGpaCompletion ?? 0,
      },
      {
        title: "Education",
        value: questionnaire?.completion?.educationCompletion ?? 0,
      },
      {
        title: "Test Scores",
        value: questionnaire?.completion?.testScoresCompletion ?? 0,
      },
      {
        title: "Contact",
        value: questionnaire?.completion?.contactsCompletion ?? 0,
      },
      {
        title: "Counselor",
        value: questionnaire?.completion?.counselorCompletion ?? 0,
      },
      {
        title: "Acitivities",
        value: questionnaire?.completion?.activitiesCompletion ?? 0,
      },
      {
        title: "External ID",
        value: questionnaire?.completion?.externalIdCompletion ?? 0,
      },
    ];

    const completed100 = profileArray.filter((item) => item.value === 100);
    const notCompleted = profileArray.filter((item) => item.value !== 100);

    let sortedProfile = [];

    if (completed100.length > 0) {
      sortedProfile = [
        completed100[completed100?.length - 1],
        ...notCompleted.slice(0, 3),
      ];
    } else {
      sortedProfile = notCompleted.slice(0, 4);
    }

    if (completed100.length === profileArray.length) {
      sortedProfile = profileArray.slice(0, 4);
    }
    setProfileArrayData(sortedProfile);
  };

  const getUniPartValues = () => {
    const uniPrefArray = [
      {
        title: "Academics",
        value: questionnaire?.completion?.academicsCompletion ?? 0,
      },
      {
        title: "Location",
        value: questionnaire?.completion?.locationCompletion ?? 0,
      },
      {
        title: "Financial",
        value: questionnaire?.completion?.financialCompletion ?? 0,
      },
      {
        title: "People",
        value: questionnaire?.completion?.peopleCompletion ?? 0,
      },
      {
        title: "Facilities",
        value: questionnaire?.completion?.faciltiesCompletion ?? 0,
      },
      {
        title: "Demographics",
        value: questionnaire?.completion?.demographicsCompletion ?? 0,
      },
      {
        title: "Others",
        value: questionnaire?.completion?.othersCompletion ?? 0,
      },
    ];

    const completed100 = uniPrefArray.filter((item) => item.value === 100);
    const notCompleted = uniPrefArray.filter((item) => item.value !== 100);

    let sortedUni = [];

    if (completed100.length > 0) {
      sortedUni = [
        completed100[completed100?.length - 1],
        ...notCompleted.slice(0, 3),
      ];
    } else {
      sortedUni = notCompleted.slice(0, 4);
    }

    if (completed100.length === uniPrefArray.length) {
      sortedUni = uniPrefArray.slice(0, 4);
    }
    setUniPrefArrayData(sortedUni);
  };

  const popoverContent = (
    <div className="flex flex-col gap-2 p-2">
      <div className="font-bold">Overall Tasks Progress</div>
      <div className="grid grid-cols-2 gap-3">
        {tasks.map((item, index) => {
          const tagColor =
            item?.status === "complete" ? "bg-[#04bf04]" : "bg-[#dc3333]";

          return (
            <div className="flex gap-2 justify-left items-center" key={index}>
              <div className="text-[12px] font-bold">Task {index + 1}:</div>
              <div
                className={`px-3 py-1 rounded-xl text-white text-[10px] font-semibold capitalize leading-4 ${tagColor} ${
                  item.status === "incomplete" && "cursor-pointer"
                }`}
                onClick={() =>
                  item.status !== "complete" && navigate(item?.link)
                }
              >
                {item?.status}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const applicationData = [
    {
      title: "Applying",
      value: ongoingApplicationsCount ?? 0,
      icon: Images.HomeApplying,
    },
    {
      title: "Applied",
      value: submittedApplicationsCount ?? 0,
      icon: Images.HomeApplied,
    },
    { title: "Shortlisted", value: count ?? 0, icon: Images.HomeShortlisted },
  ];

  const labelMapping = {
    fifteenToThirty: "15k - 30k",
    fourtyFiveToSixty: "45k - 60k",
    moreThanSixty: "> 60k",
    thirtyToFourtyFive: "30k - 45k",
    zeroToFifteen: "0 - 15k",
  };

  const dataset = [
    {
      choice1: 59,
      choice2: 57,
      choice3: 86,
      country: "Australia",
      countryCode: "AU",
    },
    {
      choice1: 50,
      choice2: 52,
      choice3: 78,
      country: "Canada",
      countryCode: "CA",
    },
    {
      choice1: 47,
      choice2: 53,
      choice3: 106,
      country: "UK",
      countryCode: "GB",
    },
    {
      choice1: 54,
      choice2: 56,
      choice3: 92,
      country: "USA",
      countryCode: "US",
    },
  ];

  const recommenderData = [
    {
      title: (
        <StatusIndicator
          text={
            <div className="flex gap-5">
              <div>Aspirational</div>
              <div>1 - 20%</div>
            </div>
          }
          showarrow={false}
          color="red"
        />
      ),
      popoverText:
        "Our AI Recommender estimates a 1-20% chance you will get into a university if shows under the Aspirational Option after you use our Recommender.",
    },
    {
      title: (
        <StatusIndicator
          text={
            <div className="flex gap-5">
              <div>Solid</div>
              <div>21 - 60%</div>
            </div>
          }
          showarrow={false}
          color="yellow"
        />
      ),
      popoverText:
        "Our AI Recommender estimates a 21-60% chance you will get into a university if shows under the Solid Option after you use our Recommender.",
    },
    {
      title: (
        <StatusIndicator
          text={
            <div className="flex gap-5">
              <div>Safe</div>
              <div>61 - 100%</div>
            </div>
          }
          showarrow={false}
          color="green"
        />
      ),
      popoverText:
        "Our AI Recommender estimates a 61-100% chance you will get into a university if shows under the Safe Option after you use our Recommender.",
    },
  ];

  const intershipData = {
    icon: Images.UnivisionLogoRound,
    title: "Data Intern",
    company: "UniVision",
    tags: ["Data Processing", "ETL", "SQL", "Python"],
    salary: "AED 600 - 1,000",
    employmentType: "Part Time",
    jobType: "Hybrid",
    description:
      "Here at UniVision (Dubai) we have an excition opportunity for a Data Intern. The position is located at our Dubai Commerce City office.",
    link: "#",
  };
  return (
    <div className="home-container">
      <div className="home-first-row">
        <div>
          <div className="box-title">Applications</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            <div className="grid grid-cols-3 h-[170px]">
              {applicationData?.map((item, index) => (
                <div
                  className="flex flex-col gap-3 items-center justify-center"
                  key={index}
                >
                  <img src={item.icon} className="h-[44px]" />
                  <div
                    className={`flex flex-col gap-3 items-center justify-center w-full ${
                      index < applicationData?.length - 1 &&
                      "border-r-[2px]  pr-2"
                    }`}
                  >
                    <div className="text-[18px] font-semibold">
                      {item.title}
                    </div>
                    <div className="text-[28px] font-bold">{item.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div>
          <div className="box-title">Profile Builder Progress</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            <div className="flex flex-col h-[170px] pl-3">
              <div className="flex flex-col gap-1 border-b-[2px] border-dashed border-b-gray-300 px-2 pb-2">
                <div className="font-bold">Profile Information</div>
                <div className="flex gap-4 justify-between">
                  {profileArrayData?.map((item, index) => (
                    <div key={index} className="flex flex-col gap-1 w-full">
                      <div className="relative">
                        <div className="absolute top-0 -left-3 z-[10] bg-white rounded-full">
                          <FaCircleCheck
                            color={getTagColor(item?.value)}
                            fontSize={24}
                          />
                        </div>
                        <Progress
                          percent={item?.value}
                          percentPosition={{
                            type: "inner",
                          }}
                          trailColor="#d6d6d6"
                          strokeColor={getTagColor(item?.value)}
                          className="profile-progress"
                        />
                      </div>
                      <div className="text-[12px] pr-2 font-semibold">
                        {item?.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2 px-2 pt-1">
                <div className="font-bold">University Preference</div>
                <div className="flex gap-4 justify-between">
                  {uniPrefArrayData?.map((item, index) => (
                    <div key={index} className="flex flex-col gap-1 w-full">
                      <div className="relative">
                        <div className="absolute top-0 -left-3 z-[10] bg-white rounded-full">
                          <FaCircleCheck
                            color={getTagColor(item?.value)}
                            fontSize={24}
                          />
                        </div>
                        <Progress
                          percent={item?.value}
                          percentPosition={{
                            type: "inner",
                          }}
                          trailColor="#d6d6d6"
                          strokeColor={getTagColor(item?.value)}
                          className="profile-progress"
                        />
                      </div>
                      <div className="text-[12px] pr-2 font-semibold">
                        {item?.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div>
          <div className="box-title">Your Tasks</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isTasksLoading ? (
              <Loader.Circular />
            ) : (
              <div className="flex flex-col justify-between gap-2 h-[170px]">
                <div className="w-full border-b-[2px] border-dashed border-gray-300">
                  {tasks?.length > 0 ? (
                    <task.TasksCarousel items={tasks} />
                  ) : (
                    <div className="text-[18px] font-semibold px-5">
                      Well done! You have completed all the tasks!
                    </div>
                  )}
                </div>

                <popover.WhitePopover
                  trigger="hover"
                  placement="bottom"
                  content={popoverContent}
                >
                  <div>
                    <progressBar.DefaultProgress percent={taskPercentage} />
                  </div>
                </popover.WhitePopover>
              </div>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
      {/* <Spacer height={10} /> */}
      <div className="home-second-row">
        <div>
          <div className="box-title">Total Universities by Budget (USD)</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isUniByBudgetLoading ? (
              <div className="h-[350px] flex items-center justify-center">
                <Loader.Circular />
              </div>
            ) : (
              <>
                {!universitiesByBudget?.zeroToFifteen ? (
                  <div className="h-[350px] flex items-center justify-center">
                    <Empty />
                  </div>
                ) : (
                  <div>
                    {/* <div className="w-full flex justify-end">
                      <div className="w-[150px]">
                        <inputFields.SelectDropdownSmall
                          items={UtilityArrays.homeCurrency}
                          onChange={(e) => setCurrencyValue(e)}
                          value={currencyValue}
                        />
                      </div>
                    </div> */}
                    <div
                      style={{
                        backgroundImage: `url(${Images.WorldMapDotted})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <Charts.VerticalBarChart
                        xAxis={[
                          {
                            scaleType: "band",
                            data: Object.keys(universitiesByBudget).map(
                              (key) => labelMapping[key]
                            ),
                          },
                        ]}
                        series={[
                          {
                            data: Object.values(universitiesByBudget),
                            valueFormatter: (v) => (
                              <div className="text-[16px] font-bold">
                                Number of Universities : {v}
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
        <div>
          <div className="box-title">Popular Countries</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isPopularCountryLoading ? (
              <Loader.Circular />
            ) : (
              <>
                {popularCountries?.length === 0 ? (
                  <div className="h-[350px] flex items-center justify-center">
                    <Empty />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${Images.WorldMapDotted})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "350px",
                      position: "relative",
                    }}
                  >
                    <div className="absolute h-[315px] w-[120px] left-0 top-0 flex flex-col justify-between">
                      {dataset?.map((item, index) => (
                        <div
                          className="flex flex-1 w-full items-center justify-left gap-2"
                          key={index}
                        >
                          <ReactCountryFlag
                            countryCode={item.countryCode}
                            svg
                            style={{
                              width: "26px",
                              height: "26px",
                            }}
                            title={item.countryCode}
                          />
                          <div>{item?.country}</div>
                        </div>
                      ))}
                    </div>
                    <Charts.HorizontalBarChart
                      dataset={dataset}
                      yAxis={[{ scaleType: "band", dataKey: "country" }]}
                      series={[
                        {
                          dataKey: "choice1",
                          label: "1st country of choice",
                          valueFormatter,
                        },
                        {
                          dataKey: "choice2",
                          label: "2nd country of choice",
                          valueFormatter,
                        },
                        {
                          dataKey: "choice3",
                          label: "3rd country of choice",
                          valueFormatter,
                        },
                      ]}
                      colors={["#387f75", "#5aee9c", "#bc4642"]}
                    />
                  </div>
                )}
              </>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
        <div>
          <div className="box-title">Popular Subjects</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isPopularSubsLoading ? (
              <Loader.Circular />
            ) : (
              <>
                {popularSubsArray?.length === 0 ? (
                  <div className="h-[350px] flex items-center justify-center">
                    <Empty />
                  </div>
                ) : (
                  <div className="flex justify-center flex-col items-center h-[350px]">
                    <Charts.PieChartPopularSubs data={popularSubsArray} />
                    <div className="flex gap-x-3 gap-y-2 flex-wrap items-center justify-center font-semibold text-[12px]">
                      {popularSubsArray?.map((item, index) => {
                        return (
                          <div
                            className="flex gap-2 items-center justify-center"
                            key={index}
                          >
                            <div
                              className="h-[10px] w-[30px]"
                              style={{ backgroundColor: colors[index] }}
                            ></div>
                            <div>{item?.label}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
      {/* <Spacer height={10} /> */}
      {profileData?.student?.studentType === "individualStudent" ? (
        <div className="home-third-row">
          <div>
            <div className="box-title">AI Recommender</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              <div className="px-3 py-1 h-[320px]">
                <div className="flex gap-3">
                  <div className="w-[60%] flex flex-col gap-3">
                    <div className="text-main font-bold text-[20px]">
                      Are You Facing Difficulties Choosing Universities?
                    </div>
                    <div className="text-[18px] font-semibold">
                      Use our proprietary recommender feature to help you find
                      the best university based on your requirements. Take a
                      peek at the video below to see how it works!
                    </div>
                    <div className="w-[180px]">
                      <Buttons.DefaultButton
                        width="full"
                        size="small"
                        onClick={() => navigate("/recommender")}
                      >
                        Start
                      </Buttons.DefaultButton>
                    </div>
                  </div>
                  <div className="w-[40%]">
                    <img src={Images.Homerecommender} className="w-[240px]" />
                  </div>
                </div>
                <Spacer height={20} />
                <div className="flex justify-between">
                  {recommenderData?.map((item, index) => (
                    <popover.WhitePopover
                      content={
                        <div className="max-w-[300px] font-medium">
                          {item.popoverText}
                        </div>
                      }
                      trigger="hover"
                      placement="top"
                    >
                      <div>{item?.title}</div>
                    </popover.WhitePopover>
                  ))}
                </div>
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
          <div>
            <div className="box-title">Internships</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {profileData?.freeUser ? (
                <div className="h-[320px] flex justify-center items-center">
                  <GetProPopup />
                </div>
              ) : (
                <div className="p-2 h-[320px] overflow-auto flex flex-col justify-between">
                  <div>
                    <div className="flex justify-between gap-2">
                      <div className="flex gap-2">
                        {intershipData?.icon && (
                          <img src={intershipData?.icon} className="h-[46px]" />
                        )}
                        <div>
                          <div className="text-[18px] font-bold">
                            {intershipData.title}
                          </div>
                          <div className="text-[14px] font-medium">
                            {intershipData.company}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link to={intershipData?.link} target="_blank">
                          <Buttons.DefaultButton size="small">
                            Apply Now
                          </Buttons.DefaultButton>
                        </Link>
                      </div>
                    </div>
                    <Spacer height={20} />
                    <div className="flex gap-2 flex-wrap">
                      {intershipData?.tags?.map((item, index) => (
                        <div
                          className="text-[14px] border border-green-100 bg-liteBoxBg rounded-[20px] p-2"
                          key={index}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                    <Spacer height={20} />
                    <div className="flex gap-2 flex-wrap">
                      <div className="flex gap-2 justify-center items-center font-medium text-[16px] border-r  pr-2">
                        <HiMiniCurrencyDollar
                          fontSize={18}
                          color={Colors.main}
                        />
                        {intershipData?.salary}
                      </div>

                      <div className="flex gap-2 justify-center items-center font-medium text-[16px] border-r  pr-2">
                        <HiBriefcase fontSize={18} color={Colors.main} />
                        {intershipData?.employmentType}
                      </div>

                      <div className="flex gap-2 justify-center items-center font-medium text-[16px] pr-2">
                        <PiMonitorFill fontSize={18} color={Colors.main} />
                        {intershipData?.jobType}
                      </div>
                    </div>
                    <Spacer height={20} />
                    <div>{intershipData?.description}</div>
                  </div>
                  <div className="w-[140px]">
                    <Link to="/internships">
                      <Buttons.WhiteButton size="small" width="full">
                        Explore All
                      </Buttons.WhiteButton>
                    </Link>
                  </div>
                </div>
              )}
            </boxContainer.LiteBoxContainer>
          </div>
          <div>
            <div className="box-title">Suggestions</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isSuggestionsLoading ? (
                <Loader.Circular />
              ) : (
                <div className="h-[320px] overflow-auto">
                  {suggestions?.map((item, index) => (
                    <div
                      className={`flex gap-2 items-center p-3 font-semibold ${
                        index < suggestions?.length - 1 &&
                        "border-b-[1px] border-b-gray-300"
                      } ${item?.link && "cursor-pointer"}`}
                      key={index}
                      onClick={() => navigate(item?.link)}
                    >
                      <div className="w-[8%] flex justify-center items-center">
                        <img
                          src={
                            item?.title?.startsWith("Deadline")
                              ? Images.HomeDeadline
                              : Images.HomeSuggestion
                          }
                          className="w-[32px]"
                        />
                      </div>
                      <div className="w-[92%]">{item?.title}</div>
                    </div>
                  ))}
                </div>
              )}
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div className="box-title">Meetings</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              <div className="px-3">
                <div className="home-meeting-row">
                  <div>
                    <div>
                      <div className="box-title">
                        Upcoming Meetings ({upcomingMeetingsCount})
                      </div>
                      <Spacer height={10} />
                      <UpcomingMeetings />
                    </div>
                    <Spacer height={30} />
                    <div>
                      <MeetingTabs
                        meetingTab={meetingTab}
                        setMeetingTab={setMeetingTab}
                        meetingSentCount={meetingSentCount}
                        meetingInvitesCount={meetingInvitesCount}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="box-title">My Calendar</div>
                    <Spacer height={10} />
                    <boxContainer.LiteBoxContainer bg="bg-[#f6fbf9]">
                      <div className="h-[280px] flex flex-col justify-between items-end">
                        <customCalendar.MeetingCalendar
                          meetingData={meetingsAll?.allMeetings}
                        />
                        <Link to="/meetings/add-meeting">
                          <Buttons.DefaultButton size="medium">
                            New Meeting
                          </Buttons.DefaultButton>
                        </Link>
                      </div>
                    </boxContainer.LiteBoxContainer>
                  </div>

                  <div>
                    <div className="box-title">Suggestions</div>
                    <Spacer height={10} />
                    <boxContainer.LiteBoxContainer>
                      {isSuggestionsLoading ? (
                        <Loader.Circular />
                      ) : (
                        <div className="h-[280px] overflow-auto">
                          {suggestions?.map((item, index) => (
                            <div
                              className={`flex gap-2 items-center p-3 font-semibold ${
                                index < suggestions?.length - 1 &&
                                "border-b-[1px] border-b-gray-300"
                              } ${item?.link && "cursor-pointer"}`}
                              key={index}
                              onClick={() => navigate(item?.link)}
                            >
                              <div className="w-[8%] flex justify-center items-center">
                                <img
                                  src={
                                    item?.title?.startsWith("Deadline")
                                      ? Images.HomeDeadline
                                      : Images.HomeSuggestion
                                  }
                                  className="w-[32px]"
                                />
                              </div>
                              <div className="w-[92%]">{item?.title}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </boxContainer.LiteBoxContainer>
                  </div>
                </div>
                <Spacer height={20} />

                <div>
                  {meetingTab === "sent" ? (
                    <MeetingsSent />
                  ) : (
                    <MeetingsReceived />
                  )}
                </div>
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
      )}

      <Spacer height={10} />
      <div>
        <div className="box-title">Top Stories</div>
        <Spacer height={10} />
        <boxContainer.LiteBoxContainer>
          {isTopStoriesLoading ? (
            <Loader.Circular />
          ) : (
            <topStoriesComponents.TopStoriesSwiper data={topStories} />
          )}
        </boxContainer.LiteBoxContainer>
      </div>
      <Modal.DefaultModal
        open={showModal}
        closeIcon={false}
        onCancel={() => handleTrialModal()}
      >
        <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[500px]">
          <img src={Images.LogoIconOnly} className="h-[66px]" />
          <div className="font-bold">
            Congratulations, {profileData.firstName} {profileData.lastName}!
          </div>
          <div className="font-semibold">
            You have been given a free trial of the{" "}
            <span className="font-bold text-main">UniVision Pro</span> for one
            month! After the free tiral, if you want to continue using{" "}
            <span className="font-bold text-main">UniVision Pro</span> platform
            please select a payment plan. Otherwise, you can continue using
            UniVision as a free user.
          </div>
          <div className="w-[250px]">
            <Buttons.DefaultButton
              width="full"
              onClick={() => handleTrialModal()}
            >
              Continue
            </Buttons.DefaultButton>
          </div>
        </div>
      </Modal.DefaultModal>
    </div>
  );
};

export default Home;
