// sidebar
import SidebarLogo from "../../assets/img/sidebar-logo.png";
import SidebarHome from "../../assets/img/sidebar-home.svg";
import SidebarApplications from "../../assets/img/sidebar-applications.svg";
import Sidebarcareers from "../../assets/img/sidebar-careers.svg";
import Sidebaressaygrader from "../../assets/img/sidebar-essaygrader.svg";
import Sidebarinteractivelearning from "../../assets/img/sidebar-interactivelearning.svg";
import Sidebarmeetings from "../../assets/img/sidebar-meetings.svg";
import Sidebarprofilebuilder from "../../assets/img/sidebar-profilebuilder.svg";
import Sidebarrecommender from "../../assets/img/sidebar-recommender.svg";
import Sidebarshortlist from "../../assets/img/sidebar-shortlist.svg";
import Sidebarvisainfo from "../../assets/img/sidebar-visainfo.svg";
import Sidebaruniversities from "../../assets/img/sidebar-universities.svg";
import Sidebarmarketplace from "../../assets/img/sidebar-marketplace.svg";

// profile dropdonw
import ProfileDropdownsettings from "../../assets/img/profiledropdown-settings.svg";
import ProfileDropdownoverview from "../../assets/img/profiledropdown-overview.svg";
import ProfileDropdownconfiguration from "../../assets/img/profiledropdown-configuration.svg";
import ProfileDropdowngroups from "../../assets/img/profiledropdown-groups.svg";
import ProfileDropdownpeople from "../../assets/img/profiledropdown-people.svg";
import ProfileDropdownbulkupload from "../../assets/img/profiledropdown-bulkupload.svg";
import ProfileDropdownappregions from "../../assets/img/profiledropdown-appregions.svg";
import ProfileDropdownhelpcenter from "../../assets/img/profiledropdown-helpcenter.svg";
import ProfileDropdownchat from "../../assets/img/profiledropdown-chat.svg";
import ProfileDropdowncommunity from "../../assets/img/profiledropdown-community.svg";
import ProfileDropdowngettingstarted from "../../assets/img/profiledropdown-gettingstarted.svg";
import ProfileDropdownlogout from "../../assets/img/profiledropdown-logout.svg";

// questionnaire
import QuestionnaireCalendar from "../../assets/img/questionnaire-calendar.svg";
import QuestionnaireFileInput from "../../assets/img/questionnaire-file-upload.svg";
import QuestionnaireInfo from "../../assets/img/questionnaire-info.svg";

// meetings
import MeetingsToday from "../../assets/img/meetings-today.svg";
import MeetingsThisWeek from "../../assets/img/meetings-thisweek.svg";
import MeetingsUpcoming from "../../assets/img/meetings-upcoming.svg";
import UpcomingMeetingsClock from "../../assets/img/upcoming-meetings-clock.svg";
import UpcomingMeetingsLocation from "../../assets/img/upcoming-meetings-location.svg";

// settings
import AccountSettings from "../../assets/img/settings-accountsettings.svg";
import SecuritySettings from "../../assets/img/settings-securitysettings.svg";
import SettingsPreferences from "../../assets/img/settings-preferences.svg";
import SettingsManageSub from "../../assets/img/settings-manage-sub.svg";

import PreferencesDarkMode from "../../assets/img/preferences-darkmode.svg";
import PreferencesNotifications from "../../assets/img/preferences-notifications.svg";

// uni details
import Unigeneral from "../../assets/img/university-general.svg";
import Uniadmission from "../../assets/img/university-admission.svg";
import Uniprograms from "../../assets/img/university-programs.svg";
import Unifinance from "../../assets/img/university-finance.svg";
import Uniappjourney from "../../assets/img/university-appjourney.svg";

import Unigeneralaid from "../../assets/img/university-general-aid.svg";
import Unigeneralappdeadline from "../../assets/img/university-general-appdeadline.svg";
import Unigeneralacceptancerate from "../../assets/img/university-general-acceptancerate.svg";
import Unigeneralavgclasssize from "../../assets/img/university-general-avgclasssize.svg";
import Unigeneralavgcourseduration from "../../assets/img/university-general-avgcourseduration.svg";
import Unigeneralapplynow from "../../assets/img/university-general-applynow.svg";
import Unigeneralbus from "../../assets/img/university-general-bus.svg";
import Unigeneraltrain from "../../assets/img/university-general-train.svg";
import Unigeneralcafe from "../../assets/img/university-general-cafe.svg";
import Unigeneralcwur from "../../assets/img/university-general-cwur.png";
import Unigeneralfacultystudentratio from "../../assets/img/university-general-facultystudentratio.svg";
import Unigenerallibrary from "../../assets/img/university-general-library.svg";
import Unigenerallocationtype from "../../assets/img/university-general-locationtype.svg";
import Unigeneralmaletofemale from "../../assets/img/university-general-maletofemale.svg";
import Unigeneralprinting from "../../assets/img/university-general-printing.svg";
import Unigeneralqs from "../../assets/img/university-general-qs.png";
import Unigeneralresearchoutput from "../../assets/img/university-general-researchoutput.svg";
import Unigeneraltimes from "../../assets/img/university-general-times.png";
import Unigeneraltotalundergrad from "../../assets/img/university-general-totalundergrad.svg";
import Unigeneralunitype from "../../assets/img/university-general-unitype.svg";
import Unigeneralfacilities from "../../assets/img/university-general-facilities.svg";
import Unigeneralavgannualgradsalary from "../../assets/img/university-general-avgannualgradsalary.svg";
import Unigeneraldefaultrestaurant from "../../assets/img/university-general-defaultrestaurant.png";
import Uniadmissionavgfee from "../../assets/img/university-admission-avgfee.svg";

import Uniadmissionielts from "../../assets/img/university-admission-ielts.png";
import Uniadmissionsat from "../../assets/img/university-admission-sat.png";
import Uniadmissionact from "../../assets/img/university-admission-act.png";
import Uniadmissiontoefl from "../../assets/img/university-admission-toefl.png";
import Unifinanceaccommodation from "../../assets/img/university-finance-accommodation.svg";
import Unifinancefood from "../../assets/img/university-finance-food.svg";
import Unifinancetransport from "../../assets/img/university-finance-transport.svg";
import Unifinanceentertainment from "../../assets/img/university-finance-entertainment.svg";
import DefaultUniCover from "../../assets/img/default-uni-cover.jpg";

import AddUniIcon from "../../assets/img/universities-adduni.svg";

// searchbox
import SearchboxSearch from "../../assets/img/searchbox-search-icon.svg";

// done animation
import Done from "../../assets/img/done.json";

// restaurants
import Hardees from "../../assets/img/hardees.png";
import Kfc from "../../assets/img/kfc.png";
import Pizzahut from "../../assets/img/pizza-hut.png";
import Mcdonalds from "../../assets/img/mcdonalds.png";

// chatbot
import Chatbot from "../../assets/img/chatbot.svg";
import ChatbotPopupButton from "../../assets/img/chatbot-popup-button.svg";
import ChatbotPopupIcon from "../../assets/img/chatbot-popup-icon.svg";
import ChatbotPopupButtonGreen from "../../assets/img/chatbot-popup-button-green.svg";

// profile builder
import ProfileBuilderacademics from "../../assets/img/profile-builder-academics.svg";
import ProfileBuilderactivities from "../../assets/img/profile-builder-activities.svg";
import ProfileBuildercontacts from "../../assets/img/profile-builder-contacts.svg";
import ProfileBuildercounselor from "../../assets/img/profile-builder-counselor.svg";
import ProfileBuilderdemographics from "../../assets/img/profile-builder-demographics.svg";
import ProfileBuildereducation from "../../assets/img/profile-builder-education.svg";
import ProfileBuilderexternalid from "../../assets/img/profile-builder-externalid.svg";
import ProfileBuilderfacilities from "../../assets/img/profile-builder-facilities.svg";
import ProfileBuilderfinancial from "../../assets/img/profile-builder-financial.svg";
import ProfileBuildergeneral from "../../assets/img/profile-builder-general.svg";
import ProfileBuilderlocation from "../../assets/img/profile-builder-location.svg";
import ProfileBuilderothers from "../../assets/img/profile-builder-others.svg";
import ProfileBuilderoverallgpa from "../../assets/img/profile-builder-overallgpa.svg";
import ProfileBuilderpeople from "../../assets/img/profile-builder-people.svg";
import ProfileBuildertestscores from "../../assets/img/profile-builder-testscores.svg";

// login
import LoginBackground from "../../assets/img/login-background.png";
import LoginSideImage from "../../assets/img/login-sideimage.png";
import LoginVideo from "../../assets/video/login-layout-video.mp4";

// visa info
import VisaInfoCoverDefault from "../../assets/img/visa-info-default.jpg";

// Home
import HomeApplying from "../../assets/img/home-applying.svg";
import HomeApplied from "../../assets/img/home-applied.svg";
import HomeShortlisted from "../../assets/img/home-shortlisted.svg";
import HomeDeadline from "../../assets/img/home-deadline.svg";
import HomeSuggestion from "../../assets/img/home-suggestions.svg";
import Homerecommender from "../../assets/img/home-recommender.png";

// internships
import InternshipsSalary from "../../assets/img/internships-salary.svg";
import InternshipsType from "../../assets/img/internships-type.svg";

// explore
import Exploreusa from "../../assets/img/explore-usa.svg";
import Exploreaustralia from "../../assets/img/explore-australia.svg";
import Exploreuk from "../../assets/img/explore-uk.svg";
import Exploremorecountries from "../../assets/img/explore-more-countries.svg";
import Explorebusiness from "../../assets/img/explore-business.svg";
import Exploreengineering from "../../assets/img/explore-engineering.svg";
import Exploresocialsciences from "../../assets/img/explore-social-sciences.svg";
import Exploremedical from "../../assets/img/explore-medical.svg";
import Explorearchitecture from "../../assets/img/explore-architecture.svg";
import Exploreeducation from "../../assets/img/explore-education.svg";
import Explorelegalstudies from "../../assets/img/explore-legal-studies.svg";
import Exploreculture from "../../assets/img/explore-culture.svg";
import Exploreglobe from "../../assets/img/explore-globe.svg";
import Exploreqs from "../../assets/img/explore-qs.png";

// general icons
import LocationPin from "../../assets/img/location-pin.svg";
import GreenCheck from "../../assets/img/green-checkmark.svg";
import FilterIcon from "../../assets/img/filter-button.svg";
import DefaultUpload from "../../assets/img/default-upload.svg";
import DocumentAttachment from "../../assets/img/document-attachment.svg";
import RightArrowCircled from "../../assets/img/right-arrow-circled.svg";
import DatePickerAddApplication from "../../assets/img/addapplication-datepicker.svg";
import UnivisionLogoWithText from "../../assets/img/univision-logo-withtext.png";
import UnivisionLogoRound from "../../assets/img/univision-logo-round.png";
import ProIcon from "../../assets/img/pro-icon.svg";
import WavyCheck from "../../assets/img/wavy-check.svg";
import WavyCancel from "../../assets/img/wavy-cancel.svg";
import GetPro from "../../assets/img/get-pro.svg";
import GetProYellow from "../../assets/img/get-pro-yellow.svg";
import LogoIconOnly from "../../assets/img/logo-icon-only.svg";
import ExclamationIcon from "../../assets/img/exclamation-icon.svg";
import WorldMapDotted from "../../assets/img/world-map-dotted.png";
import TopStoriesDefault from "../../assets/img/default-topstories.jpg";

const Images = {
  SidebarHome,
  SidebarLogo,
  SidebarApplications,
  Sidebarcareers,
  Sidebaressaygrader,
  Sidebarinteractivelearning,
  Sidebarmeetings,
  Sidebarprofilebuilder,
  Sidebarrecommender,
  Sidebarshortlist,
  Sidebarvisainfo,
  Sidebaruniversities,
  Sidebarmarketplace,

  ProfileDropdownsettings,
  ProfileDropdownoverview,
  ProfileDropdownconfiguration,
  ProfileDropdowngroups,
  ProfileDropdownpeople,
  ProfileDropdownbulkupload,
  ProfileDropdownappregions,
  ProfileDropdownhelpcenter,
  ProfileDropdownchat,
  ProfileDropdowncommunity,
  ProfileDropdowngettingstarted,
  ProfileDropdownlogout,

  QuestionnaireCalendar,
  QuestionnaireFileInput,
  QuestionnaireInfo,

  MeetingsToday,
  MeetingsThisWeek,
  MeetingsUpcoming,
  UpcomingMeetingsClock,
  UpcomingMeetingsLocation,

  AccountSettings,
  SecuritySettings,
  SettingsPreferences,
  SettingsManageSub,
  PreferencesDarkMode,
  PreferencesNotifications,

  Unigeneral,
  Uniadmission,
  Uniprograms,
  Unifinance,
  Uniappjourney,

  Unigeneralaid,
  Unigeneralappdeadline,
  Unigeneralacceptancerate,
  Unigeneralavgclasssize,
  Unigeneralavgcourseduration,
  Unigeneralapplynow,
  Unigeneralbus,
  Unigeneraltrain,
  Unigeneralcafe,
  Unigeneralcwur,
  Unigeneralfacultystudentratio,
  Unigenerallibrary,
  Unigenerallocationtype,
  Unigeneralmaletofemale,
  Unigeneralprinting,
  Unigeneralqs,
  Unigeneralresearchoutput,
  Unigeneraltimes,
  Unigeneraltotalundergrad,
  Unigeneralunitype,
  Unigeneralfacilities,
  Unigeneralavgannualgradsalary,
  Unigeneraldefaultrestaurant,
  Uniadmissionavgfee,
  Uniadmissionielts,
  Uniadmissionsat,
  Uniadmissionact,
  Uniadmissiontoefl,
  Unifinanceaccommodation,
  Unifinancefood,
  Unifinancetransport,
  Unifinanceentertainment,
  AddUniIcon,

  LocationPin,
  GreenCheck,
  Hardees,
  Kfc,
  Pizzahut,
  Mcdonalds,
  ProIcon,
  WavyCheck,
  WavyCancel,
  GetPro,
  GetProYellow,
  LogoIconOnly,
  ExclamationIcon,
  WorldMapDotted,
  TopStoriesDefault,

  SearchboxSearch,
  FilterIcon,
  Done,
  RightArrowCircled,
  DefaultUniCover,
  DefaultUpload,
  DocumentAttachment,
  DatePickerAddApplication,
  UnivisionLogoWithText,
  UnivisionLogoRound,

  Chatbot,
  ChatbotPopupButton,
  ChatbotPopupIcon,
  ChatbotPopupButtonGreen,

  ProfileBuilderacademics,
  ProfileBuilderactivities,
  ProfileBuildercontacts,
  ProfileBuildercounselor,
  ProfileBuilderdemographics,
  ProfileBuildereducation,
  ProfileBuilderexternalid,
  ProfileBuilderfacilities,
  ProfileBuilderfinancial,
  ProfileBuildergeneral,
  ProfileBuilderlocation,
  ProfileBuilderothers,
  ProfileBuilderoverallgpa,
  ProfileBuilderpeople,
  ProfileBuildertestscores,

  LoginBackground,
  LoginSideImage,
  LoginVideo,

  VisaInfoCoverDefault,

  HomeApplying,
  HomeApplied,
  HomeShortlisted,
  HomeDeadline,
  HomeSuggestion,
  Homerecommender,

  InternshipsSalary,
  InternshipsType,

  Exploreusa,
  Exploreaustralia,
  Exploreuk,
  Exploremorecountries,
  Explorebusiness,
  Exploreengineering,
  Exploresocialsciences,
  Exploremedical,
  Explorearchitecture,
  Exploreeducation,
  Explorelegalstudies,
  Exploreculture,
  Exploreglobe,
  Exploreqs,
};

export default Images;
