import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Empty } from "antd";

import { MeetingAction } from "../../store/actions";

import { meeting, Loader } from "../../components";

function UpcomingMeetings() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { upcomingMeetings, upcomingMeetingsCount, isUpcomingMeetingsLoading } =
    useSelector((state) => state.meeting);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    fetchMeeting();
  }, [pageNo]);

  const fetchMeeting = () => {
    const meetingPayload = {
      requestedBy: "student",
      studentId: profileData?.studentId,
      // limit,
      // offset,
    };
    dispatch(MeetingAction.getUpcomingMeetings(meetingPayload));
    dispatch(
      MeetingAction.getAllMeetingsAndCount({
        requestedBy: "student",
        studentId: profileData?.studentId,
      })
    );
  };

  const handlePagination = (pagenumber) => {
    setOffset((pagenumber - 1) * limit);
    setPageNo(pagenumber);
  };

  return (
    <div className="text-black h-[220px] overflow-auto">
      {isUpcomingMeetingsLoading ? (
        <Loader.Circular />
      ) : upcomingMeetingsCount === 0 ? (
        <Empty description={<span>No Meetings</span>} />
      ) : (
        <>
          {upcomingMeetings?.map((item, index) => (
            <meeting.MeetingRow item={item} index={index} key={index} />
          ))}
          {/* <div className="flex justify-center items-center p-3">
                  <Pagination
                    responsive="true"
                    showSizeChanger={false}
                    hideOnSinglePage={false}
                    current={pageNo}
                    pageSize={limit}
                    total={upcomingMeetingsCount}
                    onChange={handlePagination}
                  />
                </div> */}
        </>
      )}
    </div>
  );
}
export default UpcomingMeetings;
