import {
  GET_STUDENT_WISHLIST,
  GET_STUDENT_WISHLIST_FAILURE,
  GET_STUDENT_WISHLIST_SUCCESS,
  ADD_OR_REMOVE_FROM_WISHLIST,
  ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
  ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
  count: 0,
  watchlists: [],
};

export default function WatchlistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_WISHLIST:
    case ADD_OR_REMOVE_FROM_WISHLIST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_STUDENT_WISHLIST_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        watchlists: action.payload.shortlists,
        isLoading: false,
      };
      break;
    case ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_STUDENT_WISHLIST_FAILURE:
    case ADD_OR_REMOVE_FROM_WISHLIST_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
