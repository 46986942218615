import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Images } from "../../config";

const StudentSidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const studentType = useSelector(
    (state) => state.profile?.user?.student?.studentType
  );

  const SidebarRoutes = [
    {
      title: "Home",
      route: "/home",
      icon: Images.SidebarHome,
    },
    {
      title: "Universities",
      route: "/universities/grid",
      icon: Images.Sidebaruniversities,
    },
    {
      title: "Shortlist",
      route: "/shortlists",
      icon: Images.Sidebarshortlist,
    },
    {
      title: "Applications",
      route: "/applications",
      icon: Images.SidebarApplications,
    },
    ...(studentType === "schoolStudent"
      ? [
          {
            title: "Meetings",
            route: "/meetings/add-meeting",
            icon: Images.Sidebarmeetings,
          },
        ]
      : []),
    {
      title: "Internships",
      route: "/internships",
      icon: Images.Sidebarcareers,
    },
    {
      title: "Profile Builder",
      route: "/profile-builder",
      icon: Images.Sidebarprofilebuilder,
    },
    {
      title: "Visa Information",
      route: "/visa-information",
      icon: Images.Sidebarvisainfo,
    },
    {
      title: "Essay Grader",
      route: "/essay",
      icon: Images.Sidebaressaygrader,
    },
    {
      title: "AI Recommender",
      route: "/recommender",
      icon: Images.Sidebarrecommender,
    },
    // {
    //   title: "Interactive Learning",
    //   route: "/interactive-learning",
    //   icon: Images.Sidebarinteractivelearning,
    // },
  ];

  return (
    <div className="sidebar">
      <Link to="/home">
        <img src={Images.SidebarLogo} className="sidebar-logo" />
      </Link>

      <div className="sidebar-items-container">
        {SidebarRoutes.map((item, index) => (
          <Link
            className={`${
              item?.route?.includes(pathname.split("/")[1])
                ? "sidebar-item-active"
                : "sidebar-item"
            } ${item?.comingSoon && "pointer-events-none"}`}
            to={item.route}
            key={index}
          >
            <div className="flex gap-4 items-center">
              <img
                src={item.icon}
                className={`${
                  item?.comingSoon ||
                  !item?.route?.includes(pathname.split("/")[1])
                    ? "opacity-50"
                    : "opacity-100"
                } sidebar-icon`}
              />
              <div className="sidebar-title">
                <div
                  className={`${
                    item?.comingSoon ? "opacity-50" : "opacity-100"
                  } text-ellipsis whitespace-nowrap`}
                >
                  {item?.title}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default StudentSidebar;
