import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  Loader,
  inputFields,
  DefaultNotification,
} from "../../components";

import "./styles.css";
import { UtilityArrays } from "../../config";

const Others = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    otherImportantFactors: "",
    supportNeeded: [],
    halalFoodImportance: "",
  });

  useEffect(() => {
    getQuestionnaire();
  }, [profileData]);

  useEffect(() => {
    setState({
      otherImportantFactors: questionnaire?.otherImportantFactors ?? "",
      supportNeeded: questionnaire?.supportNeeded ?? [],
      halalFoodImportance: questionnaire?.halalFoodImportance ?? "",
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    if (!state.halalFoodImportance) {
      message.error("Please fill all the required fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            DefaultNotification(
              "Questionnaire updated successfully",
              "success",
              "top"
            );
            navigate("/profile-builder/");
          }
          getQuestionnaire();
        })
      );
    }
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">Others</div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <questionnaireComponents.QuestionnaireLabel
            text="1. What other important factors would you consider while choosing a
          university?"
          />
          <Spacer height="10px" />
          <inputFields.DefaultTextbox
            value={state?.otherImportantFactors}
            onChange={(e) =>
              setState({ ...state, otherImportantFactors: e.target.value })
            }
          />
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel text="2. What support do you need for applying to university?" />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireMultipleSelect
            style={{ marginLeft: "12px" }}
            onChange={(e) => setState({ ...state, supportNeeded: e })}
            value={state?.supportNeeded}
            array={UtilityArrays.supportArray}
          />
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="3. How important are halal food options for you?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.halalFoodImportance}
            handleChange={(e) => setState({ ...state, halalFoodImportance: e })}
          />
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Submit
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default Others;
