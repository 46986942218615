import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const DefaultInput = ({
  width = "100%",
  disabled = false,
  type = "text",
  placeholder = "Enter value",
  value,
  onChange,
  onClick,
  onKeyDown,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className="py-[11px] px-3 flex items-center !border !border-main bg-white rounded-[10px] text-black"
      style={{ width: width, opacity: disabled ? "0.7" : "1" }}
      onClick={onClick}
      onKeyDown={onKeyDown ?? null}
      {...props}
    >
      <input
        type={
          type === "password" && !showPassword
            ? "password"
            : type === "number"
            ? "number"
            : "text"
        }
        className="outline-none flex-grow"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={{ width: width, opacity: disabled ? "0.7" : "1" }}
      />
      {type === "password" && (
        <div
          className="cursor-pointer text-main text-[20px]"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
        </div>
      )}
    </div>
  );
};

export default DefaultInput;
