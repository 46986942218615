import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
  GET_ONLINE_USERS,
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAILURE,
  GET_PROGRAM_TYPES,
  GET_PROGRAM_TYPES_SUCCESS,
  GET_PROGRAM_TYPES_FAILURE,
  GET_PROGRAMS,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  GET_POPULAR_SUBS,
  GET_POPULAR_SUBS_SUCCESS,
  GET_POPULAR_SUBS_FAILURE,
  GET_TOP_STORIES,
  GET_TOP_STORIES_SUCCESS,
  GET_TOP_STORIES_FAILURE,
  GET_VISA_INFO,
  GET_VISA_INFO_SUCCESS,
  GET_VISA_INFO_FAILURE,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  UPDATE_ESSAY_GRADER_COUNT,
  UPDATE_ESSAY_GRADER_COUNT_SUCCESS,
  UPDATE_ESSAY_GRADER_COUNT_FAILURE,
  GET_PAYMENT_PLANS,
  GET_PAYMENT_PLANS_SUCCESS,
  GET_PAYMENT_PLANS_FAILURE,
} from "../constants";

export default class OthersAction {
  static getCountries(cb) {
    return {
      type: GET_COUNTRIES,
      cb,
    };
  }
  static getCountriesSuccess(payload) {
    return {
      type: GET_COUNTRIES_SUCCESS,
      payload,
    };
  }
  static getCountriesFailure(payload) {
    return {
      type: GET_COUNTRIES_FAILURE,
      payload,
    };
  }
  static getSchools(cb) {
    return {
      type: GET_SCHOOLS,
      cb,
    };
  }
  static getSchoolsSuccess(payload) {
    return {
      type: GET_SCHOOLS_SUCCESS,
      payload,
    };
  }
  static getSchoolsFailure(payload) {
    return {
      type: GET_SCHOOLS_FAILURE,
      payload,
    };
  }
  static getPopularSubs(cb) {
    return {
      type: GET_POPULAR_SUBS,
      cb,
    };
  }
  static getPopularSubsSuccess(payload) {
    return {
      type: GET_POPULAR_SUBS_SUCCESS,
      payload,
    };
  }
  static getPopularSubsFailure(payload) {
    return {
      type: GET_POPULAR_SUBS_FAILURE,
      payload,
    };
  }
  static getTasks(cb) {
    return {
      type: GET_TASKS,
      cb,
    };
  }
  static getTasksSuccess(payload) {
    return {
      type: GET_TASKS_SUCCESS,
      payload,
    };
  }
  static getTasksFailure(payload) {
    return {
      type: GET_TASKS_FAILURE,
      payload,
    };
  }
  static getSuggestions(cb) {
    return {
      type: GET_SUGGESTIONS,
      cb,
    };
  }
  static getSuggestionsSuccess(payload) {
    return {
      type: GET_SUGGESTIONS_SUCCESS,
      payload,
    };
  }
  static getSuggestionsFailure(payload) {
    return {
      type: GET_SUGGESTIONS_FAILURE,
      payload,
    };
  }
  static updateEssayGraderCount(cb) {
    return {
      type: UPDATE_ESSAY_GRADER_COUNT,
      cb,
    };
  }
  static updateEssayGraderCountSuccess(payload) {
    return {
      type: UPDATE_ESSAY_GRADER_COUNT_SUCCESS,
      payload,
    };
  }
  static updateEssayGraderCountFailure(payload) {
    return {
      type: UPDATE_ESSAY_GRADER_COUNT_FAILURE,
      payload,
    };
  }
  static getTopStories(cb) {
    return {
      type: GET_TOP_STORIES,
      cb,
    };
  }
  static getTopStoriesSuccess(payload) {
    return {
      type: GET_TOP_STORIES_SUCCESS,
      payload,
    };
  }
  static getTopStoriesFailure(payload) {
    return {
      type: GET_TOP_STORIES_FAILURE,
      payload,
    };
  }
  static getOnlineUsers(payload, cb) {
    return {
      type: GET_ONLINE_USERS,
      payload,
      cb,
    };
  }
  static getOnlineUsersSuccess(payload) {
    return {
      type: GET_ONLINE_USERS_SUCCESS,
      payload,
    };
  }
  static getOnlineUsersFailure(payload) {
    return {
      type: GET_ONLINE_USERS_FAILURE,
      payload,
    };
  }
  static getProgramTypes(cb) {
    return {
      type: GET_PROGRAM_TYPES,
      cb,
    };
  }
  static getProgramTypesSuccess(payload) {
    return {
      type: GET_PROGRAM_TYPES_SUCCESS,
      payload,
    };
  }
  static getProgramTypesFailure(payload) {
    return {
      type: GET_PROGRAM_TYPES_FAILURE,
      payload,
    };
  }
  static getPrograms(payload, cb) {
    return {
      type: GET_PROGRAMS,
      payload,
      cb,
    };
  }
  static getProgramsSuccess(payload) {
    return {
      type: GET_PROGRAMS_SUCCESS,
      payload,
    };
  }
  static getProgramsFailure(payload) {
    return {
      type: GET_PROGRAMS_FAILURE,
      payload,
    };
  }
  static getVisaInfo(cb) {
    return {
      type: GET_VISA_INFO,
      cb,
    };
  }
  static getVisaInfoSuccess(payload) {
    return {
      type: GET_VISA_INFO_SUCCESS,
      payload,
    };
  }
  static getVisaInfoFailure(payload) {
    return {
      type: GET_VISA_INFO_FAILURE,
      payload,
    };
  }
  static getPaymentPlans(cb) {
    return {
      type: GET_PAYMENT_PLANS,
      cb,
    };
  }
  static getPaymentPlansSuccess(payload) {
    return {
      type: GET_PAYMENT_PLANS_SUCCESS,
      payload,
    };
  }
  static getPaymentPlansFailure(payload) {
    return {
      type: GET_PAYMENT_PLANS_FAILURE,
      payload,
    };
  }
  static uploadImage(payload, cb) {
    return {
      type: UPLOAD_IMAGE,
      payload,
      cb,
    };
  }
  static uploadImageSuccess(payload) {
    return {
      type: UPLOAD_IMAGE_SUCCESS,
      payload,
    };
  }
  static uploadImageFailure(payload) {
    return {
      type: UPLOAD_IMAGE_FAILURE,
      payload,
    };
  }
  static uploadFile(payload, cb) {
    return {
      type: UPLOAD_FILE,
      payload,
      cb,
    };
  }
  static uploadFileSuccess(payload) {
    return {
      type: UPLOAD_FILE_SUCCESS,
      payload,
    };
  }
  static uploadFileFailure(payload) {
    return {
      type: UPLOAD_FILE_FAILURE,
      payload,
    };
  }
}
