import React from "react";
import { Dropdown, ConfigProvider } from "antd";

import { Colors } from "../../config";

const DefaultDropdown = (props) => {
  const items = props.items;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: Colors.main,
          colorText: "white",
          controlItemBgHover: Colors.LightSecondary,
        },
      }}
    >
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement={props?.placement || "bottomRight"}
        arrow
        overlayStyle={{ minWidth: "100px" }}
      >
        {props?.children}
      </Dropdown>
    </ConfigProvider>
  );
};

export default DefaultDropdown;
