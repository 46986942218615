import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  datepicker,
  Loader,
} from "../../components";
import { UtilityArrays, Utils } from "../../config";

import "./styles.css";

const GeneralInfo = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const timeZones = Intl.supportedValuesOf("timeZone");

  const [state, setState] = useState({
    photo: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    middleName: "",
    primaryEmail: "",
    personalEmail: "",
    phoneNumber: "",
    guardianPhoneNumber: "",
    timeZone: null,
    dateOfBirth: "",
    gender: "",
    placeOfBirth: "",
    ethnicity: null,
    citizenships: [],
  });

  useEffect(() => {
    setState({
      photo: questionnaire?.photo ?? "",
      firstName: questionnaire?.firstName ?? "",
      lastName: questionnaire?.lastName ?? "",
      preferredName: questionnaire?.preferredName ?? "",
      middleName: questionnaire?.middleName ?? "",
      primaryEmail: questionnaire?.primaryEmail ?? "",
      personalEmail: questionnaire?.personalEmail ?? "",
      phoneNumber: questionnaire?.phoneNumber ?? "",
      guardianPhoneNumber: questionnaire?.guardianPhoneNumber ?? "",
      timeZone: questionnaire?.timeZone ?? null,
      dateOfBirth: questionnaire?.dateOfBirth ?? null,
      placeOfBirth: questionnaire?.placeOfBirth ?? null,
      gender: questionnaire?.gender ?? null,
      ethnicity: questionnaire?.ethnicity ?? null,
      citizenships: questionnaire?.citizenships ?? [],
    });
  }, [questionnaire]);

  const handleNext = () => {
    if (
      !state.firstName ||
      !state.lastName ||
      !state.preferredName ||
      !state.primaryEmail ||
      !state.phoneNumber ||
      !state.guardianPhoneNumber ||
      !state.timeZone ||
      !state.dateOfBirth ||
      !state.gender ||
      !state.ethnicity ||
      state.citizenships?.length === 0
    ) {
      message.error("Please fill all the fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            setPage("Address");
          }
        })
      );
    }
  };

  return (
    <div className="text-black">
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="max-h-[550px] overflow-y-auto">
            <questionnaireComponents.QuestionnaireLabel text="Upload Photo" />
            <Spacer height="5px" />
            <questionnaireComponents.QuestionnaireFileInput
              onChange={(fileLink) => {
                setState({ ...state, photo: fileLink });
              }}
              state={state}
              setState={setState}
              type="image"
              photo={state?.photo}
            />
            <Spacer height="24px" />
            <div className="grid grid-cols-2 gap-x-7 gap-y-6">
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="First name"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, firstName: e.target.value })
                  }
                  placeholder="Enter your first name"
                  value={state?.firstName}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Preferred Name"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, preferredName: e.target.value })
                  }
                  placeholder="Enter your preferred name"
                  value={state?.preferredName}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel text="Middle Name" />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, middleName: e.target.value })
                  }
                  placeholder="Enter your middle name"
                  value={state?.middleName}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Last Name"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, lastName: e.target.value })
                  }
                  placeholder="Enter your last name"
                  value={state?.lastName}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Primary Email"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, primaryEmail: e.target.value })
                  }
                  placeholder="Enter your primary email"
                  value={state?.primaryEmail}
                  type="email"
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel text="Personal Email" />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, personalEmail: e.target.value })
                  }
                  placeholder="Enter your personal email"
                  value={state?.personalEmail}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Phone Number"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultPhoneInput
                  onChange={(e) => setState({ ...state, phoneNumber: e })}
                  placeholder="Enter your phone number"
                  value={state?.phoneNumber}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Guardian Phone Number"
                  required
                />
                <Spacer height="5px" />
                <inputFields.DefaultPhoneInput
                  onChange={(e) =>
                    setState({ ...state, guardianPhoneNumber: e })
                  }
                  placeholder="Enter your primary guardian phone number"
                  value={state?.guardianPhoneNumber}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Time Zone"
                  required
                />
                <Spacer height="5px" />
                <inputFields.SelectDropdown
                  onChange={(e) => setState({ ...state, timeZone: e })}
                  placeholder="Please select your time zone"
                  value={state?.timeZone}
                  items={Utils.filterArray(timeZones)}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Date of birth"
                  required
                />
                <Spacer height="5px" />
                <datepicker.DefaultDatepicker
                  onChange={(date, dateString) => {
                    setState({ ...state, dateOfBirth: dateString });
                  }}
                  placeholder="--/--/----"
                  datevalue={state?.dateOfBirth ?? null}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Gender"
                  required
                />
                <Spacer height="5px" />
                <inputFields.SelectDropdown
                  onChange={(e) => setState({ ...state, gender: e })}
                  placeholder="Please select your gender"
                  value={state?.gender}
                  items={Utils.filterArray(UtilityArrays.genderArray)}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel text="City of Birth" />
                <Spacer height="5px" />
                <inputFields.DefaultInput
                  onChange={(e) =>
                    setState({ ...state, placeOfBirth: e.target.value })
                  }
                  placeholder="Enter your city of birth"
                  value={state?.placeOfBirth}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Ethnicity"
                  required
                />
                <Spacer height="5px" />
                <inputFields.SelectDropdown
                  onChange={(e) => setState({ ...state, ethnicity: e })}
                  placeholder="Please select your ethnicity"
                  value={state?.ethnicity}
                  items={Utils.filterArray(UtilityArrays.ethnicityArray)}
                />
              </div>
              <div>
                <questionnaireComponents.QuestionnaireLabel
                  text="Citizenship(s)"
                  required
                />
                <Spacer height="5px" />
                <inputFields.SelectDropdownMultiple
                  onChange={(e) => setState({ ...state, citizenships: e })}
                  placeholder="Please select your nationality"
                  value={state?.citizenships}
                  items={Utils.filterArray(UtilityArrays.nationalities)}
                />
              </div>
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInfo;
