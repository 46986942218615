import React, { useEffect, useRef } from "react";
import "./styles.css";

export default function PieChartUniGeneral(props) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!props.data) return;

    let chart;

    // Initialize chart
    am4core.ready(function () {
      am4core.useTheme(am4themes_animated);

      // Create a 3D Pie Chart
      chart = am4core.create(chartRef.current, am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 1; // Ensure visibility

      // Hide legend and logo
      chart.legend = null;
      chart.logo.disabled = true;

      // Set chart data
      chart.data = props?.data;

      // Create a Pie Series
      let series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "value";
      series.dataFields.category = "label";

      // Set colors
      series.colors.list = [am4core.color("#d70d57"), am4core.color("#3ad1bb")];
      series.colors.wrap = true;

      // Configure labels
      series.labels.template.text = "{value}%";
      series.labels.template.truncate = false;
      series.labels.template.fontSize = 12;
      series.labels.template.fontWeight = 700;
      series.labels.template.horizontalCenter = "middle";
      series.labels.template.verticalCenter = "middle";
      series.labels.template.maxWidth = 100;

      // Configure tooltips
      series.slices.template.tooltipText = "{category}: {value}%";

      // Set pie chart size and appearance
      series.radius = am4core.percent(90);
      series.innerRadius = am4core.percent(0);
      series.labels.template.radius = -20; // Bring labels closer to the pie

      // Adjust 3D depth
      series.depth = 10;
    });
    // Cleanup function to dispose of the chart
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [props.data]);

  return <div ref={chartRef}></div>;
}
