import {
  UNI_SEARCH,
  UNI_SEARCH_SUCCESS,
  UNI_SEARCH_FAILURE,
  SHORTLIST_SEARCH,
  SHORTLIST_SEARCH_SUCCESS,
  SHORTLIST_SEARCH_FAILURE,
  EXPLORE_SEARCH,
  EXPLORE_SEARCH_SUCCESS,
  EXPLORE_SEARCH_FAILURE,
} from "../constants";

const initialState = {
  uniSearch: {},
  shortlistSearch: {},
  exploreSearch: {},
  isLoading: false,
  error: null,
};

export default function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case UNI_SEARCH:
    case SHORTLIST_SEARCH:
    case EXPLORE_SEARCH:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UNI_SEARCH_SUCCESS:
      state = {
        ...state,
        uniSearch: action.payload,
        isLoading: false,
      };
      break;
    case SHORTLIST_SEARCH_SUCCESS:
      state = {
        ...state,
        shortlistSearch: action.payload,
        isLoading: false,
      };
      break;
    case EXPLORE_SEARCH_SUCCESS:
      state = {
        ...state,
        exploreSearch: action.payload,
        isLoading: false,
      };
      break;

    case UNI_SEARCH_FAILURE:
    case SHORTLIST_SEARCH_FAILURE:
    case EXPLORE_SEARCH_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
}
