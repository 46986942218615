import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { AuthAction, QuestionnaireAction } from "../actions";
import { io } from "socket.io-client";
import { DefaultNotification } from "../../components";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket"],
  withCredentials: true,
});

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/login", payload);
      if (res.data.status === 404) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 400) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 403) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 401) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else {
        yield put(AuthAction.SignInSuccess(res.data.data));
        message.success(res.data.message);
        socket.emit("add-user", res.data.data?.user?.id);
        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
        yield localStorage.setItem("showProfileBuilder", true);
        yield localStorage.setItem("showPaymentPage", true);

        if (cb) {
          cb(res.data);
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }
  static *refreshToken({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/refresh-token", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.refreshTokenSuccess(res.data.data));
        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
      } else {
        yield put(AuthAction.refreshTokenFailure());
        if (cb) {
          cb(res.data);
        }
      }
    } catch (err) {
      yield put(AuthAction.refreshTokenFailure());
    }
  }

  static *studentSignup({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/student-signup", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.studentSignupSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(AuthAction.studentSignupFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (error) {
      yield put(AuthAction.studentSignupFailure());
    }
  }

  static *Logout() {
    try {
      const user = JSON.parse(localStorage.getItem("authUser"));
      const userId = user?.user?.id;
      socket.emit("logout", userId);
      yield localStorage.setItem("showProfileBuilder", true);
      yield localStorage.setItem("showPaymentPage", true);

      localStorage.removeItem("authUser");

      yield put(QuestionnaireAction.getQuestionnaireSuccess({}));
      yield put(AuthAction.LogoutSuccess());
    } catch (err) {
      yield put(AuthAction.LogoutFailure());
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/forget-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ForgotPasswordSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(AuthAction.ForgotPasswordFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure());
    }
  }
  static *CheckEmail({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/check-user-email", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.CheckEmailSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(AuthAction.CheckEmailFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(AuthAction.CheckEmailFailure());
    }
  }

  static *VerifyOtp({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/verify-code", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifyOtpSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(AuthAction.VerifyOtpFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(AuthAction.VerifyOtpFailure());
    }
  }
  static *verifySchoolCode({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/verify-school-code", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.verifySchoolCodeSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(AuthAction.verifySchoolCodeFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(AuthAction.verifySchoolCodeFailure());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/reset-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));

        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }
}
