import React, { useRef, useState, useEffect } from "react";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import GridViewCard from "./grid-view-card";

const GridViewSwiper = (props) => {
  const containerRef = useRef(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(false);

  const handlePopoverScroll = (e) => {
    if (isPopoverOpen && containerRef.current) {
      if (e.deltaY !== 0) {
        containerRef.current.scrollTop += e.deltaY;
      }
      if (e.deltaX !== 0) {
        containerRef.current.scrollLeft += e.deltaX;
      }
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      window.addEventListener("wheel", handlePopoverScroll, { passive: false });
    } else {
      window?.removeEventListener("wheel", handlePopoverScroll);
    }
    return () => window?.removeEventListener("wheel", handlePopoverScroll);
  }, [isPopoverOpen]);

  const scrollCards = (direction) => {
    const cardWidth = 295;
    const scrollAmount = cardWidth * 5;
    const newScrollPosition =
      containerRef.current.scrollLeft + direction * scrollAmount;

    containerRef.current.scrollTo({
      left: newScrollPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const checkButtons = () => {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowPrevButton(scrollLeft > 0);
      setShowNextButton(scrollLeft + clientWidth < scrollWidth);
    };

    checkButtons();
    containerRef.current.addEventListener("scroll", checkButtons);
    return () =>
      containerRef?.current?.removeEventListener("scroll", checkButtons);
  }, []);

  return (
    <div className={`relative w-[${props?.width}px]`}>
      <div
        className={`absolute inset-y-0 left-0 w-[60px] pointer-events-none z-[50] bg-gradient-to-r from-white via-[#ffffff58] to-transparent transition-opacity ${
          showPrevButton ? "opacity-100" : "opacity-0"
        }`}
      />
      {showPrevButton && (
        <FaCircleChevronLeft
          onClick={() => scrollCards(-1)}
          className="absolute -left-[40px] top-1/2 transform -translate-y-1/2 text-main z-[100] opacity-0 hover:opacity-100 transition-all text-[58px] cursor-pointer bg-white rounded-full"
        >
          &lt;
        </FaCircleChevronLeft>
      )}
      <div ref={containerRef} className="flex overflow-x-auto user-select-auto">
        <div className="flex gap-4 pb-2">
          {props?.data?.map((item, index) => (
            <GridViewCard
              shownumber={props?.shownumber}
              key={index}
              index={index}
              data={item}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          ))}
        </div>
      </div>
      {showNextButton && (
        <FaCircleChevronRight
          onClick={() => scrollCards(1)}
          className="absolute z-[100] -right-[46px] top-1/2 transform -translate-y-1/2 text-main text-[58px] opacity-0 hover:opacity-100 transition-all cursor-pointer bg-white rounded-full"
        >
          &gt;
        </FaCircleChevronRight>
      )}
      <div
        className={`absolute inset-y-0 right-0 w-[60px] pointer-events-none z-[50] bg-gradient-to-l from-white via-[#ffffff58] to-transparent transition-opacity ${
          showNextButton ? "opacity-100" : "opacity-0"
        }`}
      />
    </div>
  );
};

export default GridViewSwiper;
