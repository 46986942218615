import {
  GET_RECOMMENDER,
  GET_RECOMMENDER_FAILURE,
  GET_RECOMMENDER_SUCCESS,
} from "../constants";

const initialState = {
  aspirational: [],
  solid: [],
  safe: [],
  isLoading: false,
  error: null,
};

export default function RecommenderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDER:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_RECOMMENDER_SUCCESS:
      state = {
        ...state,
        aspirational: action.payload?.aspirational,
        solid: action.payload?.solid,
        safe: action.payload?.safe,
        isLoading: false,
      };
      break;

    case GET_RECOMMENDER_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
