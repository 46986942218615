import { combineReducers } from "redux";

import ApplicationReducer from "./ApplicationReducer";
import AuthReducer from "./AuthReducer";
import ChatbotReducer from "./ChatbotReducer";
import ContactReducer from "./ContactReducer";
import CounselorReducer from "./CounselorReducer";
import OthersReducer from "./OthersReducer";
import ETextbookReducer from "./ETextbookReducer";
import InteractiveLearningReducer from "./InteractiveLearningReducer";
import IndividualStudentReducer from "./IndividualStudentReducer";
import InternshipsReducer from "./InternshipsReducer";
import MeetingReducer from "./MeetingReducer";
import NotificationsReducer from "./NotificationsReducer";
import ProfileReducer from "./ProfileReducer";
import QuestionnaireReducer from "./QuestionnaireReducer";
import RecommenderReducer from "./RecommenderReducer";
import SearchReducer from "./SearchReducer";
import UniversityReducer from "./UniversityReducer";
import WatchlistReducer from "./WatchlistReducer";

import { LOGOUT_SUCCESS } from "../constants";

const appReducer = combineReducers({
  application: ApplicationReducer,
  auth: AuthReducer,
  chatbot: ChatbotReducer,
  contact: ContactReducer,
  counselors: CounselorReducer,
  others: OthersReducer,
  eTextbook: ETextbookReducer,
  interactiveLearning: InteractiveLearningReducer,
  individualStudent: IndividualStudentReducer,
  internships: InternshipsReducer,
  meeting: MeetingReducer,
  notifications: NotificationsReducer,
  profile: ProfileReducer,
  questionnaire: QuestionnaireReducer,
  recommender: RecommenderReducer,
  search: SearchReducer,
  university: UniversityReducer,
  watchlist: WatchlistReducer,
});

const RootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default RootReducer;
