import React from "react";
import { useDispatch } from "react-redux";

import { BsBookmarkStarFill } from "react-icons/bs";

import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";

import { WatchlistAction } from "../../store/actions";
import { Images, Utils } from "../../config";

import "./styles.css";
import { Link } from "react-router-dom";

const RecommenderTable = (props) => {
  const dispatch = useDispatch();

  const uniData = props?.items;

  const handleUniversityWishlist = (id) => {
    const payload = { id };
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(payload, () => {
        dispatch(WatchlistAction.getWatchlist({}));
      })
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="recommender-table-header">
        <div>Name</div>
        <div className="text-center">QS World Ranking</div>
        <div className="text-center">Average Fee</div>
        <div></div>
      </div>
      {uniData?.map((item, index) => (
        <div className="recommender-table-row" key={index}>
          <div className="flex gap-2">
            <div className=" pr-3 border-r-[2px] border-r-liteBoxBorder min-w-[65px] flex items-center justify-center">
              <img
                src={item?.profilePic}
                className="h-[48px] w-[48px] rounded-full object-contain border border-gray-300"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-[16px] font-bold">{item?.name}</div>
              <div className="flex gap-2 items-center">
                <img src={Images.LocationPin} className="h-[18px]" />
                <div className="text-[16px] font-semibold">
                  {item?.city && `${item?.city}`}
                  {item?.state && `, ${item?.state}`}
                  {item?.country?.name && `, ${item?.country.name}`}
                </div>
              </div>
            </div>
          </div>
          <div className="text-[16px] font-semibold flex justify-center items-center">
            {item?.rankings?.QS ?? "NA"}
          </div>
          <div className="text-[16px] font-semibold flex justify-center items-center">
            {item?.avgFees ?? "NA"}
          </div>
          <div className="flex gap-2 justify-center items-center">
            {props?.selectedUni.includes(item?.id) ? (
              <MdOutlineCheckBox
                className="text-main text-[32px] cursor-pointer"
                onClick={() => {
                  props?.handleSelected(item?.id);
                }}
              />
            ) : (
              <MdOutlineCheckBoxOutlineBlank
                className="text-main text-[32px] cursor-pointer"
                onClick={() => {
                  props?.handleSelected(item?.id);
                }}
              />
            )}
            <BsBookmarkStarFill
              className={`${
                props?.watchlistedUnis.includes(item?.id)
                  ? "text-main"
                  : "text-gray-400"
              }  text-[28px] cursor-pointer`}
              onClick={() => {
                handleUniversityWishlist(item?.id);
              }}
            />
            <Link to={`/universities/general/${item?.id}`}>
              <img src={Images.RightArrowCircled} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecommenderTable;
