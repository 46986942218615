import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import { Spacer, boxContainer, Buttons } from "../../components";

function Recommenders() {
  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <div className="header-md text-main">
          Are You Facing Difficulties Choosing Universities?
        </div>
        <Spacer height={20} />
        <div className="header-sm">
          Use Our Proprietary Recommender Feature To Help You Find The Best
          University Based On Your Requirements. Take A Peek At The Video Below
          To See How It Works!
        </div>
        <Spacer height={40} />
        <div className="flex flex-col justify-center items-center gap-4">
          <ReactPlayer
            url={"https://youtu.be/lrjixk6fmbQ?si=SDDIGUPBnJBRvZep"}
            height="350px"
            width="623px"
            controls={true}
          />
          <div className="header-sm">Click The Button Below To Start Now.</div>
          <Link to="/recommender/qualifications" className="w-[200px]">
            <Buttons.DefaultButton width="full">Start</Buttons.DefaultButton>
          </Link>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default Recommenders;
