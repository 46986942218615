import {
  UNI_SEARCH,
  UNI_SEARCH_SUCCESS,
  UNI_SEARCH_FAILURE,
  SHORTLIST_SEARCH,
  SHORTLIST_SEARCH_SUCCESS,
  SHORTLIST_SEARCH_FAILURE,
  EXPLORE_SEARCH,
  EXPLORE_SEARCH_SUCCESS,
  EXPLORE_SEARCH_FAILURE,
} from "../constants";

export default class SearchAction {
  static uniSearch(payload, cb) {
    return {
      type: UNI_SEARCH,
      payload,
      cb,
    };
  }
  static uniSearchSuccess(payload) {
    return {
      type: UNI_SEARCH_SUCCESS,
      payload,
    };
  }
  static uniSearchFailure(payload) {
    return {
      type: UNI_SEARCH_FAILURE,
      payload,
    };
  }
  static shortlistSearch(payload, cb) {
    return {
      type: SHORTLIST_SEARCH,
      payload,
      cb,
    };
  }
  static shortlistSearchSuccess(payload) {
    return {
      type: SHORTLIST_SEARCH_SUCCESS,
      payload,
    };
  }
  static shortlistSearchFailure(payload) {
    return {
      type: SHORTLIST_SEARCH_FAILURE,
      payload,
    };
  }

  static exploreSearch(payload, cb) {
    return {
      type: EXPLORE_SEARCH,
      payload,
      cb,
    };
  }
  static exploreSearchSuccess(payload) {
    return {
      type: EXPLORE_SEARCH_SUCCESS,
      payload,
    };
  }
  static exploreSearchFailure(payload) {
    return {
      type: EXPLORE_SEARCH_FAILURE,
      payload,
    };
  }
}
