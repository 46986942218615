import React from "react";
import { Select, ConfigProvider } from "antd";
import DefaultInput from "./default-input";

import { Colors } from "../../config";

import { FaChevronDown } from "react-icons/fa";

import "./styles.css";

const handleFilter = (input, option) => {
  return option.label.toString().toLowerCase().startsWith(input.toLowerCase());
};

const SelectDropdown = ({
  showSearch = true,
  placeholder = "Select",
  loading = false,
  items = [],
  custominput,
  disabled = false,
  largedropdown = false,
  ...props
}) => (
  <ConfigProvider
    theme={{
      components: {
        Select: {
          controlOutline: "none",
          colorPrimaryTextActive: Colors.main,
          colorPrimaryHover: Colors.main,
          colorPrimaryActive: Colors.main,
          colorPrimaryBorder: Colors.main,
          optionSelectedBg: Colors.liteBoxBorder,
        },
      },
      token: {
        colorBorder: Colors.main,
        paddingSM: 16,
        controlHeight: 48,
        fontSize: 16,
        borderRadius: 10,
      },
    }}
  >
    <Select
      showSearch={showSearch}
      placeholder={placeholder}
      loading={loading}
      suffixIcon={<FaChevronDown color={Colors.main} fontSize={18} />}
      className={`${
        disabled
          ? "default-select-dropdown-disabled"
          : "default-select-dropdown"
      }`}
      filterOption={handleFilter}
      options={items.map((item) => ({
        label: item?.label,
        value: item?.value,
      }))}
      disabled={disabled}
      listHeight={largedropdown ? 350 : 256}
      dropdownRender={(menu) => (
        <>
          {menu}
          {custominput && (
            <div className="flex gap-1 mt-2 items-center p-2">
              <div>Other: </div>
              <DefaultInput
                placeholder="Please enter value"
                onChange={(e) => {
                  props?.onChange(e.target.value);
                }}
              />
            </div>
          )}
        </>
      )}
      {...props}
    />
  </ConfigProvider>
);

export default SelectDropdown;
