import ReactCountryFlag from "react-country-flag";

const homeCurrency = [
  {
    label: (
      <div className="flex gap-2 items-center">
        <ReactCountryFlag
          countryCode={"US"}
          svg
          style={{
            width: "16px",
            height: "16px",
          }}
          title={"US"}
        />
        <div className="font-semibold mt-1">USD</div>
      </div>
    ),
    value: "USD",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <ReactCountryFlag
          countryCode={"GB"}
          svg
          style={{
            width: "16px",
            height: "16px",
          }}
          title={"GB"}
        />
        <div className="font-semibold mt-1">GBP</div>
      </div>
    ),
    value: "GBP",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <ReactCountryFlag
          countryCode={"EU"}
          svg
          style={{
            width: "16px",
            height: "16px",
          }}
          title={"EU"}
        />
        <div className="font-semibold mt-1">EUR</div>
      </div>
    ),
    value: "EUR",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <ReactCountryFlag
          countryCode={"AE"}
          svg
          style={{
            width: "16px",
            height: "16px",
          }}
          title={"AE"}
        />
        <div className="font-semibold mt-1">AED</div>
      </div>
    ),
    value: "AED",
  },
];

export default homeCurrency;
