import React from "react";

import { BarChart } from "@mui/x-charts/BarChart";

import { Colors } from "../../config";

const HorizontalBarChart = (props) => {
  return (
    <BarChart
      skipAnimation={false}
      height={350}
      borderRadius={4}
      layout="horizontal"
      bottomAxis={{
        disableTicks: true,
        disableLine: true,
        tickLabelStyle: {
          display: "none",
        },
      }}
      leftAxis={{
        disableTicks: true,
        disableLine: true,
        tickLabelStyle: {
          fontSize: "10px",
          fontWeight: "700",
          display: "none",
        },
      }}
      slotProps={{
        barLabel: {
          style: { fill: "white", fontSize: "20px", fontWeight: "700" },
        },

        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "left" },
          padding: 5,
          labelStyle: {
            fontSize: 12,
            fontWeight: "600",
            fill: "black",
            paddingLeft: "10px",
          },
        },
      }}
      grid={{ vertical: true, horizontal: true }}
      margin={{ top: 5, right: 1, bottom: 35, left: 125 }}
      tooltip={{ trigger: "item" }}
      {...props}
    />
  );
};

export default HorizontalBarChart;
