import {
  RETRIEVE_SESSION,
  RETRIEVE_SESSION_SUCCESS,
  RETRIEVE_SESSION_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CREATE_CHECKOUT_SESSION_MONTHLY,
  CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS,
  CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE,
  CREATE_CHECKOUT_SESSION_ONETIME,
  CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS,
  CREATE_CHECKOUT_SESSION_ONETIME_FAILURE,
  UPDATE_SHOW_TRIAL_MODAL,
  UPDATE_SHOW_TRIAL_MODAL_SUCCESS,
  UPDATE_SHOW_TRIAL_MODAL_FAILURE,
} from "../constants";

export default class IndividualStudentAction {
  static retrieveSession(payload, cb) {
    return {
      type: RETRIEVE_SESSION,
      payload,
      cb,
    };
  }
  static retrieveSessionSuccess(payload) {
    return {
      type: RETRIEVE_SESSION_SUCCESS,
      payload,
    };
  }
  static retrieveSessionFailure(payload) {
    return {
      type: RETRIEVE_SESSION_FAILURE,
      payload,
    };
  }
  static cancelSubscription(cb) {
    return {
      type: CANCEL_SUBSCRIPTION,
      cb,
    };
  }
  static cancelSubscriptionSuccess(payload) {
    return {
      type: CANCEL_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  static cancelSubscriptionFailure(payload) {
    return {
      type: CANCEL_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
  static createCheckoutMonthly(payload, cb) {
    return {
      type: CREATE_CHECKOUT_SESSION_MONTHLY,
      payload,
      cb,
    };
  }
  static createCheckoutMonthlySuccess(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS,
      payload,
    };
  }
  static createCheckoutMonthlyFailure(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE,
      payload,
    };
  }
  static createCheckoutOnetime(payload, cb) {
    return {
      type: CREATE_CHECKOUT_SESSION_ONETIME,
      payload,
      cb,
    };
  }
  static createCheckoutOnetimeSuccess(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS,
      payload,
    };
  }
  static createCheckoutOnetimeFailure(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_ONETIME_FAILURE,
      payload,
    };
  }

  static updateTrialModal(cb) {
    return {
      type: UPDATE_SHOW_TRIAL_MODAL,
      cb,
    };
  }
  static updateTrialModalSuccess(payload) {
    return {
      type: UPDATE_SHOW_TRIAL_MODAL_SUCCESS,
      payload,
    };
  }
  static updateTrialModalFailure(payload) {
    return {
      type: UPDATE_SHOW_TRIAL_MODAL_FAILURE,
      payload,
    };
  }
}
