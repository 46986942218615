import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { OthersAction, ETextbookAction } from "../../store/actions";

import { Spacer, Loader } from "../../components";

const ETextbooks = (props) => {
  const dispatch = useDispatch();

  const books = useSelector((state) => state?.eTextbook?.books);
  const booksLoading = useSelector((state) => state?.eTextbook?.isLoading);
  const profileData = useSelector((state) => state?.profile?.user);

  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    const payload = {
      pageNo,
      search: props?.search,
      filter:
        props?.filter === "paid"
          ? "paid-ebooks"
          : props?.filter === "free"
          ? "free-ebooks"
          : "eBooks",
    };
    dispatch(
      ETextbookAction.getETextbooks(payload, () => {
        dispatch(OthersAction.getTasks());
      })
    );
  }, [pageNo, props?.search, props?.filter]);

  const handlePagination = (pageNumber) => {
    setPageNo(pageNumber);
  };

  return (
    <div>
      {booksLoading ? (
        <Loader.Circular />
      ) : (
        <div className="item-container">
          {books?.items?.map((book) => {
            return (
              <div
                className="item"
                onClick={() => {
                  window.open(book?.volumeInfo?.previewLink, "_blank");
                }}
                key={book?.id}
              >
                <div>
                  <img
                    src={book?.volumeInfo?.imageLinks?.thumbnail}
                    className="item-image"
                  />
                </div>
                {/* <div className="item-price">{book?.saleInfo?.saleability}</div> */}
                <div className="item-title">
                  {book?.volumeInfo?.title.length > 50
                    ? `${book?.volumeInfo?.title.slice(0, 50)}...`
                    : book?.volumeInfo?.title}{" "}
                  ({book?.volumeInfo?.publishedDate})
                </div>
                <div className="h-[1px] w-[90%] bg-gray-300"></div>
                {book?.volumeInfo?.authors?.length > 0 ? (
                  <div className="item-instructor">
                    <span className="font-bold">Author:</span>{" "}
                    {book?.volumeInfo?.authors?.map((author, index) => (
                      <span className="font-semibold line-clamp-3 text-ellipsis overflow-hidden">
                        {book?.volumeInfo?.authors?.length === 1 ? (
                          <>{author}</>
                        ) : (
                          <>{` ${author},`}</>
                        )}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="item-instructor">
                    <span className="font-bold">Author:</span> N/A
                  </div>
                )}
                <div className="h-[1px] w-[90%] bg-gray-300"></div>
                {book?.volumeInfo?.categories?.length > 0 ? (
                  <div className="item-instructor h-[40px]">
                    <b>Categories:</b>{" "}
                    {book?.volumeInfo?.categories?.map((category, index) => (
                      <span style={{ margin: "0px" }}>
                        {book?.volumeInfo?.categories?.length === 1 ? (
                          <>{category}</>
                        ) : (
                          <>{`, ${category}`}</>
                        )}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="item-instructor h-[40px]">
                    <b className="">Categories:</b> N/A
                  </div>
                )}
                <div className="h-[1px] w-[90%] bg-gray-300"></div>
                <div className="flex justify-start text-main font-semibold text-[14px] my-2">
                  Learn More
                </div>
                <Spacer height={10} />
              </div>
            );
          })}
        </div>
      )}
      <Spacer height={10} />
      {!profileData?.freeUser && (
        <div className="flex justify-center items-center">
          <Pagination
            responsive="true"
            showSizeChanger={false}
            hideOnSinglePage={true}
            current={pageNo}
            pageSize={10}
            total={books?.totalItems}
            onChange={handlePagination}
          />
        </div>
      )}
    </div>
  );
};

export default ETextbooks;
