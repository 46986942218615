import React from "react";
import { Table, ConfigProvider } from "antd";

import "./styles.css";

const MeetingTable = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#f6fbf9",
            headerBorderRadius: 12,
            headerColor: "black",
            borderColor: "#c5e0dcc4",
          },
        },
      }}
    >
      <Table
        columns={props?.columns}
        dataSource={props?.data}
        bordered={props?.bordered ?? true}
        pagination={props?.pagination ?? false}
        className="meeting-table"
        {...props}
      />
    </ConfigProvider>
  );
};

export default MeetingTable;
