import React from "react";

const QuestionnaireFieldContainer = (props) => {
  return (
    <div
      className="bg-[#F9FFFE] shadow-sm w-full rounded-xl px-3 py-4"
      {...props}
    >
      {props?.children}
    </div>
  );
};

export default QuestionnaireFieldContainer;
