import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import { QuestionnaireAction } from "../../store/actions";

import { Images } from "../../config";

const QuestionnaireFileInput = (props) => {
  const dispatch = useDispatch();
  const fileSelect = useRef();

  const handleFileUpload = (file) => {
    const data = new FormData();
    data.append("file", file);

    dispatch(
      QuestionnaireAction.uploadImageQuestionnaire(data, (res) => {
        const fileLink = res.data;
        props?.onChange(fileLink);

        if (fileSelect.current) {
          fileSelect.current.value = "";
        }
      })
    );
  };

  return (
    <div
      onClick={() => {
        fileSelect.current.click();
      }}
      className="questionnaire-file-input"
    >
      <input
        style={{ display: "none" }}
        type="file"
        name="image"
        id={`getFile-${props?.id}`}
        ref={fileSelect}
        onChange={(event) => {
          handleFileUpload(event.target.files[0]);
        }}
        accept={
          props?.type === "image"
            ? ".jpg, .jpeg, .png"
            : ".pdf, .csv, .xlsx, .doc, .docx"
        }
      />
      <div
        className={`${
          props?.photo ? "opacity-[100%]" : "opacity-[50%]"
        } text-[14px]`}
      >
        {props?.photo ? "File uploaded" : "Select a file"}
      </div>
      <div>
        <img src={Images.QuestionnaireFileInput} className="h-[18px] pr-2" />
      </div>
    </div>
  );
};

export default QuestionnaireFileInput;
