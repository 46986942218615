import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie-player";

import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";

import {
  RecommenderAction,
  WatchlistAction,
  UniversityAction,
} from "../../store/actions";

import {
  Spacer,
  Loader,
  tables,
  boxContainer,
  Buttons,
  Modal,
} from "../../components";

import { Images } from "../../config";

import CircleContainer from "./circle-container";

function RecommendersResults() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { watchlists } = useSelector((state) => state.watchlist);
  const { aspirational, solid, safe, isLoading } = useSelector(
    (state) => state.recommender
  );

  const [selectedUni, setSelectedUni] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [watchlistedUnis, setWatchlistedUnis] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(WatchlistAction.getWatchlist({}));
  }, []);

  useEffect(() => {
    const unisWatchlisted = watchlists?.map((item) => item?.id);
    setWatchlistedUnis(unisWatchlisted);
  }, [watchlists]);

  useEffect(() => {
    const payload = location.state;
    dispatch(RecommenderAction.getRecommender(payload));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setShowModal(true);
    }
  }, [isLoading]);

  const uniCompareAPICall = () => {
    const payload = {
      uniIds: selectedUni,
    };

    dispatch(
      UniversityAction.compareUniverities(payload, () => {
        navigate("/recommender/compare");
      })
    );
  };

  const handleSelected = (id) => {
    if (selectedUni.includes(id)) {
      setSelectedUni(selectedUni.filter((i) => i !== id));
    } else {
      setSelectedUni([...selectedUni, id]);
    }
  };

  const handleAll = () => {
    if (allSelected === false) {
      setSelectedUni([]);
      let uniArray = [];
      aspirational.map((uni) => {
        uniArray.push(uni.id);
      });
      solid.map((uni) => {
        uniArray.push(uni.id);
      });
      safe.map((uni) => {
        uniArray.push(uni.id);
      });

      setSelectedUni(uniArray);
      setAllSelected(true);
    } else {
      setSelectedUni([]);
      setAllSelected(false);
    }
  };

  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <div className="h-[calc(100vh-280px)] overflow-scroll">
          <CircleContainer page="results" />
          <div className="px-3">
            <Spacer height={20} />
            <div className="header-md text-main">Results</div>
            <Spacer height={10} />
            <div className="text-[18px] font-semibold">
              Based On Your Entered Details & Your Interests, The Recommender
              Has Found Following Universities For You.
            </div>
          </div>
          <Spacer height={20} />
          {isLoading ? (
            <Loader.Circular />
          ) : (
            <>
              <div className="flex flex-col gap-3 px-3">
                <div className="flex justify-between">
                  <div className="text-[18px] text-main font-semibold">
                    Aspirational Options
                  </div>
                  <div className="flex justify-center items-center">
                    {allSelected ? (
                      <MdOutlineCheckBox
                        className="text-main text-[24px]"
                        onClick={() => handleAll()}
                      />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-main text-[24px]"
                        onClick={() => handleAll()}
                      />
                    )}

                    <span className="text-black font-semibold">Select All</span>
                  </div>
                </div>
                <tables.RecommenderTable
                  items={aspirational}
                  selectedUni={selectedUni}
                  handleSelected={handleSelected}
                  watchlistedUnis={watchlistedUnis}
                />
              </div>
              <Spacer height={20} />
              <div className="flex flex-col gap-3 px-3">
                <div className="flex justify-between">
                  <div className="text-[18px] text-main font-semibold">
                    Solid Options
                  </div>
                </div>
                <tables.RecommenderTable
                  items={solid}
                  selectedUni={selectedUni}
                  handleSelected={handleSelected}
                  watchlistedUnis={watchlistedUnis}
                />
              </div>
              <Spacer height={20} />
              <div className="flex flex-col gap-3 px-3">
                <div className="flex justify-between">
                  <div className="text-[18px] text-main font-semibold">
                    Safe Options
                  </div>
                </div>
                <tables.RecommenderTable
                  items={safe}
                  selectedUni={selectedUni}
                  handleSelected={handleSelected}
                  watchlistedUnis={watchlistedUnis}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-end">
          <Buttons.DefaultButton
            onClick={() => uniCompareAPICall()}
            disabled={selectedUni?.length === 0}
          >
            Compare Options
          </Buttons.DefaultButton>
        </div>
      </boxContainer.LiteBoxContainer>
      <Modal.DefaultModal open={showModal} onCancel={() => setShowModal(false)}>
        <div className="flex flex-col justify-center items-center p-4 w-[600px]">
          <Lottie
            loop
            animationData={Images.Done}
            play
            style={{ width: "100px" }}
          ></Lottie>
          <div className="text-[18px] font-medium text-center">
            These Are Results Generated By UniVision’s State Of The Art
            Algorithm Based Recommender Feature! Feel Free To Navigate And
            Compare The Different University Options In Each Category.
          </div>
          <div className="w-[200px] mt-4">
            <Buttons.DefaultButton
              onClick={() => setShowModal(false)}
              size="medium"
              width="full"
            >
              Ok
            </Buttons.DefaultButton>
          </div>
        </div>
      </Modal.DefaultModal>
    </div>
  );
}

export default RecommendersResults;
