import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_UNREAD_MESSAGES,
  GET_UNREAD_MESSAGES_SUCCESS,
  GET_UNREAD_MESSAGES_FAILURE,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE,
} from "../constants";

const initialState = {
  unreadMessages: [],
  support: [],
  counselors: [],
  messages: [],
  isUsersLoading: false,
  isMessagesLoading: false,
  error: null,
};

export default function ChatbotReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        isUsersLoading: true,
        error: null,
      };
      break;
    case GET_MESSAGES:
    case GET_UNREAD_MESSAGES:
      state = {
        ...state,
        isMessagesLoading: true,
        error: null,
      };
      break;
    case ADD_MESSAGE:
      state = {
        ...state,
        error: null,
      };
      break;
    case GET_MESSAGES_SUCCESS:
      state = {
        ...state,
        messages: action.payload,
        isMessagesLoading: false,
      };
      break;
    case GET_UNREAD_MESSAGES_SUCCESS:
      state = {
        ...state,
        unreadMessages: action.payload,
        isMessagesLoading: false,
      };
      break;
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        support: action.payload.support,
        counselors: action.payload.counselors,
        isUsersLoading: false,
      };
      break;
    case ADD_MESSAGE_SUCCESS:
      state = {
        ...state,
      };
      break;

    case GET_MESSAGES_FAILURE:
    case GET_UNREAD_MESSAGES_FAILURE:
      state = {
        ...state,
        isMessagesLoading: false,
        error: action.payload,
      };
    case ADD_MESSAGE_FAILURE:
      state = {
        ...state,
        error: action.payload,
      };
    case GET_USERS_FAILURE:
      state = {
        ...state,
        isUsersLoading: false,
        error: action.payload,
      };
    default:
      break;
  }
  return state;
}
