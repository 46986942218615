import React from "react";
import { Checkbox } from "antd";

import "./styles.css";

const VerticalCheckbox = (props) => {
  return (
    <Checkbox.Group
      {...props}
      className="flex flex-col gap-3 verticalCheckbox"
    />
  );
};

export default VerticalCheckbox;
