import {
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  SIGNUPSTUDENT,
  SIGNUPSTUDENT_FAILURE,
  SIGNUPSTUDENT_SUCCESS,
  CHECK_EMAIL,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  VERIFY_SCHOOL_CODE,
  VERIFY_SCHOOL_CODE_SUCCESS,
  VERIFY_SCHOOL_CODE_FAILURE,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../constants";

const initialState = {
  isLoggedIn: localStorage.getItem("authUser") ? true : false,
  user: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {},
  isLoading: false,
  error: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
    case REFRESH_TOKEN:
    case SIGNUPSTUDENT:
    case FORGOT_PASSWORD:
    case VERIFY_OTP:
    case CHECK_EMAIL:
    case VERIFY_SCHOOL_CODE:
    case RESET_PASSWORD:
    case LOGOUT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case LOGIN_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
      state = {
        ...state,
        user: { ...action.payload },
        isLoggedIn: true,
        isLoading: false,
      };
      break;
    case LOGIN_FAILURE:
    case REFRESH_TOKEN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        user: {},
        isLoggedIn: false,
        isLoading: false,
      };
      break;
    case SIGNUPSTUDENT_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case CHECK_EMAIL_SUCCESS:
    case VERIFY_OTP_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case VERIFY_SCHOOL_CODE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case SIGNUPSTUDENT_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case CHECK_EMAIL_FAILURE:
    case VERIFY_OTP_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case VERIFY_SCHOOL_CODE_FAILURE:
    case LOGOUT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case LOADER_TRUE:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOADER_FALSE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
}
