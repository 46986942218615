import React, { useRef, useState, useEffect } from "react";

import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

import TopStoriesCard from "./top-stories-card";
const TopStoriesSwiper = (props) => {
  const containerRef = useRef(null);

  const [showNextButton, setShowNextButton] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(false);

  const scrollCards = (direction) => {
    const cardWidth = 295;
    const scrollAmount = cardWidth * 5;
    const newScrollPosition =
      containerRef.current.scrollLeft + direction * scrollAmount;

    containerRef.current.scrollTo({
      left: newScrollPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const checkButtons = () => {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowPrevButton(scrollLeft > 0);
      setShowNextButton(scrollLeft + clientWidth < scrollWidth);
    };

    checkButtons();
    containerRef.current.addEventListener("scroll", checkButtons);
    return () =>
      containerRef?.current?.removeEventListener("scroll", checkButtons);
  }, []);

  return (
    <div className="relative w-[calc(100vw-410px)]">
      {showPrevButton && (
        <FaCircleChevronLeft
          onClick={() => scrollCards(-1)}
          className="absolute -left-[30px] top-1/2 transform -translate-y-1/2 text-main z-[100] opacity-0 hover:opacity-100 transition-all text-[48px] cursor-pointer"
        >
          &lt;
        </FaCircleChevronLeft>
      )}
      <div ref={containerRef} className="flex overflow-x-auto user-select-auto">
        <div className="flex gap-4 pb-2">
          {props?.data?.map((item, index) => (
            <TopStoriesCard key={index} data={item} />
          ))}
        </div>
      </div>
      {showNextButton && (
        <FaCircleChevronRight
          onClick={() => scrollCards(1)}
          className="absolute -right-[36px] top-1/2 transform -translate-y-1/2 text-main text-[48px] opacity-0 hover:opacity-100 transition-all cursor-pointer"
        >
          &gt;
        </FaCircleChevronRight>
      )}
    </div>
  );
};

export default TopStoriesSwiper;
