import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { WatchlistAction } from "../actions";
import { DefaultNotification } from "../../components";

export default class WatchlistMiddleware {
  static *getWatchlist({ payload, cb }) {
    try {
      const {
        search,
        limit,
        offset,
        region,
        areaOfStudy,
        majors,
        entranceRequirements,
        budget,
        scholarships,
        admissionRequirements,
        sortby,
      } = payload;
      const res = yield ApiCaller.Get(
        `/university/fetch-wishlist?search=${search ?? ""}&budget=${
          budget ?? ""
        }&limit=${limit ?? ""}&offset=${offset ?? ""}&region=${
          region ?? ""
        }&areaOfStudy=${areaOfStudy ?? ""}&majors=${
          majors ?? ""
        }&entranceRequirements=${entranceRequirements ?? ""}&scholarships=${
          scholarships ?? ""
        }&admissionRequirements=${admissionRequirements ?? ""}&sortby=${
          sortby ?? "qs"
        }`
      );
      if (res.status === 200) {
        yield put(WatchlistAction.getWatchlistSuccess(res.data.data));
      } else {
        yield put(WatchlistAction.getWatchlistFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(WatchlistAction.getWatchlistFailure());
    }
  }

  static *addOrRemoveWishlist({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/university/add-to-wishlist?uniId=${payload.id}`
      );
      if (res.status === 200) {
        yield put(WatchlistAction.addOrRemoveToWishlistSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(WatchlistAction.addOrRemoveToWishlistFailure());
        message.error(res?.data?.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(WatchlistAction.addOrRemoveToWishlistFailure());
    }
  }
}
