import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { OthersAction, QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import { Utils } from "../../config";

import "./styles.css";

const Address = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { countries, isCountryLoading } = useSelector((state) => state.others);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [countriesFiltered, setCountriesFiltered] = useState([]);
  const [state, setState] = useState({
    addressLane1: "",
    addressLane2: "",
    addressLane3: "",
    postalCode: "",
    country: null,
    city: null,
  });

  useEffect(() => {
    getQuestionnaire();
    if (countries?.length === 0) {
      dispatch(OthersAction.getCountries());
    }
  }, []);

  useEffect(() => {
    const countryNames = countries?.map((country) => country.name);
    setCountriesFiltered(countryNames);
  }, [countries]);

  useEffect(() => {
    setState({
      addressLane1: questionnaire?.addressLane1 ?? "",
      addressLane2: questionnaire?.addressLane2 ?? "",
      addressLane3: questionnaire?.addressLane3 ?? "",
      postalCode: questionnaire?.postalCode ?? "",
      country: questionnaire?.country ?? null,
      city: questionnaire?.city ?? null,
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    if (!state.addressLane1 || !state.country || !state.city) {
      message.error("Please fill all the fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            navigate("/profile-builder/overall-gpa");
          }
          getQuestionnaire();
        })
      );
    }
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2">Address</div>
      <Spacer height="10px" />
      {isLoading || isCountryLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6">
            <div className="col-span-2">
              <questionnaireComponents.QuestionnaireLabel
                text="Lane 1"
                required
              />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, addressLane1: e.target.value })
                }
                placeholder="Box No. 2639, Abu Dhabi,Emirates"
                value={state?.addressLane1}
              />
            </div>
            <div className="col-span-2">
              <questionnaireComponents.QuestionnaireLabel text="Lane 2" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, addressLane2: e.target.value })
                }
                placeholder="Box No. 2639, Abu Dhabi,Emirates"
                value={state?.addressLane2}
              />
            </div>
            <div className="col-span-2">
              <questionnaireComponents.QuestionnaireLabel text="Lane 3" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, addressLane3: e.target.value })
                }
                placeholder="Box No. 2639, Abu Dhabi,Emirates"
                value={state?.addressLane3}
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="Zip/Postal Code" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, postalCode: e.target.value })
                }
                placeholder="35412"
                type="number"
                value={state?.postalCode}
              />
            </div>

            <div>
              <questionnaireComponents.QuestionnaireLabel
                text="Country"
                required
              />
              <Spacer height="5px" />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, country: e })}
                placeholder="Please select your country"
                value={state?.country}
                items={Utils.filterArray(countriesFiltered)}
              />
            </div>

            <div>
              <questionnaireComponents.QuestionnaireLabel
                text="City"
                required
              />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) => setState({ ...state, city: e.target.value })}
                placeholder="Enter your city of birth"
                value={state?.city}
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default Address;
