import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { OthersAction, QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const Location = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { countries, isCountryLoading } = useSelector((state) => state.others);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [countriesFiltered, setCountriesFiltered] = useState([]);
  const [state, setState] = useState({
    studyAboradPreference: null,
    regionInterest: null,
    regionPreference: [],
    urbanPreference: null,
  });

  useEffect(() => {
    getQuestionnaire();
    if (countries?.length === 0) {
      dispatch(OthersAction.getCountries());
    }
  }, [profileData]);

  useEffect(() => {
    const countryNames = countries?.map((country) => ({
      label: country.name,
      value: country.name,
    }));
    setCountriesFiltered(countryNames);
  }, [countries]);

  useEffect(() => {
    setState({
      studyAboradPreference: questionnaire?.studyAboradPreference ?? null,
      regionInterest: questionnaire?.regionInterest ?? null,
      regionPreference: questionnaire?.regionPreference ?? [],
      urbanPreference: questionnaire?.urbanPreference ?? null,
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    const payload = { userId: profileData?.id, ...state };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          navigate("/profile-builder/financial");
        }
        getQuestionnaire();
      })
    );
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">Location</div>
      <Spacer height="10px" />
      {isLoading || isCountryLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <div className="section-input-title flex justify-between">
            <questionnaireComponents.QuestionnaireLabel text="1. How important is it for you to study abroad?" />
            <span className="text-[12px] text-grayText opacity-60">
              (least important 1, important 3, most important 5)
            </span>
          </div>
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.studyAboradPreference}
            handleChange={(e) =>
              setState({ ...state, studyAboradPreference: e })
            }
          />
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel text="2. Do you know which country or region you're interested to study in?" />
          <Spacer height="10px" />
          <div className="pl-3">
            <inputFields.HorizontalRadio
              onChange={(e) =>
                setState({ ...state, regionInterest: e.target.value })
              }
              value={state.regionInterest}
              items={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
            />
          </div>
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel text="3. What region do you prefer?" />
          <Spacer height="5px" />
          <div className="w-[500px]">
            <inputFields.SelectDropdown
              style={{ width: "50%", paddingLeft: "12px" }}
              onChange={(e) => setState({ ...state, regionPreference: [e] })}
              placeholder="Search here"
              value={state?.regionPreference[0]}
              items={countriesFiltered}
            />
          </div>
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="4. How important is it for you to study in a university located in an
          urban area?"
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.urbanPreference}
            handleChange={(e) => setState({ ...state, urbanPreference: e })}
          />

          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default Location;
