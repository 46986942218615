import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";

import { OthersAction } from "../../store/actions";

import AppRoutes from "./app-routes";
import AuthRoutes from "./auth-routes";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket"],
  withCredentials: true,
});

const AllRoutes = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn || false);

  useEffect(() => {
    const handleUserUpdate = (users) => {
      const payload = { users };
      console.log("Updated online users payload", payload);
      dispatch(OthersAction.getOnlineUsers(payload));
    };

    socket.on("update-users", handleUserUpdate);

    return () => {
      socket.off("update-users", handleUserUpdate);
    };
  }, []);

  if (isLoggedIn) {
    return <AppRoutes />;
  } else {
    return <AuthRoutes />;
  }
};

export default AllRoutes;
