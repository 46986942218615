import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import {
  Buttons,
  Spacer,
  boxContainer,
  application,
  inputFields,
  datepicker,
  upload,
  GetProPopup,
} from "../../components";

import { UniversityAction, ApplicationAction } from "../../store/actions";

const AddApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.user);
  const { allUniversities, isLoading } = useSelector(
    (state) => state.university
  );
  const { programTypes, isProgramLoading } = useSelector(
    (state) => state.others
  );

  const [allUniList, setAllUniList] = useState([]);
  const [programTypeList, setProgramTypeList] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [state, setState] = useState({
    universityId: null,
    dateOfApplication: "",
    preferredMajorId: null,
    proofOfApplication: "",
    submittedApplication: true,
    proofOfApplication: "",
    pendingRequirements: [],
  });

  useEffect(() => {
    if (allUniversities.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, [dispatch, allUniversities]);

  useEffect(() => {
    if (allUniversities.length) {
      setAllUniList(
        allUniversities.map(({ id, name }) => ({ value: id, label: name }))
      );
    }
  }, [allUniversities]);

  useEffect(() => {
    if (programTypes.length) {
      setProgramTypeList(
        programTypes.map(({ id, programType }) => ({
          value: id,
          label: programType,
        }))
      );
    }
  }, [programTypes]);

  useEffect(() => {
    const selectedUniversity = allUniversities.find(
      (uni) => uni.id === state.universityId
    );
    if (selectedUniversity) {
      handleApplicationChange("pendingRequirements", []);
      setChecklists(
        selectedUniversity.checklists.map((item) => ({
          label: item.details,
          value: item.details,
        }))
      );
    }
  }, [state.universityId]);

  const defaultStates = () => {
    setIsDisabled(false);
    setButtonText("Submit");
    setLoading(false);
  };

  const handleApplicationChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    if (!profileData?.freeUser) {
      if (
        !state.universityId ||
        !state.dateOfApplication ||
        !state.preferredMajorId
      ) {
        message.error("Please fill all required fields.");
      } else if (state.submittedApplication && !state.proofOfApplication) {
        message.error("Please upload proof of application.");
      } else {
        setLoading(true);
        setButtonText("Submitting");
        setIsDisabled(true);
        const payload = { ...state };

        dispatch(
          ApplicationAction.addApplications(payload, (res) => {
            defaultStates();
            if (res.status === 200) {
              navigate("/applications");
            }
          })
        );
      }
    }
  };

  const renderButtonGroup = () => (
    <div className="flex gap-3">
      {state.submittedApplication ? (
        <>
          <Buttons.DefaultButton
            size="medium"
            disabled={!state?.universityId}
            onClick={() => {
              handleApplicationChange("submittedApplication", true);
              handleApplicationChange("pendingRequirements", []);
            }}
          >
            Yes
          </Buttons.DefaultButton>
          <Buttons.WhiteButton
            size="medium"
            disabled={!state?.universityId}
            onClick={() => {
              handleApplicationChange("submittedApplication", false);
              handleApplicationChange("proofOfApplication", "");
            }}
          >
            No
          </Buttons.WhiteButton>
        </>
      ) : (
        <>
          <Buttons.WhiteButton
            size="medium"
            disabled={!state?.universityId}
            onClick={() => {
              handleApplicationChange("submittedApplication", true);
              handleApplicationChange("pendingRequirements", []);
            }}
          >
            Yes
          </Buttons.WhiteButton>
          <Buttons.DefaultButton
            size="medium"
            disabled={!state?.universityId}
            onClick={() => {
              handleApplicationChange("submittedApplication", false);
              handleApplicationChange("proofOfApplication", "");
            }}
          >
            No
          </Buttons.DefaultButton>
        </>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-3 text-black relative">
      {profileData?.freeUser && (
        <div className="absolute top-[150px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div className={`${profileData?.freeUser && "blur pointer-events-none"}`}>
        <div className="text-[18px] font-bold pl-3">Add Application</div>
        <Spacer height={10} />
        <boxContainer.LiteBoxContainer>
          <div className="text-[16px] font-semibold">
            If you have applied to any university, or are in the process of
            applying, please provide the application details below.
          </div>
          <Spacer height={20} />
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <application.AddApplicationBoxLabel
                title="University Name"
                required={true}
              />
              <inputFields.SelectDropdown
                disabled={profileData?.freeUser}
                items={allUniList}
                placeholder="Enter University Name"
                value={state?.universityId}
                onChange={(value) =>
                  handleApplicationChange("universityId", value)
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <application.AddApplicationBoxLabel
                title="Date of Application"
                required={true}
              />
              <datepicker.DefaultDatepicker
                datevalue={state?.dateOfApplication ?? null}
                disabled={profileData?.freeUser}
                onChange={(date, dateString) =>
                  handleApplicationChange("dateOfApplication", dateString)
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <application.AddApplicationBoxLabel
                title="Degree of Choice"
                required={true}
              />
              <inputFields.SelectDropdown
                disabled={profileData?.freeUser}
                items={programTypeList}
                loading={isProgramLoading}
                onChange={(value) =>
                  handleApplicationChange("preferredMajorId", value)
                }
                placeholder="Enter Degree of Choice"
                value={state.preferredMajorId}
              />
            </div>
            <div className="flex flex-col gap-2">
              <application.AddApplicationBoxLabel title="Have You Submitted the Application?" />
              <div className="flex gap-3">{renderButtonGroup()}</div>
            </div>

            {state?.submittedApplication ? (
              <div className="flex flex-col gap-2">
                <application.AddApplicationBoxLabel
                  title="Proof of Application"
                  required
                />
                <upload.DefaultFileUpload
                  disabled={profileData?.freeUser || !state?.universityId}
                  onChange={(file) =>
                    handleApplicationChange("proofOfApplication", file)
                  }
                  value={state.proofOfApplication}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <application.AddApplicationBoxLabel title="Pending Requirements" />
                <inputFields.SelectDropdownMultiple
                  disabled={profileData?.freeUser || !state?.universityId}
                  Multiple
                  loading={isLoading}
                  items={checklists}
                  placeholder="Enter Pending Requirements"
                  value={state.pendingRequirements}
                  custominput
                  onChange={(value) =>
                    handleApplicationChange("pendingRequirements", value)
                  }
                />
              </div>
            )}
          </div>
          <Spacer height={30} />
          <div className="flex w-full justify-end">
            <Buttons.DefaultButton
              disabled={isDisabled}
              onClick={handleSubmit}
              loading={loading}
            >
              {buttonText}
            </Buttons.DefaultButton>
          </div>
        </boxContainer.LiteBoxContainer>
      </div>
    </div>
  );
};

export default AddApplication;
