import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoSend } from "react-icons/io5";

import { OthersAction } from "../../store/actions";

import { Spacer, boxContainer, GetProPopup } from "../../components";
import { Images } from "../../config";

import "./styles.css";

const API_KEY = process.env.REACT_APP_CHATGPT_API_KEY;
const systemMessage = {
  role: "system",
  content:
    "Explain things like you're talking to a school student of grade 11.",
};

const Essay = () => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);

  const profileData = useSelector((state) => state?.profile?.user);

  const [typing, setIsTyping] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showPreset, setShowPreset] = useState(true);

  const preset = [
    {
      title: "Please give feedback",
      desc: "Does my essay line up with the mission statement of (specific university's business/art school name)",
      sender: "user",
    },
    {
      title: "Give me ideas",
      desc: "I'm applying to (university), how I can change my essay to increase my chances of acceptance?",
      sender: "user",
    },
    {
      title: "Please give feedback",
      desc: "From the perspective of a university admissions officer, how can I improve this essay?",
      sender: "user",
    },
    {
      title: "Give me ideas",
      desc: "Suggest how I can improve my writing to reflect (university name)'s values.",
      sender: "user",
    },
  ];

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, typing]);

  const handleSend = async (e) => {
    if (!profileData?.freeUser) {
      if (e) {
        if (!e.trim()) return;
      } else {
        if (!message.trim()) return;
      }

      dispatch(
        OthersAction.updateEssayGraderCount((res) => {
          dispatch(OthersAction.getTasks());
        })
      );

      const newMessage = {
        message: e
          ? e.replace(/<[^>]+>/g, "")
          : message.replace(/<[^>]+>/g, ""),
        sender: "user",
      };

      const newMessages = [...messages, newMessage];

      setMessages(newMessages);

      setIsTyping(true);
      setMessage("");

      await processMessageToChatGPT(newMessages);
    }
  };

  const processMessageToChatGPT = async (chatMessages) => {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return {
        role: role,
        content: messageObject.message,
      };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.error
              ? data.error.message
              : data.choices[0].message.content,
            sender: "ChatGPT",
            direction: "incoming",
          },
        ]);
        setIsTyping(false);
      });
  };

  const handlePreset = (e) => {
    setShowPreset(false);

    handleSend(e);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="text-black">
      <div className="header-md">Automated Essay Grading</div>
      <Spacer height={20} />
      <boxContainer.LiteBoxContainer>
        <div className={`essay-content-container relative`}>
          {profileData?.freeUser && (
            <div className="absolute top-[100px] left-[35%] z-[10]">
              <GetProPopup />
            </div>
          )}
          <div
            className={`${
              profileData?.freeUser && "blur pointer-events-none"
            } h-full flex flex-col justify-between`}
          >
            <div className="header-sm text-center w-[65%] mx-auto">
              Use The Power Of Our Model Trained Essay Grader To Get Have Your
              University Essays Graded By Artificial Intelligence. According To
              The University You Are Applying To.
            </div>
            <div className="w-[80%] mx-auto relative">
              {showPreset && (
                <div className="absolute grid grid-cols-2 gap-4 bottom-[80px]">
                  {preset?.map((item, index) => (
                    <div
                      key={index}
                      className="p-3 rounded-2xl border border-main bg-[#fcfffe] cursor-pointer"
                      onClick={() =>
                        handlePreset(`${item?.title}\n${item?.desc}`)
                      }
                    >
                      <div className="font-semibold text-[18px]">
                        {item?.title}
                      </div>
                      <div>{item?.desc}</div>
                    </div>
                  ))}
                </div>
              )}
              <div className="absolute w-full flex justify-center mt-[80px]">
                <img
                  src={Images.LogoIconOnly}
                  className="h-[100px] opacity-15"
                />
              </div>
              <div className="chat-container" ref={chatContainerRef}>
                {messages?.map((item, index) => (
                  <span
                    key={index}
                    className={
                      item?.sender === "user"
                        ? "outgoing-chat"
                        : "incoming-chat"
                    }
                  >
                    {item?.message.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                ))}
                {typing && <span className="incoming-chat">Analyzing...</span>}
              </div>
              <div className="message-input-container">
                <input
                  className="message-input"
                  placeholder="Enter your essay"
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyPress}
                  value={message}
                  disabled={profileData?.freeUser}
                />
                <div className="message-send-button" onClick={handleSend}>
                  <IoSend />
                </div>
              </div>
            </div>
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
};

export default Essay;
