import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";

import { IoBookmark } from "react-icons/io5";

import { WatchlistAction } from "../../store/actions";

import { Images, Colors } from "../../config";

import "./styles.css";

const ExploreCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = props.data;
  const profileData = useSelector((state) => state?.profile?.user);

  const handleShortlist = () => {
    const payload = { id: data?.id };
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(payload, (res) => {
        dispatch(WatchlistAction.getWatchlist({}));
      })
    );
  };

  const popoverContent = (
    <div className="w-[230px] h-[150px] rounded-lg">
      <div
        className="w-full h-[100px] rounded-t-lg relative"
        style={{
          background: `url(${data?.campusPic ?? Images.DefaultUniCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-40 rounded-t-lg"></div>
        <div
          className={`absolute z-10 rounded-full h-[28px] w-[28px] bg-white flex items-center justify-center top-2 right-2 cursor-pointer ${
            profileData?.freeUser && "pointer-events-none"
          }`}
          onClick={handleShortlist}
        >
          <IoBookmark
            fontSize={22}
            color={data?.watchlisted ? Colors?.main : Colors.LightGrayText}
          />
        </div>
        <div className="relative p-2 flex flex-col gap-1  text-white h-full justify-end">
          <img
            src={data?.profilePic}
            className="h-[36px] w-[36px] border border-searchBorder shadow-boxContainer rounded-full object-contain bg-white cursor-pointer"
            onClick={(event) => {
              if (event.ctrlKey) {
                window.open(`/universities/general/${data.id}`, "_blank");
              } else {
                navigate(`/universities/general/${data.id}`);
              }
            }}
          />

          <div
            className="cursor-pointer font-semibold text-[14px] line-clamp-2 overflow-hidden text-ellipsis"
            onClick={(event) => {
              if (event.ctrlKey) {
                window.open(`/universities/general/${data.id}`, "_blank");
              } else {
                navigate(`/universities/general/${data.id}`);
              }
            }}
          >
            {data?.name}
          </div>
        </div>
      </div>
      <div className="flex flex-col p-2 py-1 gap-1">
        <div className="grid-popover-value-container">
          <div>Acceptance Rate:</div>
          <div className="explore-popover-value">
            {(data?.acceptanceRate * 100).toFixed(1)}%
          </div>
        </div>
        <div className="grid-popover-value-container">
          <div>Application Status:</div>
          {data?.deadlines?.length > 0 ? (
            data?.deadlines[0]?.appDeadline ? (
              new Date() > new Date(data?.deadlines[0]?.appDeadline) ? (
                <span className="bg-red-500 text-white font-medium px-1 pt-0.5 pb-0.25 rounded-md ml-1 text-[10px]">
                  CLOSED
                </span>
              ) : (
                <span className="bg-green-500 text-white font-medium px-1 pt-0.5 pb-0.25 rounded-lg ml-1 text-[10px]">
                  OPEN
                </span>
              )
            ) : (
              "NA"
            )
          ) : (
            "NA"
          )}
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      content={popoverContent}
      trigger={"hover"}
      arrow={false}
      overlayInnerStyle={{
        position: "absolute",
        padding: "0px",
        top: "-154px",
        right: "-115px",
      }}
      onOpenChange={props.setIsPopoverOpen}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <div>
        <div
          className="w-[230px] h-[150px] rounded-lg relative"
          style={{
            background: `url(${data?.campusPic ?? Images.DefaultUniCover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40 rounded-lg"></div>
          <div className="relative p-3 flex flex-col justify-end h-full gap-2 overflow-y-auto text-white">
            <img
              src={data?.profilePic}
              className="h-[42px] w-[42px] border border-searchBorder shadow-boxContainer rounded-full object-contain bg-white cursor-pointer"
              onClick={(event) => {
                if (event.ctrlKey) {
                  window.open(`/universities/general/${data.id}`, "_blank");
                } else {
                  navigate(`/universities/general/${data.id}`);
                }
              }}
            />

            <div className="font-bold text-[14px]">{data?.name}</div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default ExploreCard;
