import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { boxContainer } from "../../components";

import { FaAnglesRight } from "react-icons/fa6";

import ContactUs from "./contact-us";
import FAQ from "./faq";

function Support() {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("contact-us");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");

    if (tabParam) {
      setActiveTab(tabParam);
    } else {
      setActiveTab("Contact Us");
    }
  }, [location.search]);

  const sideItems = [
    {
      title: "Contact Us",
      tabName: "contact-us",
      route: "/support?tab=contact-us",
    },
    {
      title: "FAQs",
      tabName: "faq",
      route: "/support?tab=faq",
    },
    {
      title: "Chat with Support",
      tabName: "chat",
      route: "/chatbot?tab=support",
    },
  ];

  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <div className="flex gap-3 ">
          <div className="flex flex-col gap-4 w-[40%] pr-5 border-r-[1px] border-r-liteBoxBorder">
            {sideItems?.map((item, index) => (
              <Link to={item?.route}>
                <div
                  key={index}
                  className={`flex items-center justify-between !border !border-main rounded-[23px] cursor-pointer p-4 text-[19px] font-semibold ${
                    activeTab === item?.tabName
                      ? "bg-main text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => setActiveTab(item?.tabName)}
                >
                  {item?.title}
                  <FaAnglesRight fontSize={20} />
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full">
            {activeTab === "contact-us" ? (
              <ContactUs />
            ) : activeTab === "faq" ? (
              <FAQ />
            ) : (
              <ContactUs />
            )}
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default Support;
