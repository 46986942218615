import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const OverallGPA = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    schoolGpa: "",
    gpa: "",
    weightedGpa: "",
  });

  useEffect(() => {
    setState({
      schoolGpa: questionnaire?.schoolGpa ?? "",
      gpa: questionnaire?.gpa ?? "",
      weightedGpa: questionnaire?.weightedGpa ?? "",
    });
  }, [questionnaire]);

  const handleNext = () => {
    const payload = { userId: profileData?.id, ...state };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          setPage("Education");
        }
      })
    );
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6 max-h-[550px] overflow-y-auto">
            <div>
              <questionnaireComponents.QuestionnaireLabel text="School GPA" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, schoolGpa: e.target.value })
                }
                placeholder="3.5"
                value={state?.schoolGpa}
                type="number"
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="GPA (4.0)" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) => setState({ ...state, gpa: e.target.value })}
                placeholder="3.6"
                value={state?.gpa}
                type="number"
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="Weighted GPA (5.0)" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, weightedGpa: e.target.value })
                }
                placeholder="4.6"
                value={state?.weightedGpa}
                type="number"
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={handleNext} size="medium">
              Next
            </Buttons.DefaultButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverallGPA;
