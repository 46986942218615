import React from "react";
import { Collapse, ConfigProvider } from "antd";
import { Colors } from "../../config";

import QuestionnaireFieldContainer from "./questionnaire-field-container";

import { FaChevronDown } from "react-icons/fa";

const QuestionnaireCollapse = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: "rgba(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Collapse
        className={`questionnaire-collapse ${
          props?.isOpen
            ? "questionnaire-collapse-open"
            : "questionnaire-collapse"
        }`}
        ghost
        expandIconPosition="end"
        expandIcon={() => (
          <FaChevronDown
            color={props?.isOpen ? "white" : Colors.main}
            style={{
              transform: props?.isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        )}
        items={[
          {
            key: "1",
            label: (
              <div
                className={`${
                  props?.isOpen ? "text-white" : "text-main"
                } text-[16px] font-semibold`}
              >
                {props?.title}
              </div>
            ),
            children: (
              <QuestionnaireFieldContainer>
                {props?.children}
              </QuestionnaireFieldContainer>
            ),
          },
        ]}
        {...props}
      />
    </ConfigProvider>
  );
};

export default QuestionnaireCollapse;
