import React from "react";
import { Spin, ConfigProvider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ButtonLoader = (props) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: props?.color ?? "white",
        contentHeight: 16,
      },
    }}
  >
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: props?.size ?? 16,
            color: "white",
          }}
          spin
        />
      }
    />
  </ConfigProvider>
);
export default ButtonLoader;
