import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";

import { CiCircleChevLeft } from "react-icons/ci";

import { AuthAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import {
  Buttons,
  Spacer,
  inputFields,
  checkbox,
  loginAndSignup,
} from "../../components";

import { Images, Utils } from "../../config";

const Login = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleLogin = () => {
    if (!state.email) {
      message.error("Email can not be left empty.");
    } else if (Utils.emailRegex.test(state.email) === false) {
      message.error("Please enter a valid email address.");
    } else if (!state.password) {
      message.error("Password can not be left empty.");
    } else {
      const body = {
        email: state.email,
        password: state.password,
        rememberMe: state.rememberMe,
      };
      dispatch(AuthAction.SignIn(body));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <LoginLayout img={true}>
      <div className="flex flex-col items-center justify-center py-[60px] bg-login-gradient gap-[40px] w-[700px] h-full">
        <img src={Images.UnivisionLogoWithText} className="h-[60px]" />

        <div className="flex flex-col gap-[20px] justify-center items-center">
          <div className="text-main font-bold text-[56px]">Welcome</div>
          <div className="text-[27px] font-semibold">Login to Continue</div>
        </div>

        <div className="flex flex-col gap-2 w-[450px]">
          <loginAndSignup.LoginInputTitle title="Email Address" />
          <inputFields.DefaultInput
            onChange={(e) => setState({ ...state, email: e.target.value })}
            value={state?.email}
            onKeyDown={handleKeyDown}
            placeholder="xyz@abc.com"
          />
          <Spacer height={5} />
          <loginAndSignup.LoginInputTitle title="Password" />
          <inputFields.DefaultInput
            type="password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            value={state?.password}
            onKeyDown={handleKeyDown}
            placeholder="Enter your password"
          />
          <div className="flex justify-between items-center font-medium">
            <checkbox.DefaultCheckbox
              text="Remember Me"
              onChange={(e) => {
                setState({ ...state, rememberMe: e.target.checked });
              }}
              checked={state.rememberMe}
            />
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <Spacer height={30} />
          <Buttons.DefaultButton onClick={handleLogin} width="full">
            Login
          </Buttons.DefaultButton>
          <Spacer height={30} />
          <div className="flex justify-center items-center font-medium gap-1">
            <div>Don't have an account? </div>
            <Link to="/signup/general-info">
              <div className="text-main font-bold">Sign Up</div>
            </Link>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Login;
