import React from "react";
import { Modal, ConfigProvider } from "antd";

import "./styles.css";

const DefaultModal = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: "white",
          color: "black",
        },
      }}
    >
      <Modal
        title={null}
        centered={true}
        footer={null}
        closeIcon={props?.closeIcon ?? true}
        className="default-modal"
        {...props}
      >
        {props?.children}
      </Modal>
    </ConfigProvider>
  );
};

export default DefaultModal;
