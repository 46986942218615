import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { InteractiveLearningAction } from "../actions";

export default class InteractiveLearningMiddleware {
  static *getUdemyCourses({ payload, cb }) {
    try {
      const res = payload.search
        ? yield ApiCaller.Get(
            `/courses/getUdemyCourses?pageNo=${payload.pageNo}&filter=${payload.filter}&search=${payload.search}`
          )
        : yield ApiCaller.Get(
            `/courses/getUdemyCourses?pageNo=${payload.pageNo}&filter=${payload.filter}`
          );

      if (res.status === 200) {
        yield put(
          InteractiveLearningAction.getUdemyCoursesSuccess(res.data.data.data)
        );
      } else {
        yield put(InteractiveLearningAction.getUdemyCoursesFailure());
      }
      if (cb) {
        cb(res.data.data.data);
      }
    } catch (err) {
      yield put(InteractiveLearningAction.getUdemyCoursesFailure());
    }
  }
}
