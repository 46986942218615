import React from "react";
import { Select } from "antd";
import { FaAngleDown } from "react-icons/fa6";

const QuestionnaireMultipleDropdown = (props) => {
  return (
    <Select
      className="questionnaire-dropdown-multiple w-full"
      mode="multiple"
      placeholder={props?.placeholder ?? "Please Select"}
      suffixIcon={<FaAngleDown />}
      value={props?.value ?? null}
      {...props}
    >
      {props?.array?.map((item, index) => (
        <Select.Option value={item} key={index}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default QuestionnaireMultipleDropdown;
