import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";

import { CiCircleChevLeft } from "react-icons/ci";

import { AuthAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import { Buttons, Spacer, inputFields, loginAndSignup } from "../../components";

import { Images } from "../../config";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleForgot = () => {
    if (!email) {
      message.error("Email can not be left empty.");
    } else {
      const body = {
        email,
      };
      dispatch(
        AuthAction.ForgotPassword(body, (res) => {
          if (res.status === 200) {
            navigate("/verify-otp", { state: { email } });
          }
        })
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleForgot();
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col items-center justify-center  px-[100px] py-[60px] bg-login-gradient gap-4">
        <div className="relative">
          <CiCircleChevLeft
            className="text-[40px] absolute -left-[130px] top-3 cursor-pointer"
            onClick={() => {
              window.history.back();
            }}
          />
          <img src={Images.UnivisionLogoWithText} className="h-[60px]" />
        </div>
        <Spacer height={5} />
        <div className="flex flex-col gap-2 justify-center items-center">
          <div className="text-main font-bold text-[40px]">Forgot Password</div>
          <div className="text-[22px] font-semibold">
            Enter email to send OTP
          </div>
        </div>

        <div className="flex flex-col gap-2 w-[450px]">
          <loginAndSignup.LoginInputTitle title="Email Address" />
          <inputFields.DefaultInput
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            onKeyDown={handleKeyDown}
            placeholder="xyz@abc.com"
          />

          <Spacer height={10} />
          <Buttons.DefaultButton onClick={handleForgot} width="full">
            Continue
          </Buttons.DefaultButton>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
