import { put } from "redux-saga/effects";

import { InternshipsAction } from "../actions";
import { ApiCaller } from "../../config";

export default class InternshipsMiddleware {
  static *getInternships({ payload, cb }) {
    try {
      const { search, date, location, jobType, company } = payload;

      const res = yield ApiCaller.Get(
        `/internships/get-internships?search=${search ?? ""}&date=${
          date ?? ""
        }&location=${location ?? ""}&jobType=${jobType ?? ""}&company=${
          company ?? ""
        }`
      );

      if (res.status === 200) {
        yield put(InternshipsAction.getInternshipsSuccess(res.data.data));
      } else {
        yield put(InternshipsAction.getInternshipsSuccess([]));
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(InternshipsAction.getInternshipsFailure());
    }
  }
}
