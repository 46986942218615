import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  CHANGE_NOTIFICATIONS_STATUS,
  CHANGE_NOTIFICATIONS_STATUS_FAILURE,
  CHANGE_NOTIFICATIONS_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  unreadCount: 0,
  notifications: [],
  isLoading: false,
  error: null,
};

export default function NotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
    case CHANGE_NOTIFICATIONS_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case CHANGE_NOTIFICATIONS_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        unreadCount: action.payload.unreadCount,
        notifications: action.payload.notifications,
        isLoading: false,
      };
      break;
    case GET_NOTIFICATIONS_FAILURE:
    case CHANGE_NOTIFICATIONS_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
