import {
  ADD_QUESTIONNAIRE,
  ADD_QUESTIONNAIRE_SUCCESS,
  ADD_QUESTIONNAIRE_FAILURE,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILURE,
  UPLOAD_IMAGE_QUESTIONNAIRE,
  UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS,
  UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE,
  UPDATE_QUESTIONNAIRE_PREF,
  UPDATE_QUESTIONNAIRE_PREF_SUCCESS,
  UPDATE_QUESTIONNAIRE_PREF_FAILURE,
} from "../constants";

const initialState = {
  questionnaire: {},
  questionnaireImage: "",
  isLoading: false,
  error: null,
};

export default function QuestionnaireReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_QUESTIONNAIRE:
    case GET_QUESTIONNAIRE:
    case UPDATE_QUESTIONNAIRE_PREF:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPLOAD_IMAGE_QUESTIONNAIRE:
      state = {
        ...state,
        error: null,
      };
      break;

    case ADD_QUESTIONNAIRE_SUCCESS:
    case UPDATE_QUESTIONNAIRE_PREF_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_QUESTIONNAIRE_SUCCESS:
      state = {
        ...state,
        questionnaire: action.payload,
        isLoading: false,
      };
      break;
    case UPLOAD_IMAGE_QUESTIONNAIRE_SUCCESS:
      state = {
        ...state,
        questionnaireImage: action.payload,
      };
      break;

    case ADD_QUESTIONNAIRE_FAILURE:
    case GET_QUESTIONNAIRE_FAILURE:
    case GET_QUESTIONNAIRE_FAILURE:
    case UPDATE_QUESTIONNAIRE_PREF_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case UPLOAD_IMAGE_QUESTIONNAIRE_FAILURE:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
