import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import chatbot from "../chatbot";

import { ProfileBuilderAfterSignin, Payment } from "../../containers";

import {
  ChatbotAction,
  ProfileAction,
  NotificationsAction,
  UniversityAction,
  MeetingAction,
  CounselorAction,
  OthersAction,
  QuestionnaireAction,
} from "../../store/actions";

import StudentNavHeader from "./student-nav-header";
import StudentSidebar from "./student-sidebar";

import "./dashboard-layout.css";

const StudentDashboardLayout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const path = location.pathname;

  const [showProfileBuilder, setShowProfileBuilder] = useState(false);
  const [allCompleted, setAllCompleted] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);

  const auth = useSelector((state) => state.auth);
  const profileData = useSelector((state) => state.profile?.user);
  const studentType = useSelector(
    (state) => state.profile?.user?.student?.studentType
  );
  const showQuestionnaire = useSelector(
    (state) => state.profile?.user?.student?.showQuestionnaire
  );
  const subscriptionPlan = useSelector(
    (state) => state.profile?.user?.individualStudent?.subscriptionPlan
  );
  const meetings = useSelector((state) => state.meetings);
  const allUniversities = useSelector(
    (state) => state.university.allUniversities
  );
  const { questionnaire } = useSelector((state) => state?.questionnaire);

  const updateVisibility = () => {
    const getShowProfileBuilder = localStorage.getItem("showProfileBuilder");
    const getShowPaymentPage = localStorage.getItem("showPaymentPage");

    setShowProfileBuilder(getShowProfileBuilder === "true");
    setShowPaymentPage(getShowPaymentPage === "true");
  };

  useEffect(() => {
    if (questionnaire?.completion) {
      const allComplete = Object.values(questionnaire?.completion).every(
        (value) => value === 100
      );
      setAllCompleted(allComplete);
    } else {
      setAllCompleted(false);
    }
  }, [profileData, questionnaire]);

  useEffect(() => {
    updateVisibility();

    const intervalId = setInterval(() => {
      updateVisibility();
    }, 500);
    return () => clearInterval(intervalId);
  }, [showProfileBuilder, showPaymentPage]);

  useEffect(() => {
    if (
      profileData?.role === "student" &&
      profileData?.student?.studentType === "individualStudent" &&
      path.includes("meetings/")
    ) {
      navigate("/home");
    }
    if (
      profileData?.freeUser &&
      (path.includes("universities/compare") ||
        path.includes("recommender/preferences") ||
        path.includes("recommender/compare") ||
        path.includes("recommender/results"))
    ) {
      navigate("/home");
    }
  }, [profileData, path]);

  useEffect(() => {
    if (auth?.user?.user?.id) {
      dispatch(ProfileAction.getUserProfile());
    }
  }, [auth]);

  useEffect(() => {
    dispatch(OthersAction.getCountries());
    if (!profileData?.freeUser) {
      dispatch(OthersAction.getVisaInfo());
      dispatch(OthersAction.getProgramTypes());
    }
  }, [profileData]);

  useEffect(() => {
    if (allUniversities?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    if (profileData?.id) {
      dispatch(QuestionnaireAction.getQuestionnaire());
    }
    dispatch(
      ChatbotAction.getUsers({
        schoolId: profileData?.student?.schoolId,
      })
    );
    if (
      profileData?.role === "student" &&
      profileData?.student?.studentType === "schoolStudent"
    ) {
      dispatch(
        CounselorAction.getCounselors({
          id: profileData?.student?.schoolId,
        })
      );
    }
  }, [profileData]);

  useEffect(() => {
    if (
      profileData?.role === "student" &&
      profileData?.student?.studentType !== "individualStudent"
    ) {
      if (!showProfileBuilder || !showPaymentPage) {
        const meetingPayload = {
          requestedBy: "student",
          studentId: profileData?.studentId,
        };
        dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
      }
    }
  }, [meetings, profileData]);

  useEffect(() => {
    if (!showProfileBuilder || !showPaymentPage) {
      dispatch(
        NotificationsAction.getNotifications({
          userId: profileData?.id,
        })
      );
    }
  }, [path, profileData]);

  if (!allCompleted && showQuestionnaire && showProfileBuilder) {
    return <ProfileBuilderAfterSignin />;
  } else if (
    studentType === "individualStudent" &&
    subscriptionPlan === "free" &&
    showPaymentPage
  ) {
    return <Payment />;
  } else {
    return (
      <div className="dashboard-layout">
        <StudentSidebar />
        <div className="content-container">
          <StudentNavHeader />
          <div className="content">
            <Outlet />
            {children}
          </div>
          {!path.includes("/chatbot") && (
            <div className="absolute bottom-[20px] right-3">
              <chatbot.ChatbotPopup />
            </div>
          )}
        </div>
      </div>
    );
  }
};
export default StudentDashboardLayout;
