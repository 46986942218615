import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const Counselor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [counselors, setCounselors] = useState([
    {
      name: "",
      email: "",
      phone: "",
    },
  ]);

  useEffect(() => {
    getQuestionnaire();
  }, []);

  useEffect(() => {
    setCounselors(
      questionnaire?.counselors?.length > 0
        ? questionnaire?.counselors
        : [
            {
              name: "",
              email: "",
              phone: "",
            },
          ]
    );
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const addCounselor = () => {
    const lastCounselor = counselors[counselors.length - 1];

    if (!lastCounselor.name) {
      message.error(
        "Please fill in all required fields before adding a new counselor?."
      );
    } else {
      setCounselors([
        ...counselors,
        {
          name: "",
          email: "",
          phone: "",
        },
      ]);
    }
  };

  const handleCounselorChange = (index, field, value) => {
    const updatedCounselors = [...counselors];
    updatedCounselors[index][field] = value;
    setCounselors(updatedCounselors);
  };

  const handleNext = () => {
    const filteredCounselors = counselors.filter((counselor) => {
      if (!counselor?.name) {
        return false;
      }
      return true;
    });

    if (filteredCounselors.length === 0) {
      message.error("Please fill in all the required fields.");
      return;
    }

    const payload = { userId: profileData?.id, counselors: filteredCounselors };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          navigate("/profile-builder/activities");
        }
        getQuestionnaire();
      })
    );
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">Counselor</div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          {counselors?.map((counselor, index) => (
            <div className={`${index > 0 ? "mt-6" : ""} `}>
              <div className="section-header">Counselor {index + 1}</div>
              <div className="grid grid-cols-2 gap-x-7 gap-y-6" key={index}>
                <div>
                  <questionnaireComponents.QuestionnaireLabel
                    text="Name"
                    required
                  />
                  <Spacer height="5px" />
                  <inputFields.DefaultInput
                    onChange={(e) =>
                      handleCounselorChange(index, "name", e.target.value)
                    }
                    placeholder="Enter name"
                    value={counselor?.name}
                  />
                </div>
                <div>
                  <questionnaireComponents.QuestionnaireLabel text="Email" />
                  <Spacer height="5px" />
                  <inputFields.DefaultInput
                    onChange={(e) =>
                      handleCounselorChange(index, "email", e.target.value)
                    }
                    placeholder="Enter email"
                    value={counselor?.email}
                  />
                </div>
                <div>
                  <questionnaireComponents.QuestionnaireLabel text="Phone Number" />
                  <Spacer height="5px" />
                  <inputFields.DefaultPhoneInput
                    onChange={(e) => handleCounselorChange(index, "phone", e)}
                    placeholder="Enter your counselor phone number"
                    value={counselor?.phone}
                  />
                </div>
              </div>
            </div>
          ))}

          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={addCounselor} size="medium">
              + Add More
            </Buttons.DefaultButton>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default Counselor;
