import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { CiCircleChevLeft } from "react-icons/ci";
import { FaEnvelope } from "react-icons/fa";

import { Images, UtilityArrays } from "../../config";

import { ProfileDropDown } from "./profile-dropdown";
import { NotificationDropDown } from "./notification-dropdown";

const NavHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  const notifications = useSelector((state) => state.notifications);
  const profileData = useSelector((state) => state.profile?.user);
  const studentType = useSelector(
    (state) => state.profile?.user?.student?.studentType
  );

  const subscriptionPlan = useSelector(
    (state) => state.profile?.user?.individualStudent?.subscriptionPlan
  );
  const unreadMessages = useSelector((state) => state.chatbot?.unreadMessages);

  const [backIcon, setBackIcon] = useState(false);
  const [navbarTitle, setNavbarTitle] = useState(false);

  useEffect(() => {
    UtilityArrays?.NavbarRoutes.map((item) => {
      if (path.includes(item.route)) {
        setNavbarTitle(item.navbarTitle);
        setBackIcon(item.backIcon);
      }
    });
  }, [path]);

  return (
    <div className="navbar">
      <div className="flex flex-row justify-between items-center w-full flex-1">
        <div className="navbar-left-part">
          {backIcon && (
            <CiCircleChevLeft
              className="navbar-back-icon"
              onClick={() => {
                window.history.back();
              }}
            />
          )}
          <div className="navbar-title">{navbarTitle}</div>
        </div>
        <div className="navbar-right-part">
          {studentType === "individualStudent" &&
            subscriptionPlan === "free" && (
              <div
                className="flex gap-2 bg-getProOrange rounded-lg p-2 items-center cursor-pointer"
                onClick={() => localStorage.setItem("showPaymentPage", true)}
              >
                <div className="font-semibold text-[14px]">
                  Get UniVIsion Pro
                </div>
                <img src={Images.GetPro} className="h-[14px]" />
              </div>
            )}

          <div className="navbar-icon-container">
            <div className="relative">
              <FaEnvelope
                className="notification-bell"
                onClick={() => navigate("/chatbot")}
              />
              {unreadMessages?.length > 0 && (
                <div className="unread-count">{unreadMessages?.length}</div>
              )}
            </div>
          </div>

          <NotificationDropDown notifications={notifications} />

          <ProfileDropDown profileData={profileData} />

          <div className="navbar-name-container">
            <div className="navbar-name">
              {profileData?.firstName} {profileData?.lastName}
            </div>
            <div className="navbar-email">{profileData?.email}</div>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-200 w-full"></div>
    </div>
  );
};
export default NavHeader;
