import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import ReactCodeInput from "react-code-input";

import { CiCircleChevLeft } from "react-icons/ci";

import { AuthAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import { Buttons, Spacer } from "../../components";

import { Images, Colors } from "../../config";

const VerifyOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const prevData = location.state;

  const [otpCode, setOtpCode] = useState("");

  useEffect(() => {
    if (!prevData?.email) {
      navigate("/forgot-password");
    }
  }, [prevData]);

  const handleVerifyCode = () => {
    if (otpCode.length === 0) {
      message.error("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      message.error("Incomplete Code");
    } else {
      const body = {
        OTPCode: otpCode,
        email: prevData.email,
      };

      dispatch(
        AuthAction.VerifyOtp(body, (res) => {
          if (res.status === 200) {
            navigate("/reset-password", { state: { email: prevData.email } });
          }
        })
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleVerifyCode();
    }
  };

  return (
    <LoginLayout>
      <div className="flex flex-col items-center justify-center px-[120px] py-[40px] bg-login-gradient gap-3">
        <div className="relative">
          <CiCircleChevLeft
            className="text-[40px] absolute -left-[150px] top-3 cursor-pointer"
            onClick={() => {
              window.history.back();
            }}
          />
          <img src={Images.UnivisionLogoWithText} className="h-[60px]" />
        </div>
        <div className="flex flex-col gap-2 items-center w-full text-center">
          <div className="text-main font-bold text-[40px]">OTP</div>
          <div className="text-[22px] font-semibold">Please enter OTP</div>
        </div>
        <ReactCodeInput
          type="number"
          fields={4}
          onChange={(e) => setOtpCode(e)}
          value={otpCode}
          inputStyle={{
            border: `1px solid #707070`,
            borderRadius: "10px",
            fontSize: "38px",
            color: Colors.main,
            fontWeight: "700",
            height: "70px",
            width: "50px",
            margin: "0 20px",
            textAlign: "center",
          }}
          onKeyDown={handleKeyDown}
        />
        <Spacer height={10} />

        <Buttons.DefaultButton onClick={handleVerifyCode} width="full">
          Continue
        </Buttons.DefaultButton>
        {/* <Spacer height={10} />
        <div className="flex justify-center items-center font-medium gap-1">
          <div>Haven't received OTP? </div>
          <Link to="/signup/general-info">
            <div className="text-main font-semibold">Resend</div>
          </Link>
        </div> */}
      </div>
    </LoginLayout>
  );
};

export default VerifyOTP;
