import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IoIosInformationCircleOutline } from "react-icons/io";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
  popover,
} from "../../components";

import "./styles.css";

const OverallGPA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    schoolGpa: "",
    gpa: "",
    weightedGpa: "",
  });

  useEffect(() => {
    getQuestionnaire();
  }, []);

  useEffect(() => {
    setState({
      schoolGpa: questionnaire?.schoolGpa ?? "",
      gpa: questionnaire?.gpa ?? "",
      weightedGpa: questionnaire?.weightedGpa ?? "",
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    const payload = { userId: profileData?.id, ...state };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          navigate("/profile-builder/education");
        }
        getQuestionnaire();
      })
    );
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">
        Overall GPA{" "}
        <span>
          <popover.WhitePopover
            content={
              <Link
                to="https://www.calculator.net/gpa-calculator.html"
                className="!text-blue-500 font-semibold text-[24px] !underline"
              >
                Calculator Guide
              </Link>
            }
            placement="right"
          >
            <IoIosInformationCircleOutline className="text-main" />
          </popover.WhitePopover>
        </span>
      </div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6">
            <div>
              <questionnaireComponents.QuestionnaireLabel text="School GPA" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, schoolGpa: e.target.value })
                }
                placeholder="3.5"
                value={state?.schoolGpa}
                type="number"
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="GPA (4.0)" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) => setState({ ...state, gpa: e.target.value })}
                placeholder="3.6"
                value={state?.gpa}
                type="number"
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="Weighted GPA (5.0)" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, weightedGpa: e.target.value })
                }
                placeholder="4.6"
                value={state?.weightedGpa}
                type="number"
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default OverallGPA;
