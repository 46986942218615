import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { boxContainer } from "../../components";
import { Images } from "../../config";

import { FaAnglesRight } from "react-icons/fa6";

import AccountSettings from "./account-settings";
import ChangePassword from "./change-password";
import PrivacyPolicy from "../privacy-policy";
import TermsAndCondition from "../terms-and-conditions";
import ManagePayment from "./manage-payment";

function Settings() {
  const location = useLocation();

  const profileData = useSelector((state) => state.profile?.user);

  const [activeTab, setActiveTab] = useState("account-settings");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");

    if (tabParam) {
      setActiveTab(tabParam);
    } else {
      setActiveTab("account-settings");
    }
  }, [location.search]);

  const settingItems = [
    {
      title: "Account Settings",
      tab: "account-settings",
      subTitle: "Change Personal Info",
      route: "/settings?tab=account-settings",
      icon: Images.AccountSettings,
    },
    {
      title: "Security Settings",
      tab: "security-settings",
      subTitle: "Change Password",
      route: "/settings?tab=security-settings",
      icon: Images.SecuritySettings,
    },
    {
      title: "Privacy Policy",
      tab: "privacy-policy",
      route: "/settings?tab=privacy-policy",
      icon: Images.SettingsPreferences,
      subTitle: " ",
    },
    {
      title: "Terms & Conditions",
      tab: "termsnconditions",
      route: "/settings?tab=termsnconditions",
      icon: Images.SettingsPreferences,
      subTitle: " ",
    },
  ];
  if (profileData?.student?.studentType === "individualStudent") {
    settingItems.push({
      title: "Payment Plan",
      tab: "payment-plan",
      route: "/settings?tab=payment-plan",
      icon: Images.SettingsManageSub,
      subTitle: "Manage your payment plan",
    });
  }

  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <div className="flex gap-3">
          <div className="flex flex-col gap-4 w-[40%] pr-5 border-r-[1px] border-r-liteBoxBorder">
            {settingItems?.map((item, index) => (
              <Link to={item?.route}>
                <div
                  key={index}
                  className="flex items-center !border !border-main rounded-[23px] cursor-pointer"
                  to={item?.route}
                  onClick={() => setActiveTab(item?.title)}
                >
                  <div className="w-[20%] h-full flex justify-center items-center">
                    <img src={item?.icon} className="h-[36px]" />
                  </div>
                  <div
                    className={`p-4 w-[80%]  flex justify-between items-center !border-l !border-l-main ${
                      activeTab === item?.tab
                        ? "bg-main text-white rounded-r-[20px]"
                        : "bg-white text-black rounded-r-[24px]"
                    }`}
                  >
                    <div>
                      <div className="text-[19px] font-semibold">
                        {item?.title}
                      </div>
                      <div className="text-[14px] opacity-50">
                        {item?.subTitle}
                      </div>
                    </div>
                    <FaAnglesRight fontSize={20} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full">
            {activeTab === "account-settings" ? (
              <AccountSettings />
            ) : activeTab === "security-settings" ? (
              <ChangePassword />
            ) : activeTab === "privacy-policy" ? (
              <PrivacyPolicy />
            ) : activeTab === "termsnconditions" ? (
              <TermsAndCondition />
            ) : activeTab === "payment-plan" ? (
              <ManagePayment />
            ) : (
              <AccountSettings />
            )}
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default Settings;
