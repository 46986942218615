import { put } from "redux-saga/effects";

import { QuestionnaireAction } from "../actions";
import { ApiCaller } from "../../config";

export default class QuestionnaireMiddleware {
  static *addQuestionnaire({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/questionnaire/addQuestionnaire`,
        payload
      );
      if (res.status === 200) {
        yield put(QuestionnaireAction.addQuestionnaireSuccess(res.data.data));
      } else {
        yield put(QuestionnaireAction.addQuestionnaireFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(QuestionnaireAction.addQuestionnaireFailure());
    }
  }
  static *getQuestionnaire({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/questionnaire/getQuestionnaire`);
      if (res.status === 200) {
        yield put(QuestionnaireAction.getQuestionnaireSuccess(res.data.data));
      } else {
        yield put(QuestionnaireAction.getQuestionnaireFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(QuestionnaireAction.getQuestionnaireFailure());
    }
  }
  static *uploadImageQuestionnaire({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/questionnaire/uploadQuestionnaireFile`,
        payload
      );
      if (res.status === 200) {
        yield put(
          QuestionnaireAction.uploadImageQuestionnaireSuccess(res.data.data)
        );
      } else {
        yield put(QuestionnaireAction.uploadImageQuestionnaireFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(QuestionnaireAction.uploadImageQuestionnaireFailure());
    }
  }
  static *updateQuestionnairePref({ cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/questionnaire/update-questionnaire-pref`
      );
      if (res.status === 200) {
        yield put(
          QuestionnaireAction.updateQuestionnairePrefSuccess(res.data.data)
        );
      } else {
        yield put(QuestionnaireAction.updateQuestionnairePrefFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(QuestionnaireAction.updateQuestionnairePrefFailure());
    }
  }
}
