const getTagColor = (value) => {
  if (value === 0 && value <= 20) {
    return "#dc3333";
  } else if (value > 20 && value <= 59) {
    return "#fbbd14";
  } else {
    return "#04bf04";
  }
};
export default getTagColor;
