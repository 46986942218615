import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dropdown, ConfigProvider } from "antd";

import { NotificationsAction } from "../../store/actions";

import { BiCalendar } from "react-icons/bi";
import { GoBellFill } from "react-icons/go";

export const NotificationDropDown = ({ notifications }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMarkAll = () => {
    const payload = {
      update: "all",
    };
    dispatch(
      NotificationsAction.changeNotificationsStatus(payload, () => {
        dispatch(NotificationsAction.getNotifications({}));
      })
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="notification-dropdown">
          <div className="notification-title">Notifications</div>
          {notifications?.notifications?.length > 0 && (
            <div
              className="text-[14px] font-medium text-right"
              onClick={handleMarkAll}
            >
              Mark all as read
            </div>
          )}
          {notifications?.notifications?.slice(0, 6).map((notification) => (
            <div
              key={notification.id}
              onClick={() => navigate("/notifications")}
            >
              <div className="notification-box">
                <div>
                  <BiCalendar className="notification-box-icon" />
                </div>
                <div className="notification-box-message">
                  <b>Reminder: </b>
                  {notification.text}
                </div>
              </div>
            </div>
          ))}
          <div
            onClick={() => navigate("/notifications")}
            className="see-all-notification"
          >
            See All Notifications
          </div>
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          controlItemBgHover: "white",
        },
      }}
    >
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottom"
        overlayStyle={{ top: "80px" }}
      >
        <div className="navbar-icon-container">
          <div style={{ position: "relative" }}>
            <GoBellFill className="notification-bell" />
            {notifications?.unreadCount > 0 && (
              <div className="unread-count">{notifications?.unreadCount}</div>
            )}
          </div>
        </div>
      </Dropdown>
    </ConfigProvider>
  );
};
