import DefaultInput from "./default-input";
import DefaultSearchBox from "./default-searchbox";
import DefaultPhoneInput from "./default-phone-input";
import DefaultTextbox from "./default-textbox";
import HorizontalRadio from "./horizontal-radio";
import SelectDropdown from "./select-dropdown";
import SelectDropdownSmall from "./select-dropdown-small";
import SelectDropdownMultiple from "./select-dropdown-multiple";
import VerticalRadio from "./vertical-radio";

export default {
  DefaultInput,
  DefaultPhoneInput,
  DefaultSearchBox,
  DefaultTextbox,
  HorizontalRadio,
  SelectDropdown,
  SelectDropdownSmall,
  SelectDropdownMultiple,
  VerticalRadio,
};
