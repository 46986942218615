import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { FaBuilding, FaRegClock } from "react-icons/fa";
import { FaLocationDot, FaIndustry } from "react-icons/fa6";
import { HiBriefcase } from "react-icons/hi2";

import {
  Buttons,
  Spacer,
  popover,
  boxContainer,
  inputFields,
  checkbox,
  GetProPopup,
  Loader,
} from "../../components";

import { UtilityArrays, Images } from "../../config";

import { InternshipsAction } from "../../store/actions";
import { Empty } from "antd";

const Internships = () => {
  const dispatch = useDispatch();

  const internshipItemRef = useRef(null);
  const [itemHeight, setItemHeight] = useState("auto");
  const { internships, isLoading } = useSelector((state) => state.internships);
  const profileData = useSelector((state) => state.profile?.user);

  const [activeInternship, setActiveInternship] = useState(null);
  const [filters, setFilters] = useState({
    search: "",
    location: [],
    company: "",
    date: "",
    jobType: "",
  });

  useEffect(() => {
    if (internshipItemRef.current) {
      // Set the height dynamically when the reference is available
      setItemHeight(internshipItemRef.current.offsetHeight);
    }
  }, [internshipItemRef, internships]);

  console.log(itemHeight);
  useEffect(() => {
    if (internships?.length === 0) {
      handleSearch();
    }
  }, []);
  useEffect(() => {
    if (internships?.length > 0) {
      setActiveInternship(internships[0]);
    }
  }, [internships]);

  const handleSearch = () => {
    const payload = {
      ...filters,
      location: JSON.stringify(filters.location),
    };
    setActiveInternship(null);
    if (!profileData?.freeUser) {
      dispatch(InternshipsAction.getInternships(payload));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(true);
    }
  };

  const countrylist = UtilityArrays.Countries.map((country) => ({
    label: country,
    value: country,
  }));

  const dateValues = [
    { title: "Posted this week", value: "Posted this week" },
    { title: "Posted last week", value: "Posted last week" },
    { title: "Posted this month", value: "Posted this month" },
    { title: "Posted in last 6 months", value: "Posted in last 6 months" },
    { title: "Posted this year", value: "Posted this year" },
  ];
  const companyValues = [
    { title: "Microsoft", value: "Microsoft" },
    { title: "Apple", value: "Apple" },
    { title: "Google", value: "Google" },
    { title: "Samsung", value: "Samsung" },
    { title: "Xiaomi", value: "Xiaomi" },
  ];
  const jobTypeValues = [
    { title: "Internship", value: "Internship" },
    { title: "Part-time", value: "Part-time" },
  ];

  const filterButtons = [
    {
      title: "Date Posted",
      items: (
        <div className="filter-dropdown">
          {dateValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => setFilters({ ...filters, date: item.value })}
              className={`${
                filters.date === item.value
                  ? "active-filter-value"
                  : "filter-value"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Company",
      items: (
        <div className="filter-dropdown">
          {companyValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => setFilters({ ...filters, company: item.value })}
              className={`${
                filters.company === item.value
                  ? "active-filter-value"
                  : "filter-value"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Job Type",
      items: (
        <div className="filter-dropdown">
          {jobTypeValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => setFilters({ ...filters, jobType: item.value })}
              className={`${
                filters.jobType === item.value
                  ? "active-filter-value"
                  : "filter-value"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Location",
      placement: "bottomLeft",
      items: (
        <div>
          <div className="flex text-white justify-end font-semibold">
            <div
              className="cursor-pointer"
              onClick={() => setFilters({ ...filters, location: [] })}
            >
              Clear filter
            </div>
          </div>
          <checkbox.VerticalCheckbox
            value={filters.location}
            options={countrylist}
            onChange={(value) => {
              setFilters({ ...filters, location: value });
            }}
            style={{
              maxHeight: "400px",
              maxWidth: "500px",
              overflow: "auto",
              paddingBottom: "10px",
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={`text-black relative`}>
      {profileData?.freeUser && (
        <div className="absolute z-10 left-[35%] top-20">
          <GetProPopup />
        </div>
      )}
      <div className={`${profileData?.freeUser && "blur pointer-events-none"}`}>
        <div className="text-[18px] font-semibold">
          Search For Internships Opportunities
        </div>

        <Spacer height={20} />
        <div className="flex items-center justify-center gap-2">
          <inputFields.DefaultSearchBox
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
            onKeyDown={handleKeyDown}
            disabled={profileData?.freeUser}
          />
          <div className="w-[120px]">
            <Buttons.DefaultButton
              size="medium"
              width="full"
              style={{ height: "48px" }}
              onClick={() => handleSearch()}
              disabled={profileData?.freeUser}
            >
              Search
            </Buttons.DefaultButton>
          </div>
        </div>
        <Spacer height={20} />
        <div className="flex gap-2">
          {filterButtons?.map((item, index) => (
            <popover.DefaultPopover
              content={item.items}
              placement={item?.placement ?? "bottom"}
              disabled={profileData?.freeUser}
            >
              <Buttons.DefaultButton size="small">
                {item?.title}
              </Buttons.DefaultButton>
            </popover.DefaultPopover>
          ))}
        </div>
        <Spacer height={20} />
        <boxContainer.LiteBoxContainer addstyle="p-0">
          {isLoading ? (
            <Loader.Circular />
          ) : (
            <>
              {internships?.length === 0 ? (
                <div className="p-6">
                  <Empty />
                </div>
              ) : (
                <div className="flex">
                  <div className="flex flex-col w-[20%] border-r-[1px] border-r-gray-300">
                    {internships?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex flex-col gap-2 py-5 pl-[15px] pr-3 cursor-pointer border-b-[1px] border-b-gray-300 ${
                          index === 0 && "rounded-tl-3xl"
                        } ${
                          activeInternship?.id === item?.id && "bg-[#f6fffc]"
                        }`}
                        ref={internshipItemRef}
                        onClick={() => setActiveInternship(item)}
                      >
                        <div className="font-semibold flex gap-2">
                          <div className="w-[5%] mt-1">
                            <HiBriefcase fontSize={18} />
                          </div>
                          <div>{item.title}</div>
                        </div>
                        <div className="font-medium flex gap-2">
                          <div className="w-[5%] mt-1">
                            <FaLocationDot />
                          </div>
                          <div>
                            {item.location}, {item.country}
                          </div>
                        </div>
                        <div className="font-medium opacity-70 flex gap-2">
                          <div className="w-[5%] mt-[3px]">
                            <FaRegClock />
                          </div>
                          <div>{item.time_posted}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col w-[80%]">
                    {!activeInternship ? (
                      <div className="flex items-center justify-center text-[24px] text-main font-bold h-full">
                        Please select an internship to view details!
                      </div>
                    ) : (
                      <div>
                        <div
                          className="p-6 border-b-[1px] border-b-gray-300 flex flex-col justify-between"
                          style={{ height: itemHeight }}
                        >
                          <div>
                            <div className="text-[22px] font-semibold">
                              {activeInternship.title}
                            </div>
                            <Spacer height={10} />
                            <div className="font-medium flex gap-2 items-center">
                              <FaLocationDot />
                              {activeInternship.location},{" "}
                              {activeInternship.country}
                            </div>
                          </div>
                          <Spacer height={15} />
                          <div className="flex justify-between items-center">
                            <div className="flex gap-4">
                              {activeInternship?.salary && (
                                <div className="flex gap-2 justify-center items-center px-2 bg-gray-200 rounded-lg font-medium">
                                  <img
                                    src={Images.InternshipsSalary}
                                    className="h-[16px]"
                                  />
                                  {activeInternship?.salary}
                                </div>
                              )}
                              {activeInternship?.employment_type && (
                                <div className="flex gap-2 justify-center items-center px-2 py-1 bg-gray-200 rounded-lg font-medium">
                                  <img
                                    src={Images.InternshipsType}
                                    className="h-[16px]"
                                  />
                                  {activeInternship?.employment_type}
                                </div>
                              )}
                              {activeInternship?.job_functions_collection
                                ?.length > 0 && (
                                <div className="flex gap-2 justify-center items-center px-2 py-1 bg-gray-200 rounded-lg font-medium">
                                  <FaIndustry fontSize={16} />
                                  {
                                    activeInternship
                                      ?.job_functions_collection[0]
                                  }
                                </div>
                              )}
                              {activeInternship?.job_industry_collection
                                ?.length > 0 && (
                                <div className="flex gap-2 justify-center items-center px-2 py-1 bg-gray-200 rounded-lg font-medium">
                                  <FaBuilding fontSize={16} />
                                  {
                                    activeInternship?.job_industry_collection[0]
                                      .job_industry_list?.industry
                                  }
                                </div>
                              )}
                            </div>
                            <div>
                              <Link
                                to={activeInternship?.external_url}
                                target="_blank"
                              >
                                <Buttons.DefaultButton size="medium">
                                  Apply
                                </Buttons.DefaultButton>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="p-6">
                          <div className="text-[20px] font-bold">
                            Job Description
                          </div>
                          <Spacer height={15} />
                          {activeInternship.description
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </boxContainer.LiteBoxContainer>
      </div>
    </div>
  );
};

export default Internships;
