import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Divider } from "antd";

import { ChatbotAction } from "../../store/actions";

import { Loader } from "..";
import ChatbotPopupInput from "./chatbot-popup-input";

const formatDividerDate = (date) => {
  const now = moment();
  const givenDate = moment(date);

  if (now.isSame(givenDate, "day")) {
    return "Today";
  } else if (now.subtract(1, "day").isSame(givenDate, "day")) {
    return "Yesterday";
  } else {
    return givenDate.format("MMMM Do YYYY");
  }
};

const ChatbotPopupChat = ({ currentChat, socket }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const profileData = useSelector((state) => state.profile?.user);
  const { isMessagesLoading } = useSelector((state) => state?.chatbot);

  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);

  let lastMessageDate = null;

  useEffect(() => {
    const payload = {
      from: profileData?.id,
      to: currentChat?.userId,
    };
    dispatch(
      ChatbotAction.getMessages(payload, (res) => {
        setMessages(res.data);
      })
    );
  }, [currentChat]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (msg) => {
        setArrivalMessage({
          fromSelf: false,
          message: msg,
          receiverId: profileData?.id,
          senderId: currentChat?.userId,
        });
      });
    }
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMsg = async (msg) => {
    socket.current.emit("send-msg", {
      to: currentChat.userId,
      from: profileData?.id,
      msg,
    });
    const payload = {
      from: profileData?.id,
      to: currentChat.userId,
      message: msg,
    };
    dispatch(ChatbotAction.addMessage(payload));

    const msgs = [...messages];
    msgs.push({
      fromSelf: true,
      message: msg,
      senderId: profileData?.id,
      receiverId: currentChat?.userId,
      date: new Date(),
    });
    setMessages(msgs);
  };

  return (
    <div>
      <div className="chatbot-popup-chat-messages">
        {isMessagesLoading ? (
          <Loader.Circular />
        ) : (
          <>
            {messages?.map((message, index) => {
              if (
                (message.senderId === currentChat?.userId &&
                  message.receiverId === profileData?.id) ||
                (message.senderId === profileData?.id &&
                  message.receiverId === currentChat?.userId)
              ) {
                const messageDate = moment(message?.date).startOf("day");
                let showDivider = false;

                if (
                  !lastMessageDate ||
                  !lastMessageDate.isSame(messageDate, "day")
                ) {
                  showDivider = true;
                  lastMessageDate = messageDate;
                }

                return (
                  <div ref={scrollRef} key={index} className="flex flex-col">
                    {showDivider && (
                      <Divider>
                        <div className="text-gray-500 text-[12px]">
                          {formatDividerDate(message?.date)}
                        </div>
                      </Divider>
                    )}
                    <div
                      className={`${
                        message.fromSelf
                          ? "chatbot-popup-outgoing-chat"
                          : "chatbot-popup-incoming-chat"
                      }`}
                    >
                      {message?.message.split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                      {/* {message?.date} */}
                      <div className="text-[10px] font-medium text-end">
                        {moment(message?.date).format("h:mm A")}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      </div>
      <ChatbotPopupInput handleSendMsg={handleSendMsg} />
    </div>
  );
};

export default ChatbotPopupChat;
