import React from "react";
import { Images } from "../config";

const SortButton = (props) => {
  return (
    <div
      {...props}
      className="h-[36px] w-[36px] cursor-pointer rounded-full bg-main flex justify-center items-center"
    >
      <img src={Images.FilterIcon} className="w-7" />
    </div>
  );
};

export default SortButton;
