import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

import { UniversityAction } from "../../store/actions";

import {
  Modal,
  Spacer,
  inputFields,
  boxContainer,
  Loader,
  Buttons,
  checkbox,
  popover,
  SortButton,
} from "../../components";
import { Images } from "../../config";

import "./styles.css";

const options = {
  method: "save",
  filename: "compare_result.pdf",
  resolution: Resolution.MEDIUM,
  page: {
    margin: Margin.SMALL,
    format: "A4",
    orientation: "landscape",
  },
  overrides: {
    pdf: {
      compress: true,
    },
    canvas: {
      useCORS: true,
    },
  },
};

const tableHeader = [
  "University Name",
  "Location",
  "QS World Ranking",
  "Average Fee",
  "Acceptance Rate",
  "Average Course Duration",
  "University Type",
  "Total Students",
  "SAT Midrange",
  "ACT Midrange",
  "IELTS Requirements",
  "TOEFL Requirements",
  "Application Deadline",
];

const defaultCheckedList = [
  "University Name",
  "Location",
  "QS World Ranking",
  "Average Fee",
  "Acceptance Rate",
  "Average Course Duration",
  "University Type",
  "Total Students",
  "SAT Midrange",
  "ACT Midrange",
  "IELTS Requirements",
  "TOEFL Requirements",
  "Application Deadline",
];

const Compare = () => {
  const dispatch = useDispatch();

  const pdfRef = useRef(null);

  const compareUnis = useSelector(
    (state) => state?.university?.compareUniversity
  );
  const { allUniversities, isLoading } = useSelector(
    (state) => state.university
  );

  const [showModal, setShowModal] = useState(false);
  const [uniIdArray, setUniIdArray] = useState([]);
  const [allUniList, setAllUniList] = useState([]);
  const [modalUni, setModalUni] = useState(null);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  useEffect(() => {
    if (allUniversities?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    const filteredUnis = allUniversities?.map((university) => ({
      label: university.name,
      value: university.id,
    }));
    setAllUniList(filteredUnis);
  }, [allUniversities]);

  useEffect(() => {
    const initialUniIds = compareUnis.map((item) => item.id);
    setUniIdArray(initialUniIds);
  }, []);

  useEffect(() => {
    uniCompareAPICall();
  }, [uniIdArray]);

  const handleUniIdArray = () => {
    setUniIdArray([...uniIdArray, modalUni]);
    setModalUni(null);
  };
  const uniCompareAPICall = async () => {
    const payload = {
      uniIds: uniIdArray,
    };
    dispatch(UniversityAction.compareUniverities(payload));
  };

  const removeUnis = async (uniId) => {
    const filteredUniIds = uniIdArray.filter((id) => id !== uniId);
    setUniIdArray(filteredUniIds);
  };

  const compareValues = (university) => {
    return [
      { id: 1, value: university?.name, tableTitle: "University Name" },
      {
        id: 2,
        value: `${university?.city ? university?.city : ""}${
          university?.state ? `, ${university?.state}` : ""
        }${university?.country?.name ? `, ${university?.country.name}` : ""}`,
        tableTitle: "Location",
      },
      {
        id: 3,
        value: university?.rankings?.QS ?? "NA",
        tableTitle: "QS World Ranking",
      },
      {
        id: 4,
        value: university?.avgFees ?? "NA",
        tableTitle: "Average Fee",
      },
      {
        id: 5,
        value: university?.acceptanceRate
          ? `${(university?.acceptanceRate * 100).toFixed(1)}%`
          : "NA",
        tableTitle: "Acceptance Rate",
      },
      {
        id: 6,
        value: university?.courseDuration,
        tableTitle: "Average Course Duration",
      },
      {
        id: 7,
        value: university?.Type?.type ?? "NA",
        tableTitle: "University Type",
      },
      {
        id: 8,
        value: university?.totalStudents ?? "NA",
        tableTitle: "Total Students",
      },
      {
        id: 9,
        value: university?.testScores?.SATMidrange ?? "NA",
        tableTitle: "SAT Midrange",
      },
      {
        id: 10,
        value: university?.testScores?.ACTMidrange ?? "NA",
        tableTitle: "ACT Midrange",
      },
      {
        id: 11,
        value: university?.requirements?.IELTS ?? "NA",
        tableTitle: "IELTS Requirements",
      },
      {
        id: 12,
        value: university?.requirements?.TOEFL ?? "NA",
        tableTitle: "TOEFL Requirements",
      },
      {
        id: 13,
        value: (
          <div className="font-semibold rounded-md bg-liteBoxBg border border-main p-1">
            {university?.deadlines?.length > 0
              ? university?.deadlines[0]?.appDeadline
                ? moment(university?.deadlines[0]?.appDeadline).format(
                    "DD MMMM, YYYY"
                  )
                : "NA"
              : "NA"}
          </div>
        ),
        tableTitle: "Application Deadline",
      },
    ];
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <boxContainer.LiteBoxContainer addstyle="py-2">
            {/* top part start */}
            <div className="compare-top-boxes-container">
              <div className="compare-top-boxes">
                <div className="text-main text-[22px] font-semibold">
                  Compare Universities
                </div>
                <div className="text-[12px] text-gray-500 font-medium">
                  This section shows the overall summary of different
                  universities
                </div>
              </div>

              {compareUnis?.map((uni, index) => (
                <div
                  className="compare-top-boxes bg-main rounded-xl p-2 relative justify-start pt-[40px]"
                  key={index}
                >
                  <div
                    className="absolute top-[2px] right-3 font-medium text-white text-[18px] cursor-pointer"
                    onClick={() => removeUnis(uni.id)}
                  >
                    x
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      src={uni?.profilePic}
                      className="h-[56px] w-[56px] object-contain rounded-full bg-white p-1"
                    />
                  </div>
                  <div className="text-white font-semibold">{uni?.name}</div>
                </div>
              ))}

              <div
                className="compare-add-more-uni"
                onClick={() => setShowModal(true)}
              >
                <img src={Images.AddUniIcon} />
                <div>Add University</div>
              </div>
            </div>
            {/* top part end */}
          </boxContainer.LiteBoxContainer>
          <Spacer height={20} />
          <div>
            <div className="bg-main rounded-t-[30px] text-white font-semibold p-3 flex justify-between items-center">
              Comparison
              <popover.DefaultPopover
                content={
                  <checkbox.VerticalCheckbox
                    value={checkedList}
                    options={tableHeader.filter(
                      (header) => header !== "University Name"
                    )}
                    onChange={(value) => {
                      setCheckedList(["University Name", ...value]);
                    }}
                  />
                }
              >
                <SortButton />
              </popover.DefaultPopover>
            </div>
            <div className="compare-table-container">
              <div
                className="flex w-[calc(100vw-410px)] overflow-auto"
                ref={pdfRef}
              >
                <div className="flex flex-col w-[260px]">
                  {tableHeader.map((header, index) =>
                    header === "University Name" ||
                    checkedList?.includes(header) ? (
                      <div
                        key={index}
                        className="compare-table-value font-bold flex gap-2"
                      >
                        <div className="border-r-[1px] w-[30px] border-r-liteBoxBorder py-3 px-2 h-full flex items-center">
                          {index + 1}
                        </div>
                        <div className="py-3 px-2 text-left">{header}</div>
                      </div>
                    ) : null
                  )}
                </div>

                {compareUnis?.map((university, index) => {
                  const values = compareValues(university);
                  return (
                    <div key={index} className="w-[290px]">
                      {values?.map((item, index) =>
                        item?.tableTitle === "University Name" ||
                        checkedList?.includes(item?.tableTitle) ? (
                          <div key={index} className="compare-table-value">
                            <div className="py-3 px-2 w-full">
                              {item?.value}
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  );
                })}
              </div>
              <Spacer height={10} />
              <div className="flex justify-end gap-3">
                <Buttons.DefaultButton
                  size="medium"
                  onClick={() => generatePDF(pdfRef, options)}
                >
                  Download PDF
                </Buttons.DefaultButton>
                <Buttons.DefaultButton
                  size="medium"
                  onClick={() => window.history.back()}
                >
                  Main Menu
                </Buttons.DefaultButton>
              </div>
              <Spacer height={20} />
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
      <Modal.DefaultModal open={showModal} onCancel={() => setShowModal(false)}>
        <div className="w-[600px] flex flex-col justify-center items-center">
          <div className="header-md text-main">
            Select the University you want to compare
          </div>

          <Spacer height={20} />
          {isLoading ? (
            <Loader.Circular />
          ) : (
            <inputFields.SelectDropdown
              items={allUniList}
              largedropdown={true}
              onChange={(e) => {
                setModalUni(e);
              }}
              value={modalUni}
              placeholder="Select a University to compare"
            />
          )}

          <Spacer height={20} />

          <div className="w-[150px]">
            <Buttons.DefaultButton
              onClick={() => {
                handleUniIdArray();
                setShowModal(false);
              }}
              width="full"
            >
              Done
            </Buttons.DefaultButton>
          </div>

          <Spacer height={10} />
        </div>
      </Modal.DefaultModal>
    </div>
  );
};

export default Compare;
