import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const ContactProfileBuilder = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [contacts, setContacts] = useState([
    {
      name: "",
      relation: "",
      email: "",
      phone: "",
    },
  ]);

  useEffect(() => {
    setContacts(
      questionnaire?.contacts?.length > 0
        ? questionnaire?.contacts
        : [
            {
              name: "",
              relation: "",
              email: "",
              phone: "",
            },
          ]
    );
  }, [questionnaire]);

  const addContact = () => {
    const lastContact = contacts[contacts.length - 1];

    if (!lastContact.name || !lastContact.relation || !lastContact.phone) {
      message.error(
        "Please fill in all required fields before adding a new contact."
      );
    } else {
      setContacts([
        ...contacts,
        {
          name: "",
          relation: "",
          email: "",
          phone: "",
        },
      ]);
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const handleNext = () => {
    const filteredContacts = contacts.filter((contact) => {
      if (!contact.name || !contact.relation || !contact.phone) {
        return false;
      }
      return true;
    });

    if (filteredContacts.length === 0) {
      message.error("Please fill in all the required fields.");
      return;
    }

    const payload = { userId: profileData?.id, contacts: filteredContacts };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          setPage("Counselor");
        }
      })
    );
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="max-h-[550px] overflow-auto">
            {contacts?.map((contact, index) => (
              <div className={`${index > 0 ? "mt-6" : ""} `}>
                <div className="section-header">Contact {index + 1}</div>
                <div className="grid grid-cols-2 gap-x-7 gap-y-6" key={index}>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel
                      text="Name"
                      required
                    />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleContactChange(index, "name", e.target.value)
                      }
                      placeholder="Enter name"
                      value={contact.name}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel
                      text="Relation"
                      required
                    />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleContactChange(index, "relation", e.target.value)
                      }
                      placeholder="Father"
                      value={contact.relation}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Email" />
                    <Spacer height="5px" />
                    <inputFields.DefaultInput
                      onChange={(e) =>
                        handleContactChange(index, "email", e.target.value)
                      }
                      placeholder="Enter email"
                      value={contact.email}
                    />
                  </div>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel
                      text="Phone Number"
                      required
                    />
                    <Spacer height="5px" />
                    <inputFields.DefaultPhoneInput
                      onChange={(e) => handleContactChange(index, "phone", e)}
                      placeholder="Enter your primary guardian phone number"
                      value={contact?.phone}
                      editCountryCode={true}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={addContact} size="medium">
              + Add More
            </Buttons.DefaultButton>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactProfileBuilder;
