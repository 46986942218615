import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { UniversityAction } from "../../store/actions";

import {
  Spacer,
  Loader,
  boxContainer,
  Buttons,
  inputFields,
  recommender,
} from "../../components";
import { UtilityArrays } from "../../config";

import CircleContainer from "./circle-container";

import "./styles.css";

function RecommendersPreferences() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { allUniversities, isLoading } = useSelector(
    (state) => state.university
  );
  const { countries, isCountryLoading, programTypes, isProgramLoading } =
    useSelector((state) => state.others);

  const [state, setState] = useState({
    acceptanceRate: "0-100",
    durationOfDegree: "",
    countryChoice: [],
    locationType: "",
    budget: "",
    scholarship: "",
    aid: "",
    preferredSubject: "",
    halalFood: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [programList, setProgramList] = useState([]);

  const handleNext = () => {
    if (state?.countryChoice?.length < 3) {
      message.error("Fill all the required fields.");
    } else {
      navigate("/recommender/results", {
        state: {
          qualifications: location.state,
          preferences: state,
        },
      });
    }
  };

  useEffect(() => {
    if (allUniversities?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);
  useEffect(() => {
    const filteredCountries = countries.map((country) => ({
      label: country?.name,
      value: country.name,
    }));

    const filteredPrograms = programTypes.map((program) => ({
      label: program?.programType,
      value: program.programType,
    }));

    setCountryList(filteredCountries);
    setProgramList([{ label: "All", value: "" }, ...filteredPrograms]);
  }, [countries]);

  const acceptanceRateList = [
    { label: "All", value: "0-100" },
    { label: "0 - 20 %", value: "0-20" },
    { label: "21 - 40 %", value: "21-40" },
    { label: "41 - 60 %", value: "41-60" },
    { label: "61 - 80 %", value: "61-80" },
    { label: "81 - 100 %", value: "81-100" },
  ];
  const degreeDurationList = [
    { label: "All", value: "" },
    { label: "3 Years", value: "3" },
    { label: "4 Years", value: "4" },
  ];
  const locationTypeList = [
    { label: "All", value: "" },
    { label: "Urban", value: "urban" },
    { label: "Suburban", value: "suburban" },
    { label: "Rural", value: "rural" },
  ];
  const scholarshipList = [
    { label: "All", value: "" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const halalFoodList = [
    { label: "Any Availibility", value: "" },
    { label: "Easy Availibility", value: "easy" },
  ];

  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <CircleContainer page="preferences" />
        <div className="px-3">
          <Spacer height={20} />
          <div className="header-md text-main">Preferences</div>
          <Spacer height={10} />
          <div className="text-[18px] font-semibold">
            Let Us Know Your Academic Choices To Help Our Recommender Generate
            Potential Universities For You.
          </div>
        </div>
        {isLoading || isCountryLoading || isProgramLoading ? (
          <Loader.Circular />
        ) : (
          <div className="inputFieldContainer">
            <div>
              <recommender.RecommenderBoxLabel
                title="Acceptance Rate"
                required={true}
              />
              <div className="box-subtitles">
                Your Target university Should be Between what Range?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={acceptanceRateList}
                value={state?.acceptanceRate}
                onChange={(e) => {
                  setState({ ...state, acceptanceRate: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Duration of Degree"
                required={true}
              />
              <div className="box-subtitles">
                How Long Do You Want Your University Degree to be?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={degreeDurationList}
                value={state?.durationOfDegree}
                onChange={(e) => {
                  setState({ ...state, durationOfDegree: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Top 3 Countries of Choice"
                required={true}
              />
              <div className="box-subtitles">
                What are Your Top 3 Countries of Choice?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdownMultiple
                items={countryList}
                value={state.countryChoice}
                onChange={(e) => {
                  if (e.length <= 3) {
                    setState({ ...state, countryChoice: e });
                  }
                }}
                placeholder="Select you top 3 countries of choice"
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Location Type"
                required={true}
              />
              <div className="box-subtitles">
                Would You Prefer to Live in an Urban or Rural Campus?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={locationTypeList}
                value={state?.locationType}
                onChange={(e) => {
                  setState({ ...state, locationType: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel title="Budget" required={true} />
              <div className="box-subtitles">
                How Much are You Looking to Allocate to Your University Fee
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={UtilityArrays?.budgetRange}
                value={state?.budget}
                onChange={(e) => {
                  setState({ ...state, budget: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Scholarship"
                required={true}
              />
              <div className="box-subtitles">
                Is Scholarship a Criteria for University Selection?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={scholarshipList}
                value={state?.scholarship}
                onChange={(e) => {
                  setState({ ...state, scholarship: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Financial Aid"
                required={true}
              />
              <div className="box-subtitles">
                Is Financial Aid a Criteria for University Selection?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={scholarshipList}
                value={state?.aid}
                onChange={(e) => {
                  setState({ ...state, aid: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Preferred Subject"
                required={true}
              />
              <div className="box-subtitles">
                What Majors are You Looking to Study at University?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={programList}
                value={state?.preferredSubject}
                onChange={(e) => {
                  setState({ ...state, preferredSubject: e });
                }}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel
                title="Halal Food Availibility"
                required={true}
              />
              <div className="box-subtitles">
                Is Nearby Halal Food a Criteria for University Selection?
              </div>
              <Spacer height={10} />
              <inputFields.SelectDropdown
                items={halalFoodList}
                value={state?.halalFood}
                onChange={(e) => {
                  setState({ ...state, halalFood: e });
                }}
              />
            </div>
          </div>
        )}
        <Spacer height={20} />
        <div className="flex justify-end mr-4">
          <div className="w-[200px]">
            <Buttons.DefaultButton
              onClick={handleNext}
              size="medium"
              width="full"
            >
              Continue
            </Buttons.DefaultButton>
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default RecommendersPreferences;
