import React from "react";
import { Tooltip } from "antd";
import { Colors } from "../../config";

const WhiteTooltip = (props) => {
  return (
    <Tooltip
      placement={props?.placement ?? "left"}
      title={props?.text}
      color="white"
      overlayInnerStyle={{
        color: "black",
        overflowY: "auto",
        maxWidth: props?.maxwidth ?? "350px",
        maxHeight: props?.maxheight ?? "250px",
        padding: "15px",
        fontWeight: "500",
      }}
      overlayStyle={{
        border: `1px solid ${Colors.main}`,
        borderRadius: "8px",
      }}
    >
      {props?.children}
    </Tooltip>
  );
};

export default WhiteTooltip;
