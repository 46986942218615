import React from "react";

import "./styles.css";

const WhiteButton = (props) => {
  const sizeClasses = {
    extraSmall: "text-[12px] px-[16px] py-[8px]",
    small: "text-[12px] px-[24px] py-[8px] pt-[10px]",
    medium: "text-[14px] px-[30px] py-[10px]",
    large: "text-[18px] px-[38px] py-[12px]",
  };
  const radiusClasses = {
    none: "rounded-none",
    small: "rounded-sm",
    medium: "rounded-md",
    large: "rounded-lg",
    xl: "rounded-xl",
    full: "rounded-full",
  };
  const widthClasses = {
    max: "w-max",
    fifty: "w-[50%]",
    full: "w-full",
  };

  const buttonSize = sizeClasses[props?.size] || sizeClasses.large;
  const borderRadius = radiusClasses[props?.radius] || "rounded-[12px]";
  const width = widthClasses[props?.width] || "w-max";

  return (
    <button
      {...props}
      className={`bg-white text-main button-font button-border hover:drop-shadow-lg transition-all capitalize ${buttonSize} ${borderRadius} ${width} whitespace-nowrap overflow-hidden text-ellipsis ${
        props?.disabled && "opacity-80 cursor-not-allowed"
      }`}
      title={props?.children}
    >
      {props?.children}
    </button>
  );
};

export default WhiteButton;
