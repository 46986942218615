import React from "react";
import { Steps, ConfigProvider } from "antd";

import { Colors } from "../../config";

import "./styles.css";

const AppjourneySteps = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Colors.main,
        },
      }}
    >
      <Steps
        progressDot
        current={props?.array?.length - 1}
        direction="vertical"
        items={props?.array?.map((item, index) => ({
          title: (
            <div className="flex flex-col gap-2 font-bold text-[20px]">
              <div className="flex gap-2">
                <span className="text-main">{index + 1}.</span>
                {item.title}
              </div>
            </div>
          ),
          description: (
            <div className="font-semibold text-[18px] text-justify">
              {item?.details}
            </div>
          ),
        }))}
        className="app-journey-steps"
        {...props}
      />
    </ConfigProvider>
  );
};

export default AppjourneySteps;
