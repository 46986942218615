import React from "react";
import { useSelector } from "react-redux";

import popover from "../popover";

import { Images } from "../../config";

import ChatbotPopupContent from "./chatbot-popup-content";

import "./styles.css";

const ChatbotPopup = () => {
  const unreadMessages = useSelector((state) => state.chatbot?.unreadMessages);

  return (
    <popover.ChatbotPopover
      content={<ChatbotPopupContent />}
      placement="topRight"
    >
      <div className="relative">
        {unreadMessages?.length > 0 && (
          <div className="unread-count h-5 w-5 top-1 right-1">
            {unreadMessages?.length}
          </div>
        )}
        <img src={Images.ChatbotPopupButton} className="cursor-pointer" />
      </div>
    </popover.ChatbotPopover>
  );
};

export default ChatbotPopup;
