import React from "react";
import { Tabs, ConfigProvider } from "antd";

import { Colors } from "../../config";

import "./styles.css";

const UniversityTab = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            inkBarColor: Colors.main,
            itemSelectedColor: "black",
            titleFontSize: 20,
            itemHoverColor: "black",
          },
        },
      }}
    >
      <Tabs
        className="university-tabs"
        moreIcon={false}
        animated={true}
        {...props}
      />
    </ConfigProvider>
  );
};

export default UniversityTab;
