import React from "react";
import { Images } from "../../config";

const DefaultSearchBox = ({
  iconposition = "left",
  paddingy = "10px",
  placeholder,
  ...props
}) => {
  const iconClass = iconposition === "right" ? "order-2" : "order-1";

  return (
    <div
      className={`flex items-center gap-3 border border-searchBorder px-4 py-[${paddingy}] rounded-[10px] w-full text-black shadow-sm bg-white`}
    >
      <img src={Images.SearchboxSearch} className={`w-5 ${iconClass}`} />
      <input
        className="outline-none text-[18px] w-full order-1"
        placeholder={placeholder ?? "Search"}
        disabled={props.disabled}
        {...props}
      />
    </div>
  );
};

export default DefaultSearchBox;
