import { put } from "redux-saga/effects";

import { IndividualStudentAction } from "../actions";
import { ApiCaller } from "../../config";

export default class IndividualStudentMiddleware {
  static *retrieveSession({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/retrieve-session`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.retrieveSessionSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.retrieveSessionFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.retrieveSessionFailure());
    }
  }

  static *cancelSubscription({ cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/individualStudents/cancel-subscription`
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.cancelSubscriptionSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.cancelSubscriptionFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.cancelSubscriptionFailure());
    }
  }
  static *createCheckoutMonthly({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/create-checkout-session-monthly`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.createCheckoutMonthlySuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.createCheckoutMonthlyFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.createCheckoutMonthlyFailure());
    }
  }
  static *createCheckoutOnetime({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/create-checkout-session-onetime`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.createCheckoutOnetimeSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.createCheckoutOnetimeFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.createCheckoutOnetimeFailure());
    }
  }

  static *updateTrialModal({ cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/individualStudents/update-trial-modal-status`
      );
      if (res.status === 200) {
        yield put(
          IndividualStudentAction.updateTrialModalSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.updateTrialModalFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.updateTrialModalFailure());
    }
  }
}
