import {
  ADD_OR_REMOVE_STAY_CONNECTED,
  ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS,
  ADD_OR_REMOVE_STAY_CONNECTED_FAILURE,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES_GRID,
  GET_UNIVERSITIES_GRID_SUCCESS,
  GET_UNIVERSITIES_GRID_FAILURE,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
  COMPARE_UNIVERSITIES,
  COMPARE_UNIVERISTIES_SUCCESS,
  COMPARE_UNIVERISTIES_FAILURE,
  GET_UNIS_BY_BUDGET,
  GET_UNIS_BY_BUDGET_SUCCESS,
  GET_UNIS_BY_BUDGET_FAILURE,
  GET_POPULAR_COUNTRIES,
  GET_POPULAR_COUNTRIES_SUCCESS,
  GET_POPULAR_COUNTRIES_FAILURE,
} from "../constants";

const initialState = {
  count: 0,
  universities: [],
  allUniversities: [],
  topPicks: [],
  trending: [],
  popularInMyCountry: [],
  topBusiness: [],
  topMedicine: [],
  topLaw: [],
  topEngineering: [],
  topArtsAndHumanities: [],
  topPsychology: [],
  youMayLike: [],
  universitiesByBudget: [],
  popularCountries: [],
  university: {},
  compareUniversity: [],
  isLoading: false,
  isGridLoading: false,
  isStayConnectedLoading: false,
  isPopularCountryLoading: false,
  isUniByBudgetLoading: false,
  error: null,
};

export default function UniversityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNIVERSITIES:
    case GET_ALL_UNIVERSITIES:
    case GET_UNIVERSITY_DETAILS:
    case COMPARE_UNIVERSITIES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_GRID:
      state = {
        ...state,
        isGridLoading: true,
        error: null,
      };
      break;
    case GET_POPULAR_COUNTRIES:
      state = {
        ...state,
        isPopularCountryLoading: true,
        error: null,
      };
      break;
    case GET_UNIS_BY_BUDGET:
      state = {
        ...state,
        isUniByBudgetLoading: true,
        error: null,
      };
      break;
    case ADD_OR_REMOVE_STAY_CONNECTED:
      state = {
        ...state,
        isStayConnectedLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        count: action?.payload?.count,
        universities: action?.payload?.data,
        isLoading: false,
      };
      break;
    case ADD_OR_REMOVE_STAY_CONNECTED_SUCCESS:
      state = {
        ...state,
        isStayConnectedLoading: false,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_GRID_SUCCESS:
      state = {
        ...state,
        count: action?.payload?.count,
        topPicks: action?.payload?.topPicks,
        trending: action?.payload?.trending,
        popularInMyCountry: action?.payload?.popularInMyCountry,
        topBusiness: action?.payload?.topBusiness,
        topMedicine: action?.payload?.topMedicine,
        topLaw: action?.payload?.topLaw,
        topEngineering: action?.payload?.topEngineering,
        topArtsAndHumanities: action?.payload?.topArtsAndHumanities,
        topPsychology: action?.payload?.topPsychology,
        youMayLike: action?.payload?.youMayLike,
        isGridLoading: false,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        allUniversities: action?.payload,
        isLoading: false,
      };
      break;
    case GET_POPULAR_COUNTRIES_SUCCESS:
      state = {
        ...state,
        popularCountries: action?.payload,
        isPopularCountryLoading: false,
      };
      break;
    case GET_UNIS_BY_BUDGET_SUCCESS:
      state = {
        ...state,
        universitiesByBudget: action?.payload,
        isUniByBudgetLoading: false,
      };
      break;
    case GET_UNIVERSITY_DETAILS_SUCCESS:
      state = {
        ...state,
        university: action.payload,
        isLoading: false,
      };
      break;
    case COMPARE_UNIVERISTIES_SUCCESS:
      state = {
        ...state,
        compareUniversity: action.payload,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITIES_FAILURE:
    case GET_ALL_UNIVERSITIES_FAILURE:
    case GET_UNIVERSITY_DETAILS_FAILURE:
    case COMPARE_UNIVERISTIES_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITIES_GRID_FAILURE:
      state = {
        ...state,
        isGridLoading: false,
      };
      break;
    case GET_POPULAR_COUNTRIES_FAILURE:
      state = {
        ...state,
        isPopularCountryLoading: false,
      };
      break;
    case GET_UNIS_BY_BUDGET_FAILURE:
      state = {
        ...state,
        isUniByBudgetLoading: false,
      };
      break;
    case ADD_OR_REMOVE_STAY_CONNECTED_FAILURE:
      state = {
        ...state,
        isStayConnectedLoading: false,
        error: null,
      };
      break;
    default:
      break;
  }
  return state;
}
