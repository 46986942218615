import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination, Rate } from "antd";
import moment from "moment";

import { FaLocationDot } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";

import {
  SortButton,
  Buttons,
  Spacer,
  popover,
  boxContainer,
  inputFields,
  tables,
  checkbox,
  GetProPopup,
} from "../../components";

import { UtilityArrays, Utils } from "../../config";

import {
  OthersAction,
  UniversityAction,
  SearchAction,
} from "../../store/actions";

import "./styles.css";

const columns = [
  {
    title: "University Name",
    dataIndex: "name",
    key: "1",
    fixed: "left",
    width: 500,
    render: (text, record) => (
      <div className="flex gap-2">
        <img
          src={record?.profilePic}
          className="h-[60px] w-[60px] border border-searchBorder shadow-boxContainer rounded-full object-contain bg-white"
        />

        <div className="flex flex-col gap-2">
          <div className="font-bold">{record.name}</div>
          <div className="flex gap-2 items-center text-[12px]">
            <FaLocationDot />
            <div className="pr-2 border-r border-r-gray-400">
              {`${record?.city ? record?.city : ""}${
                record?.state ? `, ${record?.state}` : ""
              }${record?.country?.name ? `, ${record?.country.name}` : ""}`}
            </div>
          </div>
          <div className="flex gap-2 items-center text-[12px]">
            <div className="pr-2 border-r border-r-gray-400">
              Average Fee:{" "}
              {record?.avgFeesUSD ? (
                <popover.WhitePopover
                  content={<div>{record?.avgFees}</div>}
                  placement={"top"}
                  trigger="hover"
                >
                  <Rate
                    defaultValue={
                      record?.avgFeesUSD > 0 && record?.avgFeesUSD <= 20000
                        ? 1
                        : record?.avgFeesUSD > 20000 &&
                          record?.avgFeesUSD <= 40000
                        ? 2
                        : record?.avgFeesUSD > 40000 &&
                          record?.avgFeesUSD <= 60000
                        ? 3
                        : record?.avgFeesUSD > 60000 &&
                          record?.avgFeesUSD <= 80000
                        ? 4
                        : 5
                    }
                    character={({ index = 0 }) => customIcons[index + 1]}
                    className="text-main text-[14px]"
                    disabled
                  />
                </popover.WhitePopover>
              ) : (
                "NA"
              )}
            </div>
            <div>
              Acceptance Rate:{" "}
              {record?.acceptanceRate
                ? `${(record?.acceptanceRate * 100).toFixed(1)}%`
                : "NA"}
            </div>
          </div>
          <div className="flex gap-2 items-center text-[12px]">
            <div className="pr-2 border-r border-r-gray-400">
              Average Course Duration: {record.courseDuration}{" "}
              {record?.courseDuration
                ? record?.courseDuration?.includes("Years")
                  ? null
                  : "Years"
                : "NA"}
            </div>
            <div>
              Application Status:{" "}
              {record?.deadlines?.length > 0 ? (
                record?.deadlines[0]?.appDeadline ? (
                  new Date() > new Date(record?.deadlines[0]?.appDeadline) ? (
                    <span className="text-red-500 font-bold">CLOSED</span>
                  ) : (
                    <span className="text-green-500 font-bold">OPEN</span>
                  )
                ) : (
                  "NA"
                )
              ) : (
                "NA"
              )}
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "QS World Ranking",
    dataIndex: "qs",
    key: "2",
    width: 170,
    align: "center",
    render: (text, record) => (
      <div>{record?.rankings?.QS ? `#${record?.rankings?.QS}` : "NA"}</div>
    ),
  },
  // {
  //   title: "QS Country Ranking",
  //   dataIndex: "qs",
  //   key: "3",
  //   width: 120,
  //   align: "center",
  //   render: (text, record) => (
  //     <div>
  //       {record?.rankings?.QScountry
  //         ? `#${record?.rankings?.QScountry}`
  //         : "NA"}
  //     </div>
  //   ),
  // },
  {
    title: "Average Fee",
    dataIndex: "avgFee",
    key: "4",
    width: 150,
    align: "center",
    render: (text, record) => <div>{record?.avgFees ?? "NA"}</div>,
  },
  {
    title: "University Type",
    dataIndex: "type",
    key: "5",
    width: 170,
    align: "center",
    render: (text, record) => <div>{record?.Type?.type ?? "NA"}</div>,
  },
  {
    title: "ACT Midrange",
    dataIndex: "actmirange",
    key: "6",
    width: 150,
    align: "center",
    render: (text, record) => (
      <div>{record?.testScores?.ACTMidrange ?? "NA"}</div>
    ),
  },
  {
    title: "SAT Midrange",
    dataIndex: "satmirange",
    key: "7",
    width: 150,
    align: "center",
    render: (text, record) => (
      <div>{record?.testScores?.SATMidrange ?? "NA"}</div>
    ),
  },

  {
    title: "IELTS Requirement",
    dataIndex: "ielts",
    key: "8",
    width: 180,
    align: "center",
    render: (text, record) => <div>{record?.requirements?.IELTS ?? "NA"}</div>,
  },
  {
    title: "TOEFL Requirement",
    dataIndex: "toefl",
    key: "9",
    width: 180,
    align: "center",
    render: (text, record) => <div>{record?.requirements?.TOEFL ?? "NA"}</div>,
  },
  {
    title: "Application Deadline",
    dataIndex: "appDeadline",
    key: "10",
    width: 180,
    align: "center",
    render: (text, record) => (
      <div className="flex flex-col">
        <div className="font-bold rounded-md bg-liteBoxBg border border-main">
          {record?.deadlines?.length > 0
            ? record?.deadlines[0]?.appDeadline
              ? moment(record?.deadlines[0]?.appDeadline).format(
                  "DD MMMM, YYYY"
                )
              : "NA"
            : "NA"}
        </div>
      </div>
    ),
  },
  {
    title: (
      <>
        SAT 25<sup>th</sup> Percentile
      </>
    ),
    dataIndex: "sat25",
    key: "11",
    width: 170,
    align: "center",
    render: (text, record) => (
      <div>{record?.testScores?.SATEnglish25Percentile ?? "NA"}</div>
    ),
  },

  {
    title: (
      <>
        SAT 75<sup>th</sup> Percentile
      </>
    ),
    dataIndex: "sat75",
    key: "12",
    width: 170,
    align: "center",
    render: (text, record) => (
      <div>{record?.testScores?.SATEnglish75Percentile ?? "NA"}</div>
    ),
  },

  {
    title: "Times Ranking",
    dataIndex: "timesRanking",
    key: "13",
    width: 140,
    align: "center",
    render: (text, record) => (
      <div>
        {record?.rankings?.TIMES ? `#${record?.rankings?.TIMES}` : "NA"}
      </div>
    ),
  },
  {
    title: "CWUR Ranking",
    dataIndex: "cwurRanking",
    key: "14",
    width: 140,
    align: "center",
    render: (text, record) => (
      <div>{record?.rankings?.CWUR ? `#${record?.rankings?.CWUR}` : "NA"}</div>
    ),
  },
  {
    title: "% Student Receiving Aid",
    dataIndex: "receiveAid",
    key: "15",
    width: 200,
    align: "center",
    render: (text, record) => (
      <div>
        {record?.percStudentsReceivingAid
          ? `${(parseFloat(record?.percStudentsReceivingAid) * 100).toFixed(
              2
            )}%`
          : "NA"}
      </div>
    ),
  },
  {
    title: "Average Financial Aid Granted Per Year",
    dataIndex: "avgAid",
    key: "16",
    width: 250,
    align: "center",
    render: (text, record) => (
      <div>
        {record?.avgFinancialAid
          ? Utils.numWithCommas(record?.avgFinancialAid)
          : "NA"}
      </div>
    ),
  },
  {
    title: "City Type",
    dataIndex: "cityType",
    key: "17",
    width: 130,
    align: "center",
    render: (text, record) => <div>{record?.CityType?.cityType ?? "NA"}</div>,
  },
  {
    title: "US Region",
    dataIndex: "usRegion",
    key: "18",
    width: 130,
    align: "center",
    render: (text, record) => (
      <div>{record?.country?.name === "USA" ? "Yes" : "No"}</div>
    ),
  },
  {
    title: "Research Output",
    dataIndex: "researchOutput",
    key: "19",
    width: 150,
    align: "center",
    render: (text, record) => <div>{record?.researchOutput ?? "NA"}</div>,
  },
];
const defaultCheckedList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const customIcons = {
  1: <FaDollarSign />,
  2: <FaDollarSign />,
  3: <FaDollarSign />,
  4: <FaDollarSign />,
  5: <FaDollarSign />,
};

const UniversityLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const viewMode = location.pathname.split("/")[2];

  const profileData = useSelector((state) => state?.profile?.user);
  const { universities, isLoading, count } = useSelector(
    (state) => state.university
  );
  const { uniSearch } = useSelector((state) => state.search);
  const { countries, programTypes, programs } = useSelector(
    (state) => state.others
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [gridView, setGridView] = useState(viewMode === "grid");
  const [budgetMin, setBudgetMin] = useState(null);
  const [budgetMax, setBudgetMax] = useState(null);
  const [pageNo, setPageNo] = useState(uniSearch?.pageNo || 1);
  const [filters, setFilters] = useState({
    limit: uniSearch?.limit || 10,
    offset: uniSearch?.offset || 0,
    search: uniSearch?.search || "",
    region: uniSearch?.region || [],
    areaOfStudy: uniSearch?.areaOfStudy || [],
    majors: uniSearch?.majors || [],
    entranceRequirements: uniSearch?.entranceRequirements || "",
    budget: uniSearch?.budget || "",
    scholarships: uniSearch?.scholarships || "",
    admissionRequirements: uniSearch?.admissionRequirements || "",
  });

  const countrylist = countries.map((country) => ({
    label: country.name,
    value: country.id,
  }));
  const programTypeslist = programTypes.map((programType) => ({
    label: programType.programType,
    value: programType.id,
  }));
  const programslist = programs.map((program) => ({
    label: program.programName,
    value: program.id,
  }));

  const options = [
    { label: "QS World Ranking", value: "2" },
    // { label: "QS Country Ranking", value: "3" },
    { label: "Times Ranking", value: "13" },
    { label: "CWUR Ranking", value: "14" },
    { label: "Average Fee", value: "4" },
    { label: "University Type", value: "5" },
    { label: "SAT Midrange", value: "7" },
    { label: "ACT Midrange", value: "6" },
    {
      label: (
        <>
          SAT 25<sup>th</sup> Percentile
        </>
      ),
      value: "11",
    },
    {
      label: (
        <>
          SAT 75<sup>th</sup> Percentile
        </>
      ),
      value: "12",
    },
    { label: "IELTS Requirement", value: "8" },
    { label: "TOEFL Requirement", value: "9" },
    { label: "Application Deadline", value: "10" },
    { label: "% Student Receiving Aid", value: "15" },
    { label: "Average Financial Aid Granted Per Year", value: "16" },
    { label: "City Type", value: "17" },
    { label: "US Region", value: "18" },
    { label: "Research Output", value: "19" },
  ];

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: item.key !== "1" && !checkedList?.includes(item.key),
  }));
  newColumns.unshift({
    title: "#",
    dataIndex: "serial",
    key: "01",
    fixed: "left",
    width: 70,
    render: (text, record, index) => (
      <div>{(pageNo - 1) * filters.limit + index + 1}</div>
    ),
  });

  useEffect(() => {
    if (budgetMin && budgetMax) {
      handleFilterChange({ budget: `${budgetMin}-${budgetMax}` });
    }
  }, [budgetMin, budgetMax]);

  useEffect(() => {
    const budget = uniSearch?.budget || "";
    const matchingValue = UtilityArrays?.budgetValues.find(
      (item) => item.value === budget
    );

    if (!matchingValue && budget) {
      const [min, max] = budget.split("-").map(Number);
      setBudgetMin(min.toString() || null);
      setBudgetMax(max.toString() || null);
    } else {
      setBudgetMin(null);
      setBudgetMax(null);
    }
  }, [uniSearch]);

  useEffect(() => {
    handleSearch();
  }, [uniSearch]);

  useEffect(() => {
    dispatch(
      SearchAction.uniSearch({
        ...filters,
        pageNo,
      })
    );
  }, [filters, pageNo]);

  useEffect(() => {
    dispatch(
      OthersAction.getPrograms({
        programTypes: JSON.stringify(filters.areaOfStudy),
      })
    );
  }, [filters?.areaOfStudy]);

  const handleSearch = async () => {
    const payload = {
      ...uniSearch,
      region: JSON.stringify(uniSearch.region),
      majors: JSON.stringify(uniSearch.majors),
      areaOfStudy: JSON.stringify(uniSearch.areaOfStudy),
    };
    dispatch(UniversityAction.getUniversities(payload));
  };

  const onShowSizeChange = (current, pageSize) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    }));
    setPageNo(current);
  };

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: (pageNumber - 1) * prevFilters.limit,
    }));
  };

  const handleViewChange = (isGridView) => {
    setGridView(isGridView);
    navigate(isGridView ? "/universities/grid" : "/universities/list");
  };

  const renderButton = (isGridView, label) =>
    !isGridView ? (
      <Buttons.DefaultButton
        size="small"
        onClick={() => handleViewChange(isGridView)}
      >
        {label}
      </Buttons.DefaultButton>
    ) : (
      <Buttons.WhiteButton
        size="small"
        onClick={() => handleViewChange(isGridView)}
      >
        {label}
      </Buttons.WhiteButton>
    );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleReset = async () => {
    setFilters({
      ...filters,
      offset: 0,
      search: "",
      region: [],
      areaOfStudy: [],
      majors: [],
      entranceRequirements: "",
      budget: "",
      scholarships: "",
      admissionRequirements: "",
    });
    setBudgetMin(null);
    setBudgetMax(null);
    setPageNo(1);
  };

  const handleFilterChange = (updatedFilter) => {
    setPageNo(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilter,
      offset: 0,
    }));
  };

  const filterButtons = [
    {
      title: "Countries",
      placement: "bottomLeft",
      items: (
        <div className="w-[400px]">
          <inputFields.SelectDropdownMultiple
            items={countrylist}
            onChange={(value) => handleFilterChange({ region: value })}
            value={filters.region}
            allowClear={true}
          />
        </div>
      ),
    },
    {
      title: "Area of Study",
      items: (
        <div className="w-[400px]">
          <inputFields.SelectDropdownMultiple
            items={programTypeslist}
            onChange={(value) =>
              handleFilterChange({ areaOfStudy: value, majors: [] })
            }
            value={filters.areaOfStudy}
            allowClear={true}
          />
        </div>
      ),
    },
    ...(filters.areaOfStudy.length > 0
      ? [
          {
            title: "Majors",
            items: (
              <div className="w-[400px]">
                <inputFields.SelectDropdownMultiple
                  items={programslist}
                  onChange={(value) => handleFilterChange({ majors: value })}
                  value={filters.majors}
                  allowClear={true}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      title: "Budget",
      items: (
        <div className="filter-dropdown">
          {UtilityArrays?.budgetValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setBudgetMin(null);
                setBudgetMax(null);
                handleFilterChange({ budget: item.value });
              }}
              className={`${
                filters.budget === item.value
                  ? "active-filter-value-list"
                  : "filter-value-list"
              }`}
            >
              {item.title}
            </div>
          ))}
          <div className="flex flex-col gap2 mt-2">
            <div>Custom</div>
            <div className="flex flex-col items-center">
              <div className="w-[150px]">
                <inputFields.DefaultInput
                  placeholder="Min"
                  type="number"
                  value={budgetMin ?? ""}
                  onChange={(e) => setBudgetMin(e.target.value.toString())}
                />
              </div>
              to
              <div className="w-[150px]">
                <inputFields.DefaultInput
                  placeholder="Max"
                  type="number"
                  value={budgetMax ?? ""}
                  onChange={(e) => setBudgetMax(e.target.value.toString())}
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Scholarships",
      items: (
        <div className="filter-dropdown">
          {UtilityArrays?.scholarshipValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleFilterChange({ scholarships: item.value })}
              className={`${
                filters.scholarships === item.value
                  ? "active-filter-value-list"
                  : "filter-value-list"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="universities">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Buttons.DefaultButton size="small">All</Buttons.DefaultButton>
          {/* <Buttons.DefaultButton size="small">
            University Tags
          </Buttons.DefaultButton> */}
        </div>
      </div>
      <Spacer height={20} />
      <div className="flex items-center justify-center gap-2">
        <inputFields.DefaultSearchBox
          onChange={(e) => {
            setPageNo(1);
            setFilters({
              ...filters,
              offset: 0,
              search: e.target.value,
            });
          }}
          value={filters.search}
          onKeyDown={handleKeyDown}
        />
        <div className="w-[120px]">
          <Buttons.DefaultButton
            size="medium"
            width="full"
            style={{ height: "48px" }}
            onClick={() => handleSearch()}
          >
            Search
          </Buttons.DefaultButton>
        </div>
        <div className="w-[120px]">
          <Buttons.RedButton
            style={{ height: "48px" }}
            size="medium"
            onClick={() => navigate("/explore")}
          >
            Explore
          </Buttons.RedButton>
        </div>
      </div>
      <Spacer height={20} />
      <div className="flex justify-between">
        <div className="flex gap-2">
          {filterButtons?.map((item, index) => (
            <popover.WhitePopover
              content={item.items}
              placement={item?.placement ?? "bottom"}
              key={index}
            >
              <Buttons.DefaultButton size="small">
                {item?.title}
              </Buttons.DefaultButton>
            </popover.WhitePopover>
          ))}
        </div>
        {Object.values(filters).some(
          (value) => value !== "" && value?.length > 0
        ) && (
          <div className="font-semibold cursor-pointer" onClick={handleReset}>
            Clear Filters
          </div>
        )}
      </div>

      {Object.values(filters).some(
        (value) => value !== "" && value?.length > 0
      ) && (
        <>
          <Spacer height={20} />
          <div>Filters:</div>
          <div className="flex flex-wrap items-center gap-2 py-3">
            {filters.search && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Search: {filters.search}
              </div>
            )}
            {filters.region.length > 0 && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Countries:{" "}
                {filters.region
                  .map((id) => countrylist.find((c) => c.value === id)?.label)
                  .join(", ")}
              </div>
            )}
            {filters.areaOfStudy.length > 0 && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Area of Study:{" "}
                {filters.areaOfStudy
                  .map(
                    (id) => programTypeslist.find((p) => p.value === id)?.label
                  )
                  .join(", ")}
              </div>
            )}
            {filters.majors.length > 0 && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Majors:{" "}
                {filters.majors
                  .map((id) => programslist.find((p) => p.value === id)?.label)
                  .join(", ")}
              </div>
            )}
            {filters.budget && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Budget:{" "}
                {UtilityArrays?.budgetValues.find(
                  (b) => b.value === filters.budget
                )?.title ?? `${uniSearch?.budget} $`}
              </div>
            )}
            {filters.scholarships && (
              <div className="px-3 py-1 bg-liteBoxBg border border-liteBoxBorder text-black rounded-lg">
                Scholarships:{" "}
                {
                  UtilityArrays?.scholarshipValues.find(
                    (s) => s.value === filters.scholarships
                  )?.title
                }
              </div>
            )}
            {/* Add more filters if needed */}
            {Object.values(filters).every(
              (value) => value === "" || value?.length === 0
            ) && (
              <div className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg">
                No filters applied.
              </div>
            )}
          </div>
        </>
      )}

      <Spacer height={20} />
      <boxContainer.UniversityBoxContainer>
        <div className="flex justify-between border-b border-b-liteBoxBorder px-4 py-3">
          <div className="flex items-center gap-3 ">
            <div className="font-bold text-[20px] pl-2">
              {count.toLocaleString()} Universities
            </div>
            {renderButton(true, "Grid View")}
            {renderButton(false, "List View")}
          </div>
          {!gridView && (
            <popover.WhitePopover
              content={
                <checkbox.VerticalCheckbox
                  value={checkedList}
                  options={options}
                  onChange={(value) => {
                    setCheckedList(value);
                  }}
                />
              }
            >
              <SortButton />
            </popover.WhitePopover>
          )}
        </div>
        <div className="w-[calc(100vw-320px)] overflow-x-auto max-w-full relative">
          {profileData?.freeUser && (
            <div className="absolute top-[400px] left-[35%] z-[10]">
              <GetProPopup />
            </div>
          )}
          <tables.DefaultTable
            columns={newColumns}
            data={universities}
            borderradius={0}
            rowKey={() => Math.random()}
            loading={isLoading}
            onRow={(record, index) => ({
              onClick: (event) => {
                if (event.ctrlKey) {
                  window.open(`/universities/general/${record.id}`, "_blank");
                } else {
                  navigate(`/universities/general/${record.id}`);
                }
              },
              style:
                profileData?.freeUser && index !== 0
                  ? { filter: "blur(14px)", pointerEvents: "none" }
                  : {},
            })}
          />
        </div>
        {!profileData?.freeUser && (
          <div className="flex justify-center items-center p-3">
            <Pagination
              responsive
              hideOnSinglePage={false}
              current={pageNo}
              pageSize={filters.limit}
              total={count}
              showSizeChanger
              pageSizeOptions={UtilityArrays?.pageSizes}
              onShowSizeChange={onShowSizeChange}
              onChange={handlePageChange}
            />
          </div>
        )}
      </boxContainer.UniversityBoxContainer>
    </div>
  );
};

export default UniversityLayout;
