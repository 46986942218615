import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";

import { FaLocationDot } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { WatchlistAction } from "../../store/actions";

import UniversityInfoModal from "./university-info-modal";
import { Images, Colors } from "../../config";

import DefaultButton from "../buttons/default-button";
import WhiteButton from "../buttons/white-button";

import "./styles.css";

const GridViewCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = props.data;
  const profileData = useSelector((state) => state?.profile?.user);

  const [showModal, setShowModal] = useState(false);

  const handleShortlist = () => {
    const payload = { id: data?.id };
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(payload, (res) => {
        dispatch(WatchlistAction.getWatchlist({}));
      })
    );
  };

  const popoverContent = (
    <div className="university-grid">
      <div
        className="w-full rounded-t-lg relative h-[140px]"
        style={{
          background: `url(${data?.campusPic ?? Images.DefaultUniCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50 rounded-t-lg"></div>
        <div className="relative p-3 flex flex-col gap-2 overflow-y-auto text-white">
          <div className="flex justify-between">
            <img
              src={data?.profilePic}
              className="h-[42px] w-[42px] border border-searchBorder shadow-boxContainer rounded-full object-contain bg-white cursor-pointer"
              onClick={(event) => {
                if (event.ctrlKey) {
                  window.open(`/universities/general/${data.id}`, "_blank");
                } else {
                  navigate(`/universities/general/${data.id}`);
                }
              }}
            />
            <IoIosInformationCircleOutline
              fontSize={20}
              onClick={() => setShowModal(true)}
              className="cursor-pointer"
            />
          </div>
          <div
            className="flex flex-col gap-2 cursor-pointer"
            onClick={(event) => {
              if (event.ctrlKey) {
                window.open(`/universities/general/${data.id}`, "_blank");
              } else {
                navigate(`/universities/general/${data.id}`);
              }
            }}
          >
            <div className="font-bold text-[14px] line-clamp-2 overflow-hidden text-ellipsis">
              {data?.name}
            </div>
            <div className="flex gap-1 items-center font-semibold">
              <div className="text-[12px] mb-1 text-white">
                <FaLocationDot />
              </div>
              <div className="flex items-center text-[10px]">
                {data?.city && `${data?.city}`}
                {data?.state && `, ${data?.state}`}
                {data?.country?.name && `, ${data?.country.name}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-2 py-3 gap-2">
        <div className="grid-popover-value-container">
          <div>Average Course Duration:</div>
          {data?.courseDuration ? (
            <div className="grid-popover-value">{data?.courseDuration}</div>
          ) : (
            "NA"
          )}
        </div>
        <div className="grid-popover-value-container">
          <div>Average Fee:</div>
          {data?.avgFees ? (
            <div className="grid-popover-value">{data?.avgFees}</div>
          ) : (
            "NA"
          )}
        </div>
        <div className="grid-popover-value-container">
          <div>Acceptance Rate:</div>
          {data?.acceptanceRate ? (
            <div className="grid-popover-value">
              {(data?.acceptanceRate * 100).toFixed(1)}%
            </div>
          ) : (
            "NA"
          )}
        </div>
        <div className="grid-popover-value-container">
          <div>Application Status:</div>
          {data?.deadlines?.length > 0 ? (
            data?.deadlines[0]?.appDeadline ? (
              new Date() > new Date(data?.deadlines[0]?.appDeadline) ? (
                <span className="bg-red-500 text-white font-medium px-1 pt-1 pb-0.5 rounded-md ml-1 text-[10px]">
                  CLOSED
                </span>
              ) : (
                <span className="bg-green-500 text-white font-medium px-1 pt-1 pb-0.5 rounded-lg ml-1 text-[10px]">
                  OPEN
                </span>
              )
            ) : (
              "NA"
            )
          ) : (
            "NA"
          )}
        </div>
        <div className="grid-popover-value-container">
          <div>Avg. Fresh Grad Salary:</div>
          {data?.freshGradSalary ? (
            <span className="grid-popover-value">{data?.freshGradSalary}</span>
          ) : (
            "NA"
          )}
        </div>
        <div className="flex gap-2">
          <Link className="w-full" to={data?.appLink ?? "#"} target="_blank">
            <DefaultButton size="extraSmall" width="full" radius="medium">
              Apply Now
            </DefaultButton>
          </Link>
          <WhiteButton
            size="extraSmall"
            width="full"
            radius="medium"
            onClick={handleShortlist}
            disabled={profileData?.freeUser}
          >
            {data?.watchlisted ? "Remove from Shortlist" : "Add to Shortlist"}
          </WhiteButton>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      content={popoverContent}
      trigger={"hover"}
      arrow={false}
      overlayInnerStyle={{
        position: "absolute",
        padding: "0px",
        top: "-237px",
        right: "-155px",
      }}
      onOpenChange={props.setIsPopoverOpen}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <div className="university-grid">
        <img
          src={data?.campusPic ?? Images.DefaultUniCover}
          className="w-full object-cover rounded-t-lg h-[130px] relative"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = Images.DefaultUniCover;
          }}
        />
        {props?.shownumber && (
          <div className="absolute w-full bg-[#0000005e] pl-5 text-white opacity-60 text-[92px] font-semibold h-[130px] rounded-t-md flex items-center">
            {props?.index + 1}
          </div>
        )}
        <div className="p-3 flex gap-[10px] h-[100px] overflow-hidden items-center">
          <img
            src={data?.profilePic}
            className="h-[42px] w-[42px] border border-searchBorder shadow-boxContainer rounded-full object-contain bg-white"
          />
          <div className="flex flex-col gap-2">
            <div
              className="font-bold text-[14px] line-clamp-2 overflow-hidden text-ellipsis"
              title={data?.name}
            >
              {data?.name}
            </div>
            <div className="flex gap-1 items-center font-semibold">
              <div className="text-[14px] mb-1 text-main">
                <FaLocationDot />
              </div>
              <div className="flex items-center text-[12px]">
                {data?.city && `${data?.city}`}
                {data?.state && `, ${data?.state}`}
                {data?.country?.name && `, ${data?.country.name}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UniversityInfoModal
        uniId={data.id}
        open={showModal}
        onCancel={() => setShowModal(false)}
      />
    </Popover>
  );
};

export default GridViewCard;
