import React from "react";

const DefaultTextbox = (props) => {
  const inputWidth = props?.width ? props.width : "100%";

  return (
    <div
      className="py-[11px] bg-white px-3 flex items-center !border !border-main rounded-[10px]"
      style={{ width: inputWidth, opacity: props?.disabled ? "0.7" : "1" }}
    >
      <textarea
        className="outline-none flex-grow"
        rows={4}
        placeholder={props?.placeholder ?? "Enter Value"}
        value={props?.value}
        onChange={props?.onChange}
        disabled={props?.disabled}
      />
    </div>
  );
};

export default DefaultTextbox;
