import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { UniversityAction } from "../actions";
import { DefaultNotification } from "../../components";

export default class UniversityMiddleware {
  static *getUniversities({ payload, cb }) {
    try {
      const {
        search,
        limit,
        offset,
        region,
        areaOfStudy,
        majors,
        budget,
        scholarships,
        sortby,
      } = payload;

      const res = yield ApiCaller.Get(
        `/university/search-university?search=${search ?? ""}&budget=${
          budget ?? ""
        }&limit=${limit ?? ""}&offset=${offset ?? ""}&region=${
          region ?? ""
        }&areaOfStudy=${areaOfStudy ?? ""}&majors=${
          majors ?? ""
        }&scholarships=${scholarships ?? ""}&sortby=${sortby ?? "qs"}`
      );

      if (res.status === 200) {
        yield put(UniversityAction.getUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversitiesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getUniversitiesFailure());
    }
  }
  static *getUniversitiesGrid({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/university/grid-view-results`);
      if (res.status === 200) {
        yield put(UniversityAction.getUniversitiesGridSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversitiesGridFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getUniversitiesGridFailure());
    }
  }
  static *getAllUniversities({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/university/getAllUniversities`);
      if (res.status === 200) {
        yield put(UniversityAction.getAllUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getAllUniversitiesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getAllUniversitiesFailure());
    }
  }
  static *getUnisByBudget({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/university/getTotalUniversitiesByBudget?currency=${
          payload.currency ?? "USD"
        }`
      );
      if (res.status === 200) {
        yield put(UniversityAction.getUnisByBudgetSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUnisByBudgetFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getUnisByBudgetFailure());
    }
  }
  static *getPopularCountries({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/university/getPopularCountries`);
      if (res.status === 200) {
        yield put(UniversityAction.getPopularCountriesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getPopularCountriesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getPopularCountriesFailure());
    }
  }
  static *getUniversityDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/university/get-university-details/${id}`
      );
      if (res.status === 200) {
        yield put(UniversityAction.getUniversityDetailsSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversityDetailsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.getUniversityDetailsFailure());
    }
  }

  static *compareUniverities({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        "/university/compare-university",
        payload
      );
      if (res.status === 200) {
        yield put(UniversityAction.compareUniveritiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.compareUniveritiesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.compareUniveritiesFailure());
    }
  }
  static *addOrRemoveStayConnected({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/university/add-to-stay-connected?uniId=${payload.id}`,
        payload
      );
      if (res.status === 200) {
        yield put(
          UniversityAction.addOrRemoveStayConnectedSuccess(res.data.data)
        );
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(UniversityAction.addOrRemoveStayConnectedFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(UniversityAction.addOrRemoveStayConnectedFailure());
    }
  }
}
