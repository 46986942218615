import React from "react";
import { useSwiper } from "swiper/react";

import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";

const TasksCarouselNavButton = ({ index, totalSlides, slidesPerView }) => {
  const swiper = useSwiper();
  const atEnd = index + slidesPerView >= totalSlides;

  return (
    <div className="flex justify-between gap-2 w-full">
      <button
        onClick={() => swiper.slidePrev()}
        className="flex gap-2 items-center justify-center"
        style={{ opacity: index === 0 ? "0.5" : "1" }}
      >
        <CiCircleChevLeft className="text-main text-[24px]" />
        <div className="font-medium">Previous</div>
      </button>

      <button
        onClick={() => swiper.slideNext()}
        className="flex gap-2 items-center justify-center"
        style={{ opacity: atEnd ? "0.5" : "1" }}
      >
        <CiCircleChevRight className="text-main text-[24px]" />
        <div className="font-medium">Next</div>
      </button>
    </div>
  );
};

export default TasksCarouselNavButton;
