import React, { useEffect, useState } from "react";

const QuestionnaireCircleInput = (props) => {
  const [circleValue, setCircleValue] = useState(null);

  useEffect(() => {
    setCircleValue(props?.value);
  }, [props]);

  const values = props?.courseduration ? [3, 4, 5] : [1, 2, 3, 4, 5];

  return (
    <div className="flex gap-3 pl-3">
      {values.map((value, index) => (
        <div
          className={`${
            circleValue === value ? "text-white bg-main" : "text-main bg-white"
          } questionnaire-circle-input`}
          key={index}
          onClick={() => props?.handleChange(value)}
          {...props}
        >
          {value}
        </div>
      ))}
    </div>
  );
};

export default QuestionnaireCircleInput;
