import React from "react";

const QuestionnaireTextbox = (props) => {
  return (
    <textarea
      className="w-full flex justify-between items-center p-2 bg-white border border-[#C5E0DC] rounded-xl outline-none"
      rows={4}
      {...props}
      placeholder={props?.placeholder ?? "Please enter your value"}
      value={props?.value ?? ""}
    />
  );
};

export default QuestionnaireTextbox;
