import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { MeetingAction } from "../actions";
import { DefaultNotification } from "../../components";

export default class MeetingMiddleware {
  static *addMeeting({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/meeting/add-meeting`, payload);
      if (res.status === 200) {
        yield put(MeetingAction.addMeetingSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(MeetingAction.addMeetingFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.addMeetingFailure());
    }
  }
  static *rescheduleMeeting({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/meeting/reschedule-meeting`, payload);
      if (res.status === 200) {
        yield put(MeetingAction.rescheduleMeetingSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(MeetingAction.rescheduleMeetingFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.rescheduleMeetingFailure());
    }
  }

  static *getMeetingDetails({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/meeting/getMeetingDetails?meetingId=${payload.id}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getMeetingDetailsSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getMeetingDetailsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingDetailsFailure());
    }
  }

  static *getMeetingInvites({ payload, cb }) {
    const { requestedBy, studentId, limit, offset } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/meeting/meetingInvites?requestedBy=${requestedBy}&studentId=${studentId}&limit=${
          limit ?? ""
        }&offset=${offset ?? ""}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getMeetingInvitesSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getMeetingInvitesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingInvitesFailure());
    }
  }
  static *getMeetingRequests({ payload, cb }) {
    const { requestedBy, studentId, limit, offset } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/meeting/meetingRequests?requestedBy=${requestedBy}&studentId=${studentId}&limit=${
          limit ?? ""
        }&offset=${offset ?? ""}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getMeetingRequestsSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getMeetingRequestsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingRequestsFailure());
    }
  }
  static *getUpcomingMeetings({ payload, cb }) {
    const { requestedBy, studentId, limit, offset } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/meeting/upcomingMeetings?requestedBy=${requestedBy}&studentId=${studentId}&limit=${
          limit ?? ""
        }&offset=${offset ?? ""}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getUpcomingMeetingsSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getUpcomingMeetingsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getUpcomingMeetingsFailure());
    }
  }
  static *getAllMeetingsAndCount({ payload, cb }) {
    const { requestedBy, studentId } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/meeting/fetchAllMeetingsAndCount?requestedBy=${requestedBy}&studentId=${studentId}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getAllMeetingsAndCountSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getAllMeetingsAndCountFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getAllMeetingsAndCountFailure());
    }
  }
  static *getMeetingsBasedOnDate({ payload, cb }) {
    const { requestedBy, studentId, fromDate, toDate } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/meeting/fetchMeetingBasedOnDate?requestedBy=${requestedBy}&studentId=${studentId}&fromDate=${
          fromDate ?? ""
        }&toDate=${toDate ?? ""}`
      );

      if (res.status === 200) {
        yield put(MeetingAction.getMeetingsBasedOnDateSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getMeetingsBasedOnDateFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingsBasedOnDateFailure());
    }
  }

  static *changeMeetingStatus({ payload, cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/meeting/change-meeting-status?meetingId=${payload.id}&status=${payload.status}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.changeMeetingStatusSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(MeetingAction.changeMeetingStatusFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(MeetingAction.changeMeetingStatusFailure());
    }
  }
}
