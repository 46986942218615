import React from "react";
import { Table, ConfigProvider } from "antd";

import "./styles.css";
const UniProgramsTable = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: props?.borderradius ?? 0,
            headerColor: "black",
          },
        },
      }}
    >
      <Table
        columns={props?.columns}
        dataSource={props?.data}
        bordered={props?.bordered ?? true}
        pagination={props?.pagination ?? false}
        className="uni-programs-table"
        rowClassName={(record, index) =>
          index % 2 !== 0
            ? `even-row ${!props?.cursornormal && "cursor-pointer"}`
            : `odd-row ${!props?.cursornormal && "cursor-pointer"}`
        }
        {...props}
      />
    </ConfigProvider>
  );
};

export default UniProgramsTable;
