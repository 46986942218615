import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import { Images } from "../../config";

const QuestionnaireDatepicker = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            activeBorderColor: "#c5e0dc",
            hoverBorderColor: "#c5e0dc",
            activeShadow: "none",
            borderRadius: "12px",
          },
        },
      }}
    >
      <DatePicker
        className="questionnaire-datepicker"
        suffixIcon={
          <img src={Images.QuestionnaireCalendar} className="h-[18px]" />
        }
        {...props}
      />
    </ConfigProvider>
  );
};

export default QuestionnaireDatepicker;
