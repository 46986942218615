import React from "react";

import { Spacer } from "../../components";

function PrivacyPolicy() {
  return (
    <div className="w-full max-h-[calc(100vh-220px)] overflow-auto">
      <div className="flex flex-col w-full p-4 justify-center">
        <div className="text-[24px] font-bold text-main">Privacy Policy</div>
        <Spacer height={20} />
        <div className="font-bold">Introduction</div>
        <Spacer height={10} />
        <div className="font-medium">
          Introduction UniVision (hereinafter referred to as the “Company”), is
          committed to providing the highest level of security and online
          privacy. “Personal Information” means any information that may be used
          to identify an individual, including, but not limited to, a first and
          last name, phone number and an email address or other identifiable
          contact information.
        </div>
        <div className="font-medium">
          In general, you can visit the UniVision website and webpages without
          telling us who you are or revealing any Personal Information about
          yourself in the process. Personal Information may be collected through
          the submission of service requests, online banking transactions or
          through the use of online financial tools.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          This Privacy Policy demonstrates our commitment to protecting
          information that the users share with us. This Privacy Policy applies
          to data gathered on the website & webpages. This Privacy Policy shall
          be reviewed periodically as we may update it from time to time, so we
          recommend reviewing this accordingly. Each time the user accesses the
          UniVision or provides us with information, the user is, by doing so,
          effectively consenting to the practices described in this Privacy
          Policy at that time.
        </div>
        <Spacer height={30} />
        <div className="font-bold">Respect of right to privacy</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision maintains strict policies and security controls to ensure
          that user information contained in the systems and files is protected.
          UniVision respects user’s right to privacy. UniVision is committed to
          ensuring the privacy of its users’ personal information.
        </div>
        <Spacer height={30} />
        <div className="font-bold">Privacy Policy and our Cookie Policy</div>
        <Spacer height={10} />
        <div className="font-medium">
          At UniVision, we use cookies only for the protection and convenience
          of our users. Cookies enable us to serve secure pages to our users
          without asking them to sign in repeatedly. Once you agree to allow our
          website and webpages to use cookies, you also agree to our usage of
          the data it collects. This may include data that enables us to analyze
          web traffic, the web pages you spend the most time on, and the
          websites you visit.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          The data we collect by using cookies is used to customize our website
          to your needs. After we use the data for statistical analysis, the
          data is completely removed from our systems routinely. Please note
          that cookies don’t allow us to gain control of your computer in any
          way or form. They are strictly used to monitor which pages you find
          useful and which you do not so that we can provide a better experience
          for you.
        </div>
        <Spacer height={30} />
        <div className="font-bold">
          A limited employee access to user’s personal information
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision limits its access to your personal information to only
          employees who hold a business reason for having access to user’s
          information. UniVision employees are aware about the importance of
          confidentiality and customer privacy.
        </div>
        <Spacer height={30} />
        <div className="font-bold">Third Party disclosure restrictions</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision follows strict privacy procedures in protecting your
          personal information. Third parties provide certain services available
          on the School Admin portal such as payment gateway services on
          UniVision’s behalf. UniVision may provide information, including
          Personal Information, that the Company collects on the Web to
          third-party service providers to help us deliver programs, products,
          information, and services. Additionally, service providers are also an
          important means by which the Company maintains its Web site and
          mailing lists.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          Any Personal Information will be protected and UniVision will take
          reasonable measures to ensure that these third-party service providers
          are obligated to protect Personal Information on the Company’s behalf.
          However, UniVision does not assume responsibility for any action of a
          third party regarding this information in the case of a data breach.
          The Company does not intend to transfer Personal Information without
          your consent to third parties who are not bound to act on UniVision
          behalf unless such transfer is legally required. Neither does
          UniVision provide personal information to other companies for the
          purpose of independent telemarketing or direct mail marketing of any
          non-financial products or services of those companies.
        </div>
        <Spacer height={30} />
        <div className="font-bold">User’s Consent</div>
        <Spacer height={10} />
        <div className="font-medium">
          By using the UniVision mobile and web applications, the user consents
          to the terms of UniVision’s Online Privacy Policy and to UniVision’s
          processing of Personal Information for the purposes given previously.
          At UniVision, we take appropriate security measures to protect against
          unauthorized access to or unauthorized alteration, disclosure or
          destruction of data on a best effort basis. We restrict access to our
          users personal identifying information to employees who need to know
          that information to operate or improve our services.
        </div>
        <Spacer height={30} />
        <div className="font-bold">Changes to this Privacy Policy</div>
        <Spacer height={10} />
        <div className="font-medium">
          UniVision reserves the right to update, change or modify this policy
          at any time. The policy shall come to effect from the date of such
          update, change or modification.
        </div>
        <Spacer height={30} />
        <div className="font-bold">Contact Information</div>
        <Spacer height={10} />
        <div className="font-medium">
          If you have additional questions regarding the privacy and security of
          your personal information, consider referring to UniVision’s Terms and
          Conditions, however, if the query persists, please contact us on{" "}
          <a
            className="text-primary font-semibold"
            href="mailto:admin@univision.hk"
          >
            admin@univision.hk
          </a>
          . UniVision welcomes your comments regarding this Privacy Policy.
        </div>
        <Spacer height={10} />
        <div className="font-medium">
          However, it is clarified that UniVision is not obligated to comply
          with any requests or changes suggested by the User and UniVision shall
          have the exclusive decision-making right in this regard.
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
