import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { Images, Utils } from "../../config";

import { boxContainer, Buttons, Spacer, GetProPopup } from "../../components";

const Admission = (props) => {
  const data = props?.uniData;

  const testScoresData = [
    {
      icon: Images.Uniadmissionsat,
      title: "SAT (25%)",
      value:
        data?.testScores?.SATEnglish25Percentile ||
        data?.testScores?.SATMath25Percentile
          ? Utils.numWithCommas(
              (
                (data?.testScores?.SATEnglish25Percentile ?? 0) +
                (data?.testScores?.SATMath25Percentile ?? 0)
              ).toString()
            )
          : "NA",
    },
    {
      icon: Images.Uniadmissionsat,
      title: "SAT (50%)",
      value: data?.testScores?.SATMidrange
        ? Utils.numWithCommas(data?.testScores?.SATMidrange)
        : "NA",
    },
    {
      icon: Images.Uniadmissionsat,
      title: "SAT (75%)",
      value:
        data?.testScores?.SATEnglish75Percentile ||
        data?.testScores?.SATMath75Percentile
          ? Utils.numWithCommas(
              (
                (data?.testScores?.SATEnglish75Percentile ?? 0) +
                (data?.testScores?.SATMath75Percentile ?? 0)
              ).toString()
            )
          : "NA",
    },
    {
      icon: Images.Uniadmissionact,
      title: "ACT (50%)",
      value: data?.testScores?.ACTMidrange ?? "NA",
    },
  ];
  const englishTestData = [
    {
      icon: Images.Uniadmissionielts,
      value: data?.requirements?.IELTS
        ? data?.requirements?.IELTS === "Yes"
          ? "Required"
          : data?.requirements?.IELTS === "No"
          ? "Not Required"
          : data?.requirements?.IELTS
        : "NA",
      subValue: data?.testScores?.IELTS,
    },
    {
      icon: Images.Uniadmissiontoefl,
      value: data?.requirements?.TOEFL
        ? data?.requirements?.TOEFL === "Yes"
          ? "Required"
          : data?.requirements?.TOEFL === "No"
          ? "Not Required"
          : data?.requirements?.TOEFL
        : "NA",
      subValue: data?.testScores?.TOEFL,
    },
  ];
  return (
    <div className="relative">
      {props?.blur && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div
        className={`grid grid-cols-9 gap-[20px] ${
          props?.blur && "blur pointer-events-none"
        }`}
      >
        <div className="flex flex-col gap-[10px] col-span-4">
          <div className="flex gap-2 flex-col row-span-2 ">
            <div className="box-title">Eligibility Criteria</div>
            <boxContainer.LiteBoxContainer>
              <div className="flex flex-col gap-3">
                <div className="text-[16px] font-bold">
                  To be considered for admission to the university the applicant
                  must fulfill the below mentioned criteria:
                </div>
                <div className="h-[2px] bg-gray-300"></div>
                <div className="flex flex-col gap-3">
                  {data?.eligibility?.map((item, index) => (
                    <div
                      className="box-content-container items-start"
                      key={index}
                    >
                      <img
                        src={Images.GreenCheck}
                        className="h-[24px] w-[24px]"
                      />
                      <div className="box-content-title text-gray-500 font-medium">
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
                {(data?.admissions?.eligibilityDomesticLink ||
                  data?.admissions?.eligibilityInternationalLink) && (
                  <div className="text-[16px] font-semibold pl-[40px]">
                    For more details refer to the following links:
                  </div>
                )}
                <div className="flex gap-2 pl-[40px]">
                  {data?.admissions?.eligibilityDomesticLink && (
                    <Link
                      to={data?.admissions?.eligibilityDomesticLink ?? "#"}
                      target="_blank"
                    >
                      <Buttons.DefaultButton size="medium">
                        Domestic Applicant
                      </Buttons.DefaultButton>
                    </Link>
                  )}
                  {data?.admissions?.eligibilityInternationalLink && (
                    <Link
                      to={data?.admissions?.eligibilityInternationalLink ?? "#"}
                      target="_blank"
                    >
                      <Buttons.DefaultButton size="medium">
                        International Applicant
                      </Buttons.DefaultButton>
                    </Link>
                  )}
                </div>
                <Spacer height={10} />
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
          <div className="flex gap-2 flex-col row-span-2">
            <div className="box-title">Test Scores</div>
            <boxContainer.LiteBoxContainer>
              <div className="flex justify-around items-center pb-3 border-b-[2px] border-b-gray-300">
                {testScoresData?.map((item, index) => (
                  <div
                    className="flex flex-col gap-2 items-center justify-center"
                    key={index}
                  >
                    <img src={item?.icon} className="h-[70px] w-[70px]" />
                    <div className="box-content-title pb-2 border-b-[2px] border-b-gray-300 w-full text-center">
                      {item.title}
                    </div>
                    <div className="box-content-value">{item.value}</div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-4 py-3">
                <div className="box-title pl-0">English Test Requirement</div>
                <div className="flex items-center">
                  {englishTestData?.map((item, index) => (
                    <div
                      className="flex gap-2 items-center  flex-1"
                      key={index}
                    >
                      <img src={item?.icon} className="h-[70px] w-[70px]" />

                      <div>
                        <div className="box-content-value">{item.value}</div>
                        <div className="box-content-value">{item.subValue}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
          <div>
            <div className="box-title">At a Glance</div>
            <div className="flex gap-4">
              <boxContainer.LiteBoxContainer addstyle="w-full">
                <div className="flex items-center justify-center gap-3 w-full py-1">
                  <img
                    className="h-[48px] w-[48px]"
                    src={Images.Uniadmissionavgfee}
                  />
                  <div>
                    <div className="box-content-title">Average Fee</div>
                    <div className="box-content-value">
                      {data?.avgFees ?? "NA"}
                    </div>
                  </div>
                </div>
              </boxContainer.LiteBoxContainer>
              <boxContainer.LiteBoxContainer addstyle="w-full">
                <div className="flex items-center justify-center gap-3 w-full py-1">
                  <img
                    className="h-[48px] w-[48px]"
                    src={Images.Unigeneralappdeadline}
                  />
                  <div>
                    <div className="box-content-title">
                      {data?.deadlines[0]?.deadlineType ??
                        "Application Deadline"}
                    </div>
                    <div className="box-content-value">
                      {data?.deadlines[0]?.appDeadline
                        ? moment(data?.deadlines[0]?.appDeadline).format(
                            "DD MMMM, YYYY"
                          )
                        : "NA"}
                    </div>
                  </div>
                </div>
              </boxContainer.LiteBoxContainer>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[20px] col-span-5">
          <div className="flex gap-2 flex-col row-span-2">
            <div className="box-title">Scholarship Availibility</div>
            <boxContainer.LiteBoxContainer>
              <div className="flex flex-col gap-3">
                <div className="text-[16px] font-bold">
                  Admission to the University is based on a holistic review of
                  grades, GPA, the strength of curriculum, test scores (if
                  submitted), recommendation letters, personal essays,
                  co-curricular and extracurricular activities, talent and
                  personal background.
                </div>
                <div className="text-main text-[16px] font-bold">
                  Steps to Apply
                </div>
                <div className="flex flex-col gap-3">
                  {data?.howToApply?.map((item, index) => (
                    <div
                      className="box-content-container  items-start"
                      key={index}
                    >
                      <img
                        src={Images.GreenCheck}
                        className="h-[24px] w-[24px]"
                      />
                      <div className="box-content-title text-gray-500 font-medium">
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
                {(data?.admissions?.howToApplyDomesticLink ||
                  data?.admissions?.howToApplyInternationLink) && (
                  <div className="text-[16px] font-semibold pl-[40px]">
                    For more details refer to the following links:
                  </div>
                )}
                <div className="flex gap-2 pl-[40px]">
                  {data?.admissions?.howToApplyDomesticLink && (
                    <Link
                      to={data?.admissions?.howToApplyDomesticLink ?? "#"}
                      target="_blank"
                    >
                      <Buttons.DefaultButton size="medium">
                        Domestic Applicant
                      </Buttons.DefaultButton>
                    </Link>
                  )}
                  {data?.admissions?.howToApplyInternationLink && (
                    <Link
                      to={data?.admissions?.howToApplyInternationLink ?? "#"}
                      target="_blank"
                    >
                      <Buttons.DefaultButton size="medium">
                        International Applicant
                      </Buttons.DefaultButton>
                    </Link>
                  )}
                </div>
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
          <div className="flex gap-2 flex-col row-span-2">
            <div className="box-title">Recommendation Letter</div>
            <boxContainer.LiteBoxContainer>
              <div className="flex flex-col gap-3">
                <div className="text-[16px] font-bold">
                  To be considered for admission to the university the applicant
                  must fulfill the below mentioned criteria
                </div>
                <div className="h-[2px] bg-gray-300"></div>
                <div className="flex flex-col gap-3">
                  {data?.recLetter?.map((item, index) => (
                    <div
                      className="box-content-container items-start"
                      key={index}
                    >
                      <img
                        src={Images.GreenCheck}
                        className="h-[24px] w-[24px]"
                      />
                      <div className="box-content-title text-gray-500 font-medium">
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;
