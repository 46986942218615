import { put } from "redux-saga/effects";

import { SearchAction } from "../actions";

export default class SearchMiddleware {
  static *uniSearch({ payload }) {
    try {
      yield put(SearchAction.uniSearchSuccess(payload));
    } catch (err) {
      yield put(SearchAction.uniSearchFailure());
    }
  }
  static *shortlistSearch({ payload }) {
    try {
      yield put(SearchAction.shortlistSearchSuccess(payload));
    } catch (err) {
      yield put(SearchAction.shortlistSearchFailure());
    }
  }
  static *exploreSearch({ payload }) {
    try {
      yield put(SearchAction.exploreSearchSuccess(payload));
    } catch (err) {
      yield put(SearchAction.exploreSearchFailure());
    }
  }
}
