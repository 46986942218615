import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { OthersAction } from "../actions";

export default class OthersMiddleware {
  static *getCountries({ cb }) {
    try {
      const res = yield ApiCaller.Get("/other/get-countries");
      if (res.status === 200) {
        yield put(OthersAction.getCountriesSuccess(res.data.data));
      } else {
        yield put(OthersAction.getCountriesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getCountriesFailure());
    }
  }
  static *getSchools({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/school/getSchoolList`);
      if (res.status === 200) {
        yield put(OthersAction.getSchoolsSuccess(res.data.data));
      } else {
        yield put(OthersAction.getSchoolsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getSchoolsFailure());
    }
  }
  static *getPopularSubs({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/other/get-popular-subjects`);
      if (res.status === 200) {
        yield put(OthersAction.getPopularSubsSuccess(res.data.data));
      } else {
        yield put(OthersAction.getPopularSubsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getPopularSubsFailure());
    }
  }
  static *getTasks({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/other/get-tasks`);
      if (res.status === 200) {
        yield put(OthersAction.getTasksSuccess(res.data.data));
      } else {
        yield put(OthersAction.getTasksFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getTasksFailure());
    }
  }
  static *getSuggestions({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/other/get-suggestions`);
      if (res.status === 200) {
        yield put(OthersAction.getSuggestionsSuccess(res.data.data));
      } else {
        yield put(OthersAction.getSuggestionsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getSuggestionsFailure());
    }
  }
  static *updateEssayGraderCount({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/other/update-essay-grader-count`);
      if (res.status === 200) {
        yield put(OthersAction.updateEssayGraderCountSuccess(res.data.data));
      } else {
        yield put(OthersAction.updateEssayGraderCountFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.updateEssayGraderCountFailure());
    }
  }
  static *getPaymentPlans({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/other/get-payment-plans`);
      if (res.status === 200) {
        yield put(OthersAction.getPaymentPlansSuccess(res.data.data));
      } else {
        yield put(OthersAction.getPaymentPlansFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getPaymentPlansFailure());
    }
  }
  static *getPrograms({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/other/get-programs?programTypes=${payload.programTypes}`
      );
      if (res.status === 200) {
        yield put(OthersAction.getProgramsSuccess(res.data.data));
      } else {
        yield put(OthersAction.getProgramsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getProgramsFailure());
    }
  }
  static *getTopStories({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/topStories/get-top-stories`);
      if (res.status === 200) {
        yield put(OthersAction.getTopStoriesSuccess(res.data.data));
      } else {
        yield put(OthersAction.getTopStoriesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getTopStoriesFailure());
    }
  }
  static *getOnlineUsers({ payload, cb }) {
    try {
      yield put(OthersAction.getOnlineUsersSuccess(payload));
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(OthersAction.getOnlineUsersFailure());
    }
  }
  static *getProgramTypes({ cb }) {
    try {
      const res = yield ApiCaller.Get("/other/get-program-types");
      if (res.status === 200) {
        yield put(OthersAction.getProgramTypesSuccess(res.data.data));
      } else {
        yield put(OthersAction.getProgramTypesFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getProgramTypesFailure());
    }
  }
  static *getVisaInfo({ cb }) {
    try {
      const res = yield ApiCaller.Get("/other/get-visa-info");
      if (res.status === 200) {
        yield put(OthersAction.getVisaInfoSuccess(res.data.data));
      } else {
        yield put(OthersAction.getVisaInfoFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.getVisaInfoFailure());
    }
  }
  static *uploadImage({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/other/upload-image", payload);
      if (res.status === 200) {
        yield put(OthersAction.uploadImageSuccess(res.data.data));
      } else {
        yield put(OthersAction.uploadImageFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.uploadImageFailure());
    }
  }
  static *uploadFile({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/other/upload-file", payload);
      if (res.status === 200) {
        yield put(OthersAction.uploadFileSuccess(res.data.data));
      } else {
        yield put(OthersAction.uploadFileFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(OthersAction.uploadFileFailure());
    }
  }
}
