import { notification } from "antd";
import Lottie from "react-lottie-player";
import { Images } from "../config";

const DefaultNotification = (message, type, placement) => {
  notification.open({
    message: (
      <div className="flex gap-3 items-center">
        {type === "success" && (
          <Lottie
            loop
            animationData={Images.Done}
            play
            style={{ width: "50px" }}
          ></Lottie>
        )}
        <div className="text-[18px] font-semibold">{message ?? ""}</div>
      </div>
    ),
    placement: placement ?? "bottom",
    duration: 3,
    closable: false,
  });
};

export default DefaultNotification;
