import React from "react";
import { Progress } from "antd";

import { Colors } from "../../config";

const ProgressBar = (props) => {
  return (
    <Progress
      type="circle"
      percent={props?.value ?? 0}
      strokeColor={Colors.main}
      size={100}
      format={(percent) => (
        <div className="text-main font-bold text-[32px]">{percent}%</div>
      )}
      trailColor={Colors.liteBoxBorder}
    />
  );
};

export default ProgressBar;
