import React from "react";
import { Table, ConfigProvider } from "antd";
import { Colors } from "../../config";

import "./styles.css";
const DefaultTable = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: Colors.main,
            headerBorderRadius: props?.borderradius ?? 12,
            headerColor: "white",
          },
        },
      }}
    >
      <Table
        columns={props?.columns}
        dataSource={props?.data}
        bordered={props?.bordered ?? true}
        pagination={props?.pagination ?? false}
        scroll={{ x: 1500 }}
        className="default-table"
        rowClassName={(record, index) =>
          index % 2 !== 0 ? "even-row cursor-pointer" : "odd-row cursor-pointer"
        }
        {...props}
      />
    </ConfigProvider>
  );
};

export default DefaultTable;
