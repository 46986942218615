import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
  GET_ONLINE_USERS,
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAILURE,
  GET_PROGRAM_TYPES,
  GET_PROGRAM_TYPES_SUCCESS,
  GET_PROGRAM_TYPES_FAILURE,
  GET_TOP_STORIES,
  GET_TOP_STORIES_SUCCESS,
  GET_TOP_STORIES_FAILURE,
  GET_POPULAR_SUBS,
  GET_POPULAR_SUBS_SUCCESS,
  GET_POPULAR_SUBS_FAILURE,
  GET_PROGRAMS,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  GET_VISA_INFO,
  GET_VISA_INFO_SUCCESS,
  GET_VISA_INFO_FAILURE,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  UPDATE_ESSAY_GRADER_COUNT,
  UPDATE_ESSAY_GRADER_COUNT_SUCCESS,
  UPDATE_ESSAY_GRADER_COUNT_FAILURE,
  GET_PAYMENT_PLANS,
  GET_PAYMENT_PLANS_SUCCESS,
  GET_PAYMENT_PLANS_FAILURE,
} from "../constants";

const initialState = {
  countries: [],
  programTypes: [],
  programs: [],
  onlineUsers: [],
  visaInfo: [],
  schools: [],
  topStories: [],
  tasks: [],
  taskPercentage: 0,
  suggestions: [],
  topStories: [],
  popularSubjects: [],
  paymentPlans: [],
  isCountryLoading: false,
  isProgramLoading: false,
  isUploadLoading: false,
  isVisaLoading: false,
  isSchoolLoading: false,
  isTopStoriesLoading: false,
  isTasksLoading: false,
  isSuggestionsLoading: false,
  isUpdateEssayLoading: false,
  isPopularSubsLoading: false,
  isPaymentPlansLoading: false,
  error: null,
};

export default function OthersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      state = {
        ...state,
        isCountryLoading: true,
        error: null,
      };
      break;
    case GET_SCHOOLS:
      state = {
        ...state,
        isSchoolLoading: true,
        error: null,
      };
      break;
    case GET_TOP_STORIES:
      state = {
        ...state,
        isTopStoriesLoading: true,
        error: null,
      };
      break;
    case GET_POPULAR_SUBS:
      state = {
        ...state,
        isPopularSubsLoading: true,
        error: null,
      };
      break;
    case GET_TASKS:
      state = {
        ...state,
        isTasksLoading: true,
        error: null,
      };
      break;
    case GET_SUGGESTIONS:
      state = {
        ...state,
        isSuggestionsLoading: true,
        error: null,
      };
      break;
    case GET_PAYMENT_PLANS:
      state = {
        ...state,
        isPaymentPlansLoading: true,
        error: null,
      };
      break;
    case GET_ONLINE_USERS:
      state = {
        ...state,
        error: null,
      };
      break;
    case GET_PROGRAM_TYPES:
    case GET_PROGRAMS:
      state = {
        ...state,
        isProgramLoading: true,
        error: null,
      };
      break;
    case GET_VISA_INFO:
      state = {
        ...state,
        isVisaLoading: true,
        error: null,
      };
      break;
    case UPDATE_ESSAY_GRADER_COUNT:
      state = {
        ...state,
        isUpdateEssayLoading: true,
        error: null,
      };
      break;
    case UPLOAD_FILE:
    case UPLOAD_IMAGE:
      state = {
        ...state,
        isUploadLoading: true,
        error: null,
      };
      break;
    case GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        countries: action?.payload,
        isCountryLoading: false,
      };
      break;
    case GET_PAYMENT_PLANS_SUCCESS:
      state = {
        ...state,
        paymentPlans: action?.payload,
        isPaymentPlansLoading: false,
      };
      break;
    case GET_SCHOOLS_SUCCESS:
      state = {
        ...state,
        schools: action?.payload,
        isSchoolLoading: false,
      };
      break;
    case GET_POPULAR_SUBS_SUCCESS:
      state = {
        ...state,
        popularSubjects: action?.payload,
        isPopularSubsLoading: false,
      };
      break;
    case UPDATE_ESSAY_GRADER_COUNT_SUCCESS:
      state = {
        ...state,
        isUpdateEssayLoading: false,
        error: null,
      };
      break;
    case GET_TASKS_SUCCESS:
      state = {
        ...state,
        tasks: action?.payload?.tasks,
        taskPercentage: action?.payload?.taskPercentage,
        isTasksLoading: false,
      };
      break;
    case GET_SUGGESTIONS_SUCCESS:
      state = {
        ...state,
        suggestions: action?.payload,
        isSuggestionsLoading: false,
      };
      break;
    case GET_TOP_STORIES_SUCCESS:
      state = {
        ...state,
        topStories: action?.payload,
        isTopStoriesLoading: false,
      };
      break;
    case GET_ONLINE_USERS_SUCCESS:
      state = {
        ...state,
        onlineUsers: action?.payload?.users,
      };
      break;
    case GET_PROGRAM_TYPES_SUCCESS:
      state = {
        ...state,
        programTypes: action?.payload,
        isProgramLoading: false,
      };
      break;
    case GET_PROGRAMS_SUCCESS:
      state = {
        ...state,
        programs: action?.payload,
        isProgramLoading: false,
      };
      break;
    case GET_VISA_INFO_SUCCESS:
      state = {
        ...state,
        visaInfo: action?.payload,
        isVisaLoading: false,
      };
      break;
    case UPLOAD_FILE_SUCCESS:
    case UPLOAD_IMAGE_SUCCESS:
      state = {
        ...state,
        isUploadLoading: false,
        error: null,
      };
      break;
    case GET_COUNTRIES_FAILURE:
      state = {
        ...state,
        isCountryLoading: false,
        error: action.payload,
      };
      break;
    case GET_SCHOOLS_FAILURE:
      state = {
        ...state,
        isSchoolLoading: false,
        error: action.payload,
      };
      break;
    case GET_PAYMENT_PLANS_FAILURE:
      state = {
        ...state,
        isPaymentPlansLoading: false,
        error: action.payload,
      };
      break;
    case UPDATE_ESSAY_GRADER_COUNT_FAILURE:
      state = {
        ...state,
        isUpdateEssayLoading: false,
        error: null,
      };
      break;
    case GET_TASKS_FAILURE:
      state = {
        ...state,
        isTasksLoading: false,
        error: action.payload,
      };
      break;
    case GET_SUGGESTIONS_FAILURE:
      state = {
        ...state,
        isSuggestionsLoading: false,
        error: action.payload,
      };
      break;
    case GET_TOP_STORIES_FAILURE:
      state = {
        ...state,
        isTopStoriesLoading: false,
        error: action.payload,
      };
      break;
    case GET_PROGRAM_TYPES_FAILURE:
    case GET_PROGRAMS_FAILURE:
      state = {
        ...state,
        isProgramLoading: false,
        error: action.payload,
      };
      break;
    case GET_VISA_INFO_FAILURE:
      state = {
        ...state,
        isVisaLoading: false,
        error: action.payload,
      };
      break;
    case GET_POPULAR_SUBS_FAILURE:
      state = {
        ...state,
        isPopularSubsLoading: false,
        error: action.payload,
      };
      break;
    case GET_ONLINE_USERS_FAILURE:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case UPLOAD_FILE_FAILURE:
    case UPLOAD_IMAGE_FAILURE:
      state = {
        ...state,
        isUploadLoading: false,
        error: null,
      };
      break;
    default:
      break;
  }
  return state;
}
