import PieChartUniGeneral from "./pie-chart-unigeneral";
import PieChartPopularSubs from "./pie-chart-popular-subs";
import HorizontalBarChart from "./horizontal-bar-chart";
import VerticalBarChart from "./vertical-bar-chart";

export default {
  PieChartUniGeneral,
  PieChartPopularSubs,
  HorizontalBarChart,
  VerticalBarChart,
};
