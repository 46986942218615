import {
  GET_UDEMY_COURSES,
  GET_UDEMY_COURSES_SUCCESS,
  GET_UDEMY_COURSES_FAILURE,
} from "../constants";

export default class InteractiveLearningAction {
  static getUdemyCourses(payload, cb) {
    return {
      type: GET_UDEMY_COURSES,
      payload,
      cb,
    };
  }
  static getUdemyCoursesSuccess(payload) {
    return {
      type: GET_UDEMY_COURSES_SUCCESS,
      payload,
    };
  }
  static getUdemyCoursesFailure(payload) {
    return {
      type: GET_UDEMY_COURSES_FAILURE,
      payload,
    };
  }
}
