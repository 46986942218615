import {
  ADD_MEETING,
  ADD_MEETING_FAILURE,
  ADD_MEETING_SUCCESS,
  CHANGE_MEETING_STATUS,
  CHANGE_MEETING_STATUS_FAILURE,
  CHANGE_MEETING_STATUS_SUCCESS,
  GET_MEETINGS_INVITES,
  GET_MEETINGS_INVITES_SUCCESS,
  GET_MEETINGS_INVITES_FAILURE,
  GET_MEETINGS_REQUESTS,
  GET_MEETINGS_REQUESTS_SUCCESS,
  GET_MEETINGS_REQUESTS_FAILURE,
  GET_UPCOMING_MEETINGS,
  GET_UPCOMING_MEETINGS_SUCCESS,
  GET_UPCOMING_MEETINGS_FAILURE,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
  GET_ALL_MEETINGS_AND_COUNT_FAILURE,
  GET_MEETINGS_BASED_ON_DATE,
  GET_MEETINGS_BASED_ON_DATE_SUCCESS,
  GET_MEETINGS_BASED_ON_DATE_FAILURE,
  RESCHEDULE_MEETING,
  RESCHEDULE_MEETING_SUCCESS,
  RESCHEDULE_MEETING_FAILURE,
  GET_MEETING_DETAILS,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_FAILURE,
} from "../constants";

export default class MeetingAction {
  static addMeeting(payload, cb) {
    return {
      type: ADD_MEETING,
      payload,
      cb,
    };
  }
  static addMeetingSuccess(payload) {
    return {
      type: ADD_MEETING_SUCCESS,
      payload,
    };
  }
  static addMeetingFailure(payload) {
    return {
      type: ADD_MEETING_FAILURE,
      payload,
    };
  }
  static rescheduleMeeting(payload, cb) {
    return {
      type: RESCHEDULE_MEETING,
      payload,
      cb,
    };
  }
  static rescheduleMeetingSuccess(payload) {
    return {
      type: RESCHEDULE_MEETING_SUCCESS,
      payload,
    };
  }
  static rescheduleMeetingFailure(payload) {
    return {
      type: RESCHEDULE_MEETING_FAILURE,
      payload,
    };
  }
  static getMeetingDetails(payload, cb) {
    return {
      type: GET_MEETING_DETAILS,
      payload,
      cb,
    };
  }
  static getMeetingDetailsSuccess(payload) {
    return {
      type: GET_MEETING_DETAILS_SUCCESS,
      payload,
    };
  }
  static getMeetingDetailsFailure(payload) {
    return {
      type: GET_MEETING_DETAILS_FAILURE,
      payload,
    };
  }
  static getMeetingInvites(payload, cb) {
    return {
      type: GET_MEETINGS_INVITES,
      payload,
      cb,
    };
  }
  static getMeetingInvitesSuccess(payload) {
    return {
      type: GET_MEETINGS_INVITES_SUCCESS,
      payload,
    };
  }
  static getMeetingInvitesFailure(payload) {
    return {
      type: GET_MEETINGS_INVITES_FAILURE,
      payload,
    };
  }
  static getMeetingRequests(payload, cb) {
    return {
      type: GET_MEETINGS_REQUESTS,
      payload,
      cb,
    };
  }
  static getMeetingRequestsSuccess(payload) {
    return {
      type: GET_MEETINGS_REQUESTS_SUCCESS,
      payload,
    };
  }
  static getMeetingRequestsFailure(payload) {
    return {
      type: GET_MEETINGS_REQUESTS_FAILURE,
      payload,
    };
  }
  static getUpcomingMeetings(payload, cb) {
    return {
      type: GET_UPCOMING_MEETINGS,
      payload,
      cb,
    };
  }
  static getUpcomingMeetingsSuccess(payload) {
    return {
      type: GET_UPCOMING_MEETINGS_SUCCESS,
      payload,
    };
  }
  static getUpcomingMeetingsFailure(payload) {
    return {
      type: GET_UPCOMING_MEETINGS_FAILURE,
      payload,
    };
  }
  static getAllMeetingsAndCount(payload, cb) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT,
      payload,
      cb,
    };
  }
  static getAllMeetingsAndCountSuccess(payload) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
      payload,
    };
  }
  static getAllMeetingsAndCountFailure(payload) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT_FAILURE,
      payload,
    };
  }
  static getMeetingsBasedOnDate(payload, cb) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE,
      payload,
      cb,
    };
  }
  static getMeetingsBasedOnDateSuccess(payload) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE_SUCCESS,
      payload,
    };
  }
  static getMeetingsBasedOnDateFailure(payload) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE_FAILURE,
      payload,
    };
  }
  static changeMeetingStatus(payload, cb) {
    return {
      type: CHANGE_MEETING_STATUS,
      payload,
      cb,
    };
  }
  static changeMeetingStatusSuccess(payload) {
    return {
      type: CHANGE_MEETING_STATUS_SUCCESS,
      payload,
    };
  }
  static changeMeetingStatusFailure(payload) {
    return {
      type: CHANGE_MEETING_STATUS_FAILURE,
      payload,
    };
  }
}
