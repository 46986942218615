import {
  ADD_APPLICATIONS,
  ADD_APPLICATIONS_SUCCESS,
  ADD_APPLICATIONS_FAILURE,
  CHANGE_APP_STATUS,
  CHANGE_APP_STATUS_SUCCESS,
  CHANGE_APP_STATUS_FAILURE,
  GET_SUBMITTED_APPLICATIONS,
  GET_SUBMITTED_APPLICATIONS_SUCCESS,
  GET_SUBMITTED_APPLICATIONS_FAILURE,
  GET_ONGOING_APPLICATIONS,
  GET_ONGOING_APPLICATIONS_SUCCESS,
  GET_ONGOING_APPLICATIONS_FAILURE,
  UPLOAD_APPLICATION_PROOF,
  UPLOAD_APPLICATION_PROOF_SUCCESS,
  UPLOAD_APPLICATION_PROOF_FAILURE,
} from "../constants";

export default class ApplicationAction {
  static addApplications(payload, cb) {
    return {
      type: ADD_APPLICATIONS,
      payload,
      cb,
    };
  }
  static addApplicationsSuccess(payload) {
    return {
      type: ADD_APPLICATIONS_SUCCESS,
      payload,
    };
  }
  static addApplicationsFailure(payload) {
    return {
      type: ADD_APPLICATIONS_FAILURE,
      payload,
    };
  }
  static changeApplicationStatus(payload, cb) {
    return {
      type: CHANGE_APP_STATUS,
      payload,
      cb,
    };
  }
  static changeApplicationStatusSuccess(payload) {
    return {
      type: CHANGE_APP_STATUS_SUCCESS,
      payload,
    };
  }
  static changeApplicationStatusFailure(payload) {
    return {
      type: CHANGE_APP_STATUS_FAILURE,
      payload,
    };
  }

  static getSubmittedApplications(payload, cb) {
    return {
      type: GET_SUBMITTED_APPLICATIONS,
      payload,
      cb,
    };
  }
  static getSubmittedApplicationsSuccess(payload) {
    return {
      type: GET_SUBMITTED_APPLICATIONS_SUCCESS,
      payload,
    };
  }
  static getSubmittedApplicationsFailure(payload) {
    return {
      type: GET_SUBMITTED_APPLICATIONS_FAILURE,
      payload,
    };
  }
  static getOngoingApplications(payload, cb) {
    return {
      type: GET_ONGOING_APPLICATIONS,
      payload,
      cb,
    };
  }
  static getOngoingApplicationsSuccess(payload) {
    return {
      type: GET_ONGOING_APPLICATIONS_SUCCESS,
      payload,
    };
  }
  static getOngoingApplicationsFailure(payload) {
    return {
      type: GET_ONGOING_APPLICATIONS_FAILURE,
      payload,
    };
  }
  static uploadApplicationProof(payload, cb) {
    return {
      type: UPLOAD_APPLICATION_PROOF,
      payload,
      cb,
    };
  }
  static uploadApplicationProofSuccess(payload) {
    return {
      type: UPLOAD_APPLICATION_PROOF_SUCCESS,
      payload,
    };
  }
  static uploadApplicationProofFailure(payload) {
    return {
      type: UPLOAD_APPLICATION_PROOF_FAILURE,
      payload,
    };
  }
}
