import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Colors } from "../../config";

const Circular = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: props?.size ?? 36,
              color: Colors.main,
            }}
            spin
          />
        }
      />
    </div>
  );
};

export default Circular;
