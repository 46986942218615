import React from "react";

const QuestionnaireLayout = (props) => {
  return (
    <div className="text-black flex items-center flex-col">
      <div className="w-[40%] py-4">
        <div
          className={`bg-[#429B8E] rounded-t-2xl flex justify-center items-center p-2 text-white font-semibold text-[16px] z-[4] relative`}
        >
          {props?.title}
        </div>
        <div className="mt-[-8px] z-[3]">{props?.children}</div>
      </div>
    </div>
  );
};

export default QuestionnaireLayout;
