const supportArray = [
  "Career Counseling",
  "University Shortlisting",
  "Essay Writing",
  "Application Submission",
  "Enrollment Support",
  "Tution Fee Payment",
  "Accommodation",
  "Visa Application",
];
export default supportArray;
