import React from "react";

const AddApplicationBoxLabel = ({ title, required = false }) => {
  return (
    <div className="text-[16px] font-bold pl-2">
      {title} {required && <span className="text-red-600">*</span>}
    </div>
  );
};

export default AddApplicationBoxLabel;
