import React from "react";

const MeetingTabs = (props) => {
  const pageNames = [
    {
      title: `Meetings Sent (${props?.meetingSentCount})`,
      tab: "sent",
    },
    {
      title: `Meetings Received (${props?.meetingInvitesCount})`,
      tab: "received",
    },
  ];

  return (
    <div className="bg-main rounded-xl px-5 py-3 flex gap-5">
      {pageNames?.map((item, index) => (
        <div
          onClick={() => props?.setMeetingTab(item?.tab)}
          key={index}
          className={`p-2 rounded-lg cursor-pointer ${
            item?.tab === props?.meetingTab
              ? "text-black bg-white font-bold"
              : "bg-main text-white font-semibold"
          }`}
        >
          {item?.title}
        </div>
      ))}
    </div>
  );
};

export default MeetingTabs;
