import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { CiCircleChevLeft } from "react-icons/ci";

import { OthersAction } from "../../store/actions";

import LoginLayout from "../../components/layouts/login-layout";
import {
  Buttons,
  Loader,
  Spacer,
  inputFields,
  loginAndSignup,
} from "../../components";

import { Images, Utils, UtilityArrays } from "../../config";

const SignupGeneralInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { countries, isCountryLoading, schools, isSchoolLoading } = useSelector(
    (state) => state.others
  );

  const [countriesFiltered, setCountriesFiltered] = useState([]);
  const [schoolsFiltered, setSchoolsFiltered] = useState([]);
  const [state, setState] = useState({
    fullName: "",
    email: "",
    country: null,
    city: "",
    schoolId: null,
    phoneNumber: "",
    grade: null,
    gender: null,
  });

  useEffect(() => {
    if (countries?.length === 0) {
      dispatch(OthersAction.getCountries());
    }
    if (schools?.length === 0) {
      dispatch(OthersAction.getSchools());
    }
  }, []);

  useEffect(() => {
    const countryNames = countries?.map((country) => ({
      label: country.name,
      value: country.name,
    }));
    setCountriesFiltered(countryNames);
    const SchoolNames = schools?.map((school) => ({
      label: `${school.firstName} ${school.lastName}`,
      value: school.schoolId,
    }));
    setSchoolsFiltered(SchoolNames);
  }, [schools, countries]);

  const handleContinue = () => {
    const nameParts = state?.fullName?.trim().split(" ");

    if (nameParts.length < 2) {
      message.error("Please enter both a first name and a last name.");
      return;
    }
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    setState((prevState) => ({
      ...prevState,
      firstName,
      lastName,
    }));

    setTimeout(() => {
      if (
        !firstName ||
        !lastName ||
        !state.email ||
        !state.phoneNumber ||
        !state.country ||
        !state.city ||
        !state.schoolId ||
        !state.grade ||
        !state.gender
      ) {
        message.error("Please fill in all required fields.");
      } else if (
        /[^a-zA-Z\s]/.test(state?.fullName) ||
        /[^a-zA-Z\s]/.test(firstName) ||
        /[^a-zA-Z\s]/.test(lastName)
      ) {
        message.error("Names cannot contain special characters or numbers.");
      } else {
        const body = {
          ...state,
          firstName,
          lastName,
          phoneNumber: `+${state.phoneNumber}`,
        };

        if (isNaN(parseInt(state?.schoolId))) {
          navigate("/signup/password", { state: body });
        } else {
          navigate("/signup/verify-school-code", { state: body });
        }
      }
    }, 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleContinue();
    }
  };

  return (
    <LoginLayout>
      {isCountryLoading || isSchoolLoading ? (
        <Loader.Circular />
      ) : (
        <div className="flex flex-col items-center justify-center px-[120px] py-[40px] bg-login-gradient gap-4">
          <div className="relative">
            <CiCircleChevLeft
              className="text-[40px] absolute -left-[310px] top-2 cursor-pointer"
              onClick={() => {
                window.history.back();
              }}
            />
            <img src={Images.UnivisionLogoWithText} className="h-[60px]" />
          </div>

          <div className="flex flex-col gap-2 items-start w-full">
            <div className="text-main font-bold text-[40px]">Sign Up</div>
            <div className="text-[22px] font-semibold">
              Enter your details to sign up on the{" "}
              <b>UniVision Student Portal</b>
            </div>
            <Spacer height={5} />
            <div className="text-[22px] font-bold text-main">
              Register as a Student
            </div>
          </div>

          <div className="grid grid-cols-2 gap-y-3 gap-x-8 w-[800px]">
            <div>
              <loginAndSignup.LoginInputTitle title="Full Name" required />
              <Spacer height={10} />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, fullName: e.target.value })
                }
                value={state?.fullName}
                onKeyDown={handleKeyDown}
                placeholder="Enter your name"
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Email" required />
              <Spacer height={10} />
              <inputFields.DefaultInput
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={state?.email}
                onKeyDown={handleKeyDown}
                placeholder="Enter your email"
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Country" required />
              <Spacer height={10} />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, country: e })}
                value={state?.country}
                items={countriesFiltered}
                placeholder="Select country"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="City" required />
              <Spacer height={10} />
              <inputFields.DefaultInput
                onChange={(e) => setState({ ...state, city: e.target.value })}
                value={state?.city}
                placeholder="Enter you city name"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Institute Name" required />
              <Spacer height={10} />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, schoolId: e })}
                value={state?.schoolId}
                items={schoolsFiltered}
                placeholder="Enter your institution name"
                onKeyDown={handleKeyDown}
                custominput={true}
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Phone Number" required />
              <Spacer height={10} />
              <inputFields.DefaultPhoneInput
                onChange={(e) => setState({ ...state, phoneNumber: e })}
                value={state?.phoneNumber}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Grade / Class" required />
              <Spacer height={10} />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, grade: e })}
                value={state?.grade}
                items={Utils.filterArray(UtilityArrays.gradeArray)}
                placeholder="Enter your grade"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <loginAndSignup.LoginInputTitle title="Gender" required />
              <Spacer height={10} />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, gender: e })}
                value={state?.gender}
                items={Utils.filterArray(UtilityArrays.genderArray)}
                placeholder="Enter your gender"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <Spacer height={10} />
          <div className="w-[450px]">
            <Buttons.DefaultButton onClick={handleContinue} width="full">
              Continue
            </Buttons.DefaultButton>
            <Spacer height={15} />
            <div className="flex justify-center items-center font-medium gap-1">
              <div>Already have an account? </div>
              <Link to="/login">
                <div className="text-main font-semibold">Sign In</div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </LoginLayout>
  );
};

export default SignupGeneralInfo;
