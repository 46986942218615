import React, { useState } from "react";
import { Calendar, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/en";

import popover from "../popover";
import { Colors } from "../../config";
import Spacer from "../spacer";

dayjs.extend(localeData);

import "./styles.css";

const customLocale = {
  ...dayjs.localeData()._config,
  weekdaysShort: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
};

const MeetingCalendar = (props) => {
  const [popoverDate, setPopoverDate] = useState(null);
  const highlightedDates =
    props?.meetingData?.map((meetingData) => dayjs(meetingData?.meetingDate)) ||
    [];

  const isHighlighted = (date) => {
    return highlightedDates.some((highlightedDate) =>
      date.isSame(highlightedDate, "day")
    );
  };

  const handleDateClick = (date) => {
    setPopoverDate(date.isSame(popoverDate, "day") ? null : date);
  };

  const dateFullCellRender = (date) => {
    const isSelectedDate = popoverDate?.isSame(date, "day");

    const meetingsForDate = props?.meetingData?.filter((meeting) =>
      dayjs(meeting.meetingDate).isSame(date, "day")
    );

    const content = (
      <div>
        <div className="font-semibold border-b border-b-white pb-2">{`Meetings on ${date.format(
          "MMMM D, YYYY"
        )}:`}</div>
        <Spacer height={10} />
        <div className="max-w-[300px] max-h-[300px] overflow-auto flex flex-col gap-2">
          {meetingsForDate?.length > 0 ? (
            meetingsForDate?.map((meeting, index) => (
              <div key={index}>
                <div className="font-semibold">
                  Meeting with {meeting?.userSchool?.firstName}{" "}
                  {meeting?.userSchool?.lastName}
                </div>
                <div>{meeting?.subject}</div>
              </div>
            ))
          ) : (
            <div>No meetings</div>
          )}
        </div>
      </div>
    );

    return (
      <popover.DefaultPopover
        content={content}
        title={null}
        placement="bottom"
        trigger="hover"
        open={isSelectedDate}
        onOpenChange={() => handleDateClick(date)}
      >
        <div
          className="ant-picker-cell-inner"
          style={{
            backgroundColor: isHighlighted(date) ? Colors.main : "transparent",
            color: isHighlighted(date) ? "white" : "inherit",
            cursor: "pointer",
          }}
        >
          {date.date()}
        </div>
      </popover.DefaultPopover>
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Colors.main,
        },
      }}
      locale={customLocale}
    >
      <Calendar
        fullCellRender={dateFullCellRender}
        className="meeting-calendar"
      />
    </ConfigProvider>
  );
};

export default MeetingCalendar;
