import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import "./styles.css";

const ExternalId = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    collegeBoard: "",
    linkedIn: "",
  });

  useEffect(() => {
    getQuestionnaire();
  }, []);

  useEffect(() => {
    setState({
      collegeBoard: questionnaire?.collegeBoard ?? "",
      linkedIn: questionnaire?.linkedIn ?? "",
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    const payload = { userId: profileData?.id, ...state };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          navigate("/profile-builder/academics");
        }
        getQuestionnaire();
      })
    );
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2">External ID</div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6">
            <div>
              <questionnaireComponents.QuestionnaireLabel text="College Board" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, collegeBoard: e.target.value })
                }
                placeholder="Enter value"
                value={state?.collegeBoard}
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="LinkedIn" />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, linkedIn: e.target.value })
                }
                value={state?.linkedIn}
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default ExternalId;
