import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { IoIosInformationCircleOutline } from "react-icons/io";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
  popover,
} from "../../components";

import { UtilityArrays, Utils } from "../../config";

import "./styles.css";

const Education = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    currentSchool: "",
    currentGrade: null,
    highSchoolGradYear: null,
    currentDiploma: "",
  });

  useEffect(() => {
    setState({
      currentSchool: questionnaire?.currentSchool ?? "",
      currentGrade: questionnaire?.currentGrade ?? null,
      highSchoolGradYear: questionnaire?.highSchoolGradYear ?? null,
      currentDiploma: questionnaire?.currentDiploma ?? "",
    });
  }, [questionnaire]);

  const handleNext = () => {
    if (!state.currentSchool || !state.currentGrade || !state.currentDiploma) {
      message.error("Please fill all the fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            setPage("Test Scores");
          }
        })
      );
    }
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="grid grid-cols-2 gap-x-7 gap-y-6 max-h-[550px] overflow-y-auto">
            <div>
              <questionnaireComponents.QuestionnaireLabel
                text="Current School"
                required
              />
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, currentSchool: e.target.value })
                }
                placeholder="Dubai School"
                value={state?.currentSchool}
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel
                text="Current Grade"
                required
              />
              <Spacer height="5px" />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, currentGrade: e })}
                items={Utils.filterArray(UtilityArrays.gradeArray)}
                placeholder="Select your current grade"
                value={state?.currentGrade}
              />
            </div>
            <div>
              <questionnaireComponents.QuestionnaireLabel text="High School Graduation Year" />
              <Spacer height="5px" />
              <inputFields.SelectDropdown
                onChange={(e) => setState({ ...state, highSchoolGradYear: e })}
                items={Utils.filterArray(UtilityArrays.gradYearsArray)}
                placeholder="Select your graduation year"
                value={state?.highSchoolGradYear}
              />
            </div>
            <div>
              <div className="flex gap-2 items-center">
                <questionnaireComponents.QuestionnaireLabel
                  text="Current Diploma"
                  required
                />
                <span>
                  <popover.WhitePopover
                    content={
                      <div className="text-[14px] font-medium">
                        Please specify the current <br /> education
                        qualification
                        <br />
                        that you are pursuing
                      </div>
                    }
                    trigger="hover"
                    placement="right"
                  >
                    <IoIosInformationCircleOutline className="text-main" />
                  </popover.WhitePopover>
                </span>
              </div>
              <Spacer height="5px" />
              <inputFields.DefaultInput
                onChange={(e) =>
                  setState({ ...state, currentDiploma: e.target.value })
                }
                placeholder="Enter your current diploma"
                value={state?.currentDiploma}
              />
            </div>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Education;
