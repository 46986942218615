import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import { ChatbotAction } from "../../store/actions";

import ChatbotPopupCounselor from "./chatbot-popup-counselor";
import ChatbotPopupSupport from "./chatbot-popup-support";

import Spacer from "../spacer";
import tabs from "../tabs";

import { Images } from "../../config";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket"],
  withCredentials: true,
});

const ChatbotPopupContent = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (profileData?.student?.studentType === "schoolStudent") {
      setActiveTab("counsellor");
    } else {
      setActiveTab("support");
    }
  }, [profileData]);

  useEffect(() => {
    const handleUser = () => {
      const payload = {
        schoolId: profileData?.student?.schoolId,
      };
      dispatch(
        ChatbotAction.getUsers(payload, () => {
          dispatch(
            ChatbotAction.getUnreadMessages({
              from: profileData?.id,
            })
          );
        })
      );
    };

    socket.on("message-sent", handleUser);
    socket.on("message-received", handleUser);

    return () => {
      socket.off("message-sent", handleUser);
      socket.off("message-received", handleUser);
    };
  }, [profileData]);

  const tabItems =
    profileData?.student?.studentType === "schoolStudent"
      ? [
          {
            title: "Counselor",
            value: "counsellor",
          },
          { title: "Support", value: "support" },
        ]
      : [{ title: "Support", value: "support" }];

  return (
    <div className="flex flex-col justify-center items-center w-[350px]">
      <div className="flex justify-between items-center w-full px-5">
        <tabs.ChatbotPopupTab
          tabs={tabItems}
          activeTab={activeTab}
          onTabClick={setActiveTab}
        />
        <img src={Images.ChatbotPopupIcon} className="h-[36px] w-[36px]" />
      </div>
      <Spacer height={10} />
      {activeTab === "counsellor" ? (
        <ChatbotPopupCounselor />
      ) : (
        <ChatbotPopupSupport />
      )}
    </div>
  );
};

export default ChatbotPopupContent;
