import React from "react";
import { Checkbox } from "antd";

import "./styles.css";

const DefaultCheckbox = (props) => {
  return (
    <Checkbox {...props} className="defaultCheckbox">
      <span className="flex items-center justify-center pt-1">
        {props?.text}
      </span>
    </Checkbox>
  );
};

export default DefaultCheckbox;
