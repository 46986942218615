import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Autoplay } from "swiper/modules";

import { MdCancel, MdCheckCircle } from "react-icons/md";

import TasksCarouselNavButton from "./carousel-nav-btn";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./styles.css";

const TasksCarousel = (props) => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  return (
    <Swiper
      onSlideChange={handleSlideChange}
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={16}
      style={{
        width: "26vw",
        padding: "5px",
        position: "relative",
      }}
      autoplay={{ delay: 10000 }}
      pagination={{
        dynamicBullets: true,
      }}
    >
      {props?.items?.map((item, index) => (
        <SwiperSlide
          key={index}
          className={`font-semibold ${
            item.status === "incomplete" && "cursor-pointer"
          }`}
          onClick={() => item.status !== "complete" && navigate(item?.link)}
        >
          <div className="text-[24px] font-bold flex justify-between items-center">
            <div>Task #{index + 1}</div>
            <div
              className={`${
                item.status === "complete"
                  ? "bg-main text-white"
                  : "bg-red-600 text-white"
              } text-[14px] px-2 py-1 rounded-lg font-semibold capitalize flex gap-1 justify-center items-center`}
            >
              {item?.status === "complete" ? <MdCheckCircle /> : <MdCancel />}
              <span className="leading-4">{item.status}</span>
            </div>
          </div>
          <div className="h-[40px]">{item.title}</div>
        </SwiperSlide>
      ))}

      <div className="absolute bottom-2 w-[490px]">
        <TasksCarouselNavButton
          index={currentIndex}
          totalSlides={props?.items?.length}
          slidesPerView={1}
        />
      </div>
    </Swiper>
  );
};

export default TasksCarousel;
