import React from "react";
import { Popover, ConfigProvider } from "antd";

import { Colors } from "../../config";

const WhitePopover = (props) => {
  const content = props?.content;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: "white",
          colorText: "black",
          controlItemBgHover: Colors.LightSecondary,
        },
      }}
    >
      <Popover
        placement={props?.placement || "leftTop"}
        title={props?.title ?? null}
        content={content}
        arrow={{ pointAtCenter: true }}
        trigger={props?.trigger ?? "click"}
      >
        {props?.children}
      </Popover>
    </ConfigProvider>
  );
};

export default WhitePopover;
