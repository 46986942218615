import React from "react";

const DefaultBoxContainer = ({ addstyle, children, ...rest }) => {
  const additionalStyle = addstyle ?? "";
  return (
    <div
      className={`!border !border-main shadow-boxContainer rounded-[25px] p-5 ${additionalStyle}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default DefaultBoxContainer;
