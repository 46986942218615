import { put } from "redux-saga/effects";

import { NotificationsAction } from "../actions";
import { ApiCaller } from "../../config";

export default class NotificationsMiddleware {
  static *getNotifications({ payload, cb }) {
    try {
      const { limit, offset } = payload;

      const res = yield ApiCaller.Get(
        `/notifications/getNotifications?limit=${limit ?? ""}&offset=${
          offset ?? ""
        }`
      );

      if (res.status === 200) {
        yield put(NotificationsAction.getNotificationsSuccess(res.data.data));
      } else {
        yield put(NotificationsAction.getNotificationsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(NotificationsAction.getNotificationsFailure());
    }
  }

  static *changeNotificationsStatus({ payload, cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/notifications/updateNotifications`,
        payload
      );
      if (res.status === 200) {
        yield put(
          NotificationsAction.changeNotificationsStatusSuccess(res.data.data)
        );
      } else {
        yield put(NotificationsAction.changeNotificationsStatusFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(NotificationsAction.changeNotificationsStatusFailure());
    }
  }
}
