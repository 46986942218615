import {
  ADD_MEETING,
  ADD_MEETING_FAILURE,
  ADD_MEETING_SUCCESS,
  CHANGE_MEETING_STATUS,
  CHANGE_MEETING_STATUS_FAILURE,
  CHANGE_MEETING_STATUS_SUCCESS,
  GET_MEETINGS_INVITES,
  GET_MEETINGS_INVITES_SUCCESS,
  GET_MEETINGS_INVITES_FAILURE,
  GET_MEETINGS_REQUESTS,
  GET_MEETINGS_REQUESTS_SUCCESS,
  GET_MEETINGS_REQUESTS_FAILURE,
  GET_UPCOMING_MEETINGS,
  GET_UPCOMING_MEETINGS_SUCCESS,
  GET_UPCOMING_MEETINGS_FAILURE,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
  GET_ALL_MEETINGS_AND_COUNT_FAILURE,
  GET_MEETINGS_BASED_ON_DATE,
  GET_MEETINGS_BASED_ON_DATE_SUCCESS,
  GET_MEETINGS_BASED_ON_DATE_FAILURE,
  RESCHEDULE_MEETING,
  RESCHEDULE_MEETING_SUCCESS,
  RESCHEDULE_MEETING_FAILURE,
  GET_MEETING_DETAILS,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_FAILURE,
} from "../constants";

const initialState = {
  meeting: {},
  meetingsAll: [],
  meetingInvites: [],
  meetingInvitesCount: 0,
  meetingSent: [],
  meetingSentCount: 0,
  upcomingMeetings: [],
  upcomingMeetingsCount: 0,
  meetingBasedOnDate: [],
  isLoading: false,
  isMeetingInvitesLoading: false,
  isMeetingSentLoading: false,
  isUpcomingMeetingsLoading: false,
  isAllMeetingLoading: false,
  isMeetingInvitesLoading: false,
  isMeetingBasedOnDateLoading: false,
  error: null,
};

export default function MeetingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MEETING:
    case RESCHEDULE_MEETING:
    case GET_MEETING_DETAILS:
    case CHANGE_MEETING_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_MEETINGS_BASED_ON_DATE:
      state = {
        ...state,
        isMeetingBasedOnDateLoading: true,
        error: null,
      };
      break;

    case GET_MEETINGS_INVITES:
      state = {
        ...state,
        isMeetingInvitesLoading: true,
        error: null,
      };
      break;
    case GET_MEETINGS_REQUESTS:
      state = {
        ...state,
        isMeetingSentLoading: true,
        error: null,
      };
      break;
    case GET_UPCOMING_MEETINGS:
      state = {
        ...state,
        isUpcomingMeetingsLoading: true,
        error: null,
      };
      break;

    case GET_ALL_MEETINGS_AND_COUNT:
      state = {
        ...state,
        isAllMeetingLoading: true,
        error: null,
      };
      break;

    case ADD_MEETING_SUCCESS:
    case RESCHEDULE_MEETING_SUCCESS:
    case CHANGE_MEETING_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_MEETING_DETAILS_SUCCESS:
      state = {
        ...state,
        meeting: action.payload,
        isLoading: false,
      };
      break;
    case GET_MEETINGS_INVITES_SUCCESS:
      state = {
        ...state,
        meetingInvites: action.payload.meetingInvites,
        meetingInvitesCount: action.payload.count,
        isMeetingInvitesLoading: false,
      };
      break;
    case GET_MEETINGS_REQUESTS_SUCCESS:
      state = {
        ...state,
        meetingSent: action.payload.meetingSent,
        meetingSentCount: action.payload.count,
        isMeetingSentLoading: false,
      };
      break;
    case GET_UPCOMING_MEETINGS_SUCCESS:
      state = {
        ...state,
        upcomingMeetings: action.payload.upcomingMeetings,
        upcomingMeetingsCount: action.payload.count,
        isUpcomingMeetingsLoading: false,
      };
      break;

    case GET_ALL_MEETINGS_AND_COUNT_SUCCESS:
      state = {
        ...state,
        meetingsAll: action.payload,
        isAllMeetingLoading: false,
      };
      break;
    case GET_MEETINGS_BASED_ON_DATE_SUCCESS:
      state = {
        ...state,
        meetingBasedOnDate: action.payload,
        isMeetingBasedOnDateLoading: false,
      };
      break;
    case ADD_MEETING_FAILURE:
    case RESCHEDULE_MEETING_FAILURE:
    case GET_MEETING_DETAILS_FAILURE:
    case CHANGE_MEETING_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;

    case GET_MEETINGS_INVITES_FAILURE:
      state = {
        ...state,
        isMeetingInvitesLoading: false,
        error: action.payload,
      };
      break;

    case GET_MEETINGS_REQUESTS_FAILURE:
      state = {
        ...state,
        isMeetingSentLoading: false,
        error: action.payload,
      };
      break;
    case GET_UPCOMING_MEETINGS_FAILURE:
      state = {
        ...state,
        isUpcomingMeetingsLoading: false,
        error: action.payload,
      };
      break;
    case GET_ALL_MEETINGS_AND_COUNT_FAILURE:
      state = {
        ...state,
        isAllMeetingLoading: false,
        error: action.payload,
      };
      break;
    case GET_MEETINGS_BASED_ON_DATE_FAILURE:
      state = {
        ...state,
        isMeetingBasedOnDateLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
