const NavbarRoutes = [
  {
    navbarTitle: "Student Dashboard",
    route: "/home",
    backIcon: false,
  },
  {
    navbarTitle: "Internships",
    route: "/internships",
    backIcon: false,
  },
  {
    navbarTitle: "Profile Builder",
    route: "/profile-builder",
    backIcon: false,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/grid",
    backIcon: false,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/list",
    backIcon: false,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/general",
    backIcon: true,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/admission",
    backIcon: true,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/programs",
    backIcon: true,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/finance",
    backIcon: true,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/application-journey",
    backIcon: true,
  },
  {
    navbarTitle: "Shortlist",
    route: "/shortlists",
    backIcon: false,
  },
  {
    navbarTitle: "Applications",
    route: "/applications",
    backIcon: false,
  },
  {
    navbarTitle: "Applications",
    route: "/applications/addapplication",
    backIcon: true,
  },
  {
    navbarTitle: "Meetings",
    route: "/meetings/upcoming",
    backIcon: false,
  },
  {
    navbarTitle: "Meetings",
    route: "/meetings/sent",
    backIcon: false,
  },
  {
    navbarTitle: "Meetings",
    route: "/meetings/received",
    backIcon: false,
  },
  {
    navbarTitle: "Add Meeting",
    route: "/meetings/add-meeting",
    backIcon: false,
  },
  {
    navbarTitle: "Reschedule Meeting",
    route: "/meetings/reschedule-meeting",
    backIcon: true,
  },
  {
    navbarTitle: "Essay Grader",
    route: "/essay",
    backIcon: false,
  },
  {
    navbarTitle: "Interactive Learning",
    route: "/interactive-learning",
    backIcon: false,
  },
  {
    navbarTitle: "Chatbot",
    route: "/chatbot",
    backIcon: false,
  },
  {
    navbarTitle: "Notifications",
    route: "/notifications",
    backIcon: true,
  },
  {
    navbarTitle: "User Profile",
    route: "/profile",
    backIcon: true,
  },
  {
    navbarTitle: "Questionnaire",
    route: "/questionnaire/general",
    backIcon: true,
  },
  {
    navbarTitle: "Questionnaire",
    route: "/questionnaire/academics",
    backIcon: true,
  },
  {
    navbarTitle: "Questionnaire",
    route: "/questionnaire/location",
    backIcon: true,
  },
  {
    navbarTitle: "Questionnaire",
    route: "/questionnaire/demographics",
    backIcon: true,
  },
  {
    navbarTitle: "Questionnaire",
    route: "/questionnaire/others",
    backIcon: true,
  },
  {
    navbarTitle: "Settings",
    route: "/settings",
    backIcon: false,
  },
  {
    navbarTitle: "Account Settings",
    route: "/account-settings",
    backIcon: true,
  },
  {
    navbarTitle: "Security Settings",
    route: "/security-settings",
    backIcon: true,
  },
  {
    navbarTitle: "Account Preferences",
    route: "/account-preference",
    backIcon: true,
  },
  {
    navbarTitle: "The UniVision Recommender",
    route: "/recommender",
    backIcon: false,
  },
  {
    navbarTitle: "The UniVision Recommender",
    route: "/recommender/qualifications",
    backIcon: true,
  },
  {
    navbarTitle: "The UniVision Recommender",
    route: "/recommender/preferences",
    backIcon: true,
  },
  {
    navbarTitle: "The UniVision Recommender",
    route: "/recommender/results",
    backIcon: true,
  },
  {
    navbarTitle: "Universities",
    route: "/universities/compare",
    backIcon: true,
  },
  {
    navbarTitle: "The UniVision Recommender",
    route: "/recommender/compare",
    backIcon: true,
  },
  {
    navbarTitle: "Visa Information",
    route: "/visa-information",
    backIcon: false,
  },
  {
    navbarTitle: "Profile Builder",
    route: "/profile-builder",
    backIcon: false,
  },
  {
    navbarTitle: "Profile Builder",
    route: "/profile-builder/",
    backIcon: true,
  },
  {
    navbarTitle: "Explore",
    route: "/explore",
    backIcon: false,
  },
  {
    navbarTitle: "Explore",
    route: "/explore/results",
    backIcon: true,
  },
  {
    navbarTitle: "Support",
    route: "/support",
    backIcon: true,
  },
];
export default NavbarRoutes;
