import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { CounselorAction, MeetingAction } from "../../store/actions";

import {
  meeting,
  boxContainer,
  Spacer,
  inputFields,
  Loader,
  datepicker,
  upload,
  Buttons,
} from "../../components";
import { Images, Utils } from "../../config";
import TimeSlots from "../../config/utility-arrays/timeslots";

const AddMeeting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profile?.user);
  const { counselors, isLoading } = useSelector((state) => state.counselors);
  const meetingLoading = useSelector((state) => state.meeting?.isLoading);

  const [buttonText, setButtonText] = useState("Schedule");
  const [disabled, setDisabled] = useState(false);
  const [counselorList, setCounselorList] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);

  const [state, setState] = useState({
    subject: "",
    meetingDate: "",
    timeSlot: null,
    message: "",
    meetingType: "",
    organizer: "student",
    counselorUserId: null,
    meetingLink: "",
    meetingFile: "",
  });

  useEffect(() => {
    if (counselors.length === 0) {
      const payload = {
        id: profileData?.student?.schoolId,
      };
      dispatch(CounselorAction.getCounselors(payload));
    }
  }, [profileData]);

  useEffect(() => {
    const filteredTimeslots = TimeSlots.map((timeslot) => ({
      label: timeslot,
      value: timeslot,
    }));
    setTimeSlot(filteredTimeslots);
  }, []);

  useEffect(() => {
    const filteredCounselors = counselors.map((counselor) => ({
      label: `${counselor?.user?.firstName} ${counselor?.user?.lastName}`,
      value: counselor?.user?.id,
    }));
    setCounselorList(filteredCounselors);
  }, [counselors]);

  const handleSubmit = () => {
    if (
      !state.counselorUserId ||
      !state?.subject ||
      !state?.meetingDate ||
      !state?.timeSlot ||
      !state?.meetingType ||
      !state?.meetingLink
    ) {
      message.error("Please fill all the required fields.");
    } else if (
      state?.meetingType === "online" &&
      state?.meetingLink &&
      !Utils?.isValidURL(state.meetingLink)
    ) {
      message.error("Please enter a valid meeting link.");
    } else {
      setButtonText("Scheduling");
      setDisabled(true);
      const payload = {
        ...state,
        studentUserId: profileData?.id,
        schoolId: profileData?.student?.schoolId,
      };
      console.log(payload);
      dispatch(
        MeetingAction.addMeeting(payload, (res) => {
          if (res.status === 200) {
            navigate("/");
          }
          setButtonText("Schedule");
          setDisabled(false);
        })
      );
    }
  };

  const radioItems = [
    { label: "In Person", value: "offline" },
    { label: "Online", value: "online" },
  ];

  return (
    <div className="text-black">
      <div className="header-md pl-3">Schedule New Meeting</div>
      <Spacer height={20} />
      <boxContainer.LiteBoxContainer>
        <div className="text-[20px] font-semibold">
          Fill in your details and schedule a meeting for any guidance regarding
          admissions from your College counsellor.
        </div>
        <Spacer height={25} />
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <meeting.AddMeetingBoxLabel
              title="Select Counselor"
              required={true}
            />
            {isLoading ? (
              <Loader.Circular />
            ) : (
              <inputFields.SelectDropdown
                items={counselorList}
                onChange={(e) => setState({ ...state, counselorUserId: e })}
                value={state?.counselorUserId}
                placeholder="Select Counselor"
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <meeting.AddMeetingBoxLabel title="Subject" required={true} />
            <inputFields.DefaultInput
              onChange={(e) => setState({ ...state, subject: e.target.value })}
              value={state.subject}
            />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-col gap-2">
              <meeting.AddMeetingBoxLabel title="Select Date" required={true} />
              <datepicker.DefaultDatepicker
                datevalue={state?.meetingDate}
                onChange={(date, dateString) =>
                  setState({ ...state, meetingDate: dateString })
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <meeting.AddMeetingBoxLabel
                title="Select Timeslot"
                required={true}
              />
              <inputFields.SelectDropdown
                items={timeSlot}
                onChange={(e) => setState({ ...state, timeSlot: e })}
                value={state?.timeSlot}
                placeholder="Select Time Slot"
              />
            </div>
            <div className="flex flex-col gap-2">
              <meeting.AddMeetingBoxLabel title="Document Attachment" />
              <upload.DefaultFileUpload
                onChange={(file) => setState({ ...state, meetingFile: file })}
                value={state.meetingFile}
                icon={Images.DocumentAttachment}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <meeting.AddMeetingBoxLabel title="Message" />
            <inputFields.DefaultTextbox
              onChange={(e) => setState({ ...state, message: e.target.value })}
              value={state.message}
            />
          </div>
          <div className="flex flex-col gap-2">
            <meeting.AddMeetingBoxLabel
              title="Select Mode of Meeting"
              required={true}
            />
            <div className="flex gap-[100px] items-center">
              <div className="pl-2">
                <inputFields.VerticalRadio
                  items={radioItems}
                  onChange={(e) =>
                    setState({ ...state, meetingType: e.target.value })
                  }
                  value={state.meetingType}
                />
              </div>
              {state.meetingType && (
                <div className="flex flex-col gap-2 w-[500px]">
                  <meeting.AddMeetingBoxLabel
                    title={
                      state.meetingType === "offline"
                        ? "Add Location"
                        : "Attach Link"
                    }
                    required={true}
                  />
                  <inputFields.DefaultInput
                    onChange={(e) =>
                      setState({ ...state, meetingLink: e.target.value })
                    }
                    value={state.meetingLink}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Spacer height={30} />
        <div className="flex w-full justify-end">
          <Buttons.DefaultButton
            disabled={disabled}
            onClick={handleSubmit}
            loading={meetingLoading}
          >
            {buttonText}
          </Buttons.DefaultButton>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
};

export default AddMeeting;
