import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FaCircleCheck } from "react-icons/fa6";

import { QuestionnaireAction } from "../../store/actions";

import { boxContainer, progressBar } from "../../components";
import { Images } from "../../config";

import "./styles.css";

const ProfileBuilder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { questionnaire } = useSelector((state) => state?.questionnaire);

  useEffect(() => {
    getQuestionnaire();
  }, []);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const profileInfo = [
    {
      title: "General Information",
      icon: Images.ProfileBuildergeneral,
      route: "/profile-builder/general-info",
      percentage: questionnaire.completion?.generalCompletion,
    },
    {
      title: "Address",
      icon: Images.ProfileBuilderlocation,
      route: "/profile-builder/address",
      percentage: questionnaire.completion?.addressCompletion,
    },
    {
      title: "Overall GPA",
      icon: Images.ProfileBuilderoverallgpa,
      route: "/profile-builder/overall-gpa",
      percentage: questionnaire.completion?.overallGpaCompletion,
    },
    {
      title: "Education",
      icon: Images.ProfileBuildereducation,
      route: "/profile-builder/education",
      percentage: questionnaire.completion?.educationCompletion,
    },
    {
      title: "Test Scores",
      icon: Images.ProfileBuildertestscores,
      route: "/profile-builder/test-scores",
      percentage: questionnaire.completion?.testScoresCompletion,
    },
    {
      title: "Contacts",
      icon: Images.ProfileBuildercontacts,
      route: "/profile-builder/contacts",
      percentage: questionnaire.completion?.contactsCompletion,
    },
    {
      title: "Counselor",
      icon: Images.ProfileBuildercounselor,
      route: "/profile-builder/counselor",
      percentage: questionnaire.completion?.counselorCompletion,
    },
    {
      title: "Activities",
      icon: Images.ProfileBuilderactivities,
      route: "/profile-builder/activities",
      percentage: questionnaire.completion?.activitiesCompletion,
    },
    {
      title: "External ID",
      icon: Images.ProfileBuilderexternalid,
      route: "/profile-builder/external-id",
      percentage: questionnaire.completion?.externalIdCompletion,
    },
  ];

  const universityPref = [
    {
      title: "Academics",
      icon: Images.ProfileBuilderacademics,
      route: "/profile-builder/academics",
      percentage: questionnaire.completion?.academicsCompletion,
    },
    {
      title: "Location",
      icon: Images.ProfileBuilderlocation,
      route: "/profile-builder/location",
      percentage: questionnaire.completion?.locationCompletion,
    },
    {
      title: "Financial",
      icon: Images.ProfileBuilderfinancial,
      route: "/profile-builder/financial",
      percentage: questionnaire.completion?.financialCompletion,
    },
    {
      title: "People",
      icon: Images.ProfileBuilderpeople,
      route: "/profile-builder/people",
      percentage: questionnaire.completion?.peopleCompletion,
    },
    {
      title: "Facilities",
      icon: Images.ProfileBuilderfacilities,
      route: "/profile-builder/facilities",
      percentage: questionnaire.completion?.faciltiesCompletion,
    },
    {
      title: "Demographics",
      icon: Images.ProfileBuilderdemographics,
      route: "/profile-builder/demographics",
      percentage: questionnaire.completion?.demographicsCompletion,
    },
    {
      title: "Others",
      icon: Images.ProfileBuilderothers,
      route: "/profile-builder/others",
      percentage: questionnaire.completion?.othersCompletion,
    },
  ];
  return (
    <div className="text-black">
      <boxContainer.LiteBoxContainer>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="header-md">Profile Information</div>
            <div className="grid grid-cols-5 gap-4">
              {profileInfo?.map((item, index) => (
                <div
                  className={`profile-builder-card ${
                    item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 &&
                    "items-start"
                  }`}
                  key={index}
                  onClick={() => navigate(item?.route)}
                >
                  {item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 && (
                      <div className="absolute right-2 top-2 px-2 py-1 rounded-lg bg-liteBoxBorder text-[14px] font-semibold">
                        Not Completed
                      </div>
                    )}
                  <img src={item?.icon} className="h-[56px] w-[56px]" />
                  <div className="font-semibold text-[16px]">{item?.title}</div>
                  {item?.percentage === 100 && (
                    <div className="flex bg-main w-full gap-2 items-center justify-center h-[30px] text-white font-semibold">
                      <FaCircleCheck />
                      <div className="text-[14px]">Completed</div>
                    </div>
                  )}
                  {item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 && (
                      <progressBar.DefaultProgress percent={item?.percentage} />
                    )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="header-md">University Preference</div>
            <div className="grid grid-cols-5 gap-4">
              {universityPref?.map((item, index) => (
                <div
                  className={`profile-builder-card ${
                    item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 &&
                    "items-start"
                  }`}
                  key={index}
                  onClick={() => navigate(item?.route)}
                >
                  {item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 && (
                      <div className="absolute right-2 top-2 px-2 py-1 rounded-lg bg-liteBoxBorder text-[14px] font-semibold">
                        Not Completed
                      </div>
                    )}
                  <img src={item?.icon} className="h-[56px] w-[56px]" />
                  <div className="font-semibold text-[16px]">{item?.title}</div>
                  {item?.percentage === 100 && (
                    <div className="flex bg-main w-full gap-2 items-center justify-center h-[30px] text-white font-semibold">
                      <FaCircleCheck />
                      <div className="text-[14px]">Completed</div>
                    </div>
                  )}
                  {item?.percentage < 100 &&
                    questionnaire?.completion?.totalCompletion > 0 && (
                      <progressBar.DefaultProgress percent={item?.percentage} />
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
};

export default ProfileBuilder;
