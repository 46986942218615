import React, { useState } from "react";
import { useSelector } from "react-redux";

import OnlineCourses from "./online-courses";
import ETextbooks from "./e-textbooks";

import {
  SortButton,
  Spacer,
  tabs,
  dropdown,
  boxContainer,
  inputFields,
  GetProPopup,
} from "../../components";

import "./styles.css";

const InteractiveLearning = () => {
  const profileData = useSelector((state) => state?.profile?.user);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [activeTab, setActiveTab] = useState("courses");

  const tabArray = [
    { title: "Online Courses", value: "courses" },
    { title: "e Textbooks", value: "eTextbook" },
  ];

  const items = [
    {
      label: (
        <div
          onClick={() => setFilter("all")}
          className={`text-center ${
            filter === "all" ? "font-semibold" : "font-normal"
          }`}
        >
          All
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={() => setFilter("free")}
          className={`text-center ${
            filter === "free" ? "font-semibold" : "font-normal"
          }`}
        >
          Free
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={() => setFilter("paid")}
          className={`text-center ${
            filter === "paid" ? "font-semibold" : "font-normal"
          }`}
        >
          Paid
        </div>
      ),
      key: "3",
    },
  ];

  return (
    <div className="text-black relative">
      {profileData?.freeUser && (
        <div className="absolute top-[250px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <boxContainer.LiteBoxContainer>
        <div
          className={`${profileData?.freeUser && "blur pointer-events-none"}`}
        >
          <div className="flex gap-2 items-center">
            <inputFields.DefaultSearchBox
              onChange={(e) => setSearch(e.target.value)}
              disabled={profileData?.freeUser}
            />
            <dropdown.DefaultDropdown items={items}>
              <SortButton />
            </dropdown.DefaultDropdown>
          </div>
          <Spacer height={20} />
          <tabs.DefaultTab
            tabs={tabArray}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
          <Spacer height={20} />
          {activeTab === "courses" ? (
            <OnlineCourses search={search} filter={filter} />
          ) : (
            <ETextbooks search={search} filter={filter} />
          )}
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
};

export default InteractiveLearning;
