import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import {
  Buttons,
  Loader,
  Spacer,
  application,
  boxContainer,
  GetProPopup,
} from "../../components";

import { ApplicationAction } from "../../store/actions";

function Applications() {
  const dispatch = useDispatch();

  const [limitSubmitted, setLimitSubmitted] = useState(10);
  const [offsetSubmitted, setOffsetSubmitted] = useState(0);
  const [pageNoSubmitted, setPageNoSubmitted] = useState(1);

  const [limitOngoing, setLimitOngoing] = useState(10);
  const [offsetOngoing, setOffsetOngoing] = useState(0);
  const [pageNoOngoing, setPageNoOngoing] = useState(1);

  const {
    submittedApplications,
    submittedApplicationsCount,
    ongoingApplications,
    ongoingApplicationsCount,
    isOngoingLoading,
    isSubmittedLoading,
  } = useSelector((state) => state.application);
  const profileData = useSelector((state) => state?.profile?.user);

  useEffect(() => {
    const payload = { limitOngoing, offsetOngoing };
    dispatch(ApplicationAction.getOngoingApplications(payload));
  }, [limitOngoing, offsetOngoing]);

  useEffect(() => {
    const payload = { limitSubmitted, offsetSubmitted };
    dispatch(ApplicationAction.getSubmittedApplications(payload));
  }, [limitSubmitted, offsetSubmitted]);

  const handlePaginationSubmitted = (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    setPageNoSubmitted(page);
    setLimitSubmitted(pageSize);
    setOffsetSubmitted(offset);
  };

  const handlePaginationOngoing = (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    setPageNoOngoing(page);
    setLimitOngoing(pageSize);
    setOffsetOngoing(offset);
  };

  const handleStatusChange = (appId, status) => {
    const payload = {
      appId,
      status,
    };
    dispatch(
      ApplicationAction.changeApplicationStatus(payload, () => {
        dispatch(
          ApplicationAction.getSubmittedApplications({
            limitSubmitted,
            offsetSubmitted,
          })
        );
      })
    );
  };

  return (
    <div className="relative">
      {profileData?.freeUser && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <boxContainer.LiteBoxContainer>
        {submittedApplicationsCount === 0 && ongoingApplicationsCount === 0 ? (
          <div className="p-4 flex justify-center text-main font-bold text-[24px]">
            You have not created any applications yet.
          </div>
        ) : null}
        <div
          className={`flex flex-col gap-[30px] text-black ${
            profileData?.freeUser && "blur pointer-events-none"
          }`}
        >
          <div className="max-h-[calc(100vh-280px)] overflow-y-auto">
            {submittedApplicationsCount > 0 && (
              <>
                <div className="flex flex-col gap-2">
                  <div className="header-md">Submitted Applications</div>
                  {isSubmittedLoading ? (
                    <Loader.Circular />
                  ) : (
                    <>
                      {submittedApplications?.map((item, index) => (
                        <application.ApplicationRow
                          key={item.id}
                          item={item}
                          index={index}
                          handleStatusChange={handleStatusChange}
                        />
                      ))}
                    </>
                  )}
                </div>
                <Spacer height={10} />
                <div className="flex justify-center items-center">
                  <Pagination
                    responsive
                    showSizeChanger
                    current={pageNoSubmitted}
                    pageSize={limitSubmitted}
                    total={submittedApplicationsCount}
                    onChange={handlePaginationSubmitted}
                    onShowSizeChange={handlePaginationSubmitted}
                  />
                </div>
                <Spacer height={20} />
              </>
            )}
            {ongoingApplicationsCount > 0 && (
              <>
                <div className="flex flex-col gap-2">
                  <div className="header-md">Applications in Progress</div>
                  {isOngoingLoading ? (
                    <Loader.Circular />
                  ) : (
                    <>
                      {ongoingApplications?.map((item, index) => (
                        <application.ApplicationRow
                          key={item.id}
                          item={item}
                          index={index}
                        />
                      ))}
                    </>
                  )}
                </div>
                <Spacer height={10} />
                <div className="flex justify-center items-center">
                  <Pagination
                    responsive
                    showSizeChanger
                    current={pageNoOngoing}
                    pageSize={limitOngoing}
                    total={ongoingApplicationsCount}
                    onChange={handlePaginationOngoing}
                    onShowSizeChange={handlePaginationOngoing}
                  />
                </div>
              </>
            )}
          </div>
          <div className="text-end">
            <Link
              to={
                profileData?.freeUser
                  ? "/applications"
                  : "/applications/addapplication"
              }
            >
              <Buttons.DefaultButton>Add Application</Buttons.DefaultButton>
            </Link>
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default Applications;
