import { useSelector } from "react-redux";

import { Loader } from "./components";

import AllRoutes from "./config/routes/all-routes";

const App = () => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  return (
    <div>
      <AllRoutes />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <Loader.Circular />
        </div>
      ) : null}
    </div>
  );
};

export default App;
