import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  inputFields,
  Loader,
} from "../../components";

import { UtilityArrays } from "../../config";

import "./styles.css";

const Financial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    knowBudgetRange: null,
    budgetRange: null,
    budgetImportance: null,
    scholarshipImportance: null,
  });

  useEffect(() => {
    getQuestionnaire();
  }, [profileData]);

  useEffect(() => {
    setState({
      knowBudgetRange: questionnaire?.knowBudgetRange ?? null,
      budgetRange: questionnaire?.budgetRange ?? null,
      budgetImportance: questionnaire?.budgetImportance ?? null,
      scholarshipImportance: questionnaire?.scholarshipImportance ?? null,
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    if (
      !state.knowBudgetRange ||
      !state.budgetImportance ||
      !state.scholarshipImportance
    ) {
      message.error("Please fill all the fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            navigate("/profile-builder/people");
          }
          getQuestionnaire();
        })
      );
    }
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">Financial</div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <questionnaireComponents.QuestionnaireLabel
            text="1. Do you know what your ideal annual budget range for tution fee is?"
            required
          />
          <Spacer height="10px" />
          <div className="pl-3">
            <inputFields.HorizontalRadio
              onChange={(e) =>
                setState({ ...state, knowBudgetRange: e.target.value })
              }
              value={state.knowBudgetRange}
              items={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
            />
          </div>
          <Spacer height="24px" />

          <questionnaireComponents.QuestionnaireLabel text="2. What is your ideal annual budget range for university tution fee?" />
          <Spacer height="5px" />
          <div className="w-[500px]">
            <inputFields.SelectDropdown
              style={{ width: "50%", paddingLeft: "12px" }}
              onChange={(e) => setState({ ...state, budgetRange: e })}
              placeholder="Search here"
              value={state?.budgetRange}
              items={UtilityArrays?.budgetRange}
            />
          </div>
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="3. How important is it for the university fees to be within your
          budget?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.budgetImportance}
            handleChange={(e) => setState({ ...state, budgetImportance: e })}
          />
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="4. How important are university scholarships for you?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.scholarshipImportance}
            handleChange={(e) =>
              setState({ ...state, scholarshipImportance: e })
            }
          />
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default Financial;
