import React from "react";
import "./styles.css";

function CircleContainer({ page }) {
  const steps = ["qualifications", "preferences", "results"];
  const pageIndex = steps.indexOf(page);

  return (
    <div className="w-[80%] mx-auto">
      <div className="flex items-center">
        {steps.map((step, index) => (
          <React.Fragment key={step}>
            <div className="headerAndCircleContainer">
              <div
                className={
                  index <= pageIndex ? "activeCircleHeader" : "grayCircleHeader"
                }
              >
                {step}
              </div>
              <div
                className={index <= pageIndex ? "activeCircle" : "grayCircle"}
              ></div>
            </div>
            {index < steps.length - 1 && <div className="connectedLine"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default CircleContainer;
