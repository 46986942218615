import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  inputFields,
  Loader,
  datepicker,
} from "../../components";

import { UtilityArrays, Utils } from "../../config";

import "./styles.css";

const TestScores = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [tests, setTests] = useState([
    {
      name: null,
      math: "",
      english: "",
      score: "",
      testDate: "",
    },
  ]);

  useEffect(() => {
    setTests(
      questionnaire?.tests?.length > 0
        ? questionnaire?.tests
        : [
            {
              name: null,
              math: "",
              english: "",
              score: "",
              testDate: "",
            },
          ]
    );
  }, [questionnaire]);

  const addTest = () => {
    const lastTest = tests[tests.length - 1];

    if (
      !lastTest.name ||
      ((lastTest.name === "SAT" || lastTest.name === "ACT") &&
        (!lastTest.math || !lastTest.english)) ||
      ((lastTest.name === "IELTS" || lastTest.name === "TOEFL") &&
        !lastTest.score)
    ) {
      message.error(
        "Please fill in all required fields before adding a new test."
      );
    } else {
      setTests([
        ...tests,
        {
          name: null,
          math: "",
          english: "",
          score: "",
          testDate: "",
        },
      ]);
    }
  };

  const handleTestChange = (index, field, value) => {
    const updatedTests = [...tests];
    updatedTests[index][field] = value;
    setTests(updatedTests);
  };
  const handleNext = () => {
    const filteredTests = tests.filter((test) => {
      if (!test.name) return false;

      if (
        (test.name === "SAT" || test.name === "ACT") &&
        (!test.math || !test.english)
      ) {
        return false;
      }
      if ((test.name === "IELTS" || test.name === "TOEFL") && !test.score) {
        return false;
      }

      return true;
    });

    if (filteredTests.length === 0) {
      message.error("Please fill in all the required fields.");
      return;
    }

    const payload = { userId: profileData?.id, tests: filteredTests };

    dispatch(
      QuestionnaireAction.addQuestionnaire(payload, (res) => {
        if (res.status === 200) {
          setPage("Contacts");
        }
      })
    );
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div className="max-h-[550px] overflow-auto">
            {tests?.map((test, index) => (
              <div className={`${index > 0 ? "mt-6" : ""} `}>
                <div className="section-header">Test {index + 1}</div>
                <div className="grid grid-cols-2 gap-x-7 gap-y-6" key={index}>
                  <div>
                    <questionnaireComponents.QuestionnaireLabel
                      text="Test Name"
                      required
                    />
                    <Spacer height="5px" />
                    <inputFields.SelectDropdown
                      onChange={(e) => handleTestChange(index, "name", e)}
                      placeholder="Please select test name"
                      value={test?.name}
                      items={Utils.filterArray(UtilityArrays.testNames)}
                    />
                  </div>

                  {(test.name === "SAT" || test.name === "ACT") && (
                    <>
                      <div>
                        <questionnaireComponents.QuestionnaireLabel
                          text="Math"
                          required
                        />
                        <Spacer height="5px" />
                        <inputFields.DefaultInput
                          onChange={(e) =>
                            handleTestChange(index, "math", e.target.value)
                          }
                          placeholder="Enter your score"
                          value={test.math}
                          type="number"
                        />
                      </div>
                      <div>
                        <questionnaireComponents.QuestionnaireLabel
                          text="English"
                          required
                        />
                        <Spacer height="5px" />
                        <inputFields.DefaultInput
                          onChange={(e) =>
                            handleTestChange(index, "english", e.target.value)
                          }
                          placeholder="Enter your score"
                          value={test.english}
                          type="number"
                        />
                      </div>
                    </>
                  )}

                  {(test.name === "IELTS" || test.name === "TOEFL") && (
                    <div>
                      <questionnaireComponents.QuestionnaireLabel
                        text="Score"
                        required
                      />
                      <Spacer height="5px" />
                      <inputFields.DefaultInput
                        onChange={(e) =>
                          handleTestChange(index, "score", e.target.value)
                        }
                        placeholder="Enter your score"
                        value={test.score}
                        type="number"
                      />
                    </div>
                  )}

                  <div>
                    <questionnaireComponents.QuestionnaireLabel text="Test Date" />
                    <Spacer height="5px" />
                    <datepicker.DefaultDatepicker
                      onChange={(date, dateString) =>
                        handleTestChange(index, "testDate", dateString)
                      }
                      placeholder="--/--/----"
                      datevalue={test.testDate ?? null}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={addTest} size="medium">
              + Add More
            </Buttons.DefaultButton>
          </div>
          <Spacer height="20px" />
          <div className="flex justify-end">
            <Buttons.DefaultButton onClick={handleNext} size="medium">
              Next
            </Buttons.DefaultButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestScores;
