import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { Buttons, Spacer, Loader, Modal } from "../../components";

import { Images } from "../../config";

import { IndividualStudentAction } from "../../store/actions";

const ManagePayment = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const profileLoading = useSelector((state) => state.profile?.isLoading);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCancel = () => {
    dispatch(
      IndividualStudentAction.cancelSubscription((res) => {
        console.log(res);
      })
    );
  };

  const handleAddSubscription = () => {
    localStorage.setItem("showPaymentPage", true);
  };

  return (
    <div className="text-black px-4 py-1">
      {profileLoading ? (
        <Loader.Circular />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="header-md">Your Payment Plan</div>
          <div className="font-semibold text-[18px]">
            Subscription Plan:{" "}
            <span className="capitalize text-main font-bold">
              {profileData?.individualStudent?.subscriptionPlan}
            </span>
          </div>
          {profileData?.individualStudent?.subscriptionPlan === "pro" && (
            <div className="font-semibold text-[18px]">
              Subscription Type:{" "}
              <span className="capitalize text-main font-bold">
                {profileData?.individualStudent?.paymentType}
              </span>
            </div>
          )}
          {profileData?.individualStudent?.subscriptionPlan === "free" &&
            new Date(profileData?.individualStudent?.validUntil) >
              new Date() && (
              <div className="font-semibold text-[18px]">
                Your Account will be converted to Free on{" "}
                <span className="text-red-500">
                  {moment(profileData?.individualStudent?.validUntil).format(
                    "DD MMMM, YYYY"
                  )}
                </span>{" "}
                at the end of your billing cycle.
              </div>
            )}
          {profileData?.individualStudent?.subscriptionPlan === "free" && (
            <div>
              <Buttons.DefaultButton onClick={handleAddSubscription}>
                Select Payment Plan
              </Buttons.DefaultButton>
            </div>
          )}
          {profileData?.individualStudent?.subscriptionPlan === "pro" &&
            profileData?.individualStudent?.paymentType === "monthly" && (
              <div className="font-semibold text-[18px]">
                Billing cycle:{" "}
                <span className="capitalize text-main font-bold">
                  {moment(profileData?.individualStudent?.startDate).format(
                    "DD MMMM, YYYY"
                  )}{" "}
                  -{" "}
                  {moment(profileData?.individualStudent?.endDate).format(
                    "DD MMMM, YYYY"
                  )}
                </span>
              </div>
            )}
          {profileData?.individualStudent?.subscriptionPlan === "pro" &&
            profileData?.individualStudent?.paymentType === "monthly" && (
              <div>
                <Buttons.RedButton
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Cancel Subscription
                </Buttons.RedButton>
              </div>
            )}
        </div>
      )}
      <Modal.DefaultModal
        open={showConfirmationModal}
        closeIcon={false}
        onCancel={() => setShowConfirmationModal()}
      >
        <div className="flex flex-col items-center justify-center gap-4 px-5 py-4 text-center text-[18px] w-[550px]">
          <img src={Images.ExclamationIcon} className="h-[66px]" />
          <div className="font-bold">ARE YOU SURE?</div>
          <div className="font-semibold">
            If you cancel the subscription, your account will be converted into
            a free account at the end of your current billing period.
          </div>
          <div className="w-full">
            <div className="w-[250px]">
              <Buttons.DefaultButton
                width="full"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </Buttons.DefaultButton>
            </div>
            <div className="w-[250px]">
              <Buttons.WhiteButton width="full" onClick={handleCancel}>
                Continue
              </Buttons.WhiteButton>
            </div>
          </div>
        </div>
      </Modal.DefaultModal>
    </div>
  );
};

export default ManagePayment;
