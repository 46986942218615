import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Empty } from "antd";

import {
  UniversityAction,
  WatchlistAction,
  SearchAction,
} from "../../store/actions";

import {
  Buttons,
  Spacer,
  popover,
  boxContainer,
  inputFields,
  university,
  Loader,
} from "../../components";

import { Images, UtilityArrays } from "../../config";

import "./styles.css";

const Explore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const boxRef = useRef();

  const { exploreSearch } = useSelector((state) => state.search);
  const { trending, isGridLoading } = useSelector((state) => state.university);
  const { watchlists } = useSelector((state) => state.watchlist);

  const { countries, programTypes, programs } = useSelector(
    (state) => state.others
  );

  const [filteredTrending, setFilteredTrending] = useState([]);
  const [filterUsed, setFilterUsed] = useState(false);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    region: [],
    areaOfStudy: [],
    majors: [],
    entranceRequirements: "",
    budget: "",
    scholarships: "",
    admissionRequirements: "",
    sortby: "",
  });

  const countrylist = countries.map((country) => ({
    label: country.name,
    value: country.id,
  }));
  const programTypeslist = programTypes.map((programType) => ({
    label: programType.programType,
    value: programType.id,
  }));
  const programslist = programs.map((program) => ({
    label: program.programName,
    value: program.id,
  }));

  useEffect(() => {
    if (trending && trending.length === 0) {
      dispatch(UniversityAction.getUniversitiesGrid({}));
    }
    dispatch(WatchlistAction.getWatchlist({}));
  }, []);

  useEffect(() => {
    if (filterUsed) {
      handleSearch();
    }
  }, [exploreSearch]);

  useEffect(() => {
    dispatch(
      SearchAction.exploreSearch({
        ...filters,
      })
    );
  }, [filters]);

  useEffect(() => {
    if (watchlists.length > 0) {
      const watchlistedIds = new Set(
        watchlists.map((watchlist) => watchlist?.id)
      );

      const uniFilterTrending = trending?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));

      setFilteredTrending(uniFilterTrending);
    } else {
      setFilteredTrending(trending);
    }
  }, [watchlists, trending]);

  const handleSearch = async () => {
    navigate("/explore/results");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleFilterChange = (updatedFilter) => {
    setFilterUsed(true);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilter,
      offset: 0,
    }));
  };

  const filterButtons = [
    {
      title: "Countries",
      placement: "bottomLeft",
      items: (
        <div className="w-[400px]">
          <inputFields.SelectDropdownMultiple
            items={countrylist}
            onChange={(value) => handleFilterChange({ region: value })}
            value={filters.region}
            allowClear={true}
          />
        </div>
      ),
    },
    {
      title: "Area of Study",
      items: (
        <div className="w-[400px]">
          <inputFields.SelectDropdownMultiple
            items={programTypeslist}
            onChange={(value) =>
              handleFilterChange({ areaOfStudy: value, majors: [] })
            }
            value={filters.areaOfStudy}
            allowClear={true}
          />
        </div>
      ),
    },
    ...(filters.areaOfStudy.length > 0
      ? [
          {
            title: "Majors",
            items: (
              <div className="w-[400px]">
                <inputFields.SelectDropdownMultiple
                  items={programslist}
                  onChange={(value) => handleFilterChange({ majors: value })}
                  value={filters.majors}
                  allowClear={true}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      title: "Budget",
      items: (
        <div className="filter-dropdown">
          {UtilityArrays?.budgetValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleFilterChange({ budget: item.value })}
              className={`${
                filters.budget === item.value
                  ? "active-filter-value-list"
                  : "filter-value-list"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Scholarships",
      items: (
        <div className="filter-dropdown">
          {UtilityArrays?.scholarshipValues?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleFilterChange({ scholarships: item.value })}
              className={`${
                filters.scholarships === item.value
                  ? "active-filter-value-list"
                  : "filter-value-list"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      ),
    },
  ];
  const countryBox = [
    { icon: Images.Exploreusa, title: "USA", id: [1] },
    { icon: Images.Exploreaustralia, title: "Australia", id: [5] },
    { icon: Images.Exploreuk, title: "UK", id: [2] },
    { icon: Images.Exploremorecountries, title: "More Countries", id: [] },
  ];
  const subjectBox = [
    {
      icon: Images.Explorebusiness,
      title: "Business",
      id: [47],
      sortby: "business",
    },
    {
      icon: Images.Exploreengineering,
      title: "Engineering",
      id: [15],
      sortby: "engineeringTechnology",
    },
    {
      icon: Images.Exploresocialsciences,
      title: "Social Sciences",
      id: [44],
      sortby: "socialSciencesManagement",
    },
    {
      icon: Images.Exploremedical,
      title: "Medicine",
      id: [29],
      sortby: "medicine",
    },
    {
      icon: Images.Explorearchitecture,
      title: "Architecture",
      id: [3],
      sortby: "architecture",
    },
    {
      icon: Images.Exploreeducation,
      title: "Education",
      id: [51],
      sortby: "education",
    },
    {
      icon: Images.Explorelegalstudies,
      title: "Legal Studies",
      id: [53],
      sortby: "law",
    },
    {
      icon: Images.Exploreculture,
      title: "Communication",
      id: [48],
      sortby: "communication",
    },
  ];
  const qsBox = [
    {
      icon: <img src={Images.Exploreglobe} className="h-[36px]" />,
      title: "Best Globally",
      id: [],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#fde3cf",
            color: "#f56a00",
          }}
          shape="square"
          size={36}
        >
          US
        </Avatar>
      ),
      title: "Best in USA",
      id: [1],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#e3ffef",
            color: "#44b977",
          }}
          shape="square"
          size={36}
        >
          UK
        </Avatar>
      ),
      title: "Best in UK",
      id: [2],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#dcceff",
            color: "#9b73ff",
          }}
          shape="square"
          size={36}
        >
          AU
        </Avatar>
      ),
      title: "Best in Australia",
      id: [5],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#ffc6c6",
            color: "#fc6868",
          }}
          shape="square"
          size={36}
        >
          CA
        </Avatar>
      ),
      title: "Best in Canada",
      id: [9],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#fde3cf",
            color: "#f56a00",
          }}
          shape="square"
          size={36}
        >
          AE
        </Avatar>
      ),
      title: "Best in UAE",
      id: [37],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#d8d8d8",
            color: "black",
          }}
          shape="square"
          size={36}
        >
          SG
        </Avatar>
      ),
      title: "Best in Singapore",
      id: [4],
    },
    {
      icon: (
        <Avatar
          style={{
            backgroundColor: "#c2c7fb",
            color: "#7580ff",
          }}
          shape="square"
          size={36}
        >
          MY
        </Avatar>
      ),
      title: "Best in Malaysia",
      id: [14],
    },
  ];

  return (
    <div className="universities">
      <div className="flex gap-2">
        <Buttons.DefaultButton size="small">All</Buttons.DefaultButton>
        <Buttons.DefaultButton size="small">
          University Tags
        </Buttons.DefaultButton>
      </div>
      <Spacer height={20} />
      <div className="flex items-center justify-center gap-2">
        <inputFields.DefaultSearchBox
          onChange={(e) => {
            setFilters({
              ...filters,
              search: e.target.value,
            });
          }}
          onKeyDown={handleKeyDown}
        />
        <div className="w-[120px]">
          <Buttons.DefaultButton
            size="medium"
            width="full"
            style={{ height: "48px" }}
            onClick={() => handleSearch()}
          >
            Search
          </Buttons.DefaultButton>
        </div>
      </div>
      <Spacer height={20} />
      <div className="flex gap-2">
        {filterButtons?.map((item, index) => (
          <popover.WhitePopover
            content={item.items}
            placement={item?.placement ?? "bottom"}
            key={index}
          >
            <Buttons.DefaultButton size="small">
              {item?.title}
            </Buttons.DefaultButton>
          </popover.WhitePopover>
        ))}
      </div>
      <Spacer height={30} />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 flex flex-col gap-2">
          <div className="box-title">Explore by Country</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="grid grid-cols-4 gap-4 justify-between h-full">
              {countryBox?.map((item, index) => (
                <div
                  className="explore-boxes"
                  key={index}
                  onClick={() => handleFilterChange({ region: item.id })}
                >
                  <img src={item.icon} className="h-[60px] w-[60px]" />
                  <div className="font-semibold text-[18px]">{item?.title}</div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          <div className="box-title">Popular Universities</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            {isGridLoading ? (
              <Loader.Circular />
            ) : filteredTrending?.length === 0 ? (
              <Empty />
            ) : (
              <div ref={boxRef}>
                <university.ExploreSwiper
                  data={filteredTrending}
                  width={boxRef?.current?.clientWidth}
                />
              </div>
            )}
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
      <Spacer height={30} />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 flex flex-col gap-2">
          <div className="box-title">Explore by Subject Category</div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="grid grid-cols-4 gap-4 justify-between h-full">
              {subjectBox?.map((item, index) => (
                <div
                  className="explore-boxes"
                  key={index}
                  onClick={() =>
                    handleFilterChange({
                      areaOfStudy: item.id,
                      sortby: item?.sortby,
                    })
                  }
                >
                  <img src={item.icon} className="h-[60px] w-[60px]" />
                  <div className="font-semibold text-[18px]">{item?.title}</div>
                </div>
              ))}
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          <div className="box-title flex gap-2">
            Best Ranked Universities{" "}
            <img src={Images.Unigeneralqs} className="h-[30px]" />
          </div>
          <boxContainer.LiteBoxContainer addstyle="h-full">
            <div className="grid grid-cols-2 gap-2 justify-between">
              <div className="explore-qs-box">
                <img src={Images.Exploreqs} className="w-full rounded-t-2xl" />
                <div className="p-3 flex flex-col gap-2">
                  <div className="text-[16px] font-semibold text-[#38527F]">
                    QS World University of Ranking
                  </div>
                  <div className="text-[12px] font-medium text-justify text-[#38527F]">
                    Admission to the University based on a holistic review of
                    grades, GPA, the strength of curriculum, test scores (if
                    submitted), recommendation letters, personal essays,
                    co-curricular and extracurricular.
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {qsBox?.map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-2 pb-4 border-b border-b-gray-300"
                  >
                    <div>{item?.icon}</div>
                    <div>
                      <div className="text-[14px] font-semibold">
                        {item?.title}
                      </div>
                      <div
                        className="text-blue-400 underline text-[12px] cursor-pointer"
                        onClick={() =>
                          handleFilterChange({
                            region: item.id,
                          })
                        }
                      >
                        View Universities
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </boxContainer.LiteBoxContainer>
        </div>
      </div>
    </div>
  );
};

export default Explore;
