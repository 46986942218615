import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { Images } from "../../config";

const Contacts = ({ contacts, changeChat, activeUser }) => {
  const { onlineUsers } = useSelector((state) => state?.others);
  const unread = useSelector((state) => state.chatbot.unreadMessages);

  const changeCurrentChat = (contact) => {
    changeChat(contact);
  };

  return (
    <div className="flex flex-col h-full max-h-[calc(100vh-250px)] overflow-auto rounded-l-[24px]">
      {contacts?.map((contact) => {
        let active = null;

        if (onlineUsers.includes(contact.userId)) {
          active = "Active Now";
        } else if (contact.lastActive) {
          active = `Active ${moment(contact.lastActive).fromNow()}`;
        }

        return (
          <div
            key={contact.userId}
            className={`${
              contact?.userId === activeUser?.userId
                ? "contact-active"
                : "contact"
            } relative`}
            onClick={() => changeCurrentChat(contact)}
          >
            <div className="flex justify-center">
              <img
                src={contact?.profileImage ?? Images.DummyImage}
                alt=""
                className="contact-image"
              />
            </div>
            <div className="flex flex-col gap-2  p-2">
              <div className="text-[18px] font-bold">{contact.name}</div>
              {active && <div>{active}</div>}
              {unread?.some((item) => item.sender === contact.userId) && (
                <div className="absolute top-1 right-1 h-3 w-3 bg-main rounded-full"></div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Contacts;
