import React from "react";
import { Images, Videos } from "../../config"; // Assuming Videos contains the path to your login video
import "./login-layout.css";

const LoginLayout = (props) => {
  if (props?.img) {
    return (
      <div
        className="login-layout-img"
        style={{ backgroundImage: `url(${Images?.LoginBackground})` }}
      >
        <div className="max-h-[90vh] overflow-y-auto overflow-x-hidden rounded-[51px] shadow-loginContainer ml-[80px]">
          {props?.children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="login-layout-video">
        <video
          autoPlay
          muted
          loop
          className="background-video"
          src={Images?.LoginVideo}
        />
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundColor: "#b9b9b981",
          }}
        />
        <div className="overlay-content">
          <div className="max-h-[95vh] overflow-y-auto overflow-x-hidden rounded-[51px] shadow-loginContainer">
            {props?.children}
          </div>
        </div>
      </div>
    );
  }
};

export default LoginLayout;
