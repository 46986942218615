import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

import ChatbotPopupChat from "./chatbot-popup-chats";

const ChatbotPopupSupport = () => {
  const socket = useRef();

  const { support } = useSelector((state) => state.chatbot);
  const profileData = useSelector((state) => state.profile?.user);

  const [activeUser, setActiveUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setCurrentUser(profileData.id);
  }, [profileData]);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL);
    }
  }, [currentUser]);

  useEffect(() => {
    setActiveUser(support[0]);
  }, [currentUser, support]);

  return (
    <div className="flex flex-col w-full gap-1">
      <ChatbotPopupChat currentChat={activeUser} socket={socket} />
    </div>
  );
};

export default ChatbotPopupSupport;
