import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OthersAction } from "../../store/actions";

import Loader from "../loader";
import { Images } from "../../config";

import "./styles.css";

const DefaultFileUpload = (props) => {
  const dispatch = useDispatch();
  const fileSelect = useRef();

  const { isUploadLoading } = useSelector((state) => state.others);

  const handleFileUpload = (file) => {
    const data = new FormData();
    data.append("file", file);

    dispatch(
      OthersAction.uploadFile(data, (res) => {
        const fileLink = res.data;
        props?.onChange(fileLink);

        if (fileSelect.current) {
          fileSelect.current.value = "";
        }
      })
    );
  };

  return (
    <div
      onClick={() => {
        fileSelect.current.click();
      }}
      className={`${
        props?.disabled ? "default-file-input-disabled" : "default-file-input"
      }`}
    >
      <input
        style={{ display: "none" }}
        type="file"
        name="image"
        id={`getFile-${props?.id}`}
        ref={fileSelect}
        onChange={(event) => {
          handleFileUpload(event.target.files[0]);
        }}
        disabled={(props?.disabled || isUploadLoading) ?? false}
        accept={
          props?.type === "image"
            ? ".jpg, .jpeg, .png"
            : ".pdf, .csv, .xlsx, .doc, .docx"
        }
      />
      <div
        className={`${
          props?.value ? "opacity-[100%]" : "opacity-[50%]"
        } text-[14px]`}
      >
        {props?.value ? "File uploaded" : "Select a file"}
      </div>
      <div>
        {isUploadLoading ? (
          <Loader.Circular size={22} />
        ) : (
          <img
            src={props?.icon ?? Images.DefaultUpload}
            className="h-[18px] pr-2"
          />
        )}
      </div>
    </div>
  );
};

export default DefaultFileUpload;
