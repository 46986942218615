import DefaultButton from "./default-button";
import RedButton from "./red-button";
import WhiteButton from "./white-button";
import WhiteButtonWithoutBorder from "./white-button-without-border";

export default {
  DefaultButton,
  RedButton,
  WhiteButton,
  WhiteButtonWithoutBorder,
};
