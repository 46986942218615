import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Empty } from "antd";

import {
  inputFields,
  Spacer,
  boxContainer,
  GetProPopup,
} from "../../components";

import { Images } from "../../config";

const VisaInformation = () => {
  const { visaInfo } = useSelector((state) => state?.others);
  const { questionnaire } = useSelector((state) => state?.questionnaire);
  const profileData = useSelector((state) => state?.profile?.user);

  const [countryList, setCountryList] = useState([]);
  const [visaInformation, setVisaInformation] = useState(null);
  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    const filteredCountries = visaInfo?.map((country) => ({
      label: country.name,
      value: country.id,
    }));
    setCountryList(filteredCountries);
  }, [visaInfo]);

  useEffect(() => {
    visaInfo?.map((visaInfo) => {
      if (visaInfo.id === countryId) {
        setVisaInformation(visaInfo.visaInfos);
      }
    });
  }, [countryId]);

  useEffect(() => {
    visaInfo?.map((visaInfo) => {
      if (questionnaire?.regionPreference?.length > 0) {
        if (visaInfo.name === questionnaire?.regionPreference[0]) {
          setCountryId(visaInfo.id);
        }
      } else {
        if (visaInfo.name === profileData?.country) {
          setCountryId(visaInfo.id);
        }
      }
    });
  }, [profileData, visaInfo, questionnaire]);

  const generalInfo = [
    {
      icon: Images.Unifinanceaccommodation,
      title: "Cost of Application",
      subTitle: "ONE TIME",
      value: visaInformation?.costOfVisaApp ?? "NA",
    },
    {
      icon: Images.Unifinanceaccommodation,
      title: "Medical Fees",
      subTitle: "PER YEAR",
      value: visaInformation?.medicalFees ?? "NA",
    },
    {
      icon: Images.Unifinancefood,
      title: "Duration of Visa",
      value: visaInformation?.visaDuration ?? "NA",
    },
    {
      icon: Images.Unifinancefood,
      title: "Visa Processing Time",
      value: visaInformation?.visaProcessingTime ?? "NA",
    },
  ];

  return (
    <div className="text-black relative">
      {profileData?.freeUser && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <div className={`${profileData?.freeUser && "blur pointer-events-none"}`}>
        <div
          className="university-hero-section h-[360px] flex items-end"
          style={{
            backgroundImage: `url(${
              visaInformation?.countryPicture ?? Images?.VisaInfoCoverDefault
            })`,
          }}
        >
          <div className="flex gap-3 items-center py-6 px-7 w-full bg-gradient-to-t from-[#000000] rounded-b-2xl">
            <div className="text-[18px] font-semibold text-white">
              Select Country
            </div>
            <div className="w-[300px]">
              <inputFields.SelectDropdown
                items={countryList}
                onChange={(e) => setCountryId(e)}
                value={countryId}
              />
            </div>
          </div>
        </div>

        <div className="p-2">
          <Spacer height={20} />
          {visaInformation ? (
            <>
              <div className="grid grid-cols-3 gap-3">
                <div className="col-span-2 flex flex-col gap-2">
                  <div className="box-title">General Information</div>
                  <boxContainer.LiteBoxContainer addstyle="h-full">
                    <div className="flex justify-around items-center h-full">
                      {generalInfo?.map((item, index) => (
                        <div
                          className="flex flex-col gap-2 items-center justify-center"
                          key={index}
                        >
                          <img src={item?.icon} className="h-[60px] w-[60px]" />
                          <div className="box-content-title pb-2 border-b-[2px] border-b-gray-300 w-full text-center">
                            {item.title} <br />
                            <div className="text-[10px] text-main w-full text-center h-[12px]">
                              {item.subTitle}
                            </div>
                          </div>
                          <div className="box-content-value">{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </boxContainer.LiteBoxContainer>
                </div>
                <div className="col-span-1 flex flex-col gap-2 ">
                  <div className="box-title">Additional Information</div>
                  <boxContainer.LiteBoxContainer addstyle="h-full">
                    <div className="h-full font-medium text-justify">
                      {visaInformation?.visaDescription ?? <Empty />}
                    </div>
                  </boxContainer.LiteBoxContainer>
                </div>
              </div>
              <Spacer height={30} />
              <div className="grid grid-cols-3 gap-3">
                {visaInformation?.documentsRequired?.length > 0 && (
                  <div className="col-span-1 flex flex-col gap-2">
                    <div className="box-title">Documents Required</div>
                    <boxContainer.LiteBoxContainer addstyle="h-full">
                      <div className="flex flex-col gap-2 max-h-[400px] overflow-auto pr-1">
                        {visaInformation?.documentsRequired?.map(
                          (item, index) => (
                            <div
                              className="h-full font-medium flex w-full gap-2"
                              key={index}
                            >
                              <div>
                                <div className="h-3 w-3 rounded-full bg-main mt-2"></div>
                              </div>
                              <div className="text-justify">
                                {item?.document}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </boxContainer.LiteBoxContainer>
                  </div>
                )}
                {visaInformation?.healthInsurance?.length > 0 && (
                  <div className="col-span-1 flex flex-col gap-2 ">
                    <div className="box-title">Health Insurance</div>
                    <boxContainer.LiteBoxContainer addstyle="h-full">
                      <div className="flex flex-col gap-2 max-h-[400px] overflow-auto pr-1">
                        <div className="font-bold">
                          {visaInformation?.healthInsurance[0].text}
                        </div>
                        {visaInformation?.healthInsurance?.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="h-full font-medium flex w-full gap-2"
                                key={index}
                              >
                                <div>
                                  <div className="h-3 w-3 rounded-full bg-main mt-2"></div>
                                </div>
                                <div className="text-justify">{item?.text}</div>
                              </div>
                            )
                        )}
                      </div>
                    </boxContainer.LiteBoxContainer>
                  </div>
                )}
                {visaInformation?.pointsToNote?.length > 0 && (
                  <div className="col-span-1 flex flex-col gap-2 ">
                    <div className="box-title">Points to Note</div>
                    <boxContainer.LiteBoxContainer addstyle="h-full">
                      <div className="flex flex-col gap-2 max-h-[400px] overflow-auto pr-1">
                        {visaInformation?.pointsToNote?.map((item, index) => (
                          <div
                            className="h-full font-medium flex w-full gap-2"
                            key={index}
                          >
                            <div>
                              <div className="h-3 w-3 rounded-full bg-main mt-2"></div>
                            </div>
                            <div className="text-justify">{item?.point}</div>
                          </div>
                        ))}
                      </div>
                    </boxContainer.LiteBoxContainer>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="text-[28px] text-main text-center font-bold p-4">
              Please Select a Country to View Visa Information Details!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisaInformation;
