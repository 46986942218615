import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { IoCloudDownloadOutline } from "react-icons/io5";

import { OthersAction, ApplicationAction } from "../../store/actions";

import { dropdown, Spacer } from "..";
import Loader from "../loader";
import { Modal } from "..";
import { Images } from "../../config";

import StatusIndicator from "../status-indicator";

import "./styles.css";

const ApplicationRow = (props) => {
  const appData = props?.item;

  const dispatch = useDispatch();
  const fileSelect = useRef();

  const [isRowLoading, setIsRowLoading] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);

  const modalData = [
    { title: "University Name", value: appData?.university?.name },
    {
      title: "Date of Submission",
      value: appData?.dateOfApplication
        ? moment(appData?.dateOfApplication).format("DD MMMM, YYYY")
        : "NA",
    },
    { title: "Application Status", value: appData?.status },
    {
      title: "Application Submitted",
      value: appData?.submittedApplication ? "Yes" : "No",
    },
  ];
  const handleFileUpload = (file) => {
    const data = new FormData();
    data.append("file", file);
    setIsRowLoading(true);

    dispatch(
      OthersAction.uploadFile(data, (res) => {
        const fileLink = res.data;
        setIsRowLoading(false);
        if (fileLink) {
          const payload = { proof: fileLink, applicationId: appData.id };
          dispatch(
            ApplicationAction.uploadApplicationProof(payload, () => {
              dispatch(
                ApplicationAction.getOngoingApplications({
                  limitOngoing: 10,
                  offsetOngoing: 0,
                })
              );
              dispatch(
                ApplicationAction.getSubmittedApplications({
                  limitSubmitted: 10,
                  offsetSubmitted: 0,
                })
              );
            })
          );
        }

        if (fileSelect.current) {
          fileSelect.current.value = "";
        }
      })
    );
  };

  const handleRequestLoR = () => {
    console.log(appData.id);
  };

  const items = [
    {
      label: (
        <div
          onClick={() => props?.handleStatusChange(appData.id, "Pending")}
          className={`text-center ${
            appData?.status === "Pending" ? "font-semibold" : "font-normal"
          }`}
        >
          Pending
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={() => props?.handleStatusChange(appData.id, "Accepted")}
          className={`text-center ${
            appData?.status === "Accepted" ? "font-semibold" : "font-normal"
          }`}
        >
          Accepted
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={() => props?.handleStatusChange(appData.id, "Rejected")}
          className={`text-center ${
            appData?.status === "Rejected" ? "font-semibold" : "font-normal"
          }`}
        >
          Rejected
        </div>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={() => props?.handleStatusChange(appData.id, "Waitlisted")}
          className={`text-center ${
            appData?.status === "Waitlisted" ? "font-semibold" : "font-normal"
          }`}
        >
          Waitlisted
        </div>
      ),
      key: "4",
    },
  ];

  return (
    <div key={props.index} className="application-row">
      <div className="flex gap-3 w-full">
        <div className="approw-image-container">
          <img src={appData?.university?.profilePic} className="approw-image" />
        </div>
        <div className="flex flex-col justify-center gap-2 w-[83%]">
          <div className="app-row-heading">
            {appData?.university?.name} Application
          </div>
          <div className="app-row-subheading">{appData?.university?.name}</div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-2 pl-4">
        <div className="app-row-heading">
          {appData?.university?.deadlines?.length > 0
            ? appData?.university?.deadlines[0].deadlineType
            : "NA"}
        </div>
        <div className="app-row-subheading">
          {appData?.university?.deadlines?.length > 0
            ? appData?.university?.deadlines[0].appDeadline
              ? moment(appData?.university?.deadlines[0].appDeadline).format(
                  "DD MMMM, YYYY"
                )
              : "NA"
            : "NA"}
        </div>
      </div>
      {!appData?.submittedApplication ? (
        <div className="flex justify-start">
          <div className="flex flex-col justify-center items-center gap-2">
            <div className="app-row-heading">Letter of Recommendation</div>
            <div
              className="px-4 py-2 bg-main text-[14px] font-bold rounded-lg text-white cursor-pointer"
              onClick={handleRequestLoR}
            >
              Request Now
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center gap-2">
          <div className="app-row-heading">Date of Submission</div>
          <div className="app-row-subheading">
            {appData?.dateOfApplication
              ? moment(appData?.dateOfApplication).format("DD MMMM, YYYY")
              : "NA"}
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        {!appData?.submittedApplication ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <div className="app-row-heading">Proof of Application</div>
            <div
              onClick={() => {
                fileSelect.current.click();
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                name="image"
                id={`getFile-${props?.id}`}
                ref={fileSelect}
                onChange={(event) => {
                  handleFileUpload(event.target.files[0]);
                }}
                accept={
                  props?.type === "image"
                    ? ".jpg, .jpeg, .png"
                    : ".pdf, .csv, .xlsx, .doc, .docx"
                }
              />

              <div>
                {isRowLoading ? (
                  <Loader.Circular size={20} />
                ) : (
                  <img
                    src={Images.DefaultUpload}
                    className="h-[20px] w-[20px] cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2">
            <div className="app-row-heading">Decision</div>
            <dropdown.DefaultDropdown items={items} placement="bottom">
              <div className="app-row-subheading">
                <StatusIndicator
                  text={appData?.status}
                  color={
                    appData?.status?.toLowerCase() === "accepted"
                      ? "green"
                      : appData?.status?.toLowerCase() === "rejected"
                      ? "red"
                      : appData?.status?.toLowerCase() === "waitlisted"
                      ? "blue"
                      : appData?.status?.toLowerCase() === "pending"
                      ? "yellow"
                      : "gray"
                  }
                />
              </div>
            </dropdown.DefaultDropdown>
          </div>
        )}
        <img
          src={Images.RightArrowCircled}
          onClick={() => setApplicationModal(true)}
          className="cursor-pointer"
        />
      </div>
      <Modal.DefaultModal
        open={applicationModal}
        onCancel={() => setApplicationModal(false)}
        closable
      >
        <div className="flex flex-col items-center justify-center w-[600px] p-4">
          <div className="text-[26px] font-bold">Application Details</div>
          <Spacer height={20} />
          <div className="flex flex-col gap-2 w-full">
            {modalData?.map((item, index) => (
              <div className="grid grid-cols-2" key={index}>
                <div className="text-left font-semibold text-[18px]">
                  {item?.title}
                </div>
                <div className="text-right font-medium text-[18px]">
                  {item?.value}
                </div>
              </div>
            ))}
          </div>
          <Spacer height={20} />
          {appData?.submittedApplication && (
            <div className="w-full flex flex-col gap-2">
              <div className="text-left font-semibold text-[18px]">
                Proof of Application
              </div>
              <div
                className="border border-dashed border-main rounded-xl p-4 flex justify-center items-center flex-col gap-3 w-full cursor-pointer"
                onClick={() =>
                  window.open(appData?.proofOfApplication, "_blank")
                }
              >
                <IoCloudDownloadOutline className="text-[50px] text-main" />
                <div className="text-[18px] text-main font-semibold">
                  Click Here to Download
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.DefaultModal>
    </div>
  );
};

export default ApplicationRow;
