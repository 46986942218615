import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

import { OthersAction, InteractiveLearningAction } from "../../store/actions";

import { Spacer, Loader } from "../../components";

const OnlineCourses = (props) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state?.profile?.user);
  const udemy = useSelector((state) => state?.interactiveLearning?.udemy);
  const udemyLoading = useSelector(
    (state) => state?.interactiveLearning?.isUdemyLoading
  );

  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    const payload = {
      pageNo,
      search: props?.search,
      filter: props?.filter,
    };
    dispatch(
      InteractiveLearningAction.getUdemyCourses(payload, () => {
        dispatch(OthersAction.getTasks());
      })
    );
  }, [pageNo, props?.search, props?.filter]);

  const handlePagination = (pageNumber) => {
    setPageNo(pageNumber);
  };

  return (
    <div>
      {udemyLoading ? (
        <Loader.Circular />
      ) : (
        <div className="item-container">
          {udemy?.results?.map((course) => {
            return (
              <Link
                className="item"
                to={`https://www.udemy.com${course.url}`}
                target="_blank"
                key={course.title}
              >
                <div>
                  <img src={course.image_480x270} className="item-image" />
                </div>
                <div className="item-price">{course.price}</div>
                <div className="item-title">
                  {course.title.length > 50
                    ? `${course.title.slice(0, 50)}...`
                    : course.title}
                </div>
                <div className="h-[1px] w-[90%] bg-gray-300"></div>
                <div className="item-instructor">
                  <img
                    src={course?.visible_instructors[0].image_50x50}
                    className="rounded-full h-[32px] w-[32px]"
                  />
                  <div>
                    <div className="font-semibold">
                      {course?.visible_instructors[0].display_name}
                    </div>
                    {course?.visible_instructors[0].job_title && (
                      <div className="font-semibold text-main">
                        {course?.visible_instructors[0].job_title}
                      </div>
                    )}
                  </div>
                </div>
                <div className="h-[1px] w-[90%] bg-gray-300"></div>
                <div className="flex justify-start text-main font-semibold text-[14px] my-2">
                  Learn More
                </div>
                <Spacer height={10} />
              </Link>
            );
          })}
        </div>
      )}
      <Spacer height={10} />
      {!profileData?.freeUser && (
        <div className="flex justify-center items-center">
          <Pagination
            responsive="true"
            showSizeChanger={false}
            hideOnSinglePage={true}
            current={pageNo}
            pageSize={10}
            total={udemy?.count}
            onChange={handlePagination}
          />
        </div>
      )}
    </div>
  );
};

export default OnlineCourses;
