import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";

import { IoMdCloseCircle } from "react-icons/io";

import {
  Spacer,
  boxContainer,
  inputFields,
  recommender,
  Buttons,
  GetProPopup,
} from "../../components";

import CircleContainer from "./circle-container";

import "./styles.css";

function RecommendersQualifications() {
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.profile?.user);

  const [qualification, setQualification] = useState("aLevels");
  const [expectedGrade, setExpectedGrade] = useState({
    expectedGrade1: undefined,
    expectedGrade2: undefined,
    expectedGrade3: undefined,
    expectedGrade4: undefined,
  });
  const [toefl, setToefl] = useState("");
  const [ielts, setIelts] = useState("");
  const [satAll, setSatAll] = useState("");
  const [satEnglish, setSatEnglish] = useState("");
  const [satMath, setSatMath] = useState("");
  const [showSatMathEnglish, setShowSatMathEnglish] = useState(false);
  const [state, setState] = useState({
    grades: [{ subject: "", grade: "" }],
  });

  const handleNext = () => {
    if (!profileData?.freeUser) {
      if (
        (qualification === "aLevels" || qualification === "stpm") &&
        (!expectedGrade.expectedGrade1 ||
          !expectedGrade.expectedGrade2 ||
          !expectedGrade.expectedGrade3)
      ) {
        message.error("Please fill all the required fields");
      } else if (
        (qualification === "ibDiploma" || qualification === "fsc") &&
        !expectedGrade.expectedGrade1
      ) {
        message.error("Please fill all the required fields");
      } else if (
        (satEnglish || satMath || satAll) &&
        parseInt(satEnglish) + parseInt(satMath) !== parseInt(satAll)
      ) {
        message.error(
          "Your total SAT score does not match the total SAT score"
        );
      } else {
        navigate("/recommender/preferences", {
          state: {
            qualification,
            expectedGrade: expectedGrade,
            grades: !state.grades[0].subject ? [] : state.grades,
            toefl: toefl ? toefl : "0",
            ielts: ielts ? ielts : "0",
            satAll: satAll ? satAll : "0",
            satEnglish: satEnglish ? satEnglish : "0",
            satMath: satMath ? satMath : "0",
          },
        });
      }
    }
  };

  const handleAddMoregrades = () => {
    if (!profileData?.freeUser) {
      let checkFieldsValidation = checkFields();
      if (checkFieldsValidation) {
        message.error("Input atleast one grade before adding another.");
      } else {
        setState({
          ...state,
          grades: [...state.grades, { subject: "", grade: "" }],
        });
      }
    }
  };

  const handleRemovegrades = (index) => {
    if (!profileData?.freeUser) {
      let array = [...state.grades];
      array.splice(index, 1);
      setState({
        ...state,
        grades: array,
      });
    }
  };

  const checkFields = () => {
    if (!profileData?.freeUser) {
      let flag = false;
      state.grades.forEach((item) => {
        if (!item.subject || !item.grade) {
          flag = true;
        }
      });
      return flag;
    }
  };

  const qualificationList = [
    { value: "aLevels", label: "A-Levels" },
    { value: "ibDiploma", label: "IB Diploma" },
    { value: "stpm", label: "STPM" },
    { value: "fsc", label: "FSC" },
  ];
  const gradeList = [
    { value: "A*", label: "A*" },
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "U", label: "U" },
  ];

  return (
    <div className="relative">
      {profileData?.freeUser && (
        <div className="absolute top-[100px] left-[35%] z-[10]">
          <GetProPopup />
        </div>
      )}
      <boxContainer.LiteBoxContainer>
        <div
          className={`${profileData?.freeUser && "blur pointer-events-none"}`}
        >
          <CircleContainer page="qualifications" />
          <Spacer height={20} />
          <div>
            <div className="inputFieldContainer">
              <div>
                <recommender.RecommenderBoxLabel
                  title="Current Qualification"
                  required={true}
                />
                <Spacer height={10} />
                <inputFields.SelectDropdown
                  disabled={profileData?.freeUser}
                  items={qualificationList}
                  value={qualification}
                  onChange={(e) => {
                    setQualification(e);
                    setExpectedGrade({
                      expectedGrade1: undefined,
                      expectedGrade2: undefined,
                      expectedGrade3: undefined,
                      expectedGrade4: undefined,
                    });
                  }}
                />
              </div>
              {qualification === "aLevels" || qualification === "stpm" ? (
                <>
                  <div>
                    <recommender.RecommenderBoxLabel
                      title="Expected Grade"
                      required={true}
                    />
                    <Spacer height={10} />
                    <inputFields.SelectDropdown
                      disabled={profileData?.freeUser}
                      placeholder="A*"
                      items={gradeList}
                      value={expectedGrade.expectedGrade1}
                      onChange={(e) => {
                        setExpectedGrade({
                          ...expectedGrade,
                          expectedGrade1: e,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <recommender.RecommenderBoxLabel
                      title="Expected Grade"
                      required={true}
                    />
                    <Spacer height={10} />
                    <inputFields.SelectDropdown
                      disabled={profileData?.freeUser}
                      placeholder="A*"
                      items={gradeList}
                      value={expectedGrade.expectedGrade2}
                      onChange={(e) => {
                        setExpectedGrade({
                          ...expectedGrade,
                          expectedGrade2: e,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <recommender.RecommenderBoxLabel
                      title="Expected Grade"
                      required={true}
                    />
                    <Spacer height={10} />
                    <inputFields.SelectDropdown
                      disabled={profileData?.freeUser}
                      placeholder="A*"
                      items={gradeList}
                      value={expectedGrade.expectedGrade3}
                      onChange={(e) => {
                        setExpectedGrade({
                          ...expectedGrade,
                          expectedGrade3: e,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <recommender.RecommenderBoxLabel title="Expected Grade" />
                    <Spacer height={10} />
                    <inputFields.SelectDropdown
                      disabled={profileData?.freeUser}
                      placeholder="A*"
                      items={gradeList}
                      value={expectedGrade.expectedGrade4}
                      onChange={(e) => {
                        setExpectedGrade({
                          ...expectedGrade,
                          expectedGrade4: e,
                        });
                      }}
                    />
                  </div>
                </>
              ) : qualification === "ibDiploma" ? (
                <div>
                  <recommender.RecommenderBoxLabel
                    title="Expected Grade"
                    required={true}
                  />
                  <Spacer height={10} />
                  <inputFields.DefaultInput
                    disabled={profileData?.freeUser}
                    placeholder="0-45"
                    type="number"
                    value={expectedGrade.expectedGrade1}
                    onChange={(e) =>
                      setExpectedGrade({
                        ...expectedGrade,
                        expectedGrade1: e.target.value,
                      })
                    }
                  />
                </div>
              ) : qualification === "fsc" ? (
                <div>
                  <recommender.RecommenderBoxLabel
                    title="Percentage"
                    required={true}
                  />
                  <Spacer height={10} />
                  <inputFields.DefaultInput
                    disabled={profileData?.freeUser}
                    placeholder="0-100%"
                    type="number"
                    value={expectedGrade.expectedGrade1}
                    onChange={(e) =>
                      setExpectedGrade({
                        ...expectedGrade,
                        expectedGrade1: e.target.value,
                      })
                    }
                  />
                </div>
              ) : null}
            </div>

            <Spacer height={20} />

            <div className="flex justify-end">
              <Buttons.DefaultButton
                disabled={profileData?.freeUser}
                onClick={handleAddMoregrades}
                size="medium"
              >
                Add Subject & Grade
              </Buttons.DefaultButton>
            </div>
            <div className="flex flex-col gap-3 ml-5">
              {state.grades.map((item, index) => (
                <div className="flex" key={index}>
                  <div className="flex items-center w-[65%]">
                    <div className="flex flex-col w-[50%]">
                      <recommender.RecommenderBoxLabel title="Subject" />
                      <Spacer height={10} />
                      <inputFields.DefaultInput
                        disabled={profileData?.freeUser}
                        placeholder="English"
                        value={item.subject}
                        onChange={(e) => {
                          let newValue = [...state.grades];

                          newValue[index].subject = e.target.value;
                          setState({
                            grades: newValue,
                          });
                        }}
                      />
                    </div>
                    <Spacer width={50} />
                    <div className="flex flex-col w-[50%]">
                      <recommender.RecommenderBoxLabel title="Grade" />
                      <Spacer height={10} />
                      <inputFields.DefaultInput
                        disabled={profileData?.freeUser}
                        placeholder="A* / 80%"
                        value={item.grade}
                        onChange={(e) => {
                          let newValue = [...state.grades];

                          newValue[index].grade = e.target.value;
                          setState({
                            grades: newValue,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {state.grades.length > 0 && index !== 0 && (
                    <IoMdCloseCircle
                      className="mt-11 ml-2 text-red-600 cursor-pointer"
                      fontSize={30}
                      onClick={() => handleRemovegrades(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <Spacer height={20} />
          <div className="inputFieldContainer">
            <div>
              <recommender.RecommenderBoxLabel title="TOEFL Score" />
              <Spacer height={10} />
              <inputFields.DefaultInput
                disabled={profileData?.freeUser}
                placeholder="0-120"
                type="number"
                value={toefl}
                onChange={(e) => setToefl(e.target.value)}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel title="IELTS Score" />
              <Spacer height={10} />
              <inputFields.DefaultInput
                disabled={profileData?.freeUser}
                placeholder="1-9"
                type="number"
                value={ielts}
                onChange={(e) => setIelts(e.target.value)}
              />
            </div>
            <div>
              <recommender.RecommenderBoxLabel title="SAT Score" />
              <Spacer height={10} />
              <inputFields.DefaultInput
                disabled={profileData?.freeUser}
                placeholder="0-1600"
                type="number"
                value={satAll}
                onChange={(e) => setSatAll(e.target.value)}
                onClick={() => setShowSatMathEnglish(true)}
              />
            </div>
            {showSatMathEnglish && (
              <>
                <div>
                  <recommender.RecommenderBoxLabel title="SAT (English)" />

                  <Spacer height={10} />
                  <inputFields.DefaultInput
                    disabled={profileData?.freeUser}
                    placeholder="0 - 800"
                    type="number"
                    value={satEnglish}
                    onChange={(e) => setSatEnglish(e.target.value)}
                  />
                </div>
                <div>
                  <recommender.RecommenderBoxLabel title="SAT (Math)" />
                  <Spacer height={10} />
                  <inputFields.DefaultInput
                    disabled={profileData?.freeUser}
                    placeholder="0 - 800"
                    type="number"
                    value={satMath}
                    onChange={(e) => setSatMath(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                disabled={profileData?.freeUser}
                onClick={handleNext}
                size="medium"
                width="full"
              >
                Continue
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      </boxContainer.LiteBoxContainer>
    </div>
  );
}

export default RecommendersQualifications;
