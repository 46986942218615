import React from "react";

const QuestionnaireLabel = (props) => {
  return (
    <div className="section-input-title">
      {props?.text} {props?.required && <span className="text-red-600">*</span>}
    </div>
  );
};

export default QuestionnaireLabel;
