import React from "react";

const LiteBoxContainer = ({ bg = "bg-white", addstyle, children, ...rest }) => {
  const additionalStyle = addstyle ?? "";

  return (
    <div
      className={`!border !border-liteBoxBorder shadow-boxContainer rounded-[25px] p-4 ${bg} ${additionalStyle}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default LiteBoxContainer;
