import React from "react";
import Loader from "../loader";

import "./styles.css";

const DefaultButton = (props) => {
  const sizeClasses = {
    extraSmall: "text-[12px] px-[17px] py-[9px]",
    small: "text-[12px] px-[25px] py-[8px] pt-[11px]",
    medium: "text-[14px] px-[31px] py-[11px]",
    large: "text-[18px] px-[39px] py-[13px]",
  };
  const radiusClasses = {
    none: "rounded-none",
    small: "rounded-sm",
    medium: "rounded-md",
    large: "rounded-lg",
    xl: "rounded-xl",
    full: "rounded-full",
  };
  const widthClasses = {
    max: "w-max",
    fifty: "w-[50%]",
    full: "w-full",
  };

  const buttonSize = sizeClasses[props?.size] || sizeClasses.large;
  const borderRadius = radiusClasses[props?.radius] || "rounded-[12px]";
  const width = widthClasses[props?.width] || "w-max";

  return (
    <button
      {...props}
      className={`bg-main text-white button-font hover:drop-shadow-lg transition-all capitalize ${buttonSize} ${borderRadius} ${width} whitespace-nowrap overflow-hidden text-ellipsis ${
        props?.disabled && "opacity-80 cursor-not-allowed"
      }`}
      title={props?.children}
    >
      {props?.children}
      {props?.loading && (
        <span className="ml-2">
          <Loader.ButtonLoader />
        </span>
      )}
    </button>
  );
};

export default DefaultButton;
