import React from "react";
import { FaAngleDown } from "react-icons/fa";

const StatusIndicator = ({
  color = "gray",
  text,
  showarrow = true,
  ...props
}) => {
  return (
    <div
      className={`font-semibold px-4 py-1 border rounded-3xl w-max capitalize cursor-pointer flex items-center gap-2 ${
        color?.toLowerCase() === "green"
          ? "bg-[#cbffd8]  border-green-600"
          : color?.toLowerCase() === "yellow"
          ? "bg-[#ffefaf] border-yellow-500"
          : color?.toLowerCase() === "red"
          ? "bg-[#ffd5d8] border-red-400"
          : color?.toLowerCase() === "blue"
          ? "bg-[#c3f2e9] border-emerald-500"
          : color?.toLowerCase() === "skyblue"
          ? "bg-[#c5e8fb] border-blue-400"
          : "bg-gray-200 border-gray-400"
      }`}
      {...props}
    >
      {text} {showarrow && <FaAngleDown />}
    </div>
  );
};

export default StatusIndicator;
