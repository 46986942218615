import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthAction, ProfileAction } from "../../store/actions";

import { Buttons, Spacer, inputFields } from "../../components";
import { Utils } from "../../config";

function ChangePassword() {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const [state, setState] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const defaultState = () => {
    setState({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const defaultError = () => {
    setError({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleChangePassword = () => {
    if (!state.password) {
      setError({
        ...error,
        password: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.password)) {
      setError({
        ...error,
        password: "Please enter a valid password",
      });
    } else if (!state.newPassword) {
      setError({
        ...error,
        password: "",
        newPassword: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.newPassword)) {
      setError({
        ...error,
        newPassword: "Please enter a valid password",
      });
    } else if (!state.confirmPassword) {
      setError({
        password: "",
        newPassword: "",
        confirmPassword: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: "Please enter a valid password",
      });
    } else if (state.newPassword !== state.confirmPassword) {
      setError({
        password: "",
        newPassword: "",
        confirmPassword: "Password did not match",
      });
    } else {
      defaultError();
      const body = {
        email: profileData.email,
        password: state.password,
        newPassword: state.confirmPassword,
      };
      dispatch(
        ProfileAction.changePassword(body, (res) => {
          if (res.status === 200) {
            defaultState();
            dispatch(AuthAction.Logout());
          }
        })
      );
    }
  };

  return (
    <div className="text-black px-4 py-2">
      <div className="header-md">Reset Password</div>
      <Spacer height={20} />
      <div className="text-[18px]">
        The password must be 8 character long and must include at least one
        special character and at least one number.
      </div>
      <Spacer height={20} />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <div className="header-sm">Current Password</div>
          <inputFields.DefaultInput
            type="password"
            width="400px"
            placeholder="********"
            value={state.password}
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
          />
          {error.password && (
            <div className="text-[14px] text-red-500">{error.password}</div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="header-sm">New Password</div>
          <inputFields.DefaultInput
            type="password"
            width="400px"
            placeholder="********"
            value={state.newPassword}
            onChange={(e) =>
              setState({
                ...state,
                newPassword: e.target.value,
              })
            }
          />
          {error.newPassword && (
            <div className="text-[14px] text-red-500">{error.newPassword}</div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="header-sm">Confirm Password</div>
          <inputFields.DefaultInput
            type="password"
            width="400px"
            placeholder="********"
            value={state.confirmPassword}
            onChange={(e) =>
              setState({
                ...state,
                confirmPassword: e.target.value,
              })
            }
          />
          {error.confirmPassword && (
            <div className="text-[14px] text-red-500">
              {error.confirmPassword}
            </div>
          )}
        </div>
      </div>
      <Spacer height={100} />
      <div className="flex justify-end mr-10">
        <Buttons.DefaultButton
          onClick={handleChangePassword}
          width="max"
          size="medium"
        >
          Save Changes
        </Buttons.DefaultButton>
      </div>

      <Spacer height={40} />
    </div>
  );
}

export default ChangePassword;
