import { Navigate } from "react-router-dom";

import {
  Home,
  UniversityGridView,
  UniversityListView,
  Compare,
  UniversityDetailsMain,
  Shortlists,
  AddApplication,
  Applications,
  // UpcomingMeetings,
  // MeetingsSent,
  // MeetingsReceived,
  AddMeeting,
  RescheduleMeeting,
  MeetingDetails,
  ProfileBuilder,
  Academics,
  Activities,
  Address,
  ContactProfileBuilder,
  Counselor,
  Demographics,
  Education,
  ExternalId,
  Facilities,
  Financial,
  GeneralInfo,
  Location,
  Others,
  OverallGPA,
  People,
  TestScores,
  VisaInformation,
  Essay,
  Recommender,
  RecommenderPreferences,
  RecommendersQualifications,
  RecommendersResults,
  RecommenderCompare,
  InteractiveLearning,
  Chatbot,
  Settings,
  Notifications,
  Login,
  ForgotPassword,
  VerifyOTP,
  ResetPassword,
  SignupGeneralInfo,
  PasswordForm,
  VerifySchoolOTP,
  Internships,
  Explore,
  ExploreResults,
  Support,
} from "../../containers";

export const routes = {
  protected: {
    home: "/home",

    universityGridView: "/universities/grid",
    universityListView: "/universities/list",
    studentUniversityCompare: "/universities/compare",

    exploreUniversities: "/explore",
    exploreResults: "/explore/results",

    universityGeneralDetails: "/universities/general/:id",
    universityAdmissionDetails: "/universities/admission/:id",
    universityProgramsDetails: "/universities/programs/:id",
    universityFinanceDetails: "/universities/finance/:id",
    universityApplicationJourneyDetails:
      "/universities/application-journey/:id",

    shortlists: "/shortlists",

    // upcomingMeetings: "/meetings/upcoming",
    // meetingsSent: "/meetings/sent",
    // meetingsReceived: "/meetings/received",
    addMeeting: "/meetings/add-meeting",
    rescheduleMeeting: "/meetings/reschedule-meeting",
    meetingdetails: "/meetings/meetingdetails",

    applications: "/applications",
    addapplication: "/applications/addapplication",

    internships: "/internships",

    profileBuilder: "/profile-builder",
    generalInfo: "/profile-builder/general-info",
    address: "/profile-builder/address",
    overalGpa: "/profile-builder/overall-gpa",
    education: "/profile-builder/education",
    testScores: "/profile-builder/test-scores",
    contacts: "/profile-builder/contacts",
    counselor: "/profile-builder/counselor",
    activities: "/profile-builder/activities",
    externalId: "/profile-builder/external-id",
    academics: "/profile-builder/academics",
    location: "/profile-builder/location",
    financial: "/profile-builder/financial",
    people: "/profile-builder/people",
    facilities: "/profile-builder/facilities",
    demographics: "/profile-builder/demographics",
    others: "/profile-builder/others",

    visaInformation: "/visa-information",

    essay: "/essay",

    recommender: "/recommender",
    recommenderPreferences: "/recommender/preferences",
    recommenderQualifications: "/recommender/qualifications",
    recommenderResults: "/recommender/results",
    recommenderCompare: "/recommender/compare",

    interactiveLearning: "/interactive-learning",

    chatbot: "/chatbot",

    notifications: "/notifications",

    settings: "/settings",

    support: "/support",
  },
  public: {
    // login
    login: "/login",

    // forgot pass
    resetPassword: "/reset-password",
    verifyOtp: "/verify-otp",
    forgotPassword: "/forgot-password",

    // signup
    generalInfo: "/signup/general-info",
    verifySchoolOTP: "/signup/verify-school-code",
    signupPassword: "/signup/password",
  },
};

export const PROTECTED_ROUTES = [
  // home
  {
    path: routes.protected.home,
    exact: false,
    component: <Home />,
  },
  // university
  {
    path: routes.protected.universityGridView,
    exact: false,
    component: <UniversityGridView />,
  },
  {
    path: routes.protected.universityListView,
    exact: false,
    component: <UniversityListView />,
  },
  {
    path: routes.protected.studentUniversityCompare,
    exact: false,
    component: <Compare />,
  },
  // explore
  {
    path: routes.protected.exploreUniversities,
    exact: false,
    component: <Explore />,
  },
  {
    path: routes.protected.exploreResults,
    exact: false,
    component: <ExploreResults />,
  },
  // uni details
  {
    path: routes.protected.universityGeneralDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityAdmissionDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityProgramsDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityFinanceDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityApplicationJourneyDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  // shortlists
  {
    path: routes.protected.shortlists,
    exact: false,
    component: <Shortlists />,
  },
  // meeting
  // {
  //   path: routes.protected.upcomingMeetings,
  //   exact: false,
  //   component: <UpcomingMeetings />,
  // },
  // {
  //   path: routes.protected.meetingsReceived,
  //   exact: false,
  //   component: <MeetingsReceived />,
  // },
  // {
  //   path: routes.protected.meetingsSent,
  //   exact: false,
  //   component: <MeetingsSent />,
  // },
  {
    path: routes.protected.addMeeting,
    exact: false,
    component: <AddMeeting />,
  },
  {
    path: routes.protected.rescheduleMeeting,
    exact: false,
    component: <RescheduleMeeting />,
  },
  {
    path: routes.protected.meetingdetails,
    exact: false,
    component: <MeetingDetails />,
  },
  // applications
  {
    path: routes.protected.applications,
    exact: false,
    component: <Applications />,
  },
  {
    path: routes.protected.addapplication,
    exact: false,
    component: <AddApplication />,
  },
  // internships
  {
    path: routes.protected.internships,
    exact: false,
    component: <Internships />,
  },
  // profile builder
  {
    path: routes.protected.profileBuilder,
    exact: false,
    component: <ProfileBuilder />,
  },
  {
    path: routes.protected.generalInfo,
    exact: false,
    component: <GeneralInfo />,
  },
  {
    path: routes.protected.address,
    exact: false,
    component: <Address />,
  },
  {
    path: routes.protected.overalGpa,
    exact: false,
    component: <OverallGPA />,
  },
  {
    path: routes.protected.education,
    exact: false,
    component: <Education />,
  },
  {
    path: routes.protected.testScores,
    exact: false,
    component: <TestScores />,
  },
  {
    path: routes.protected.contacts,
    exact: false,
    component: <ContactProfileBuilder />,
  },
  {
    path: routes.protected.counselor,
    exact: false,
    component: <Counselor />,
  },
  {
    path: routes.protected.activities,
    exact: false,
    component: <Activities />,
  },
  {
    path: routes.protected.externalId,
    exact: false,
    component: <ExternalId />,
  },
  {
    path: routes.protected.academics,
    exact: false,
    component: <Academics />,
  },
  {
    path: routes.protected.location,
    exact: false,
    component: <Location />,
  },
  {
    path: routes.protected.financial,
    exact: false,
    component: <Financial />,
  },
  {
    path: routes.protected.people,
    exact: false,
    component: <People />,
  },
  {
    path: routes.protected.facilities,
    exact: false,
    component: <Facilities />,
  },
  {
    path: routes.protected.demographics,
    exact: false,
    component: <Demographics />,
  },
  {
    path: routes.protected.others,
    exact: false,
    component: <Others />,
  },
  // visaInformation
  {
    path: routes.protected.visaInformation,
    exact: false,
    component: <VisaInformation />,
  },
  // automated essay grading
  {
    path: routes.protected.essay,
    exact: false,
    component: <Essay />,
  },
  // recommender
  {
    path: routes.protected.recommender,
    exact: false,
    component: <Recommender />,
  },
  {
    path: routes.protected.recommenderPreferences,
    exact: false,
    component: <RecommenderPreferences />,
  },
  {
    path: routes.protected.recommenderQualifications,
    exact: false,
    component: <RecommendersQualifications />,
  },
  {
    path: routes.protected.recommenderResults,
    exact: false,
    component: <RecommendersResults />,
  },
  {
    path: routes.protected.recommenderCompare,
    exact: false,
    component: <RecommenderCompare />,
  },
  // interactive learning
  {
    path: routes.protected.interactiveLearning,
    exact: false,
    component: <InteractiveLearning />,
  },
  // chatbot
  {
    path: routes.protected.chatbot,
    exact: false,
    component: <Chatbot />,
  },
  // notifications
  {
    path: routes.protected.notifications,
    exact: false,
    component: <Notifications />,
  },
  // settings
  {
    path: routes.protected.settings,
    exact: false,
    component: <Settings />,
  },
  // support
  {
    path: routes.protected.support,
    exact: false,
    component: <Support />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.protected.home} />,
  },
];

export const PUBLIC_ROUTES = [
  // login
  {
    path: routes.public.login,
    exact: false,
    component: <Login />,
  },
  // forgot pass
  {
    path: routes.public.forgotPassword,
    exact: false,
    component: <ForgotPassword />,
  },
  {
    path: routes.public.verifyOtp,
    exact: false,
    component: <VerifyOTP />,
  },
  {
    path: routes.public.resetPassword,
    exact: false,
    component: <ResetPassword />,
  },
  // signup
  {
    path: routes.public.generalInfo,
    exact: false,
    component: <SignupGeneralInfo />,
  },
  {
    path: routes.public.verifySchoolOTP,
    exact: false,
    component: <VerifySchoolOTP />,
  },
  {
    path: routes.public.signupPassword,
    exact: false,
    component: <PasswordForm />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.public.login} />,
  },
];
