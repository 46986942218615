import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { BiCalendar } from "react-icons/bi";

import { NotificationsAction } from "../../store/actions";

import { Colors } from "../../config";

export default function Notifications() {
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(NotificationsAction.getNotifications({}));
  }, []);

  const handleMarkAll = () => {
    const payload = {
      update: "all",
    };
    dispatch(
      NotificationsAction.changeNotificationsStatus(payload, () => {
        dispatch(NotificationsAction.getNotifications({}));
      })
    );
  };

  const handleMarkSingle = (notification) => {
    const payload = {
      notificationId: notification.id,
      update: "single",
    };
    dispatch(
      NotificationsAction.changeNotificationsStatus(payload, () => {
        dispatch(NotificationsAction.getNotifications({}));
      })
    );
  };

  return (
    <div className="text-black">
      <div className="flex justify-end mb-2">
        <div
          className="cursor-pointer text-[14px] font-medium"
          onClick={handleMarkAll}
        >
          Mark All as Read
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {notifications.notifications.map((notification) => {
          return (
            <div
              className="bg-gray-100 p-2 rounded-xl flex items-center"
              key={notification.id}
            >
              <div className="w-[5%] m-3">
                <BiCalendar fontSize={32} color={Colors.main} />
              </div>

              <div className="flex-1">
                <div className="header-xs">{notification.text}</div>
                <div className="text-[14px] font-semibold text-gray-500">
                  {moment(notification.createdAt).format("MMMM DD, yyyy")}
                </div>
              </div>
              <div className="w-[5%]">
                {notification.read === false && (
                  <div
                    onClick={() => handleMarkSingle(notification)}
                    className="h-3 w-3 rounded-full cursor-pointer bg-main"
                  ></div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
