import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CiCamera } from "react-icons/ci";

import { Buttons, inputFields, Spacer, Loader } from "../../components";
import { UtilityArrays, Utils } from "../../config";

import { ProfileAction, OthersAction } from "../../store/actions";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const fileSelect = useRef();

  const profileData = useSelector((state) => state.profile?.user);
  const profileLoading = useSelector((state) => state.profile?.isLoading);
  const { countries, isUploadLoading } = useSelector((state) => state.others);

  const [countryList, setCountryList] = useState([]);
  const [cameraIcon, setCameraIcon] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    country: "",
    linkedIn: "",
    grade: "",
    age: "",
    gender: "",
    about: "",
    profileImage: "",
  });

  useEffect(() => {
    const filteredCountries = countries?.map((country) => ({
      label: country?.name,
      value: country?.name,
    }));
    setCountryList(filteredCountries);
  }, [countries]);

  useEffect(() => {
    setState({
      firstName: profileData?.firstName ?? "",
      lastName: profileData?.lastName ?? "",
      phoneNumber: profileData?.phoneNumber ?? "",
      country: profileData?.country ?? null,
      linkedIn: profileData?.student?.linkedIn ?? "",
      grade: profileData?.student?.grade ?? null,
      age: profileData?.student?.age ?? null,
      gender: profileData?.student?.gender ?? null,
      about: profileData?.about ?? null,
      profileImage: profileData?.profileImage ?? "",
    });
  }, [profileData]);

  const uploadImage = (image) => {
    const formData = new FormData();
    formData.append("image", image);

    dispatch(
      OthersAction.uploadImage(formData, (res) => {
        if (res.status === 200) {
          setState({ ...state, profileImage: res.data });
        }
      })
    );
  };

  const handleReset = () => {
    dispatch(ProfileAction.getUserProfile());
  };

  const handleSubmit = () => {
    const body = {
      firstName: state?.firstName,
      lastName: state?.lastName,
      phoneNumber: state?.phoneNumber,
      profileImage: state?.profileImage,
      country: state?.country,
      linkedIn: state?.linkedIn,
      grade: state?.grade,
      age: state?.age,
      gender: state?.gender,
      about: state?.about,
    };

    dispatch(
      ProfileAction.editUserProfile(body, () => {
        dispatch(ProfileAction.getUserProfile());
      })
    );
  };

  return (
    <div className="text-black px-4 py-1">
      {profileLoading ? (
        <Loader.Circular />
      ) : (
        <>
          <div className="flex gap-3 items-center">
            <div
              className="h-[120px] w-[120px] rounded-full relative cursor-pointer border !border-main"
              onClick={() => {
                document.getElementById("getFile").click();
              }}
              onMouseEnter={() => setCameraIcon(true)}
              onMouseLeave={() => setCameraIcon(false)}
            >
              <input
                style={{ display: "none" }}
                type="file"
                name="image"
                id="getFile"
                ref={fileSelect}
                onChange={(event) => {
                  uploadImage(event.target.files[0]);
                }}
                accept=" .png, .jpg, .jpeg"
              />
              <img
                src={state?.profileImage}
                className="h-[118px] w-[118px] rounded-full object-contain p-1"
              />
              {cameraIcon && (
                <div className="absolute top-0 h-[120px] w-[120px] rounded-full flex justify-center items-center bg-black bg-opacity-50">
                  <CiCamera color="white" fontSize={50} />
                </div>
              )}
              {isUploadLoading && (
                <div className="absolute top-0 h-[120px] w-[120px] rounded-full flex justify-center items-center bg-black bg-opacity-50">
                  <Loader.Circular />
                </div>
              )}
            </div>
            <div className="header-sm">Upload Profile Picture</div>
          </div>
          <Spacer height={10} />

          <div className="grid grid-cols-2 gap-x-10 gap-y-3">
            <div className="flex flex-col gap-2">
              <div className="header-xs">First Name</div>
              <inputFields.DefaultInput
                value={state?.firstName}
                onChange={(e) =>
                  setState({ ...state, firstName: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Last Name</div>
              <inputFields.DefaultInput
                value={state?.lastName}
                onChange={(e) =>
                  setState({ ...state, lastName: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Phone Number</div>
              <inputFields.DefaultPhoneInput
                value={state?.phoneNumber}
                onChange={(e) => setState({ ...state, phoneNumber: e })}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Location</div>
              <inputFields.SelectDropdown
                value={state?.country}
                items={countryList}
                onChange={(e) => setState({ ...state, country: e })}
                placeholder="Select Your country"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">LinkedIn URL</div>
              <inputFields.DefaultInput
                value={state?.linkedIn}
                onChange={(e) =>
                  setState({ ...state, linkedIn: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Grades</div>
              <inputFields.SelectDropdown
                value={state?.grade}
                items={Utils.filterArray(UtilityArrays.gradeArray)}
                onChange={(e) => setState({ ...state, grade: e })}
                placeholder="Select Grade"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Age</div>
              <inputFields.SelectDropdown
                value={state?.age}
                items={Utils.filterArray(UtilityArrays.Ages)}
                onChange={(e) => setState({ ...state, age: e })}
                placeholder="Select Age"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="header-xs">Gender</div>
              <inputFields.SelectDropdown
                value={state?.gender}
                items={Utils.filterArray(UtilityArrays.genderArray)}
                onChange={(e) => setState({ ...state, gender: e })}
                placeholder="Select Gender"
              />
            </div>
            <div className="flex flex-col gap-2 col-span-2">
              <div className="header-xs">About Me</div>
              <inputFields.DefaultTextbox
                value={state?.about}
                onChange={(e) => setState({ ...state, about: e.target.value })}
                placeholder="Tell us more about yourself"
              />
            </div>
          </div>
        </>
      )}
      <Spacer height={20} />
      <div className="flex justify-end w-full">
        <div className="flex gap-3">
          <Buttons.WhiteButton size="medium" onClick={handleReset}>
            Reset Changes
          </Buttons.WhiteButton>
          <Buttons.DefaultButton
            size="medium"
            onClick={handleSubmit}
            loading={profileLoading}
            disabled={profileLoading || isUploadLoading}
          >
            Save Changes
          </Buttons.DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
