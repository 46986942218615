import {
  GET_INTERNSHIPS,
  GET_INTERNSHIPS_SUCCESS,
  GET_INTERNSHIPS_FAILURE,
} from "../constants";

export default class InternshipsAction {
  static getInternships(payload, cb) {
    return {
      type: GET_INTERNSHIPS,
      payload,
      cb,
    };
  }
  static getInternshipsSuccess(payload) {
    return {
      type: GET_INTERNSHIPS_SUCCESS,
      payload,
    };
  }
  static getInternshipsFailure(payload) {
    return {
      type: GET_INTERNSHIPS_FAILURE,
      payload,
    };
  }
}
