import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  boxContainer,
  questionnaireComponents,
  Loader,
} from "../../components";

import "./styles.css";

const People = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    sameUniAsClassmates: "",
    alumniRecommendation: "",
  });

  useEffect(() => {
    getQuestionnaire();
  }, [profileData]);

  useEffect(() => {
    setState({
      sameUniAsClassmates: questionnaire?.sameUniAsClassmates ?? "",
      alumniRecommendation: questionnaire?.alumniRecommendation ?? "",
    });
  }, [questionnaire]);

  const getQuestionnaire = () => {
    dispatch(QuestionnaireAction.getQuestionnaire());
  };

  const handleNext = () => {
    if (!state.sameUniAsClassmates || !state.alumniRecommendation) {
      message.error("Please fill all the required fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            navigate("/profile-builder/facilities");
          }
          getQuestionnaire();
        })
      );
    }
  };

  return (
    <div className="text-black">
      <div className="header-md pl-2 flex gap-2 items-center">People</div>
      <Spacer height="10px" />
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <boxContainer.LiteBoxContainer>
          <questionnaireComponents.QuestionnaireLabel
            text="1. How important is it for you to select the same university as your
            classmates?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.sameUniAsClassmates}
            handleChange={(e) => setState({ ...state, sameUniAsClassmates: e })}
          />
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="2. How important is your school alumni university recommendation for
           you?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.alumniRecommendation}
            handleChange={(e) =>
              setState({ ...state, alumniRecommendation: e })
            }
          />
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </boxContainer.LiteBoxContainer>
      )}
    </div>
  );
};

export default People;
