import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Empty } from "antd";

import {
  SortButton,
  Buttons,
  Spacer,
  popover,
  boxContainer,
  inputFields,
  university,
  GetProPopup,
  Loader,
} from "../../components";

import { UniversityAction, WatchlistAction } from "../../store/actions";

import "./styles.css";

const UniversityLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const boxRef = useRef();

  const viewMode = location.pathname.split("/")[2];

  const {
    topPicks,
    trending,
    popularInMyCountry,
    topBusiness,
    topMedicine,
    topLaw,
    topEngineering,
    topArtsAndHumanities,
    topPsychology,
    youMayLike,
    isGridLoading,
    count,
  } = useSelector((state) => state.university);
  const { watchlists } = useSelector((state) => state.watchlist);
  const profileData = useSelector((state) => state?.profile?.user);

  const [gridView, setGridView] = useState(viewMode === "grid");
  const [filteredWatchlist, setFilteredWatchlist] = useState([]);
  const [filteredTopPicks, setFilteredTopPicks] = useState([]);
  const [filteredTrending, setFilteredTrending] = useState([]);
  const [filteredPopular, setFilteredPopular] = useState([]);
  const [filteredTopBusiness, setFilteredTopBusiness] = useState([]);
  const [filteredTopMedicine, setFilteredTopMedicine] = useState([]);
  const [filteredTopLaw, setFilteredTopLaw] = useState([]);
  const [filteredTopEngineering, setFilteredTopEngineering] = useState([]);
  const [filteredTopArtsAndHumanities, setFilteredTopArtsAndHumanities] =
    useState([]);
  const [filteredTopPsychology, setFilteredTopPsychology] = useState([]);
  const [filteredYouMayLike, setFilteredYouMayLike] = useState([]);

  useEffect(() => {
    dispatch(UniversityAction.getUniversitiesGrid({}));
  }, [gridView]);

  useEffect(() => {
    dispatch(WatchlistAction.getWatchlist({}));
  }, []);

  useEffect(() => {
    if (watchlists.length > 0) {
      const watchlistedIds = new Set(
        watchlists.map((watchlist) => watchlist?.id)
      );

      const uniFilterTopPick = topPicks?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTrending = trending?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterPopular = popularInMyCountry?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTopBusiness = topBusiness?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTopMedicine = topMedicine?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTopLaw = topLaw?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTopEngineering = topEngineering?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterTopArtsAndHumanities = topArtsAndHumanities?.map(
        (university) => ({
          ...university,
          watchlisted: watchlistedIds.has(university.id),
        })
      );
      const uniFilterTopPsychology = topPsychology?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));
      const uniFilterYouMayLike = youMayLike?.map((university) => ({
        ...university,
        watchlisted: watchlistedIds.has(university.id),
      }));

      setFilteredTopPicks(uniFilterTopPick);
      setFilteredTrending(uniFilterTrending);
      setFilteredPopular(uniFilterPopular);
      setFilteredTopBusiness(uniFilterTopBusiness);
      setFilteredTopMedicine(uniFilterTopMedicine);
      setFilteredTopLaw(uniFilterTopLaw);
      setFilteredTopEngineering(uniFilterTopEngineering);
      setFilteredTopArtsAndHumanities(uniFilterTopArtsAndHumanities);
      setFilteredTopPsychology(uniFilterTopPsychology);
      setFilteredYouMayLike(uniFilterYouMayLike);
      setFilteredWatchlist(
        watchlists.map((watchlist) => ({
          ...watchlist,
          watchlisted: true,
        }))
      );
    } else {
      setFilteredTopPicks(topPicks);
      setFilteredTrending(trending);
      setFilteredPopular(popularInMyCountry);
      setFilteredTopBusiness(topBusiness);
      setFilteredTopMedicine(topMedicine);
      setFilteredTopLaw(topLaw);
      setFilteredTopEngineering(topEngineering);
      setFilteredTopArtsAndHumanities(topArtsAndHumanities);
      setFilteredTopPsychology(topPsychology);
      setFilteredYouMayLike(youMayLike);
    }
  }, [
    watchlists,
    topPicks,
    trending,
    popularInMyCountry,
    topBusiness,
    topMedicine,
    topLaw,
    topEngineering,
    topArtsAndHumanities,
    topPsychology,
    youMayLike,
  ]);

  const handleViewChange = (isGridView) => {
    setGridView(isGridView);
    navigate(isGridView ? "/universities/grid" : "/universities/list");
  };

  const renderButton = (isGridView, label) =>
    isGridView ? (
      <Buttons.DefaultButton
        size="small"
        onClick={() => handleViewChange(isGridView)}
      >
        {label}
      </Buttons.DefaultButton>
    ) : (
      <Buttons.WhiteButton
        size="small"
        onClick={() => handleViewChange(isGridView)}
      >
        {label}
      </Buttons.WhiteButton>
    );

  const filterButtons = [
    "Countries",
    "Area of Study",
    "Budget",
    "Scholarships",
  ];

  return (
    <div className="universities">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Buttons.DefaultButton
            size="small"
            onClick={() => navigate("/universities/list")}
          >
            All
          </Buttons.DefaultButton>
          {/* <Buttons.DefaultButton
            size="small"
            onClick={() => navigate("/universities/list")}
          >
            University Tags
          </Buttons.DefaultButton> */}
        </div>
      </div>
      <Spacer height={20} />

      <div className="flex items-center justify-center gap-2">
        <inputFields.DefaultSearchBox
          onClick={() => navigate("/universities/list")}
        />
        <div className="w-[120px]">
          <Buttons.DefaultButton
            size="medium"
            width="full"
            style={{ height: "48px" }}
            onClick={() => navigate("/universities/list")}
          >
            Search
          </Buttons.DefaultButton>
        </div>
        <div className="w-[120px]">
          <Buttons.RedButton
            style={{ height: "48px" }}
            size="medium"
            onClick={() => navigate("/explore")}
          >
            Explore
          </Buttons.RedButton>
        </div>
      </div>
      <Spacer height={20} />
      <div className="flex gap-2">
        {filterButtons?.map((item, index) => (
          <Buttons.DefaultButton
            key={index}
            size="small"
            onClick={() => navigate("/universities/list")}
          >
            {item}
          </Buttons.DefaultButton>
        ))}
      </div>
      <Spacer height={20} />
      <boxContainer.UniversityBoxContainer>
        <div className="flex justify-between border-b border-b-liteBoxBorder px-4 py-3">
          <div className="flex items-center gap-3 ">
            <div className="font-bold text-[20px] pl-2">
              {count.toLocaleString()} Universities
            </div>
            {renderButton(true, "Grid View")}
            {renderButton(false, "List View")}
          </div>
          {!gridView && (
            <popover.DefaultPopover content={columnFilter}>
              <SortButton />
            </popover.DefaultPopover>
          )}
        </div>
        <div className="p-4 relative">
          {profileData?.freeUser && (
            <div className="absolute top-[900px] left-[35%] z-[10]">
              <GetProPopup />
            </div>
          )}
          <div className="header-sm pl-3">Trending Universities</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isGridLoading ? (
              <Loader.Circular />
            ) : filteredTrending?.length === 0 ? (
              <Empty />
            ) : (
              <div ref={boxRef}>
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTrending}
                />
              </div>
            )}
          </boxContainer.LiteBoxContainer>
          <Spacer height={10} />
          <div className="header-sm pl-3">UniVision Top Picks for You</div>
          <Spacer height={10} />
          <boxContainer.LiteBoxContainer>
            {isGridLoading ? (
              <Loader.Circular />
            ) : filteredTopPicks?.length === 0 ? (
              <Empty />
            ) : (
              <university.GridViewSwiper
                width={boxRef?.current?.clientWidth}
                data={filteredTopPicks}
                shownumber={true}
              />
            )}
          </boxContainer.LiteBoxContainer>
          <Spacer height={10} />

          <div
            className={`${
              profileData?.freeUser && "blur-lg pointer-events-none"
            }`}
          >
            <div className="header-sm pl-3">
              Popular Universities in Your Country
            </div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredPopular?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredPopular}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">Top Business Schools</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopBusiness?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopBusiness}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">Top Medicine Schools</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopMedicine?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopMedicine}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">Top Law Schools</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopLaw?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopLaw}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">
              Top Engineering and Technology Schools
            </div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopEngineering?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopEngineering}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">
              Top Arts and Humanities Schools
            </div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopArtsAndHumanities?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopArtsAndHumanities}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">Top Psychology Schools</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredTopPsychology?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredTopPsychology}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">Universities You May Like</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredYouMayLike?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredYouMayLike}
                />
              )}
            </boxContainer.LiteBoxContainer>
            <Spacer height={10} />
            <div className="header-sm pl-3">My Shortlist</div>
            <Spacer height={10} />
            <boxContainer.LiteBoxContainer>
              {isGridLoading ? (
                <Loader.Circular />
              ) : filteredWatchlist?.length === 0 ? (
                <Empty />
              ) : (
                <university.GridViewSwiper
                  width={boxRef?.current?.clientWidth}
                  data={filteredWatchlist}
                />
              )}
            </boxContainer.LiteBoxContainer>
          </div>
        </div>
        <Spacer height={30} />
      </boxContainer.UniversityBoxContainer>
    </div>
  );
};

export default UniversityLayout;
