import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { QuestionnaireAction } from "../../store/actions";

import {
  Buttons,
  Spacer,
  questionnaireComponents,
  Loader,
} from "../../components";

import "./styles.css";

const People = ({ setPage }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);
  const { questionnaire, isLoading } = useSelector(
    (state) => state?.questionnaire
  );

  const [state, setState] = useState({
    sameUniAsClassmates: "",
    alumniRecommendation: "",
  });

  useEffect(() => {
    setState({
      sameUniAsClassmates: questionnaire?.sameUniAsClassmates ?? "",
      alumniRecommendation: questionnaire?.alumniRecommendation ?? "",
    });
  }, [questionnaire]);

  const handleNext = () => {
    if (!state.sameUniAsClassmates || !state.alumniRecommendation) {
      message.error("Please fill all the required fields.");
    } else {
      const payload = { userId: profileData?.id, ...state };

      dispatch(
        QuestionnaireAction.addQuestionnaire(payload, (res) => {
          if (res.status === 200) {
            setPage("Facilities");
          }
        })
      );
    }
  };

  return (
    <div className="text-black">
      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <questionnaireComponents.QuestionnaireLabel
            text="1. How important is it for you to select the same university as your
            classmates?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.sameUniAsClassmates}
            handleChange={(e) => setState({ ...state, sameUniAsClassmates: e })}
          />
          <Spacer height="24px" />
          <questionnaireComponents.QuestionnaireLabel
            text="2. How important is your school alumni university recommendation for
           you?"
            required
          />
          <Spacer height="10px" />
          <questionnaireComponents.QuestionnaireCircleInput
            value={state?.alumniRecommendation}
            handleChange={(e) =>
              setState({ ...state, alumniRecommendation: e })
            }
          />
          <Spacer height="20px" />
          <div className="flex justify-end">
            <div className="w-[200px]">
              <Buttons.DefaultButton
                onClick={handleNext}
                size="large"
                width="full"
              >
                Next
              </Buttons.DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default People;
