import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import dayjs from "dayjs";

import { Colors, Images } from "../../config";

import "./styles.css";

const DefaultDatepicker = (props) => {
  const date = props?.datevalue ? dayjs(props?.datevalue, "YYYY-MM-DD") : null;

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            activeBorderColor: Colors.main,
            activeShadow: "none",
            hoverBorderColor: Colors.main,
            borderRadius: 10,
            controlHeight: 48,
          },
        },
        token: {
          colorBorder: Colors.main,
          colorPrimary: Colors.main,
          paddingSM: 16,
          fontSize: 16,
        },
      }}
    >
      <DatePicker
        suffixIcon={<img src={Images.DatePickerAddApplication} />}
        value={date}
        className={`w-full ${
          props?.disabled ? "default-datepicker-disbled" : "default-datepicker"
        } `}
        {...props}
      />
    </ConfigProvider>
  );
};

export default DefaultDatepicker;
