import {
  GET_INTERNSHIPS,
  GET_INTERNSHIPS_SUCCESS,
  GET_INTERNSHIPS_FAILURE,
} from "../constants";

const initialState = {
  internships: [],
  isLoading: false,
  error: null,
};

export default function InternshipsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INTERNSHIPS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_INTERNSHIPS_SUCCESS:
      state = {
        ...state,
        internships: action.payload,
        isLoading: false,
      };
      break;
    case GET_INTERNSHIPS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
