import {
  RETRIEVE_SESSION,
  RETRIEVE_SESSION_SUCCESS,
  RETRIEVE_SESSION_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CREATE_CHECKOUT_SESSION_MONTHLY,
  CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS,
  CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE,
  CREATE_CHECKOUT_SESSION_ONETIME,
  CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS,
  CREATE_CHECKOUT_SESSION_ONETIME_FAILURE,
  UPDATE_SHOW_TRIAL_MODAL,
  UPDATE_SHOW_TRIAL_MODAL_SUCCESS,
  UPDATE_SHOW_TRIAL_MODAL_FAILURE,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
};

export default function IndividualStudentReducer(state = initialState, action) {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION:
    case RETRIEVE_SESSION:
    case CREATE_CHECKOUT_SESSION_MONTHLY:
    case CREATE_CHECKOUT_SESSION_ONETIME:
    case UPDATE_SHOW_TRIAL_MODAL:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case CANCEL_SUBSCRIPTION_SUCCESS:
    case RETRIEVE_SESSION_SUCCESS:
    case CREATE_CHECKOUT_SESSION_MONTHLY_SUCCESS:
    case CREATE_CHECKOUT_SESSION_ONETIME_SUCCESS:
    case UPDATE_SHOW_TRIAL_MODAL_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case CANCEL_SUBSCRIPTION_FAILURE:
    case RETRIEVE_SESSION_FAILURE:
    case CREATE_CHECKOUT_SESSION_MONTHLY_FAILURE:
    case CREATE_CHECKOUT_SESSION_ONETIME_FAILURE:
    case UPDATE_SHOW_TRIAL_MODAL_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
