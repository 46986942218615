import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Avatar } from "antd";
import { IoCloudDownloadOutline } from "react-icons/io5";

import { Images } from "../../config";

import { Modal, Spacer } from "..";

import "./styles.css";

const MeetingRow = (props) => {
  const meetingData = props?.item;

  const profileData = useSelector((state) => state.profile?.user);

  const [meetingModal, setMeetingModal] = useState(false);

  const modalData = [
    {
      title: "Subject",
      value: meetingData?.subject,
    },
    {
      title: "Date",
      value: moment(meetingData?.meetingDate).format("DD MMMM, YYYY"),
    },
    { title: "Time", value: meetingData?.timeSlot },
    {
      title: "Meeting Type",
      value: meetingData?.meetingType === "online" ? "Online" : "In Person",
    },
    {
      title: "Message",
      value: meetingData?.message,
    },
    {
      title:
        meetingData?.meetingType === "online"
          ? "Meeting Link"
          : "Meeting Place",
      value:
        meetingData?.meetingType === "online" ? (
          <a
            href={meetingData?.meetingLink}
            target="_blank"
            className="overflow-hidden text-ellipsis"
          >
            {meetingData?.meetingLink}
          </a>
        ) : (
          <div>{meetingData?.meetingLink}</div>
        ),
    },
  ];

  return (
    <div key={props.index} className="meeting-row" {...props}>
      <div className="flex gap-2 w-full">
        <div className="meetingrow-date-container">
          <div
            className={`text-[20px] text-center font-bold uppercase ${
              props?.index === 0 ? "text-red-600" : "text-main"
            }`}
          >
            {moment(meetingData?.meetingDate).format("ddd")}
          </div>
          <div
            className={`text-[18px] font-bold ${
              props?.index === 0 ? "text-red-600" : "text-main"
            }`}
          >
            {moment(meetingData?.meetingDate).format("DD MMM")}
          </div>
        </div>
        <div className="flex flex-col justify-center items-start gap-2">
          <div className="flex gap-3 justify-center items-center">
            <img src={Images.UpcomingMeetingsClock} />
            <div className="meeting-row-heading">{meetingData?.timeSlot}</div>
          </div>
          <div className="flex gap-3 justify-center items-center">
            <img src={Images.UpcomingMeetingsLocation} />
            <div className="meeting-row-heading capitalize">
              {meetingData?.meetingType === "online" ? "Online" : "In Person"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-2">
        <div className="meeting-row-heading">{meetingData?.subject}</div>
        <Avatar.Group>
          <Avatar src={profileData?.profileImage} />
          <Avatar src={meetingData?.userSchool?.profileImage} />
        </Avatar.Group>
      </div>

      <div className="flex justify-end items-center cursor-pointer">
        <img
          src={Images.RightArrowCircled}
          onClick={() => setMeetingModal(true)}
        />
      </div>

      <Modal.DefaultModal
        open={meetingModal}
        onCancel={() => setMeetingModal(false)}
        closable
      >
        <div className="flex flex-col items-center justify-center w-[600px] p-4">
          <div className="text-[26px] font-bold">Meeting Details</div>
          <Spacer height={20} />
          <div className="flex flex-col gap-2 w-full">
            {modalData?.map((item, index) => (
              <div className="grid grid-cols-2" key={index}>
                <div className="text-left font-semibold text-[18px] capitalize">
                  {item?.title}
                </div>
                <div className="text-right font-medium text-[18px] capitalize">
                  {item?.value}
                </div>
              </div>
            ))}
          </div>
          <Spacer height={20} />
          {meetingData?.meetingFile && (
            <div className="w-full flex flex-col gap-2">
              <div className="text-left font-semibold text-[18px]">
                Meeting File
              </div>
              <div
                className="border border-dashed border-main rounded-xl p-4 flex justify-center items-center flex-col gap-3 w-full cursor-pointer"
                onClick={() => window.open(meetingData?.meetingFile, "_blank")}
              >
                <IoCloudDownloadOutline className="text-[50px] text-main" />
                <div className="text-[18px] text-main font-semibold">
                  Click Here to Download
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.DefaultModal>
    </div>
  );
};

export default MeetingRow;
