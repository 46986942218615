import { put } from "redux-saga/effects";
import { message } from "antd";

import { ApiCaller } from "../../config";
import { ApplicationAction } from "../actions";
import { DefaultNotification } from "../../components";

export default class ApplicationMiddleware {
  static *addApplications({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/school/add-applications`, payload);
      if (res.status === 200) {
        yield put(ApplicationAction.addApplicationsSuccess(res.data.data));
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(ApplicationAction.addApplicationsFailure());
        message.error(res?.data?.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ApplicationAction.addApplicationsFailure());
    }
  }
  static *changeApplicationStatus({ payload, cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/school/change-application-status`,
        payload
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.changeApplicationStatusSuccess(res.data.data)
        );
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(ApplicationAction.changeApplicationStatusFailure());
        message.error(res?.data?.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ApplicationAction.changeApplicationStatusFailure());
    }
  }

  static *getOngoingApplications({ payload, cb }) {
    try {
      const { limitOngoing, offsetOngoing } = payload;
      const res = yield ApiCaller.Get(
        `/school/ongoing-applications-student?limit=${
          limitOngoing ?? ""
        }&offset=${offsetOngoing ?? ""}`
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.getOngoingApplicationsSuccess(res.data.data)
        );
      } else {
        yield put(ApplicationAction.getOngoingApplicationsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ApplicationAction.getOngoingApplicationsFailure());
    }
  }
  static *getSubmittedApplications({ payload, cb }) {
    try {
      const { limitSubmitted, offsetSubmitted } = payload;
      const res = yield ApiCaller.Get(
        `/school/submitted-applications-student?limit=${
          limitSubmitted ?? ""
        }&offset=${offsetSubmitted ?? ""}`
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.getSubmittedApplicationsSuccess(res.data.data)
        );
      } else {
        yield put(ApplicationAction.getSubmittedApplicationsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ApplicationAction.getSubmittedApplicationsFailure());
    }
  }
  static *uploadApplicationProof({ payload, cb }) {
    try {
      const res = yield ApiCaller.Put(
        `/school/upload-application-proof`,
        payload
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.uploadApplicationProofSuccess(res.data.data)
        );
        DefaultNotification(res?.data?.message, "success", "top");
      } else {
        yield put(ApplicationAction.uploadApplicationProofFailure());
        message.error(res?.data?.message);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(ApplicationAction.uploadApplicationProofFailure());
    }
  }
}
