const ethnicityArray = [
  "Arab - Middle Eastern",
  "Arab - North African",
  "Asian - Afghan",
  "Asian - Bangladeshi",
  "Asian - Central",
  "Asian - Chinese",
  "Asian - Filipino",
  "Asian - Indian",
  "Asian - Indonesian",
  "Asian - Japanese",
  "Asian - Kurdish",
  "Asian - Malay",
  "Asian - Maldivian",
  "Asian - Other Background",
  "Asian - Pakistani",
  "Asian - Persian",
  "Asian - Singaporian",
  "Asian - Sri Lankan",
  "Asian - Thai",
  "Berbers - North African",
  "Black - African",
  "Black - American",
  "Black - British",
  "Black - Caribbean",
  "Black - Nigerian",
  "Black - Other Background",
  "Hispanic / Latino",
  "Indian Carribean",
  "Other Ethnicity",
  "Pacific Islander",
  "Turkish",
  "White - American",
  "White - Australian",
  "White - British",
  "White - Canadian",
  "White - European",
  "White - Hispanic",
  "White - Other Background",
];
export default ethnicityArray;
