import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, ConfigProvider } from "antd";

import { AuthAction } from "../../store/actions";

import { AiOutlineUser } from "react-icons/ai";
import { Colors, Images } from "../../config";

import Spacer from "../spacer";

export const ProfileDropDown = (props) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.user);

  const handleLogout = () => {
    dispatch(AuthAction.Logout());
  };

  const dropdownAccountItems = [
    {
      image: Images.ProfileDropdownoverview,
      title: "Profile",
      route: "/settings?tab=account-settings",
    },
    {
      image: Images.ProfileDropdownconfiguration,
      title: "Security Settings",
      route: "/settings?tab=security-settings",
    },
    // {
    //   image: Images.ProfileDropdowncommunity,
    //   title: "Community",
    //   route: "/",
    // },
    // {
    //   image: Images.ProfileDropdowngettingstarted,
    //   title: "Getting Started",
    //   route: "/",
    // },
  ];
  const dropdownHelpItems = [
    {
      image: Images.ProfileDropdownhelpcenter,
      title: "Help Center",
      route: "/support?tab=contact-us",
    },
    {
      image: Images.ProfileDropdownchat,
      title: "Chat With UniVision",
      route: "/chatbot?tab=support",
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <div className="profile-dropdown-container">
          <div className="flex flex-col gap-2">
            <div className="profile-dropdown-item">
              <img
                src={profileData?.profileImage}
                className="rounded-full w-[60px] h-[60px] object-cover"
              />
              <div className="text-main text-[20px] font-semibold">
                {profileData?.firstName} {profileData?.lastName}
              </div>
            </div>
          </div>
          <div className="profile-dropdown-divider"></div>
          <div>
            <div className="profile-dropdown-item-text font-bold">Account</div>
            <Spacer height={20} />
            <div className="profile-dropdown-item-container">
              {dropdownAccountItems?.map((item, index) => (
                <Link
                  to={item?.route}
                  className="profile-dropdown-item"
                  key={index}
                >
                  <img
                    src={item?.image}
                    className="profile-dropdown-item-image"
                  />
                  <div className="profile-dropdown-item-text">
                    {item?.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="profile-dropdown-divider"></div>
          <div>
            <div className="profile-dropdown-item-text font-bold">Help</div>
            <Spacer height={20} />
            <div className="profile-dropdown-item-container">
              {dropdownHelpItems?.map((item, index) => (
                <Link
                  to={item?.route}
                  className="profile-dropdown-item"
                  key={index}
                >
                  <img
                    src={item?.image}
                    className="profile-dropdown-item-image"
                  />
                  <div className="profile-dropdown-item-text">
                    {item?.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="profile-dropdown-divider"></div>
          <div className="profile-dropdown-item" onClick={handleLogout}>
            <img src={Images.ProfileDropdownlogout} />
            <div className="profile-dropdown-item-text font-bold">Log out</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          controlItemBgHover: "white",
        },
      }}
    >
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        overlayStyle={{
          top: "80px",
          border: `1px solid ${Colors.main}`,
          borderRadius: "8px",
          height: "max-content",
        }}
        className="profile"
        arrow={false}
      >
        {props?.profileData?.profileImage ? (
          <img
            src={props?.profileData?.profileImage}
            alt="user"
            className="navbar-profile-photo"
          />
        ) : (
          <AiOutlineUser className="navbar-default-photo" />
        )}
      </Dropdown>
    </ConfigProvider>
  );
};
