import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import "./styles.css";
import { Images } from "../../config";

const TopStoriesCard = (props) => {
  const data = props?.data;

  return (
    <Link to={data?.url} target="_blank">
      <div className="rounded-[20px] border border-liteBoxBorder shadow-boxContainer flex">
        <div className="w-[250px] h-[200px] p-4 flex flex-col gap-3">
          <div className="text-red-600 font-semibold text-[18px]">
            Education
          </div>
          <div
            className="text-[16px] font-semibold truncate-title"
            title={data?.title}
          >
            {data?.title}
          </div>
          <div className="text-medium text-[14px] opacity-80">
            {data?.publishedAt
              ? moment(data?.publishedAt).format("DD-MMM-YY")
              : ""}
          </div>
          <div
            className="text-medium text-[14px] opacity-80"
            title={data?.author}
          >
            {data?.author?.length > 50
              ? `${data?.author?.slice(0, 50)}...`
              : data?.author}
          </div>
        </div>
        <div className="h-[200px] w-[200px]">
          <img
            src={data?.urlToImage ?? Images?.TopStoriesDefault}
            className="h-full w-full object-cover rounded-r-[20px]"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = Images?.TopStoriesDefault;
            }}
          />
        </div>
      </div>
    </Link>
  );
};

export default TopStoriesCard;
